import API from "@/apis";
import useApplyEmojiGroup from "@/hooks/useApplyEmojiGroup";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { IEmoji, IHomeData, IHomeDataEmoji } from "@/models";
import { emojiListState } from "@/store";
import { setInfo2Map } from "@/utils";
import { Drawer, Modal, Popover } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useLongPress } from "use-long-press";
import DiscordEmoji from "../DiscordEmoji";
import EmojiPicker from "../EmojiPicker";
import IconFont from "../IconFont";

const defaultEmojiCodes = ["LOL", "Boring", "WTF", "Helpful", "Useful", "Tasty", "Cute", "Mad"];

const handleVibrate = () => {
  if ("vibrate" in window.navigator) {
    window.navigator.vibrate(60);
  }
};

interface INoteEmoji {
  emoji: IEmoji;
  count: number;
  index: number;
  clicked: boolean;
}
interface NoteEmojisProps {
  emoji?: IHomeDataEmoji[];
  dark?: boolean;
  pcMaxWidth?: number;
  postDetail: IHomeData;
  showAnimation?: boolean;
}

const NoteEmojis = (props: NoteEmojisProps) => {
  const emojiListValue = useRecoilValue(emojiListState);
  const [emojiList, setEmojiList] = useState<Array<INoteEmoji>>([]);
  const [showAllEmojis, setShowAllEmojis] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  // apply的表情组
  const { applyedEmojiGroup } = useApplyEmojiGroup();

  const applyEmojiGroupMap = setInfo2Map(applyedEmojiGroup);

  const bindLongPress = useLongPress(() => {
    setShowAllEmojis(true);
  });

  useLayoutEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [showAllEmojis]);

  useEffect(() => {
    const emojis = props.emoji || [];
    if (emojiListValue.length > 8) {
      let res: INoteEmoji[] = [];
      emojiListValue.forEach((i, index) => {
        const code = i.code;
        const noteEmoji = emojis.find((e) => e.emoji_code === code);
        if (noteEmoji && noteEmoji?.emoji_num) {
          res.push({
            emoji: i,
            count: noteEmoji?.emoji_num || 0,
            index: index,
            clicked: false,
          });
        } else {
          if (defaultEmojiCodes.includes(code)) {
            res.push({
              emoji: i,
              count: 0,
              index: index,
              clicked: false,
            });
          }
        }
        // return ;
      });
      // 排序，大的在前面
      res.sort((a, b) => {
        return a.count > b.count ? -1 : 0;
      });
      setEmojiList(res);
    }
  }, [props.emoji, emojiListValue]);

  const handleClickSelectedEmoji = (data: INoteEmoji) => async () => {
    handleVibrate();
    const newEmojiList = emojiList.map((item) => {
      if (item.emoji.code === data.emoji.code) {
        return {
          ...data,
          clicked: !data.clicked,
          count: data.clicked ? item.count - 1 : item.count + 1,
        };
      } else {
        return item;
      }
    });
    setEmojiList([...newEmojiList]);
    API.postEmoji(props.postDetail.id, data.emoji.code);
    return 0;
  };

  const handleClickAdd = () => {
    if (isLargerThan414) {
      setVisible(true);
    } else {
      setDrawerVisible(true);
    }
  };

  const cls = showAllEmojis
    ? `w-full flex items-center flex-wrap ${isLargerThan414 ? "justify-start" : "justify-around"
    } px-1 pt-1 xs:py-2`
    : "w-full flex items-center overflow-x-scroll px-1 justify-start pt-1 xs:py-2";

  const maxWidth = props.pcMaxWidth || 188;

  return (
    <div
      className={`relative min-h-[38px] sm:min-h-[50px] ${props.dark ? "dark-theme" : ""}`}
      style={{ maxWidth: showAllEmojis ? "inherit" : isLargerThan414 ? maxWidth : 228 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={cls} style={{ paddingRight: showAllEmojis ? 0 : isLargerThan414 ? 40 : 30 }}>
        {emojiList.map((item, index) => {
          // const EmojiView = slide-in-bottom
          // const EmojiView = slide-in-bottom
          return (
            <DiscordEmoji
              width={24}
              height={24}
              onClick={handleClickSelectedEmoji(item)}
              emoji={item.emoji}
              applyedEmoji={applyEmojiGroupMap[item.emoji.code]}
              clicked={item.clicked}
              count={item.count}
              key={item.emoji.code}
              dark={props.dark}
              showName={showAllEmojis && isLargerThan414}
              customClass={
                props.showAnimation && !showAllEmojis && index <= 2 ? "slide-in-bottom" : "0"
              }
            />
          );
        })}

        {showAllEmojis ? (
          <div
            className="mr-1 mb-1 px-1 cursor-pointer flex items-center"
            onClick={() => setShowAllEmojis(false)}
          >
            {/* {props.dark ? (
              <EmojiBackIcon color="#ffffff" size={24} />
            ) : (
              <img src={IMG_EMOJI_ADD} alt="" className="w-[18px] h-[18px] no-user-select" />
            )} */}
            <IconFont type="icon-arrow" style={{ fontSize: 16, transform: "rotate(180deg)" }} />
            {/* <EmojiBackIcon color="#2F2F2F" size={18} /> */}
          </div>
        ) : null}
      </div>
      {showAllEmojis ? null : (
        <>
          {isLargerThan414 ? (
            <div>
              <div className="note-right-arrow-pc absolute right-0 top-0 cursor-pointer">
                <Popover
                  title={null}
                  content={
                    <div className="bg-slate-700 text-white text-center text-xs">
                      <div>Press to Add Reaction</div>
                      <div>Hold to Show All Reactions</div>
                    </div>
                  }
                  trigger="hover"
                  className="home-emoji-popover"
                  overlayClassName="home-emoji-popover"
                >
                  <div
                    className="flex items-center justify-center flex-col relative cursor-pointer "
                    style={{ backgroundColor: props.dark ? "#36393F" : "#fff" }}
                    {...bindLongPress()}
                    onClick={handleClickAdd}
                  >
                    <div className="w-5 h-5 min-w-[16px] flex items-center justify-center">
                      <IconFont type="icon-add" style={{ fontSize: 18 }} />
                    </div>
                    {/* {props.dark ? (
                      <EmojiBackIcon color="#ffffff" size={24} />
                    ) : (
                      <div className="w-5 h-5 min-w-[16px] flex items-center justify-center">
                        <img src={IMG_EMOJI_ADD} alt="" className="w-[18px] h-[18px]" />
                      </div>
                    )} */}
                  </div>
                </Popover>
              </div>
              <Modal
                footer={null}
                className="post-modal"
                visible={visible}
                onCancel={() => setVisible(false)}
                title="Emojis/GIFs/Stickers"
                closeIcon={
                  <div className="flex items-center justify-center w-14 h-14">
                    <div className="flex items-center justify-center w-8 h-8">
                      <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
                    </div>
                  </div>
                }
              >
                <div>
                  <EmojiPicker creatable type="pc" />
                </div>
              </Modal>
            </div>
          ) : (
            // </Popover>
            <>
              <Drawer
                closable={false}
                extra={<div onClick={() => setDrawerVisible(false)}>Close</div>}
                placement="bottom"
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
                className="detail-drawer"
                height={372}
                title="Emojis/GIFs/Stickers"
              >
                <EmojiPicker type="mobile" creatable />
              </Drawer>
              <div
                className="note-right-arrow absolute right-[-2px] top-0.5 cursor-pointer"
                {...bindLongPress()}
              >
                <div
                  className=" flex items-center justify-center flex-col px-1 sm:px-2 relative pt-0.5"
                  style={{ backgroundColor: props.dark ? "#36393F" : "#fff" }}
                  onClick={() => setDrawerVisible(true)}
                >
                  <div className="w-5 h-5 min-w-[16px] flex items-center justify-center">
                    <IconFont type="icon-add" style={{ fontSize: 16 }} />
                  </div>
                  {/* {props.dark ? (
                    <EmojiBackIcon color="#ffffff" size={24} />
                  ) : (
                    <img src={IMG_EMOJI_ADD} alt="" className="w-[18px] h-[18px] no-user-select" />
                  )} */}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default NoteEmojis;
