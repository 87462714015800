import API from "@/apis";
import { IHomeData, UserPostType } from "@/models";
import { userInfoState } from "@/store";
import { Masonry } from "@mui/lab";
import { Empty } from "antd";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { WaterfallItem } from "../Waterfall";

const UserNoteList = (props: { id: number; type: UserPostType }) => {
  const [list, setList] = useState<IHomeData[]>([]);
  const [page, setPage] = useState<number>(1);
  const userInfo = useRecoilValue(userInfoState);
  useEffect(() => {
    const fetchList = async (id: number, type: UserPostType) => {
      if (type === UserPostType.Liked) {
        const data = await API.getMyLikedPosts({ page, size: 200 });

        if (Array.isArray(data)) {
          setList([...data]);
        }
      } else {
        const data = await API.getPostList(id, type, page);
        if (Array.isArray(data)) {
          setList([...data]);
        }
      }
    };
    if (props.id) {
      fetchList(props.id, props.type);
    }
  }, [props.id, props.type, page]);

  const handleDelete = (id: number) => {
    const newList = list.filter((item) => item.id !== id);
    setList(newList);
  };

  const userID = userInfo?.id || "";
  const isOwner = `${userID}` === `${props.id}`;

  return (
    <div className="note-list waterfall-container mt-4 sm:mt-10 ">
      {/* <WaterfallWithoutInfiniteScroll
        deletable={isOwner}
        onDelete={handleDelete}
        useV2
        list={list}
      /> */}
      <List
        deletable={isOwner && UserPostType.Created === props.type}
        onDelete={handleDelete}
        list={list}
      />
    </div>
  );
};

const List = ({
  list,
  deletable,
  onDelete,
}: {
  list: IHomeData[];
  deletable?: boolean;
  onDelete?: (id: number) => void;
}) => {
  const now = new Date();
  const hours = now.getHours();
  const showEmojiIndex = hours % 3;

  if (list.length === 0) {
    return <Empty />;
  }

  return (
    <Masonry
      columns={{ xs: 2, sm: 4, md: 4 }}
      defaultHeight={400}
      defaultSpacing={0}
      spacing={0}
      sx={{
        alignContent: "center",
      }}
      // defaultSpacing={isMobile ? 1 : 2}
      // spacing={{ xs: 1, sm: 2, md: 2 }}
    >
      {list.map((item, index) => (
        <WaterfallItem
          useV2
          deletable={deletable}
          onDelete={onDelete}
          data={item}
          key={`${item.id}_${index}`}
          showTopEmoji={showEmojiIndex === index % 3}
        />
      ))}
    </Masonry>
  );
};

export default UserNoteList;
