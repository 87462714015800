import API from "@/apis";
import { HomeDataDisplayMode, IHomeData } from "@/models/index";
import {
  emojiListState,
  homeActiveEmojiState,
  homeDataState,
  searchState,
  searchWordState,
  tagState,
} from "@/store";
import { Avatar, Popover, message } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import NEWS_ICON_BITCOIN from "@/assets/news/bitcoin.webp";
import NEWS_ICON_BLOOMBERG from "@/assets/news/bloomberg.png";
import NEWS_ICON_cnn from "@/assets/news/cnn.png";
import NEWS_ICON_crunchyroll from "@/assets/news/crunchyroll.png";
import NEWS_ICON_dailymail from "@/assets/news/dailymail.png";
import NEWS_ICON_espn from "@/assets/news/espn.png";
import NEWS_ICON_fox from "@/assets/news/fox.png";
import NEWS_ICON_gematsu from "@/assets/news/gematsu.png";
import NEWS_ICON_nytimes from "@/assets/news/nytimes.png";
import NEWS_ICON_politico from "@/assets/news/politico.png";
import NEWS_ICON_seekingalpha from "@/assets/news/seekingalpha.webp";
import NEWS_ICON_usmagazine from "@/assets/news/usmagazine.png";
import NEWS_ICON_theverge from "@/assets/news/verge.png";

import UserImg from "@/components/UserImg";
import useApplyEmojiGroup from "@/hooks/useApplyEmojiGroup";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { findMaxNumEmoji, setInfo2Map } from "@/utils";
import { OfficialMap, iOSMap } from "@/utils/emojis";
import { EmbedYouTubeVideo } from "../EmbedYouTubeVideo";
import MovieIcon from "../Icons/Movie";
import NoteButtons from "./NoteButtons";
import NoteEmojis from "./NoteEmojis";

const formatTagsToArray = (tagsStr: string): string[] => {
  try {
    const res = JSON.parse(tagsStr);
    if (Array.isArray(res)) {
      return res;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

// vercel 强制 https 问题
export const formatImage = (url?: string) => {
  if (!url) {
    return "";
  }

  if (url.startsWith("/uploads")) {
    return url;
  }

  if (url.startsWith("http")) {
    return url;
  }

  const _url = `https://image.tmdb.org/t/p/w500/${url}`;
  return _url.replace("http://49.235.185.49:3000", "").replace("http://100.24.6.246", "");
};

interface NoteProps {
  data: IHomeData;
  onClickComment?: () => void;
  mode?: HomeDataDisplayMode;
  hideTag?: boolean;
  showTopEmoji?: boolean;
  hideBottomEmoji?: boolean;
  deletable?: boolean;
  onDelete?: (id: number) => void;
  size?: "small" | "medium" | "large";
}

const NoteV2 = ({
  data,
  deletable,
  hideTag,
  showTopEmoji,
  hideBottomEmoji,
  onDelete,
}: NoteProps) => {
  const navigate = useNavigate();
  const homeActiveEmoji = useRecoilValue(homeActiveEmojiState);
  const [tag, setTag] = useRecoilState(tagState);
  const [homeData, setHomeData] = useRecoilState(homeDataState);
  const tags = formatTagsToArray(data?.tags);
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  const emojiListValue = useRecoilValue(emojiListState);

  // apply的表情组
  const { applyedEmojiGroup } = useApplyEmojiGroup();

  const handleGoDetail = () => {
    if (data.website === "nytimes") {
      navigate("/news-detail/nytimes?id=" + data.id);
      return;
    }

    if (data.website === "movies") {
      navigate("/news-detail/movie?id=" + data.id);
      return;
    }

    if (data.website === "funny") {
      navigate("/news-detail/funny?id=" + data.id);
      return;
    }

    if (data?.isDemo) {
      return;
    }
    navigate("/detail?id=" + data.id);
  };

  const handleClickTag = (name: string) => {
    if (name !== tag) {
      setTag(name);

      setHomeData({
        ...homeData,
        loading: true,
      });
      API.getHomePostList({
        code: homeActiveEmoji ? homeActiveEmoji.code : undefined,
        orderDir: homeActiveEmoji ? "desc" : undefined,
        tag: name,
        page: 1,
      }).then((data) => {
        const newItems = data.data.map((i: IHomeData, idx: number) => {
          return {
            groupKey: 1,
            key: idx,
            data: i,
          };
        });
        setHomeData({
          page: 2,
          total: data.total || 0,
          data: [...newItems],
          loading: false,
        });
      });
    } else {
      setTag("");

      setHomeData({
        ...homeData,
        loading: true,
      });
      API.getHomePostList({
        code: homeActiveEmoji ? homeActiveEmoji.code : undefined,
        orderDir: homeActiveEmoji ? "desc" : undefined,
        page: 1,
      }).then((data) => {
        const newItems = data.data.map((i: IHomeData, idx: number) => {
          return {
            groupKey: 1,
            key: idx,
            data: i,
          };
        });
        setHomeData({
          page: 2,
          total: data.total || 0,
          data: [...newItems],
          loading: false,
        });
      });
    }
  };

  const handleDelete = async () => {
    try {
      await API.delPost({ id: data.id });
      message.success("Successfully");
      onDelete?.(data.id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCLickBg = () => {
    handleGoDetail();
  };

  const imageSrc =
    data.youtubeLink && data.youtubeImage ? data.youtubeImage : formatImage(data.image);

  const imgNote = !!(data.image || data.youtube);
  const maxEmoji = findMaxNumEmoji(data.emoji || [], emojiListValue);
  let maxEmojiPath = maxEmoji?.path || "";
  if (maxEmoji) {
    const applyEmojiGroupMap = setInfo2Map(applyedEmojiGroup);
    if (applyEmojiGroupMap[maxEmoji.code]) {
      if (iOSMap[applyEmojiGroupMap[maxEmoji.code].code]) {
        maxEmojiPath = iOSMap[applyEmojiGroupMap[maxEmoji.code].code];
      } else if (OfficialMap[applyEmojiGroupMap[maxEmoji.code].code]) {
        maxEmojiPath = OfficialMap[applyEmojiGroupMap[maxEmoji.code].code];
      } else {
        maxEmojiPath = applyEmojiGroupMap[maxEmoji.code].path;
        maxEmojiPath = maxEmojiPath.startsWith("/emoji") ? maxEmojiPath : `/emoji/${maxEmojiPath}`;
      }
    } else {
      if (iOSMap[maxEmoji.code]) {
        maxEmojiPath = iOSMap[maxEmoji.code];
      } else if (OfficialMap[maxEmoji.code]) {
        maxEmojiPath = OfficialMap[maxEmoji.code];
      }
    }
  }
  // const maxEmojiPath = findMaxNumEmojiPath(data.emoji || [], emojiListValue);

  return (
    <div
      className={`note bg-white rounded-lg overflow-hidden w-full sm:w-[300px] pb-0 mb-2 sm:mb-6 relative sm:px-0 ${
        imgNote ? "img-note shadow" : "text-note"
      }`}
    >
      {imgNote ? (
        <>
          <div
            onClick={handleCLickBg}
            className="group relative w-full sm:w-[300px] overflow-hidden cursor-pointer min-h-[200px]"
          >
            {data.youtube ? (
              <EmbedYouTubeVideo src={data.youtube} height={isLargerThan414 ? 240 : 200} />
            ) : (
              <img
                src={imageSrc}
                alt=""
                className="note-img w-full min-h-[200px] sm:min-h-[320px] align-middle object-cover"
              />
            )}

            {showTopEmoji ? (
              <div className="rounded-full h-8 w-10 ml-4 mb-3 right-2 sm:right-5 top-2 sm:top-4 absolute">
                <div>
                  <div className="flex items-center justify-center relative">
                    {data.website === "movies" ? (
                      <div className="absolute right-7 sm:right-12 text-white text-xs font-bold whitespace-nowrap">
                        Top 10%
                      </div>
                    ) : null}
                    <img src={maxEmojiPath} alt="" className="mb-1 h-7 w-7 sm:w-8 sm:h-8" />
                  </div>
                </div>
              </div>
            ) : null}
            <NoteButtons
              data={data}
              showTopEmoji={showTopEmoji}
              onDelete={deletable ? handleDelete : undefined}
            />
            {hideTag ? null : (
              <div
                onClick={() => handleClickTag(tags?.[0] || "")}
                className="absolute bottom-5 right-5 text-white font-bold text-base"
              >
                {tags[0]}
              </div>
            )}
            <div
              className="absolute bottom-0 left-0 w-full text-white linear-bg p-2"
              onClick={handleGoDetail}
            >
              <div className="text-base font-bold with-2-col mb-1">{data.title || "Title"}</div>
              <NoteUserAvatarAndTimes data={data} />
            </div>
          </div>
          <div className="p-1">
            {hideBottomEmoji ? (
              <div className="h-2 w-full"></div>
            ) : (
              <NoteEmojis postDetail={data} emoji={data.emoji} />
            )}
            {data.topComment ? (
              <div className="pl-1 -mt-1.5 pt-1 sm:pb-1 sm:pt-0.5 flex items-start">
                <div className="mr-2">
                  <div className="h-5 min-w-[20px] inline-flex items-center align-top">
                    <UserImg
                      className="w-4 h-4 rounded-full z-10"
                      size={16}
                      src={data.topComment.avatar}
                      name={data.topComment.nickname}
                    />
                  </div>
                  <span className="text-slate-500 text-sm">{data.topComment!.content}</span>
                  {/* <div className="inline-block h-5 w-0 relative align-top">
                    <div className="-left-3 top-2 absolute h-4 min-w-[20px] flex items-center justify-center">
                      <img className="w-3 h-3 z-10" src={EmojiImageSrcMap.lol} alt="" />
                    </div>
                  </div> */}
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <NoteFooter
            handleGoDetail={handleGoDetail}
            data={data}
            hideBottomEmoji={hideBottomEmoji}
            showButtons
            onDelete={deletable ? handleDelete : undefined}
          />
        </>
      )}
    </div>
  );
};

const DemoTags = ({ data }: { data: IHomeData }) => {
  if (data.isDemo) {
    if (data.website === "seekingalpha.com") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_seekingalpha} alt="seekingalpha" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag news-tag-black">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "nytimes") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_nytimes} alt="bitcoin" />}
              />
            </div>
          </Popover>

          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "movies") {
      return (
        <div className="movies-tags">
          {/* <span className="movies-icon">
            <img
              src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_short-8e7b30f73a4020692ccca9c88bafe5dcb6f8a62a4c6bc55cd9ba82bb2cd95f6c.svg"
              alt=""
            />

          </span> */}
          <div
            style={{ display: "inline-flex", alignItems: "center" }}
            onClick={() => {
              window.open(data.link);
            }}
          >
            <Avatar size={16} shape="square" src={<MovieIcon size={16} />} />
          </div>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </div>
      );
    } else if (data.website === "bitcoin") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_BITCOIN} alt="bitcoin" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "bloomberg") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_BLOOMBERG} alt="bloomberg" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "usmagazine") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_usmagazine} alt="usmagazine" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "cnn") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar size={16} shape="square" src={<img src={NEWS_ICON_cnn} alt="cnn" />} />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "crunchyroll") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_crunchyroll} alt="crunchyroll" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "dailymail") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_dailymail} alt="dailymail" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "espn") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar size={16} shape="square" src={<img src={NEWS_ICON_espn} alt="espn" />} />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "fox") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar size={16} shape="square" src={<img src={NEWS_ICON_fox} alt="fox" />} />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "gematsu") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_gematsu} alt="gematsu" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "politico") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_politico} alt="politico" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else if (data.website === "theverge") {
      return (
        <>
          <Popover title={null} content={<TagLink link={data.link} />} trigger="hover">
            <div
              style={{ display: "inline-flex", alignItems: "center" }}
              onClick={() => {
                window.open(data.link);
              }}
            >
              <Avatar
                size={16}
                shape="square"
                src={<img src={NEWS_ICON_theverge} alt="theverge" />}
              />
            </div>
          </Popover>
          {/* <Tag className="news-tag">{data.tag?.toLocaleUpperCase()}</Tag> */}
        </>
      );
    } else {
      // https://www.themoviedb.org/assets/2/v4/logos/v2/blue_short-8e7b30f73a4020692ccca9c88bafe5dcb6f8a62a4c6bc55cd9ba82bb2cd95f6c.svg
      return <span></span>;
    }
  } else {
    return <span></span>;
  }
};

export default NoteV2;

const TagLink = ({ link }: { link?: string }) => {
  if (!link) {
    return null;
  }
  return (
    <div
      style={{
        fontSize: 12,
        maxWidth: 200,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
      }}
      onClick={() => {
        window.open(link);
      }}
    >
      {link}
    </div>
  );
};

const getTitle = (data: IHomeData) => {
  if (data.title && data.title !== " ") {
    return data.title;
  } else if (data.text) {
    return data.text;
  } else {
    return "Title";
  }
};

const NoteFooter = ({
  data,
  hideBottomEmoji,
  handleGoDetail,
  showButtons,
  onDelete,
}: {
  data: IHomeData;
  hideBottomEmoji?: boolean;
  handleGoDetail: () => void;
  showButtons: boolean;
  onDelete?: () => void;
}) => {
  return (
    <div className="pl-0 group">
      {showButtons ? <NoteButtons data={data} showTopEmoji={false} onDelete={onDelete} /> : null}
      <div
        onClick={handleGoDetail}
        className="w-full h-[80px] sm:h-[120px] pt-2 cursor-pointer text-black"
      >
        {/* {data.isDemo ? (
          <div className="flex items-start">
            <div className="pt-1.5">
              <DemoTags data={data} />
            </div>
            <div className="text-lg leading-1 font-bold with-2-col pr-6">
              {data.title || "Title"}
            </div>
          </div>
        ) : (
          )} */}
        <div className="text-md sm:text-lg font-bold with-3-col pr-4 sm:pr-6">{getTitle(data)}</div>
      </div>
      <div className="flex items-center">
        {data.isDemo ? (
          <DemoTags data={data} />
        ) : (
          <UserImg
            name={data?.author?.nickname || data?.author?.username}
            src={data.author?.avatar}
            size={20}
            type={data.type}
            className="w-5 h-5 rounded-full"
          />
        )}
        {/* <UserImg
          name={data?.author?.nickname || data?.author?.username}
          src={data.author?.avatar}
          size={24}
          className="w-5 h-5 rounded-full"
        /> */}
        <div className="ml-2 text-sm">{dayjs(data.created_at).format("MM/DD/YY")}</div>
      </div>

      {hideBottomEmoji ? (
        <div className="h-2 w-full"></div>
      ) : (
        <div className="-ml-1">
          <NoteEmojis postDetail={data} emoji={data.emoji} />
        </div>
      )}
      {data.topComment ? (
        <div className="pl-1 flex items-center">
          <div className="mr-1">
            <div className="h-5 min-w-[20px] inline-flex items-center align-top">
              <UserImg
                className="w-4 h-4 rounded-full z-10"
                size={16}
                src={data.topComment.avatar}
                name={data.topComment.nickname}
              />
            </div>
            <span className="text-slate-500 text-sm">{data.topComment!.content}</span>
          </div>
          {/* <div className="h-4 min-w-[20px] flex items-center self-end">
            <img className="w-3 h-3 z-10 mr-1" src={EmojiImageSrcMap.lol} alt="" />
          </div> */}
        </div>
      ) : null}
    </div>
  );
};

const NoteUserAvatarAndTimes = ({ data }: { data: IHomeData }) => {
  const setSearchWord = useSetRecoilState(searchWordState);
  const setHomeSearch = useSetRecoilState(searchState);
  const handleSearch = async (word: string) => {
    if (!word) {
      return;
    }

    try {
      const res = await API.getHomePostList({ keyword: word, page: 1 });
      setHomeSearch({
        keyword: word,
        results: res.data,
        total: res.total,
        page: 1,
      });
    } catch (error) {}
  };

  if (!data) {
    return null;
  }
  if (data.type !== "movie" && data.type !== "news") {
    return null;
  }
  return (
    <div className="flex items-center">
      <Popover
        trigger="hover"
        content={<div className="p-0">{data?.author?.nickname || data?.author?.username}</div>}
        title={null}
        getPopupContainer={(trigger) => trigger.parentElement!}
      >
        <div>
          <UserImg
            name={data?.author?.nickname || data?.author?.username}
            src={data.author?.avatar}
            size={12}
            className="w-3 h-3 rounded-full z-10"
            type={data.type}
            website={data.website}
          />
        </div>
      </Popover>
      {data.type === "movie" && data.tags?.length > 0 ? (
        <div
          className="ml-2 text-sm"
          onClick={(e) => {
            e.stopPropagation();
            setSearchWord(`#${data.tags[0]}`);
            handleSearch(data.tags[0]);
          }}
        >
          {data.tags[0]}
        </div>
      ) : null}
      <div className={`ml-2 pt-[2px] text-sm`}>
        {data.created_at ? dayjs(data.created_at).format("MM/DD/YY") : dayjs().format("MM/DD/YY")}
      </div>
    </div>
  );
};
