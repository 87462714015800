import TabBar from "@/layout/TabBar";
import * as React from "react";

const NoticeItem = (props: { src: string }) => {
  return (
    <div className="flex items-center mt-5 w-full">
      <div className="w-20 h-16 overflow-hidden rounded-xl mr-3">
        <img src={props.src} alt="" className="w-20 h-16" />
      </div>
      <div className="flex-auto">
        <div className="text-base font-bold mb-1">Ribbon Hunter</div>
        <div className="mb-2">
          <span>by </span>
          <span className="text-md font-bold">Alex Morgan</span>
        </div>
      </div>
      <div className="text-xs">2 days ago</div>
    </div>
  );
};

interface Props {}

const MessagePage: React.FC<Props> = (props: Props) => {
  return (
    <div className="msg-page-container max-w-lg mx-auto px-4">
      <div className="flex flex-col items-center justify-center">
        <NoticeItem src="https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-21.png" />
        <NoticeItem src="https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-1.png" />
        <NoticeItem src="https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-2.png" />
        <NoticeItem src="https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-5.png" />
      </div>
      <div className="border-b mt-8 pb-2">
        <h3 className="font-bold text-lg">Featured</h3>
      </div>
      <div className="flex flex-col items-center justify-center opacity-50">
        <NoticeItem src="https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-21.png" />
        <NoticeItem src="https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-1.png" />
        <NoticeItem src="https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-2.png" />
        <NoticeItem src="https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-5.png" />
      </div>
      <TabBar />
    </div>
  );
};

export default MessagePage;
