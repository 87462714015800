import { IPostDetail } from "@/models";

import API from "@/apis";
import { EmbedYouTubeVideo } from "@/components/EmbedYouTubeVideo";
import Loading from "@/components/Loading";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { searchState, searchWordState } from "@/store";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import DetailEmojis from "./DetailEmojis";
import PostComments from "./PostComments";
import PostDetailAuthorInfo from "./PostDetailAuthorInfo";
import PostDetailHeader from "./PostDetailHeader";
import PostDetailNextButton from "./PostDetailNextButton";
import PostDetailPrevButton from "./PostDetailPrevButton";
import PostDetailSide from "./PostDetailSide";

export const formatImage = (url?: string) => {
  if (!url) {
    return "";
  }
  if (url.startsWith("/uploads")) {
    return url;
  }
  if (url.startsWith("http")) {
    return url;
  }
  const _url = `https://image.tmdb.org/t/p/original/${url}`;
  return _url.replace("http://49.235.185.49:3000", "").replace("http://100.24.6.246", "");
};

const PostDetailV2 = ({
  detail,
  nextId,
  prevId,
}: {
  detail: IPostDetail | null;
  nextId?: string;
  prevId?: string;
}) => {
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  const setHomeSearch = useSetRecoilState(searchState);
  const setSearchWord = useSetRecoilState(searchWordState);
  const navigate = useNavigate();

  const handleClickTag = (tag: string) => () => {
    setSearchWord(`#${tag}`);
    handleSearch(tag);
    navigate("/");
  };

  const handleSearch = async (word: string) => {
    if (!word) {
      return;
    }

    try {
      const res = await API.getHomePostList({ keyword: word, page: 1 });
      setHomeSearch({
        keyword: word,
        results: res.data,
        total: res.total,
        page: 1,
      });
    } catch (error) {}
  };

  if (!detail) {
    return (
      <div className="flex items-center justify-center py-20">
        <Loading />
      </div>
    );
  }

  return (
    <div className="post-detail-card max-w-[688px] mx-auto rounded-xl relative">
      {/* <PostDetailMobileHeader detail={detail} /> */}

      <div className="px-4 sm:p-6 rounded-xl sm:rounded-3xl relative">
        <PostDetailSide detail={detail} />

        {/* 区分手机标题和PC标题 */}
        <div className="pt-2 sm:hidden">
          <PostDetailAuthorInfo detail={detail} />
        </div>
        <div className="hidden sm:block">
          <PostDetailHeader detail={detail} />
        </div>

        <div className="-ml-1" id="postDetailEmoji">
          <DetailEmojis emoji={detail.emoji} id={detail.id} />
          {/* <NoteEmojis emoji={detail.emoji} /> */}
        </div>
        <div className="mt-2 relative">
          {detail.youtube ? (
            <EmbedYouTubeVideo src={detail.youtube} height={isLargerThan414 ? 430 : 260} />
          ) : (
            <img
              src={formatImage(detail?.image)}
              alt=""
              className="max-w-[640px] max-h-[452px] object-cover w-full rounded-lg img-shadow"
            />
          )}

          <PostDetailPrevButton id={prevId} />
          <PostDetailNextButton id={nextId} />
        </div>
        {detail?.credit ? (
          <div className="my-4 text-lg opacity-50 flex items-center">
            <span className="credit-title"></span>
            <span className="text-ellipsis whitespace-nowrap overflow-hidden text-sm">
              {detail.credit}
            </span>
          </div>
        ) : null}
        {detail?.summary ? (
          <div className="my-4 text-base merriweather-font">{detail.summary}</div>
        ) : (
          <div className="my-4 text-base merriweather-font">{detail?.summary || ""}</div>
        )}

        <div className="text-base mb-2 text-blue-500">
          {detail.tags?.map((tag, index) => {
            return (
              <span
                className="mr-2 cursor-pointer"
                key={`${tag}-${index}`}
                onClick={handleClickTag(tag)}
              >
                #{tag.toUpperCase()}
              </span>
            );
          })}
        </div>

        <div id="comment">
          <PostComments detail={detail} />
        </div>
        {/* <div className="flex items-center justify-center mt-8">
          <div
            className="border border-slate-300 flex items-center justify-center rounded flex-1 mr-4 h-11 cursor-pointer hover:bg-slate-100"
            onClick={() => {
              setVisible(true);
            }}
          >
            <IconFont className="w-5 h-5 text-lg flex items-center mr-2" type="icon-share-2" />
            <div>SHARE</div>
          </div>
          <div
            className="border border-slate-300 flex items-center justify-center rounded flex-1 h-11 cursor-pointer hover:bg-slate-100"
            onClick={() => {
              detail.link && window.open(detail.link);
            }}
          >
            <IconFont className="w-5 h-5 text-lg flex items-center mr-2" type="icon-link" />
            <div>VISIT WEBSITE</div>
          </div>
        </div> */}
      </div>
      {/* <Drawer
        closable={false}
        extra={<div>Close</div>}
        placement="bottom"
        onClose={() => setVisible(false)}
        visible={visible}
        className="detail-drawer"
        height={300}
      >
        <div className="relative">
          <div className="text-center text-base pr-3">Actions</div>
          <div
            onClick={() => setVisible(false)}
            className="absolute text-base font-bold top-0 right-0"
          >
            close
          </div>
          <div className="flex items-center justify-between mt-20">
            <div>
              <div className="flex items-center bg-[#F2F2F2] rounded-full justify-center w-[62px] h-[62px]">
                <IconFont type="icon-share" style={{ fontSize: 24 }} />
              </div>
              <div className="text-xs mt-2 text-center">To Share</div>
            </div>
            <div>
              <div className="flex items-center bg-[#F2F2F2] rounded-full justify-center w-[62px] h-[62px]">
                <IconFont type="icon-share" style={{ fontSize: 24 }} />
              </div>
              <div className="text-xs mt-2 text-center">Copy Link</div>
            </div>
            <div>
              <div className="flex items-center bg-[#32A6DD] rounded-full justify-center w-[62px] h-[62px]">
                <IconFont type="icon-twitter" style={{ fontSize: 30, color: "#FFFFFF" }} />
              </div>
              <div className="text-xs mt-2 text-center">Twitter</div>
            </div>
            <div>
              <div className="flex items-center bg-[#745FF2] rounded-full justify-center w-[62px] h-[62px]">
                <IconFont type="icon-reddit" style={{ fontSize: 30, color: "#FFFFFF" }} />
              </div>
              <div className="text-xs mt-2 text-center">Reddit</div>
            </div>
            <div>
              <div className="flex items-center bg-[#66CF72] rounded-full justify-center w-[62px] h-[62px]">
                <IconFont type="icon-liaotian" style={{ fontSize: 30, color: "#FFFFFF" }} />
              </div>
              <div className="text-xs mt-2 text-center">Chat</div>
            </div>
          </div>
        </div>
      </Drawer> */}
    </div>
  );
};

export default PostDetailV2;
