import { Button } from "antd";
import UserAddImg from "@/assets/user-add.png";
import BannerContainer from "@/components/Banner/Container";
import BannerAuthors from "@/components/Banner/Authors";
import { useEffect, useState } from "react";
import API from "@/apis";
import { ITopUser } from "@/models";
import UserImg from "@/components/UserImg";

const CreatorAvatar = ({ user }: { user: ITopUser }) => {
  const avatarStyle = {
    width: "120px",
    height: "120px",
  };

  return (
    <div className="relative overflow-hidden bg-white rounded-full" style={avatarStyle}>
      <UserImg
        style={{
          width: 112,
          height: 112,
          minWidth: 112,
          left: 4,
          top: 4,
        }}
        className="absolute rounded-full overflow-hidden"
        src={user.avatar}
      />
      <div className="absolute border-8 rounded-full border-white" style={avatarStyle} />
    </div>
  );
};

const RankingCreator = (props: { user?: ITopUser }) => {
  const [creator, setCreator] = useState<ITopUser>();
  const [followLoading, setFollowLoading] = useState<boolean>(false);
  const [unFollowLoading, setUnFollowLoading] = useState<boolean>(false);

  useEffect(() => {
    props.user && setCreator(props.user);
  }, [props.user]);

  const handleFollow = async () => {
    const uid = creator?.id;
    if (!uid || followLoading) {
      return;
    }
    setFollowLoading(true);
    const res = await API.follow(`${uid}`);
    if (res) {
      setCreator({
        ...creator,
        is_follow: 1,
        follow_num: +creator.follow_num + 1,
      });
    }
    setFollowLoading(false);
  };

  const handleUnfollow = async () => {
    const uid = creator?.id;
    if (!uid || unFollowLoading) {
      return;
    }
    setUnFollowLoading(true);
    const res = await API.unFollow(`${uid}`);
    if (res) {
      setCreator({
        ...creator,
        is_follow: 0,
        follow_num: +creator.follow_num - 1,
      });
    }
    setUnFollowLoading(false);
  };

  if (!creator) {
    return <div style={{ width: "304px", height: "165px" }}></div>;
  }

  const FollowingButton = (
    <Button
      loading={unFollowLoading}
      className="main-button rounded-full h-12 px-6"
      type="primary"
      onClick={handleUnfollow}
    >
      Following
    </Button>
  );

  const FollowButton = (
    <Button
      loading={followLoading}
      className="main-button rounded-full h-12 px-6"
      type="primary"
      onClick={handleFollow}
    >
      {followLoading ? (
        "Follow"
      ) : (
        <div className="flex items-center">
          <span className="mr-2">Follow</span>
          <img src={UserAddImg} alt="" style={{ width: 20, height: 20 }} />
        </div>
      )}
    </Button>
  );

  return (
    <div
      className="rounded-3xl overflow-hidden mb-8"
      style={{ border: "1px solid #E6E8EC", maxWidth: "304px" }}
    >
      <div
        style={{
          width: "304px",
          height: "165px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% auto",
          backgroundPosition: "center top",
          backgroundImage: "url(https://picsum.photos/300)",
        }}
      />
      <div className="flex flex-col items-center justify-between px-5" style={{ height: "216px" }}>
        <div className="flex flex-col items-center">
          <div className="relative" style={{ height: "60px", width: 120 }}>
            <div className="absolute" style={{ height: "120px", width: "120px", top: -60 }}>
              <CreatorAvatar user={creator} />
            </div>
          </div>
          <div className="text-2xl font-bold">
            {creator.nickname || creator.username || creator.email}
          </div>
        </div>
        <div
          className="w-full border-t py-5 flex items-center justify-between"
          style={{ borderColor: "#E6E8EC" }}
        >
          <div className="text-black">
            <div className="text-2xl font-bold">{creator.follow_num}</div>
            <div className="text-base text-black">Followers</div>
          </div>
          {+creator.is_follow === 1 ? FollowingButton : FollowButton}
        </div>
      </div>
    </div>
  );
};

const RankingCreators = () => {
  const [topUsers, setTopUsers] = useState<ITopUser[]>([]);
  const [users, setUsers] = useState<ITopUser[]>([]);
  useEffect(() => {
    const fetchTopUserList = async () => {
      const data = await API.getTopUserList();
      if (data) {
        setTopUsers(data);
      }
    };
    const fetchUserList = async () => {
      const data = await API.getUserList();
      if (data) {
        setUsers(data);
      }
    };
    fetchTopUserList();
    fetchUserList();
  }, []);

  return (
    <div>
      <BannerContainer>
        <BannerAuthors />
      </BannerContainer>
      <div className="px-3" style={{ maxWidth: 1390, margin: "50px auto" }}>
        <h3 className="text-5xl tracking-normal font-bold mb-6">Top Authors Today</h3>
        <div className="grid grid-cols-4 gap-x-4">
          <RankingCreator user={topUsers[0]} />
          <RankingCreator user={topUsers[1]} />
          <RankingCreator user={topUsers[2]} />
          <RankingCreator user={topUsers[3]} />
        </div>
        <h3 className="text-5xl tracking-normal font-bold mb-6 mt-10">List of Authors</h3>
        <div className="grid grid-cols-4 gap-x-4">
          {users.map((user, index) => {
            return <RankingCreator user={user} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default RankingCreators;
