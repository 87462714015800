import { Layout } from "antd";
import { Outlet } from "react-router-dom";

const { Content, Sider } = Layout;

const SettingsMenuItem = ({ title }: { title: string }) => {
  return <div className="text-base font-bold tracking-tighter mb-5">{title}</div>;
};

const Settings = () => {
  return (
    <Layout style={{ maxWidth: "1200px", margin: "40px auto" }}>
      <Sider className="hidden sm:block" style={{ background: "#fff" }}>
        <SettingsMenuItem title="Public Profile" />
        <SettingsMenuItem title="Personal data" />
        <SettingsMenuItem title="Account Management" />
        <SettingsMenuItem title="Account privacy" />
        <SettingsMenuItem title="Notifications" />
        <SettingsMenuItem title="Privacy and security" />
        <SettingsMenuItem title="Account login security" />
      </Sider>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default Settings;
