import { IPostDetail } from "@/models";
import dayjs from "dayjs";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { WebsiteLogo } from "./PostDetailAuthorInfo";

interface Props {
  detail: IPostDetail;
}

const PostDetailHeader: React.FC<Props> = (props: Props) => {
  const author = props.detail.author;
  const navigate = useNavigate();

  const handleClickUser = () => {
    if (author.id) {
      navigate(`/user?id=${author.id}`);
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <div
          onClick={handleClickUser}
          className="rounded-full w-[30px] h-[30px] sm:h-9 sm:w-9 overflow-hidden mr-2 flex items-center cursor-pointer"
        >
          {props.detail.author.avatar ? (
            <img
              className="w-[30px] h-[30px] sm:h-9 sm:w-9 object-cover"
              src={props.detail.author.avatar}
              alt=""
            />
          ) : (
            <WebsiteLogo
              nickname={author.nickname || author.username}
              website={props.detail.website}
              type={props.detail.type}
            />
          )}
        </div>
        <div className="mr-4 overflow-hidden">
          <div className="text-sm font-bold leading-[18px] text-slate-600 text-ellipsis whitespace-nowrap overflow-hidden">
            {author.nickname || author.username || author.email}
          </div>
          <div className="text-[8px] leading-[12px] mt-0.5 text-slate-400 mr-4">
            {props.detail?.createdAt
              ? dayjs(props.detail?.createdAt).format("MMM DD, YYYY")
              : dayjs().format("MMM DD, YYYY")}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between my-2">
        <h3 className="text-xl font-extrabold mr-2 sm:m-0 break-word merriweather-font">
          {props.detail?.title}
        </h3>
      </div>
    </div>
  );
};

export default PostDetailHeader;
