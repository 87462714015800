import IconFont from "@/components/IconFont";
import useApplyEmojiGroup from "@/hooks/useApplyEmojiGroup";
import { IEmoji } from "@/models";
import { emojiListState } from "@/store";
import { setInfo2Map } from "@/utils";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Collapse, Empty, Input } from "antd";
import * as React from "react";
import { useRecoilState } from "recoil";
import EmojiV2 from "../Emoji/EmojiV2";
import AddEmoji from "./AddEmoji";
import EmojiCollapsePanelDetail from "./EmojiCollapsePanelDetail";
import "./index.css";

interface Props {
  type: string;
  creatable?: boolean;
}

const EmojiPicker: React.FC<Props> = (props: Props) => {
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
  const [addSuccessCode, setAddSuccessCode] = React.useState<string>("");
  const [emojiList, setEmojiList] = useRecoilState(emojiListState);
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [searchedList, setSelectedList] = React.useState<IEmoji[]>([]);
  const [searchWord, setSearchWord] = React.useState<string>("");
  // const emojiGroupsValue = useRecoilValue(emojiGroupsState);
  // apply的表情组
  // const [applyedEmojiGroup, setApplyedEmojiGroup] = React.useState<ISetInfoWithEmojis>();
  // React.useEffect(() => {
  //   const applyEmojiGroup = emojiGroupsValue.find((group) => group?.apply === 1);
  //   applyEmojiGroup && setApplyedEmojiGroup(applyEmojiGroup);
  // }, [emojiGroupsValue]);
  const { applyedEmojiGroup } = useApplyEmojiGroup();

  const applyEmojiGroupMap = setInfo2Map(applyedEmojiGroup);
  // const [emojiGroups, setEmojiGroups] = useRecoilState(emojiGroupsState);
  // const switchedEmojiListValue = useRecoilValue(switchedEmojiListState);

  // const handleClickEmoji = (item: IEmoji) => async () => {
  //   return 0;
  // };
  const handleAddSuccess = (code: string) => {
    setAddSuccessCode(code);
  };
  const handleFetched = () => {
    setAddSuccessCode("");
  };

  // const fetchGroups = async () => {
  //   const res = await API.getEmojiGroups();
  //   if (Array.isArray(res?.data)) {
  //     const promiseList = res.data.map((item: ISetInfo) => {
  //       return API.getEmojiListByGroup(item);
  //     });

  //     const resList = await Promise.all(promiseList);
  //     setEmojiGroups(resList);
  //   }
  // };

  const handleChangeActive = (key: string | string[]) => {
    if (Array.isArray(key)) {
      setActiveKeys(key);
    } else {
      setActiveKeys([key]);
    }
  };

  const handleSearch = () => {
    if (!searchWord) {
      return;
    }
    const res = emojiList.filter((emoji) => {
      return emoji.code.toUpperCase() === searchWord.toUpperCase();
    });
    setSelectedList(res);
    setShowSearch(true);
  };

  const ispc = props.type === "pc";
  // const size = ispc ? 42 : 24;

  // const hasEmojiGroups = emojiGroups.filter((i) => i.emojis.length > 0);
  // const tabContentClass = `w-full pt-3 grid gap-x-3 gap-y-2 overflow-y-scroll h-[300px] ${
  //   ispc ? "grid-cols-6" : "grid-cols-4"
  // }`;
  const AllEmojisView = (
    <div className="h-[400px] overflow-y-scroll">
      <Collapse
        expandIcon={() => null}
        bordered={false}
        className="site-collapse-custom-collapse"
        ghost
        onChange={handleChangeActive}
      >
        <Collapse.Panel
          header={
            <div className="flex items-center">
              <IconFont type="icon-recent" style={{ fontSize: 20 }} />
              <div className="mx-3">FREQUENTLY USED</div>
              <RightOutlined style={{ fontSize: 12 }} />
            </div>
          }
          key={0}
          className="site-collapse-custom-panel"
        >
          <p>TODO</p>
        </Collapse.Panel>
        {emojiList.map((item, index) => {
          const itemKey = `${index + 1}`;
          return (
            <Collapse.Panel
              header={
                <div className="flex items-center">
                  <EmojiV2
                    width={20}
                    height={20}
                    emoji={item}
                    applyedEmoji={applyEmojiGroupMap[item.code]}
                    noScale
                    autoScaleBack
                  />
                  <div className="mx-3">{item.code?.toUpperCase()}</div>
                  <RightOutlined style={{ fontSize: 12 }} />
                </div>
              }
              key={itemKey}
              className="site-collapse-custom-panel"
            >
              <EmojiCollapsePanelDetail
                ispc={ispc}
                code={item.code}
                emoji={item}
                isActive={activeKeys.includes(itemKey)}
                fetchNew={addSuccessCode === item.code}
                onFetchNew={handleFetched}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );

  const cls = `w-full pb-2 grid gap-x-3 gap-y-2 ${ispc ? "grid-cols-6" : "grid-cols-4"}`;
  const SearchEmojisView = (
    <div className="pt-3">
      {searchedList.length === 0 ? (
        <div>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{ fontSize: 300 }} />
        </div>
      ) : (
        <div className={cls}>
          {searchedList.map((item) => {
            return (
              <div className="max-w-[72px] w-[72px]" key={item.code}>
                <EmojiV2
                  // onClick={handleClickEmoji(emojiItem)}
                  width={42}
                  height={42}
                  emoji={item}
                  autoScaleBack
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <div className="px-0 pb-4">
      {props.creatable ? (
        <div className="pb-3 w-full flex items-center">
          <Input
            className="mr-2 rounded-lg"
            value={searchWord}
            onChange={(e) => {
              setSearchWord(e.target.value);
              if (e.target.value === "") {
                setShowSearch(false);
                setSelectedList([]);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            suffix={<SearchOutlined onClick={handleSearch} className="cursor-pointer" />}
          />
          <AddEmoji linkEmoji={null} ispc={ispc} onSuccess={handleAddSuccess} />
        </div>
      ) : null}
      {showSearch ? SearchEmojisView : AllEmojisView}
    </div>
  );
};

export default EmojiPicker;
