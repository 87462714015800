import IMG_SWAP from "@/assets/icons/swap.png";
import { loginVisibleState, userInfoState } from "@/store";
import { Drawer, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import * as React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import EmojiSets from "./EmojiSets";
import IconFont from "./IconFont";

interface Props {
  onClick?: () => void;
  type: string;
}

const HeaderEmojiSwitch: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const userInfo = useRecoilValue(userInfoState);
  const setLoginVisible = useSetRecoilState(loginVisibleState);
  const handleClickH5 = () => {
    setVisible(true);
    props.onClick?.();
  };

  const handleClickSwap = () => {
    if (userInfo === null) {
      message.info("Please Login");
      setLoginVisible(true);
      return;
    }
    setModalVisible(true);
  };

  if (props.type === "pc") {
    return (
      <>
        <Modal
          footer={null}
          className="post-modal"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          title="SWAP EMOJI SET"
          closeIcon={
            <div className="flex items-center justify-center w-14 h-14">
              <div className="flex items-center justify-center w-8 h-8">
                <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
              </div>
            </div>
          }
        >
          <EmojiSets type="pc" />
        </Modal>
        <div
          onClick={handleClickSwap}
          className="inline-flex items-center justify-center flex-col mx-1 mt-4"
        >
          <div className="cursor-pointer">
            <img src={IMG_SWAP} alt="" className="w-10 h-10" />
          </div>
          <div className="text-sm leading-4 font-bold h-8 text-center tracking-tight py-2 w-14">
            Swap
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* <div className="inline-flex items-center justify-center flex-col bg-[#FBFBFB] overflow-hidden">
        <div onClick={handleClickH5} className="cursor-pointer">
          <img src={IMG_SWAP} alt="" className="w-6 h-6" />
        </div>
      </div> */}
      <div
        onClick={handleClickH5}
        className="inline-flex items-center justify-center flex-col mx-1 mt-4"
      >
        <div className="h-10 flex items-center cursor-pointer">
          <img src={IMG_SWAP} alt="" className="w-7 h-7" />
        </div>
        <div className="text-xs leading-4 font-bold h-5 sm:h-8 text-center tracking-tight text-ellipsis whitespace-nowrap overflow-hidden pb-1 sm:py-2 w-14">
          Swap
        </div>
      </div>
      <Drawer
        closable={false}
        placement="bottom"
        visible={visible}
        className="switch-emoji-drawer"
        onClose={() => setVisible(false)}
      >
        <EmojiSets type="mobile" />
      </Drawer>
    </>
  );
};

export default HeaderEmojiSwitch;
