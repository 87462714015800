import { message } from "antd";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const visitorPaths = ["/"];

const getIsVisitorPath = () => {
  // console.log(window.location.pathname);
  return visitorPaths.includes(window.location.pathname);
};

const Request = axios.create({
  timeout: 20000,
});

export const requestInterceptor = (config: AxiosRequestConfig) => {
  const access_token = localStorage.getItem("access_token");
  if (access_token && config.headers && !config.headers["no-auth"]) {
    config.headers["sv-access-token"] = access_token;
  }

  return config;
};

Request.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));

export const responseIntercepter = async (res: AxiosResponse) => {
  if (res && res.status === 200) {
    switch (+res.data.code) {
      case 200:
        return Promise.resolve(res);
      case 410:
        // token 失效
        if (!getIsVisitorPath()) {
          const msg = res.data?.msg || "登录状态已过期";
          message.warning(msg, 2, () => {
            // window.location.href = "/";
          });
        }

        return Promise.reject(res);
      default:
        return Promise.reject(res);
    }
  }

  if (res && res.status >= 400) {
    return Promise.reject(res);
  }

  return Promise.resolve(res);
};

Request.interceptors.response.use(responseIntercepter, (error) => Promise.reject(error));

export default Request;
