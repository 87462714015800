import API from "@/apis";
import ICON_POST from "@/assets/icons/icon-post.png";
import IconFont from "@/components/IconFont";
import PostForm from "@/layout/PostNew/PostForm";
import { userInfoState } from "@/store";
import { Modal, Popover } from "antd";
import React from "react";
import { useRecoilState } from "recoil";

const PostFormTitle = () => {
  return (
    <>
      <div className="hidden xs:block">
        <h3 className="text-center" style={{ fontSize: 42, lineHeight: "140%" }}>
          Post
        </h3>
      </div>
      <div className="block xs:hidden">
        <h3 className="text-center text-2xl mb-4">Post</h3>
      </div>
    </>
  );
};

const PostNew = () => {
  const [visible, setVisible] = React.useState(false);
  const [userInfo] = useRecoilState(userInfoState);

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const handleSubmit = async (values: any) => {
    const tagsStr = values.tags || "";
    const tags = tagsStr.split(" ").filter((i: string) => i !== "");

    const res = await API.createPost({
      ...values,
      author_id: userInfo?.id,
      tags: values.tags ? JSON.stringify(tags) : undefined,
    });
    if (res) {
      setVisible(false);
    }
  };

  return (
    <div className="ml-2 sm:ml-0">
      {/* <div
        className="h-12 w-32 px-6 mr-3 hidden md:flex items-center justify-center rounded-full cursor-pointer text-base font-bold text-white"
        style={style}
        onClick={openModal}
      >
        Post
      </div> */}
      <Popover overlayClassName="menu-popover" content="Post">
        <div className="cursor-pointer" onClick={openModal}>
          <div className="w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center">
            <img
              src={ICON_POST}
              alt=""
              className="w-10 sm:w-12 transition duration-300 ease-in-out hover:scale-125"
            />
          </div>
        </div>
      </Popover>
      <Modal
        className="post-modal"
        visible={visible}
        style={{ top: 40 }}
        title={null}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        closeIcon={
          <div className="flex items-center justify-center w-14 h-14">
            <div className="flex items-center justify-center w-8 h-8">
              <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
            </div>
          </div>
        }
      >
        <div>
          <PostFormTitle />
          <PostForm onSubmit={handleSubmit} />
        </div>
      </Modal>
    </div>
  );
};

export default PostNew;
