import API from "@/apis";
import { emojiGroupsState, userInfoState } from "@/store";
import { DeleteFilled, LoadingOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

interface Props {
  id: number;
  uid: number;
}

const DeleteGroupIcon: React.FC<Props> = (props: Props) => {
  const [emojiGroups, setEmojiGroups] = useRecoilState(emojiGroupsState);
  const user = useRecoilValue(userInfoState);
  const [loading, setLoading] = useState<boolean>(false);

  if (!user || +user.id !== props.uid) {
    return <div></div>;
  }

  return (
    <div
      className="pr-2 flex items-center"
      onClick={async (e) => {
        e.stopPropagation();
        if (loading) {
          return;
        }
        setLoading(true);
        const success = await API.deleteEmojiGroupById({ id: props.id });
        if (success) {
          message.success("Success");
          const newEmojiGroups = emojiGroups.filter((i) => i.id !== props.id);
          setEmojiGroups(newEmojiGroups);
        }
        setLoading(false);
      }}
    >
      {loading ? <LoadingOutlined /> : <DeleteFilled />}
    </div>
  );
};

export default DeleteGroupIcon;
