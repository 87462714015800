import API from "@/apis";
import { SignupForm } from "@/layout/Header/SignUp";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const SignupPage = () => {
  const navigate = useNavigate();
  const handleSignup = async (values: any) => {
    const success = await API.register(values);

    if (success) {
      message.success("Sign up success!", 1.5, () => {
        navigate("/login");
      });
    }
  };

  return (
    <div className="p-4">
      <div className="text-2xl text-center font-bold mb-6">Create Account</div>
      <SignupForm onSubmit={handleSignup} />
    </div>
  );
};
export default SignupPage;
