import { useEffect, useState } from "react";

import API from "@/apis";

import { IPostDetail } from "@/models";
import { getIDFromUrl } from "@/utils";

import PostDetailV2 from "./PostDetailV2";

import { useLocation } from "react-router-dom";
import HomePosts from "../home/HomePosts";
import "./index.css";

const DetailPage = () => {
  const [detail, setDetail] = useState<IPostDetail | null>(null);
  const location = useLocation();

  useEffect(() => {
    const fetch = async () => {
      const id = getIDFromUrl();
      const postDetail = await API.getPostById(id);
      setDetail(postDetail);
    };
    fetch();
    window.scroll(0, 0);
  }, [location]);

  return (
    <div className="bg-white">
      <div className="common-page-container pb-24 sm:px-4">
        <div className="relative">
          <PostDetailV2 detail={detail} />

          <div className="py-4 sm:py-20 mx-auto max-w-[1264px]">
            <h3 className="px-4 text-base font-bold m-0 tracking-normal mb-4 sm:mb-8">View More</h3>
            <HomePosts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
