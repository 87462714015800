import API from "@/apis";
import { WaterfallItem } from "@/components/Waterfall";
import { IHomeData } from "@/models";
import { homeActiveEmojiState, homeDataState } from "@/store";
import { Masonry } from "@mui/lab";
import { Box, CircularProgress, Stack } from "@mui/material";
import { Empty } from "antd";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRecoilState, useRecoilValue } from "recoil";

interface Props {}

const HomePosts: React.FC<Props> = (props: Props) => {
  const now = new Date();
  const hours = now.getHours();
  const showEmojiIndex = hours % 3;
  const [homeData, setHomeData] = useRecoilState(homeDataState);
  const homeActiveEmoji = useRecoilValue(homeActiveEmojiState);

  useEffect(() => {
    handleRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParams = () => {
    if (homeActiveEmoji) {
      return {
        code: homeActiveEmoji.code,
        orderDir: "desc" as const,
        page: homeData.page,
      };
    } else {
      return {
        page: homeData.page,
      };
    }
  };

  const handleRequest = () => {
    if (homeData.loading || homeData.isError) {
      return;
    }

    if (homeData.total === 0 && homeData.page > 1) {
      return;
    }

    if (homeData.total > 0 && homeData.data.length >= homeData.total) {
      return;
    }

    setHomeData({
      ...homeData,
      loading: true,
    });

    API.getHomePostList(getParams())
      .then((data) => {
        if (data) {
          const newItems = data.data.map((i: IHomeData, idx: number) => {
            return {
              groupKey: homeData.page,
              key: idx,
              data: i,
            };
          });
          setHomeData({
            page: homeData.page + 1,
            total: data.total || 0,
            data: [...homeData.data, ...newItems],
            loading: false,
          });
        } else {
          setHomeData({
            ...homeData,
            loading: false,
            isError: true,
          });
        }
      })
      .catch(() => {
        setHomeData({
          ...homeData,
          loading: false,
        });
      });
  };

  if (homeData.data.length === 0 && homeData.loading) {
    return (
      <Stack sx={{ height: 160 }} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (homeData.data.length === 0) {
    return (
      <Stack sx={{ height: 160 }} alignItems="center" justifyContent="center">
        <Empty />
      </Stack>
    );
  }

  return (
    <Box>
      <InfiniteScroll
        style={{ height: "auto", overflow: "unset" }}
        dataLength={homeData.data.length}
        next={handleRequest}
        hasMore={homeData.data.length < homeData.total}
        loader={
          <Stack sx={{ height: 50 }} alignItems="center" justifyContent="center">
            <CircularProgress size={20} />
          </Stack>
        }
      >
        <Masonry
          columns={{ xs: 2, sm: 4, md: 4 }}
          defaultHeight={400}
          defaultSpacing={0}
          spacing={0}
          sx={{
            alignContent: "center",
          }}
        >
          {homeData.data.map((item, index) => (
            <WaterfallItem
              key={item.data.id}
              data={item.data}
              showTopEmoji={showEmojiIndex === index % 3}
              useV2
            />
          ))}
        </Masonry>
      </InfiniteScroll>
    </Box>
  );
};

export default HomePosts;
