import API from "@/apis";
import { LoginForm } from "@/layout/Header/Login";
import { message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const handleLogin = async (values: any) => {
    if (loading) {
      return;
    }

    setLoading(true);
    const data = await API.login({
      username: values.username,
      password: values.password,
    });
    setLoading(false);

    if (data) {
      localStorage.setItem("access_token", data.token);
      message.success("Login success!", 1.5, () => {
        navigate("/");
      });
    }
  };

  return (
    <div className="py-10 px-4">
      <div className="max-w-sm mx-auto">
        <div className="text-2xl text-center font-bold mb-10">
          Welcome <br />
          to Mojiverse
        </div>
        <LoginForm loading={loading} onSubmit={handleLogin} />
      </div>
    </div>
  );
};

export default LoginPage;
