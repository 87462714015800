import IconFont from "@/components/IconFont";
import UserImg from "@/components/UserImg";
import { IComment } from "@/models";

import API from "@/apis";
import { loginVisibleState, userInfoState } from "@/store";
import { Space, message } from "antd";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "./index.css";

const Comment = (props: {
  data: IComment;
  onClickReply?: (data: IComment) => void;
  hideFooter?: boolean;
  onDeleteSuccess?: () => void;
}) => {
  const data = props.data;
  const userInfo = useRecoilValue(userInfoState);
  const setLoginVisible = useSetRecoilState(loginVisibleState);
  const changeTimer = useRef<NodeJS.Timer | null>();
  const [value, setValue] = useState<number>(0);
  // const [likeCount, setLikeCount] = useState<number>(props.data.like);
  // const [isLike, setIsLike] = useState<boolean>(props.data.isLike);

  const [like, setLike] = useState<{ isLike: boolean; count: number }>({
    isLike: props.data.isLike,
    count: props.data.like,
  });

  let canDelete = false;
  if (userInfo !== null) {
    canDelete = +userInfo.id === +data.author_id;
  }

  const handleClickLike = async () => {
    if (userInfo === null) {
      message.info("Please Login");
      setLoginVisible(true);

      return;
    }

    if (like.isLike) {
      setLike({
        isLike: false,
        count: like.count - 1,
      });
    } else {
      setLike({
        isLike: true,
        count: like.count + 1,
      });
    }

    await API.likeComment({
      comment_id: data.id,
    });
  };

  const handleDelete = async () => {
    try {
      await API.deleteComment({
        comment_id: data.id,
      });
      message.success("Delete successfully");
      props.onDeleteSuccess?.();
    } catch (error) {
      console.log(error);
    }
  };

  function increment() {
    const past = Date.now();
    const rate = 500;
    changeTimer.current = setInterval(() => {
      const diff = Math.floor((Date.now() - past) / rate);
      setValue((prev) => prev + (1 + diff));
    }, 50);
  }

  const startCounting = () => {
    increment();
  };

  const stopCounting = () => {
    if (changeTimer.current) {
      clearInterval(changeTimer.current);
    }
  };

  return (
    <div className="comment py-5 border-b" style={{ borderColor: "#E6E8EC" }}>
      <div className="flex items-center">
        <div className="overflow-hidden rounded-full w-8 h-8 mr-2.5">
          <UserImg
            style={{ minWidth: 32 }}
            size={32}
            className="w-8 h-8 flex items-center justify-center"
            src={data.author_avatar}
            name={data.author}
          />
        </div>
        <div className="text-base font-bold mr-3">{data.author}</div>
        <div className="text-sm" style={{ color: "#777E91" }}>
          {dayjs(data.createdAt).format("MMM DD, YYYY h:mm A")}
        </div>
      </div>
      <div className="text-[15px] my-3">{data.content}</div>
      {props.hideFooter ? null : (
        <div className="flex items-center justify-between">
          <div
            className="flex items-center cursor-pointer"
            onMouseDown={() => {
              console.log("mouseDown");
              startCounting();
            }}
            onMouseOut={() => {
              console.log("mouseOut");
            }}
            onMouseUp={() => {
              console.log("mouseUp");
              stopCounting();
            }}
            onClick={handleClickLike}
          >
            <IconFont
              type="icon-like"
              style={{ color: like.isLike ? "#F26F4C" : "#23262F", fontSize: 16 }}
            />
            <div className="text-sm ml-2">{Math.max(like.count, 0)}</div>
            <div className="text-sm ml-2">{value}</div>
          </div>

          <Space className="">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => props.onClickReply?.(props.data)}
            >
              <IconFont type="icon-reply" style={{ color: "#23262F", fontSize: 16 }} />
              <div className="text-sm ml-2">Reply</div>
            </div>
            {canDelete ? (
              <div className="flex items-center cursor-pointer" onClick={handleDelete}>
                <IconFont type="icon-delete" style={{ color: "#23262F", fontSize: 16 }} />
                <div className="text-sm ml-2">Delete</div>
              </div>
            ) : null}
          </Space>
        </div>
      )}
    </div>
  );
};

export default Comment;
