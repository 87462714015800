import { BackTop, Layout } from "antd";
import { BrowserRouter as Router, Outlet, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import Feedback from "@/layout/Feedback";
import Header from "@/layout/Header";
import Following from "@/pages/following";
import Home from "@/pages/home";

// import Ranking from "@/pages/ranking";
import AboutUs from "@/pages/about";
import DetailPage from "@/pages/detail/index";
import Profile from "@/pages/profile";
import RankingCreators from "@/pages/ranking/creators";
import RankingList from "@/pages/ranking/list";
import User from "@/pages/user";

import Login from "@/pages/login";
import MsgPage from "@/pages/msg";
import PostPage from "@/pages/post";
import Settings from "@/pages/settings";
import PublicProfile from "@/pages/settings/PublicProfile";
import Signup from "@/pages/signup";
import TabBar from "./layout/TabBar";
// import FixedTagButton from "./layout/FixedTagButton";
import Demo from "@/pages/demo";
import NewsBitCoin from "@/pages/news/bitcoin";
import NewsBloomberg from "@/pages/news/bloomberg";
import NewsCnn from "@/pages/news/cnn";
import NewsCrunchyroll from "@/pages/news/crunchyroll";
import NewsDailymail from "@/pages/news/dailymail";
import NewsEspn from "@/pages/news/espn";
import NewsFox from "@/pages/news/fox";
import NewsFunny from "@/pages/news/funny";
import FunnyDetail from "@/pages/news/funnyDetail";
import NewsGematsu from "@/pages/news/gematsu";
import NewsMovies from "@/pages/news/movie";
import MoviesDetail from "@/pages/news/moviesDetail";
import NYTimes from "@/pages/news/nytimes";
import NyTimesDetail from "@/pages/news/nytimesDetail";
import NewsPolitico from "@/pages/news/politico";
import SeekingAlpha from "@/pages/news/seekingalpha";
import NewsTheverge from "@/pages/news/theverge";
import NewsUsmagazine from "@/pages/news/usmagazine";
import SearchPage from "@/pages/search";
import { useMediaQuery } from "./hooks/useMediaQuery";

const AppLayout = () => {
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  return (
    <Layout>
      <Header />
      {/* <MobileFixedHeader /> */}
      <Layout.Content>
        <Outlet />
      </Layout.Content>
      <TabBar />
      <Feedback />
      {/* <FixedTagButton /> */}
      {isLargerThan414 ? <BackTop style={{ bottom: 116, right: 40, zIndex: 1000 }} /> : null}
    </Layout>
  );
};

function App() {
  return (
    <RecoilRoot>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/msg" element={<MsgPage />} />
          <Route path="/post" element={<PostPage />} />
          <Route path="demo" element={<Demo />} />

          <Route path="/" element={<AppLayout />}>
            <Route index element={<Home />} />
            <Route path="user" element={<User />} />
            <Route path="news-detail/nytimes" element={<NyTimesDetail />} />
            <Route path="news/nytimes" element={<NYTimes />} />
            <Route path="news/seekingalpha" element={<SeekingAlpha />} />
            <Route path="news-detail/funny" element={<FunnyDetail />} />
            <Route path="news/funny" element={<NewsFunny />} />
            <Route path="news-detail/movie" element={<MoviesDetail />} />
            <Route path="news/movies" element={<NewsMovies />} />
            <Route path="news/bitcoin" element={<NewsBitCoin />} />
            <Route path="news/bloomberg" element={<NewsBloomberg />} />
            <Route path="news/usmagazine" element={<NewsUsmagazine />} />
            <Route path="news/cnn" element={<NewsCnn />} />
            <Route path="news/crunchyroll" element={<NewsCrunchyroll />} />
            <Route path="news/dailymail" element={<NewsDailymail />} />
            <Route path="news/espn" element={<NewsEspn />} />
            <Route path="news/fox" element={<NewsFox />} />
            <Route path="news/gematsu" element={<NewsGematsu />} />
            <Route path="news/politico" element={<NewsPolitico />} />
            <Route path="news/theverge" element={<NewsTheverge />} />

            <Route path="me" element={<User />} />
            <Route path="profile" element={<Profile />} />
            <Route path="following" element={<Following />} />
            <Route path="rating" element={<Home />} />
            <Route path="ranking/emoji/:id" element={<RankingList />} />
            <Route path="ranking/commented" element={<RankingList />} />
            <Route path="ranking/collection" element={<RankingList />} />
            <Route path="ranking/pick" element={<RankingList />} />
            <Route path="ranking/creators" element={<RankingCreators />} />
            <Route path="ranking" element={<RankingList />} />
            <Route path="detail" element={<DetailPage />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="settings" element={<Settings />}>
              <Route path="public_profile" element={<PublicProfile />} />
            </Route>
            <Route path="search" element={<SearchPage />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Router>
    </RecoilRoot>
  );
}

export default App;
