import React, { useEffect } from "react";

// 8个常用
import IMG_EMOJI_COOL from "@/assets/emoji-new/Cool.png";
import IMG_EMOJI_CRYING from "@/assets/emoji-new/Crying.png";
import IMG_EMOJI_CUTE from "@/assets/emoji-new/Cute.png";
import IMG_EMOJI_FUNNY_SWITCH from "@/assets/emoji-new/Funny-ios.png";
import IMG_EMOJI_FUNNY from "@/assets/emoji-new/Funny.png";
import IMG_EMOJI_MAD from "@/assets/emoji-new/Mad.png";
import IMG_EMOJI_TASTY from "@/assets/emoji-new/Tasty.png";
import IMG_EMOJI_USEFUL_SWITCH from "@/assets/emoji-new/Useful-ios.png";
import IMG_EMOJI_USEFUL from "@/assets/emoji-new/Useful.png";
import IMG_EMOJI_WTF from "@/assets/emoji-new/WTF.png";

// 剩下的下拉
import IMG_EMOJI_ACTION_PACKED from "@/assets/emoji-new/Action-packed.png";
import IMG_EMOJI_AWESOME from "@/assets/emoji-new/Awesome.png";
import IMG_EMOJI_BORING from "@/assets/emoji-new/Boring.png";
import IMG_EMOJI_CASH_GRABBING from "@/assets/emoji-new/Cash-grabbing.png";
import IMG_EMOJI_CAUTIOUS from "@/assets/emoji-new/Cautious.png";
import IMG_EMOJI_CHALLENGING from "@/assets/emoji-new/Challenging.png";
import IMG_EMOJI_CONFUSING from "@/assets/emoji-new/Confusing.png";
import IMG_EMOJI_COZY from "@/assets/emoji-new/Cozy.png";
import IMG_EMOJI_DISCOMFORT from "@/assets/emoji-new/Discomfort.png";
import IMG_EMOJI_DOUBTING from "@/assets/emoji-new/Doubting.png";
import IMG_EMOJI_EAZY from "@/assets/emoji-new/Easy.png";
import IMG_EMOJI_EMOTIONAL from "@/assets/emoji-new/Emotional.png";
import IMG_EMOJI_GOAT from "@/assets/emoji-new/Goat.png";
import IMG_EMOJI_HEART from "@/assets/emoji-new/Heart.png";
import IMG_EMOJI_HELPFUL from "@/assets/emoji-new/Helping.png";
import IMG_EMOJI_L from "@/assets/emoji-new/L.png";
import IMG_EMOJI_LIT from "@/assets/emoji-new/Lit.png";
import IMG_EMOJI_LOL from "@/assets/emoji-new/LOL.png";
import IMG_EMOJI_LUXURIOUS from "@/assets/emoji-new/Luxurious.png";
import IMG_EMOJI_OK from "@/assets/emoji-new/Ok.png";
import IMG_EMOJI_PERFECT from "@/assets/emoji-new/Perfect.png";
import IMG_EMOJI_PLEASANT from "@/assets/emoji-new/Pleasant.png";
import IMG_EMOJI_PLEASE from "@/assets/emoji-new/Please.png";
import IMG_EMOJI_PRETTY from "@/assets/emoji-new/Pretty.png";
import IMG_EMOJI_QUESTIONING from "@/assets/emoji-new/Questioning.png";
import IMG_EMOJI_SAD from "@/assets/emoji-new/Sad.png";
import IMG_EMOJI_SCARY from "@/assets/emoji-new/Scary.png";
import IMG_EMOJI_SPEECHLESS from "@/assets/emoji-new/Speechless.png";
import IMG_EMOJI_STAY_STRONG from "@/assets/emoji-new/Stay-strong.png";
import IMG_EMOJI_VIBING from "@/assets/emoji-new/Vibing.png";
import IMG_EMOJI_W from "@/assets/emoji-new/W.png";
import IMG_EMOJI_WEIRD from "@/assets/emoji-new/Weird.png";
import IMG_EMOJI_WOW_SWITCH from "@/assets/emoji-new/WoW-ios.png";
import IMG_EMOJI_WOW from "@/assets/emoji-new/WoW.png";
import { emojiSwitchState } from "@/store";
import { useRecoilValue } from "recoil";

export enum LottieName {
  Cool = "cool",
  Crying = "crying",
  Cute = "cute",
  Funny = "funny",
  Mad = "mad",
  Tasty = "tasty",
  Useful = "useful",
  WTF = "wtf",

  SwitchedWow = "SwitchedWow",
  SwitchedHelpful = "SwitchedHelpful",
  SwitchedLOL = "SwitchedLOL",

  ActionPacked = "action-packed",
  Awesome = "awesome",
  Boring = "boring",
  CashGrabbing = "cash-grabbing",
  Cautious = "cautious",
  Challenging = "challenging",
  Confusing = "confusing",
  Cozy = "cozy",
  Discomfort = "discomfort",
  Doubting = "doubting",
  Easy = "easy",
  Emotional = "emotional",
  Goat = "goat",
  Heart = "heart",
  Helpful = "helpful",
  L = "l",
  Lit = "lit",
  LOL = "lol",
  Luxurious = "luxurious",
  Ok = "ok",
  Perfect = "perfect",
  Pleasant = "pleasant",
  Please = "please",
  Pretty = "pretty",
  Questioning = "questioning",
  Sad = "sad",
  Scary = "scary",
  Speechless = "speechless",
  StayStrong = "stay-strong",
  Vibing = "vibing",
  W = "w",
  Weird = "weird",
  WoW = "wow",
}

export const CommonEmojiList = [
  LottieName.Helpful,
  LottieName.LOL,
  LottieName.WoW,
  LottieName.Tasty,
  LottieName.Cute,
  LottieName.Emotional,
  LottieName.WTF,
  LottieName.Mad,
];

export const SwitchedEmojiList = [
  LottieName.SwitchedWow,
  LottieName.SwitchedHelpful,
  LottieName.SwitchedLOL,
];

export const SelectOptionEmojiList = [
  LottieName.ActionPacked,
  LottieName.Awesome,
  LottieName.Boring,
  LottieName.CashGrabbing,
  LottieName.Cautious,
  LottieName.Challenging,
  LottieName.Confusing,
  LottieName.Cozy,
  LottieName.Discomfort,
  LottieName.Doubting,
  LottieName.Easy,
  // LottieName.Emotional,
  LottieName.Goat,
  LottieName.Heart,
  // LottieName.Helping,
  LottieName.L,
  LottieName.Lit,
  // LottieName.LOL,
  LottieName.Luxurious,
  LottieName.Ok,
  LottieName.Perfect,
  LottieName.Pleasant,
  LottieName.Please,
  LottieName.Pretty,
  LottieName.Questioning,
  LottieName.Sad,
  LottieName.Scary,
  LottieName.Speechless,
  LottieName.StayStrong,
  LottieName.Vibing,
  LottieName.W,
  LottieName.Weird,
  LottieName.Cool,
];

export const EmojiTitle = {
  [LottieName.WoW]: "Wow",
  [LottieName.Crying]: "Crying",
  [LottieName.Cute]: "Cute",
  [LottieName.Funny]: "Funny",
  [LottieName.Mad]: "Mad",
  [LottieName.Tasty]: "Tasty",
  [LottieName.Useful]: "Useful",
  [LottieName.WTF]: "WTF",

  [LottieName.ActionPacked]: "Action-packed",
  [LottieName.Awesome]: "Awesome",
  [LottieName.Boring]: "Boring",
  [LottieName.CashGrabbing]: "Cash-grabbing",
  [LottieName.Cautious]: "Cautious",
  [LottieName.Challenging]: "Challenging",
  [LottieName.Confusing]: "Confusing",
  [LottieName.Cozy]: "Cozy",
  [LottieName.Discomfort]: "Discomfort",
  [LottieName.Doubting]: "Doubting",
  [LottieName.Easy]: "Easy",
  [LottieName.Emotional]: "Emotional",
  [LottieName.Goat]: "Goat",
  [LottieName.Heart]: "Heart",
  [LottieName.Helpful]: "Helpful",
  [LottieName.L]: "L",
  [LottieName.Lit]: "Lit",
  [LottieName.LOL]: "LOL",
  [LottieName.Luxurious]: "Luxurious",
  [LottieName.Ok]: "Ok",
  [LottieName.Perfect]: "Perfect",
  [LottieName.Pleasant]: "Pleasant",
  [LottieName.Please]: "Please",
  [LottieName.Pretty]: "Pretty",
  [LottieName.Questioning]: "Questioning",
  [LottieName.Sad]: "Sad",
  [LottieName.Scary]: "Scary",
  [LottieName.Speechless]: "Speechless",
  [LottieName.StayStrong]: "Supportive",
  [LottieName.Vibing]: "Vibing",
  [LottieName.W]: "W",
  [LottieName.Weird]: "Weird",
  [LottieName.Cool]: "Cool",

  [LottieName.SwitchedWow]: "Wow",
  [LottieName.SwitchedLOL]: "LOL",
  [LottieName.SwitchedHelpful]: "Helpful",
};

export const EmojiImageSrcMap = {
  [LottieName.WoW]: IMG_EMOJI_WOW,
  [LottieName.Crying]: IMG_EMOJI_CRYING,
  [LottieName.Cute]: IMG_EMOJI_CUTE,
  [LottieName.Funny]: IMG_EMOJI_FUNNY,
  [LottieName.Mad]: IMG_EMOJI_MAD,
  [LottieName.Tasty]: IMG_EMOJI_TASTY,
  [LottieName.Useful]: IMG_EMOJI_USEFUL,
  [LottieName.WTF]: IMG_EMOJI_WTF,

  [LottieName.ActionPacked]: IMG_EMOJI_ACTION_PACKED,
  [LottieName.Awesome]: IMG_EMOJI_AWESOME,
  [LottieName.Boring]: IMG_EMOJI_BORING,
  [LottieName.CashGrabbing]: IMG_EMOJI_CASH_GRABBING,
  [LottieName.Cautious]: IMG_EMOJI_CAUTIOUS,
  [LottieName.Challenging]: IMG_EMOJI_CHALLENGING,
  [LottieName.Confusing]: IMG_EMOJI_CONFUSING,
  [LottieName.Cozy]: IMG_EMOJI_COZY,
  [LottieName.Discomfort]: IMG_EMOJI_DISCOMFORT,
  [LottieName.Doubting]: IMG_EMOJI_DOUBTING,
  [LottieName.Easy]: IMG_EMOJI_EAZY,
  [LottieName.Emotional]: IMG_EMOJI_EMOTIONAL,
  [LottieName.Goat]: IMG_EMOJI_GOAT,
  [LottieName.Heart]: IMG_EMOJI_HEART,
  [LottieName.Helpful]: IMG_EMOJI_HELPFUL,
  [LottieName.L]: IMG_EMOJI_L,
  [LottieName.Lit]: IMG_EMOJI_LIT,
  [LottieName.LOL]: IMG_EMOJI_LOL,
  [LottieName.Luxurious]: IMG_EMOJI_LUXURIOUS,
  [LottieName.Ok]: IMG_EMOJI_OK,
  [LottieName.Perfect]: IMG_EMOJI_PERFECT,
  [LottieName.Pleasant]: IMG_EMOJI_PLEASANT,
  [LottieName.Please]: IMG_EMOJI_PLEASE,
  [LottieName.Pretty]: IMG_EMOJI_PRETTY,
  [LottieName.Questioning]: IMG_EMOJI_QUESTIONING,
  [LottieName.Sad]: IMG_EMOJI_SAD,
  [LottieName.Scary]: IMG_EMOJI_SCARY,
  [LottieName.Speechless]: IMG_EMOJI_SPEECHLESS,
  [LottieName.StayStrong]: IMG_EMOJI_STAY_STRONG,
  [LottieName.Vibing]: IMG_EMOJI_VIBING,
  [LottieName.W]: IMG_EMOJI_W,
  [LottieName.Weird]: IMG_EMOJI_WEIRD,
  [LottieName.Cool]: IMG_EMOJI_COOL,

  [LottieName.SwitchedWow]: IMG_EMOJI_WOW_SWITCH,
  [LottieName.SwitchedLOL]: IMG_EMOJI_FUNNY_SWITCH,
  [LottieName.SwitchedHelpful]: IMG_EMOJI_USEFUL_SWITCH,
};

export const EmojiImageSrcMapSwitched = {
  ...EmojiImageSrcMap,
  [LottieName.Useful]: IMG_EMOJI_USEFUL_SWITCH,
  [LottieName.Funny]: IMG_EMOJI_FUNNY_SWITCH,
  [LottieName.WoW]: IMG_EMOJI_WOW_SWITCH,
  [LottieName.LOL]: IMG_EMOJI_FUNNY_SWITCH,
  [LottieName.Helpful]: IMG_EMOJI_USEFUL_SWITCH,
};

export const getEmojiImageSrcByName = (name: LottieName, emojiSwitch: boolean = false) => {
  if (emojiSwitch) {
    return EmojiImageSrcMapSwitched[name];
  }
  return EmojiImageSrcMap[name];
};

const EmojiWithLottie = (props: {
  name: LottieName;
  width?: number;
  height?: number;
  noScale?: boolean;
  count?: number;
  padding?: number;
  isActive?: boolean;
  onClick?: () => Promise<number>;
  autoScaleBack?: boolean;
  boxShadow?: boolean;
  showTitle?: boolean;
  showName?: boolean;
}) => {
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(props.count || 0);
  const emojiSwitch = useRecoilValue(emojiSwitchState);

  useEffect(() => {
    setIsClicked(!!props.isActive);
  }, [props.isActive]);

  const handleClick = async () => {
    setIsClicked(!isClicked);

    if (props.autoScaleBack && !isClicked) {
      const aTimeout = setTimeout(() => {
        setIsClicked(false);
        if (aTimeout) {
          clearTimeout(aTimeout);
        }
      }, 500);
    }

    if (props.onClick) {
      const newCount = await props.onClick();
      setCount(count + newCount);
    }
  };

  const imageSrc = getEmojiImageSrcByName(props.name, emojiSwitch);
  const width = props.width || 40;
  const height = props.height || 40;

  let cls = "transition duration-300 ease-in-out";
  if (isClicked) {
    cls += " active";
    if (!props.noScale) {
      cls += " scale-150";
    }
  }

  const Image = (
    <img
      className={cls}
      src={imageSrc}
      onClick={handleClick}
      style={{
        width,
        height,
        minWidth: width,
        margin: "0 auto",
        display: "block",
        cursor: "pointer",
        paddingTop: props.padding || 0,
        paddingBottom: props.padding || 0,
      }}
      alt=""
    />
  );

  return (
    <div
      className={props.boxShadow ? "relative shadow-md p-2 rounded-full" : "relative"}
      title={props.showTitle ? props.name : ""}
    >
      {Image}

      {props.showName ? (
        <div className="text-xs leading-4 py-2 font-bold h-8 tracking-tight text-ellipsis whitespace-nowrap overflow-hidden text-center">
          {props.name}{" "}
        </div>
      ) : null}

      {count > 0 ? (
        <div
          className="absolute -top-2 -right-2 rounded-full text-xs w-4 h-4 flex items-center justify-center font-medium tracking-tighter"
          style={{
            backgroundColor: "red",
            fontSize: 10,
            color: "white",
            transform: width <= 20 ? "scale(0.6)" : "scale(1)",
          }}
        >
          {count}
        </div>
      ) : null}
    </div>
  );
};
export default EmojiWithLottie;
