import { RightOutlined } from "@ant-design/icons";
import * as React from "react";

interface Props {
  id?: string;
}

const PostDetailNextButton: React.FC<Props> = (props: Props) => {
  if (!props.id) {
    return null;
  }

  const handleClick = () => {
    window.location.href = `${window.location.pathname}?id=${props.id}`;
  };

  const cls =
    "transition w-8 h-8 rounded-full overflow-hidden items-center justify-center text-2xl ease-in-out duration-300 text-zinc-300 bg-transparent hover:scale-125 hover:text-white hover:bg-black cursor-pointer absolute -right-[40px] top-[50%] hidden sm:flex";
  return (
    <div className={cls} onClick={handleClick}>
      <RightOutlined />
    </div>
  );
};

export default PostDetailNextButton;
