import { IHomeData, ITopUser, UserPostType } from "@/models";
import { message } from "antd";
import { ISetInfo } from "../models/index";
import Request from "../utils/request";

const host = "/backend"; //"http://49.235.185.49:3000/";

const config = {
  login: `${host}/api/v1/auth/login`,
  register: `${host}/api/v1/auth/register`,
  user: `${host}/api/v1/auth/user`,
  updateUserInfo: `${host}/api/v1/auth/update`,
  post: `${host}/api/v1/post`,
  postFeatures: `${host}/api/v1/post/features`,
  createPost: `${host}/api/v1/post/add`,
  upload: `${host}/api/upload`,
  postEmoji: `${host}/api/v1/post/emoji`,
  userPostList: `${host}/api/v1/post/{type}/{id}`,
  topUserList: `${host}/api/v1/auth/top`,
  userList: `${host}/api/v1/auth/list`,
  follow: `${host}/api/v1/auth/follow`,
  unFollow: `${host}/api/v1/auth/unfollow`,
  topEmojiList: `${host}/api/v1/post/top/emoji`,
  commentList: `${host}/api/v1/comment/list`,
  postComment: `${host}/api/v1/comment/add`,
  // 排行榜
  topCommented: `${host}/api/v1/post/top/commented`,
  topCollected: `${host}/api/v1/post/top/collected`,
  topRated: `${host}/api/v1/post/top/rated`,
  topEmoji: `${host}/api/v1/post/top/emoji/{emoji}`,
  // like
  postLike: `${host}/api/v1/post/like`,
  // 首页排行帖子
  topPosts: `${host}/api/v1/post/ranking`,
  // 获取following用户的发帖列表
  getFollowingPostList: `${host}/api/v1/post/list`,
  getEmojiList: `${host}/api/v1/emoji/list`,
  addEmoji: `${host}/api/v1/emoji/add`,
  addEmojiSet: `${host}/api/v1/emoji/group/add`,
  groupToEmoji: `${host}/api/v1/emoji/group-to-emoji`,
  groups: `${host}/api/v1/emoji/group/list`,
  deletePost: `${host}/api/v1/post/delete`,
  likeComment: `${host}/api/v1/comment/like`,
  deleteComment: `${host}/api/v1/comment/delete`,
  followList: `${host}/api/v1/auth/follow-list`,
  apply: `${host}/api/v1/emoji/apply`,
  // 我like的帖子
  myLikePosts: `${host}/api/v1/post/ilike`,
  deleteEmojiGroupById: `${host}/api/v1/emoji/group/delete`,
};

const handleError = (error: any) => {
  const msg = error?.data?.msg;
  if (msg) {
    message.error(msg);
  }
};

const login = async (params: { username: string; password: string }) => {
  try {
    const { data } = await Request.post(config.login, params);
    return data?.data;
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

const register = async (params: { username: string; password: string; email: string }) => {
  try {
    await Request.post(config.register, params);
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

const getUserInfo = async () => {
  try {
    const { data } = await Request.get(config.user);
    return data?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const updateUserInfo = async (params: {
  nickname?: string;
  avatar?: string;
  bio?: string;
  username?: string;
}) => {
  try {
    await Request.post(config.updateUserInfo, params);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getUserInfoById = async (uid: number) => {
  try {
    const { data } = await Request.get(config.user, {
      params: {
        uid,
      },
    });
    return data?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getPostById = async (id: string) => {
  try {
    const { data } = await Request.get(`${config.post}/${id}`);
    return data?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getHomePostList = async (params?: {
  code?: string;
  orderDir?: "asc" | "desc";
  keyword?: string;
  tag?: string;
  page: number;
}) => {
  try {
    const { data } = await Request.get(config.postFeatures, {
      params: {
        search: params?.keyword,
        orderDir: params?.orderDir,
        code: params?.code,
        page: params?.page,
        tag: params?.tag,
      },
    });
    return data.data;
  } catch (error) {
    const msg = (error as any)?.data?.msg;
    if (msg) {
      message.error(msg);
    }
    return null;
  }
};

const createPost = async (params: {
  title: string;
  author_id: string;
  website: string;
  image: string;
  link: string;
  tags: string;
}) => {
  try {
    const { data } = await Request.post(config.createPost, params);
    return data;
  } catch (error) {
    console.log(error);
    handleError(error);
    return null;
  }
};

const uploadFile = async (data: any) => {
  try {
    const res = await Request.post(config.upload, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const url = res?.data.data || "";
    return url;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const postEmoji = async (postId: number, emojiCode: string) => {
  try {
    const res = await Request.post(config.postEmoji, {
      post_id: postId.toString(),
      emoji: emojiCode,
    });
    return res.data;
  } catch (error) {
    return null;
  }
};

const getPostList = async (id: number, type: UserPostType, page: number): Promise<IHomeData[]> => {
  try {
    const url = config.userPostList.replace("{id}", `${id}`).replace("{type}", type);
    const { data } = await Request.get(url, { params: { page, size: 200 } });
    return data?.data || [];
  } catch (error) {
    return [];
  }
};

const getTopUserList = async (): Promise<ITopUser[] | null> => {
  try {
    const { data } = await Request.get(config.topUserList);
    return data?.data || [];
  } catch (error) {
    return null;
  }
};

const getUserList = async (): Promise<ITopUser[] | null> => {
  try {
    const { data } = await Request.get(config.userList);
    return data?.data || [];
  } catch (error) {
    return null;
  }
};

const follow = async (uid: string): Promise<boolean> => {
  try {
    await Request.post(config.follow, {
      uid,
    });
    return true;
  } catch (error) {
    return false;
  }
};

const unFollow = async (uid: string): Promise<boolean> => {
  try {
    await Request.post(config.unFollow, {
      uid,
    });
    return true;
  } catch (error) {
    return false;
  }
};

const getTopEmojiList = async (emojiIndex: number): Promise<IHomeData[]> => {
  try {
    const res = await Request.get(`${config.topEmojiList}/${emojiIndex}?page=1&size=4`);
    return res?.data?.data || [];
  } catch (error) {
    return [];
  }
};

const getCommentList = async (params: { comment_id?: number; post_id?: number }) => {
  try {
    const { data } = await Request.get(config.commentList, {
      params,
    });
    return data?.data;
  } catch (error) {
    return null;
  }
};

const createCommentToPost = async (params: {
  post_id: number;
  comment_id?: number;
  content: string;
}) => {
  try {
    await Request.post(config.postComment, params);
    return true;
  } catch (error) {
    return false;
  }
};

const getTopCommentedPosts = async (params: { page?: number; size?: number }) => {
  try {
    const { data } = await Request.get(config.topCommented, { params });
    return data?.data;
  } catch (error) {
    return null;
  }
};

const getTopCollectedPosts = async (params: { page?: number; size?: number }) => {
  try {
    const { data } = await Request.get(config.topCollected, { params });
    return data?.data;
  } catch (error) {
    return null;
  }
};

const getTopRatedPosts = async (params: { page?: number; size?: number }) => {
  try {
    const { data } = await Request.get(config.topRated, { params });
    return data?.data;
  } catch (error) {
    return null;
  }
};

const likePost = async (params: { post_id: number; like: number }) => {
  try {
    await Request.post(config.postLike, params);
    return true;
  } catch (error) {
    return false;
  }
};

const getTopPosts = async () => {
  try {
    const { data } = await Request.get(config.topPosts);
    return data?.data;
  } catch (error) {
    return null;
  }
};

const getFollowingPostList = async (page: number, size: number) => {
  try {
    const { data } = await Request.get(config.getFollowingPostList, {
      params: { page, size },
    });
    return [true, data?.data];
  } catch (error: any) {
    return [false, error];
  }
};

const getEmojiList = async (query: {
  code?: string;
  type?: "1" | "2";
  group?: string;
  related?: string;
}) => {
  try {
    const { data } = await Request.get(config.getEmojiList, {
      params: query,
      headers: {
        "no-auth": true,
      },
    });
    return [true, data?.data];
  } catch (error: any) {
    return [false, error];
  }
};

const getEmojiListByGroup = async (group: ISetInfo) => {
  try {
    const { data } = await Request.get(config.getEmojiList, {
      params: {
        type: "2",
        group: group.id.toString(),
      },
      headers: {
        "no-auth": true,
      },
    });
    return {
      ...group,
      emojis: data?.data?.data,
    };
  } catch (error: any) {
    return null;
  }
};

const addEmoji = async (params: {
  group?: string;
  related: string;
  path: string;
  name: string;
  description: string;
}) => {
  try {
    await Request.post(config.addEmoji, params);
    return true;
  } catch (error: any) {
    return false;
  }
};

const addEmijiSet = async (params: { name: string }) => {
  try {
    const data = await Request.post(config.addEmojiSet, params);
    return data?.data;
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

const getEmojiGroups = async () => {
  try {
    const data = await Request.get(config.groups);
    return data?.data?.data;
  } catch (error: any) {
    return null;
  }
};

// 关联emoji
const relatedEmoji = async (params: { code: string; id: number }) => {
  try {
    const data = await Request.post(config.groupToEmoji, params);
    return data?.data;
  } catch (error: any) {
    handleError(error);
    return null;
  }
};
// 删除帖子
const delPost = async (params: { id: number }) => {
  try {
    const data = await Request.post(config.deletePost, params);
    return data?.data;
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

const likeComment = async (params: { comment_id: number }) => {
  try {
    const data = await Request.post(config.likeComment, params);
    return data?.data;
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

const deleteComment = async (params: { comment_id: number }) => {
  try {
    const data = await Request.post(config.deleteComment, params);
    return data?.data;
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

const getFollowList = async (params: { id: number }) => {
  try {
    const data = await Request.get(config.followList, {
      params,
    });
    return data?.data;
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

const applySet = async (params: { group_id: string }) => {
  try {
    const data = await Request.post(config.apply, params);
    return data?.data;
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

const getMyLikedPosts = async (params: { page: number; size: number }) => {
  try {
    const data = await Request.get(config.myLikePosts, { params });
    return data?.data?.data.data || [];
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

const deleteEmojiGroupById = async (params: { id: string | number }) => {
  try {
    const data = await Request.post(config.deleteEmojiGroupById, params);
    console.log(data);
    return true;
  } catch (error: any) {
    handleError(error);
    return false;
  }
};

// api/v1/emoji/group/delete

// /api/v1/emoji/add

const API = {
  login,
  applySet,
  register,
  getUserInfo,
  getUserInfoById,
  getPostById,
  getHomePostList,
  deleteEmojiGroupById,
  createPost,
  uploadFile,
  postEmoji,
  getPostList,
  getTopUserList,
  getUserList,
  follow,
  unFollow,
  getTopEmojiList,
  updateUserInfo,
  getCommentList,
  createCommentToPost,
  getTopCommentedPosts,
  getTopCollectedPosts,
  getTopRatedPosts,
  likePost,
  getTopPosts,
  getFollowingPostList,
  getEmojiList,
  addEmoji,
  addEmijiSet,
  getEmojiListByGroup,
  relatedEmoji,
  getEmojiGroups,
  delPost,
  likeComment,
  deleteComment,
  getFollowList,
  getMyLikedPosts,
};

export default API;
