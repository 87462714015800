import API from "@/apis";
import IconFont from "@/components/IconFont";
import UserImg from "@/components/UserImg";
import { userInfoState } from "@/store";
import { Button, Input, message, Spin, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const ProfileAvatar = (props: { onChangeAvatar: (url: string) => void }) => {
  const user = useRecoilValue(userInfoState);
  // const [fileList, setFileList] = useState<FileList | null>(null);
  // const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [status, setStatus] = useState<string>("init");
  const [uploadedUrl, setUploadedUrl] = useState<string>("");

  // const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setFileList(e.target.files);
  //   if (e.target.files?.[0]) {
  //     handleUpload(e.target.files[0]);
  //   }
  //   // const uploadFile = async (file: any) => {
  //   //   const data = new FormData();
  //   //   data.append("file", file, file.name);
  //   //   await API.uploadFile(data);
  //   // };
  // };

  // const handleUpload = async (file: File) => {
  //   if (!file) {
  //     return;
  //   }

  //   const data = new FormData();
  //   data.append("file", file, file.name);
  //   const imgUrl = await API.uploadFile(data);
  //   if (imgUrl) {
  //     props.onChangeAvatar(imgUrl);
  //   }
  // };

  // const handleClickChangeAvatar = () => {
  //   if (fileInputRef?.current) {
  //     fileInputRef.current.click();
  //   }
  // };

  const handleUploadChange = (e: any) => {
    const file = e?.file;
    if (file) {
      setStatus(file.status);

      if (file?.status === "done" && file?.response?.data) {
        setUploadedUrl(file.response.data);
        props.onChangeAvatar(file.response.data);
      }
    }
  };

  // const hasFile = fileList !== null;
  let url = user?.avatar;
  if (uploadedUrl) {
    url = uploadedUrl;
  }
  // if (hasFile) {
  //   url = window.URL
  //     ? window.URL.createObjectURL(fileList[0])
  //     : window.webkitURL.createObjectURL(fileList[0]);
  // }

  return (
    <div className="py-4 flex-col sm:flex-row flex items-center sm:justify-start justify-center">
      <div
        style={{ width: 130, height: 130 }}
        className="rounded-full mr-0 sm:mr-6 overflow-hidden"
      >
        <Spin spinning={status === "uploading"}>
          <UserImg
            style={{ width: 130, height: 130, minWidth: 130 }}
            src={url}
            name={user?.nickname || user?.username || user?.email}
          />
        </Spin>
      </div>
      <div>
        <ImgCrop shape="round" rotate modalTitle="Edit Image" modalClassName='post-modal'>
          <Upload
            name="file"
            action="/backend/api/upload"
            headers={{
              "sv-access-token": localStorage.getItem("access_token") || "",
            }}
            className="avatar-upload-crop"
            maxCount={1}
            onChange={handleUploadChange}
          >
            <div
              style={{ background: "rgba(55, 114, 255, 0.2)" }}
              className="py-2 px-4 rounded-full text-xs font-bold tracking-tight cursor-pointer mt-6 sm:mt-0"
            >
              {status === "uploading" ? "loading" : "Replace"}
            </div>
          </Upload>
        </ImgCrop>
      </div>

      {/* <input
        className="hidden"
        ref={fileInputRef}
        onChange={handleChange}
        type="file"
        accept=".png, .jpg, .jpeg"
      /> */}
    </div>
  );
};

const PublicProfile = () => {
  // const [firstName, setFirstName] = useState<string>("");
  // const [lastName, setLasrName] = useState<string>("");
  // const [website, setWebsite] = useState<string>("");
  const [discord, setDiscord] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  const [nickname, setNickName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>("");
  const navigate = useNavigate();
  const user = useRecoilValue(userInfoState);

  useEffect(() => {
    user?.nickname && setNickName(user.nickname);
    user?.bio && setBio(user.bio);

    // if (user?.username) {
    //   const [first = "", second = ""] = user.username.split(" ");
    //   setFirstName(first);
    //   setLasrName(second);
    // }
  }, [user]);

  const handleClickCancel = () => {
    navigate(-1);
  };

  const handleClick = async () => {
    const params = {
      // username: `${firstName} ${lastName}`,
      nickname,
      bio,
      // website,
      avatar,
    };
    setLoading(true);
    const isSuccess = await API.updateUserInfo(params);
    if (isSuccess) {
      message.success("Update Success");
    }
    setLoading(false);
  };

  return (
    <div
      className="text-center sm:text-left text-black px-4 sm:px-0"
      style={{ margin: "0 auto", maxWidth: 800 }}
    >
      <div className="sm:hidden relative h-11 flex items-center justify-center">
        <BackButton />
        <h1 className="text-lg font-bold">My Profile</h1>
      </div>
      {/* <h1 className="text-xl mt-12 sm:mt-0 font-bold"></h1> */}
      {/* <p className="mt-2 sm:mt-1">
        You will be able to see the specified information in your profile
      </p> */}
      <ProfileAvatar onChangeAvatar={(url: string) => setAvatar(url)} />
      {/* <div className="mb-8 sm:mb-12">
        <div className="text-left text-lg font-bold mb-3">Name</div>
        <div className="sm:flex items-center">
          <Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value.trim())}
            className="h-16 text-2xl px-6 flex-1 border rounded-2xl mr-5"
            placeholder="name"
          />
          <Input
            value={lastName}
            onChange={(e) => setLasrName(e.target.value.trim())}
            className="sm:mt-0 mt-4 h-16 text-2xl px-6 flex-1 border rounded-2xl"
            placeholder="name"
          />
        </div>
      </div> */}

      <div className="mb-8 sm:mb-12">
        <div className="text-left text-base font-bold mb-3">Nickname</div>
        <div>
          <Input
            value={nickname}
            onChange={(e) => setNickName(e.target.value.trim())}
            className="h-10 text-sm px-3 border rounded w-full"
            placeholder="Edit Your Nickname"
          />
        </div>
      </div>

      <div className="mb-8 sm:mb-12">
        <div className="text-left text-base font-bold mb-3">Bio</div>
        <div>
          <Input.TextArea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            autoSize={{ minRows: 3 }}
            className="h-10 text-sm px-3 border rounded w-full"
            placeholder="What's Your Story"
          />
        </div>
      </div>

      <div className="mb-8 sm:mb-12">
        <div className="text-left text-lg font-bold mb-3">Socials</div>
        <div className="flex items-center">
          <div className="flex items-center flex-1">
            <IconFont style={{ fontSize: 20 }} type="icon-discord" />
            <Input
              value={discord}
              onChange={(e) => setDiscord(e.target.value.trim())}
              className="h-10 text-sm px-3 border rounded w-full mx-3"
              placeholder="Enter Your Discord Account to Connect"
            />
          </div>
          <div className="flex items-center flex-1">
            <IconFont style={{ fontSize: 22 }} type="icon-twitter" />
            <Input
              value={twitter}
              onChange={(e) => setTwitter(e.target.value.trim())}
              className="h-10 text-sm px-3 border rounded w-full ml-3"
              placeholder="Enter Your Twitter Account to Connect"
            />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end">
        <Button
          className="w-24 h-9 rounded-full text-black text-xs font-bold mr-3"
          style={{ border: "1px solid rgba(118, 75, 223, 0.6)" }}
          onClick={handleClickCancel}
        >
          Cancel
        </Button>
        <Button
          className="w-24 h-9 rounded-full text-white text-xs font-bold"
          style={{ background: "#3772FF" }}
          onClick={handleClick}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default PublicProfile;

const BackButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div
      onClick={handleClick}
      className="rotate-180 absolute left-0 top-0 bg-white w-11 h-11 flex items-center justify-center rounded-full"
    >
      <IconFont type="icon-right" style={{ fontSize: 18 }} />
    </div>
  );
};
