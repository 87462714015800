import { Button, Checkbox, Form, Input, Modal, Popover } from "antd";

import API from "@/apis";
import ICON_LOGIN from "@/assets/icons/icon-login.svg";
import IconFont from "@/components/IconFont";
import { ISetInfo } from "@/models";
import { emojiGroupsState, loginVisibleState, officialEmojiGroupsState } from "@/store";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

const LoginButton = (props: { onLoginSuccess: () => void }) => {
  const [loginVisible, setLoginVisible] = useRecoilState(loginVisibleState);
  const setEmojiGroups = useSetRecoilState(emojiGroupsState);
  const setOfficalEmojiGroups = useSetRecoilState(officialEmojiGroupsState);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = async (values: any) => {
    if (loading) {
      return;
    }

    setLoading(true);
    const data = await API.login({
      username: values.username,
      password: values.password,
    });
    setLoading(false);

    if (data) {
      localStorage.setItem("access_token", data.token);
      props.onLoginSuccess();
      setLoginVisible(false);
      fetchGroups();
    }
  };

  const fetchGroups = async () => {
    const res = await API.getEmojiGroups();
    if (Array.isArray(res?.data)) {
      const promiseList = res.data
        .filter((i: any) => +i.id !== 1 && +i.id !== 2)
        .map((item: ISetInfo) => {
          return API.getEmojiListByGroup(item);
        });

      const officialEmojiGroups = res.data.filter((i: any) => +i.id === 1 || +i.id === 2);
      setOfficalEmojiGroups(officialEmojiGroups);

      const resList = await Promise.all(promiseList);
      setEmojiGroups(resList);
    }
  };

  return (
    <>
      <Popover overlayClassName="menu-popover" content="Sign in">
        <div className="cursor-pointer" onClick={() => setLoginVisible(true)}>
          <div className="h-12 mx-1 flex items-center justify-center">
            <img
              src={ICON_LOGIN}
              alt=""
              className="w-6 mr-1.5 transition duration-300 ease-in-out hover:scale-125 "
            />
            <div className="text-sm font-bold">Sign in</div>
          </div>
        </div>
      </Popover>
      <Modal
        visible={loginVisible}
        className="login-modal"
        title={null}
        footer={null}
        onCancel={() => setLoginVisible(false)}
        width={560}
        closeIcon={
          <div className="flex items-center justify-center w-14 h-14">
            <div className="flex items-center justify-center w-8 h-8">
              <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
            </div>
          </div>
        }
      >
        <div className="login-modal-content p-4">
          <LoginFormTitle />
          <LoginForm loading={loading} onSubmit={handleLogin} />
        </div>
      </Modal>
    </>
  );
};

export const LoginFormTitle = () => {
  return (
    <>
      <div className="hidden xs:block">
        <h3 className="text-center" style={{ fontSize: 42, lineHeight: "140%" }}>
          Welcome
          <br /> to Mojiverse
        </h3>
      </div>
      <div className="block xs:hidden">
        <h3 className="text-center text-2xl mb-4">
          Welcome
          <br /> to Mojiverse
        </h3>
      </div>
    </>
  );
};

export const LoginForm = (props: { onSubmit: (value: string) => void; loading: boolean }) => {
  const onFinish = (values: any) => {
    if (props.loading) {
      return;
    }
    props.onSubmit(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      style={{ width: "100%" }}
      className="login-form"
    >
      <Form.Item
        label="USER NAME"
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input className="w-full" />
      </Form.Item>

      <Form.Item
        label="PASSWORD"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password className="w-full" />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>
          <div className="text-black">Remember me</div>
        </Checkbox>
      </Form.Item>

      <Form.Item style={{ textAlign: "center", marginBottom: 8 }}>
        <Button loading={props.loading} className="login-button" type="primary" htmlType="submit">
          Log In
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginButton;
