import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title:
      "‘Republicans win on pocketbook issues’: DeSantis’ migrant flights aren’t rousing voters nationally",
    link: "https://www.politico.com/news/2022/10/09/desantis-migrant-flights-voters-polls-00061061",
    summary:
      "Immigration isn't leapfrogging abortion or the economy on lists of voters' top concerns in national polls and surveys in key battleground states.",
    published: "10/09/2022",
    author: "Lisa Kashinsky",
    image:
      "https://static.politico.com/f1/0a/ed3ef5014d4c884ab94ecb5f4fcf/election-2022-transporting-migrants-02944.jpg",
    credit: "Rebecca Blackwell/AP Photo",
    maz_template: "core",
    maz_modified: 1665313230789,
    tag: "politics",
  },
  {
    title: "Battle for Senate majority remains a nail-biter",
    link: "https://www.politico.com/news/2022/10/09/senate-swing-state-polls-2022-00060983",
    summary:
      "The 50-50 Senate is still finely balanced a month out from Election Day, the latest surveys show.",
    published: "10/09/2022",
    author: "Steven Shepard",
    image: "https://static.politico.com/ad/c4/1773be6445d292e95021444f4c69/warnockherschel.jpg",
    credit: "Meg Kinnard/AP Photo and Michael M. Santiago/Getty Images",
    maz_template: "core",
    maz_modified: 1665346692949,
    tag: "politics",
  },
  {
    title: "Trump’s legal drama is nowhere in the campaign ad wars",
    link: "https://www.politico.com/news/2022/10/09/trump-legal-issues-midterm-ad-buys-00059841",
    summary:
      "Candidates in swing districts are focused on abortion and the economy, not the FBI search of the former president’s home or a lawsuit against him in New York.",
    published: "10/09/2022",
    author: "Nicholas Wu and Jessica Piper",
    image:
      "https://static.politico.com/cb/88/122a56214be2bc66671f90d1e4d7/election-2022-trump-ads-07966.jpg",
    credit: "Tom E. Puskar/AP Photo",
    maz_template: "core",
    maz_modified: 1665313209329,
    tag: "politics",
  },
  {
    title: "Opinion | The Best Way to Respond to Saudi Arabia’s Embrace of Putin",
    link: "https://www.politico.com/news/magazine/2022/10/09/the-u-s-has-leverage-over-saudi-arabia-its-time-to-use-it-00061082",
    summary:
      "Sen. Richard Blumenthal and Rep. Ro Khanna propose new legislation to halt arms sales to Saudi Arabia and rebalance the U.S.'s relationship with Riyadh.",
    published: "10/09/2022",
    author: "Sen. Richard Blumenthal, Rep. Ro Khanna and Jeffrey Sonnenfeld",
    image:
      "https://static.politico.com/e0/a6/3153cc81478fa411142a5dd911a3/trump-argentina-g20-summit-99479.jpg",
    credit: "Pablo Martinez Monsivais/AP Photo",
    maz_template: "magazine",
    maz_modified: 1665357248970,
    tag: "politics",
  },
  {
    title: "A New Documentary Gives Us a Glimpse Into the Lincoln Project’s Hypocrisy",
    link: "https://www.politico.com/news/magazine/2022/10/09/what-lincoln-project-founders-have-in-common-with-trump-voters-00060993",
    summary:
      "Like many ends-driven Trump voters, the Lincoln Project head honchos have made moral compromises, too.",
    published: "10/09/2022",
    author: "Joanna Weiss",
    image:
      "https://static.politico.com/67/c7/114ebc594766b3d1a2c30446a728/lincoln-project.lede.jpg",
    credit: "Marget Long",
    maz_template: "magazine",
    maz_modified: 1665319159068,
    tag: "politics",
  },
  {
    title: "Text messages reveal what, exactly, the Jan. 6 crowd wanted Trump to do",
    link: "https://www.politico.com/news/2022/10/08/trump-insurrection-oath-keepers-rhodes-00061055",
    summary:
      "The Oath Keepers’ trial has placed a brighter light on the hopes and expectations of those who showed up on the Capitol to stop the certification of Biden’s election.",
    published: "10/08/2022",
    author: "Kyle Cheney and Josh Gerstein",
    image: "https://static.politico.com/c8/68/4b89b1a847cc98ead3624362b4a8/ap22250013416639.jpg",
    credit: "Susan Walsh/AP Photo",
    maz_template: "core",
    maz_modified: 1665269553838,
    tag: "politics",
  },
  {
    title: "Illinois Democrats carved up their state — and may still lose a seat",
    link: "https://www.politico.com/news/2022/10/08/democrats-campaign-ads-conservative-illinois-00061024",
    summary:
      "Outgoing Rep. Cheri Bustos’ campaign message in 2020 was “Illinois is worth fighting for.” Now, her one-time Republican challenger is running a similar message: “America is worth the fight.”",
    published: "10/08/2022",
    author: "Shia Kapos",
    image:
      "https://static.politico.com/a7/36/5064f0f84df293fb47a42867b291/https-delivery.gettyimages.com/downloads/961358492",
    credit: "Chip Somodevilla/Getty Images",
    maz_template: "core",
    maz_modified: 1665236490165,
    tag: "politics",
  },
  {
    title: "Adams drifts to a political island as New York’s migrant crisis grows",
    link: "https://www.politico.com/news/2022/10/07/asylum-seekers-new-york-city-eric-adams-00060814",
    summary:
      "Adams’ city is buckling under pressure to accommodate tens of thousands of asylum-seekers. Now he wants Biden’s help.",
    published: "10/07/2022",
    author: "Sally Goldenberg",
    image:
      "https://static.politico.com/d5/b4/47be1adb465cb4f9e3ea0d632f40/https-delivery.gettyimages.com/downloads/1418042631",
    credit: "Spencer Platt/Getty Images",
    maz_template: "core",
    maz_modified: 1665158546897,
    tag: "politics",
  },
  {
    title: "New York Rep. Lee Zeldin says 2 people shot outside his home",
    link: "https://www.politico.com/news/2022/10/09/lee-zeldin-people-shot-house-00061083",
    summary:
      "He said the two people who were shot were found under his porch and in the bushes in front of his house.",
    published: "10/09/2022",
    author: "Associated Press",
    image:
      "https://static.politico.com/be/f4/205d1f784224b81246607466bce2/election-2022-new-york-zeldin-shooting-06545.jpg",
    credit: "John Minchillo/AP Photo",
    maz_template: "core",
    maz_modified: 1665361687618,
    tag: "politics",
  },
  {
    title: "Russian ally cancels Russian-led military drill on its land",
    link: "https://www.politico.com/news/2022/10/09/russian-ally-cancels-russian-led-military-drill-on-its-land-00061081",
    summary: "The Central Asian nation of Kyrgyzstan did not explain why.",
    published: "10/09/2022",
    author: "Associated Press",
    image:
      "https://static.politico.com/66/bc/269c9f524de8bcf5b6d0cde0ad26/russia-ukraine-war-military-woes-explainer-25128.jpg",
    credit: "AP Photo",
    maz_template: "core",
    maz_modified: 1665363376193,
    tag: "politics",
  },
  {
    title: "Democrats 'need new blood,' congresswoman says",
    link: "https://www.politico.com/news/2022/10/09/slotkin-democrats-need-new-blood-pelosi-00061076",
    summary:
      'Rep. Elissa Slotkin would support President Joe Biden\'s reelection, she said, but highlighted the fact that she is "one of a handful of members" who has not voted for Rep. Nancy Pelosi as speaker.',
    published: "10/09/2022",
    author: "Olivia Olander",
    image:
      "https://static.politico.com/fc/cb/06de23b04f50bda7efc2e0cc6323/trump-impeachment-84684.jpg",
    credit: "Susan Walsh/AP Photo",
    maz_template: "core",
    maz_modified: 1665336532863,
    tag: "politics",
  },
  {
    title: "Arizona's Dem governor candidate steadfast against debate",
    link: "https://www.politico.com/news/2022/10/09/arizona-hobbs-lake-debate-governor-00061075",
    summary:
      "The race is between Kari Lake, who has denied the results of the 2020 election, and Katie Hobbs, who was secretary of state at the time.",
    published: "10/09/2022",
    author: "Olivia Olander",
    image:
      "https://static.politico.com/4f/94/db06645a46f0a1e2ad21e1db3039/election-2022-arizona-86414.jpg",
    credit: "Ross D. Franklin/AP Photo",
    maz_template: "core",
    maz_modified: 1665332711963,
    tag: "politics",
  },
  {
    title:
      "Nebraska lawmaker backs states rights but also supports federal abortion ban 'on principle'",
    link: "https://www.politico.com/news/2022/10/09/don-bacon-federal-abortion-ban-herschel-walker-00061072",
    summary: "Rep. Don Bacon also expressed support for Herschel Walker's candidacy.",
    published: "10/09/2022",
    author: "Olivia Olander",
    image:
      "https://static.politico.com/b6/ac/e752995c46638780ec1543d35da5/virus-outbreak-bacon-85061.jpg",
    credit: "Nati Harnik/AP Photo",
    maz_template: "core",
    maz_modified: 1665340160103,
    tag: "politics",
  },
  {
    title:
      "Twitter blocks — and then restores — Covid-19 vaccination post from Florida's surgeon general",
    link: "https://www.politico.com/news/2022/10/09/twitter-covid-vaccination-florida-surgeon-general-joe-ladapo-00061074",
    summary: "Joe Ladapo is an outspoken skeptic of Covid-19 vaccines.",
    published: "10/09/2022",
    author: "David Kihara",
    image:
      "https://static.politico.com/f6/b4/15d76f03426e9ae0c5f8c12b1b36/virus-outbreak-florida-86823.jpg",
    credit: "Wilfredo Lee/AP Photo",
    maz_template: "core",
    maz_modified: 1665337835487,
    tag: "politics",
  },
  {
    title: "Chris Murphy on Saudi Arabia: 'There's got to be consequences'",
    link: "https://www.politico.com/news/2022/10/09/murphy-saudi-arabia-consequences-00061068",
    summary:
      '“I think it’s time to admit the Saudis are not looking out for us," the Democratic senator said.',
    published: "10/09/2022",
    author: "Olivia Olander",
    image: "https://static.politico.com/01/bd/52a797884550b01d363cacc010dc/congress-guns-61582.jpg",
    credit: "J. Scott Applewhite/AP Photo",
    maz_template: "core",
    maz_modified: 1665330274016,
    tag: "politics",
  },
  {
    title: "'Those comments were reckless': Pompeo slams Biden's 'Armageddon' remarks",
    link: "https://www.politico.com/news/2022/10/09/pompeo-biden-russia-putin-nuclear-weapons-00061067",
    summary: 'He urged the president to pursue "quiet diplomacy."',
    published: "10/09/2022",
    author: "David Cohen",
    image:
      "https://static.politico.com/c3/fd/7eb8cb84430a97e759737ce5f06b/election-2024-pompeo-86794.jpg",
    credit: "Steven Senne/AP Photo",
    maz_template: "core",
    maz_modified: 1665349916544,
    tag: "politics",
  },
  {
    title: "Florida school shooter may have been his own worst witness",
    link: "https://www.politico.com/news/2022/10/09/parkland-shooter-sentencing-witness-cruz-florida-00061070",
    summary: "A jury will likely decide Nikolas Cruz’s fate this week.",
    published: "10/09/2022",
    author: "Associated Press",
    image:
      "https://static.politico.com/41/56/aeb222da4ebfb462571ff0196f5e/school-shooting-florida-61165.jpg",
    credit: "Amy Beth Bennett/South Florida Sun Sentinel via AP, Pool",
    maz_template: "core",
    maz_modified: 1665325592421,
    tag: "politics",
  },
  {
    title: "‘A time bomb’: Anger rising in a hot spot of Iran protests",
    link: "https://www.politico.com/news/2022/10/09/anger-rising-iran-protests-00061065",
    summary:
      "The anti-government protests in Sanandaj, 300 miles from the capital, are a microcosm of the leaderless protests that have roiled Iran.",
    published: "10/09/2022",
    author: "Associated Press",
    image:
      "https://static.politico.com/59/42/763f35c44abfa17a7fde0b09be73/iran-anatomy-of-protest-47458.jpg",
    credit: "AP Photo",
    maz_template: "core",
    maz_modified: 1665312770000,
    tag: "politics",
  },
  {
    title: "How Influential Senate Democrats Shut Down a Bid to Call Witnesses Against Trump",
    link: "https://www.politico.com/news/magazine/2022/10/07/trump-impeachment-trial-jamie-raskin-00060286",
    summary:
      "A new book shows how Democrats hobbled their own case to convict Trump after Jan. 6 by shooting down a last-minute bid for witnesses.",
    published: "10/07/2022",
    author: "Rachael Bade and Karoun Demirjian",
    image: "https://static.politico.com/55/4a/dee146cd44e69b6bb41fdffc4060/politico-3-final2.jpg",
    credit: "Illustration by Klawe Rzeczy / Getty Images / iStock",
    maz_template: "magazine",
    maz_modified: 1665150073902,
    tag: "politics",
  },
  {
    title: "Opinion | If Hunter Biden Gets Indicted, There’s an Upside for Joe",
    link: "https://www.politico.com/news/magazine/2022/10/08/hunter-biden-indicted-fbi-garland-00060992",
    summary:
      "Prosecuting the president’s son would be concrete proof of the Justice Department’s non-partisanship.",
    published: "10/08/2022",
    author: "Jack Shafer",
    image: "https://static.politico.com/48/57/ff0af3e447ffb135b5790d75f907/biden-68901.jpg",
    credit: "Manuel Balce Ceneta/AP Photo",
    maz_template: "magazine",
    maz_modified: 1665232866633,
    tag: "politics",
  },
  {
    title: "The Secret Tapes of Michael Fanone",
    link: "https://www.politico.com/news/magazine/2022/10/07/michael-fanone-jan-6-riot-cop-00060556",
    summary:
      "In a new memoir, the hero cop is naming names like Kevin McCarthy, Lindsey Graham and his own union bosses.",
    published: "10/07/2022",
    author: "Michael Schaffer",
    image: "https://static.politico.com/bb/d4/915ecfd34f75834b10c4bb9d821b/new-final-v2.png",
    credit: "POLITICO illustration / Getty Images / iStock",
    maz_template: "magazine",
    maz_modified: 1665153960659,
    tag: "politics",
  },
  {
    title: "Opinion | Liberals Currently Control Twitter. That Needs to Change.",
    link: "https://www.politico.com/news/magazine/2022/10/06/elon-musk-will-rewrite-the-rules-of-twitter-good-00060780",
    summary:
      "The panic over Elon Musk owning the social media platform underscores the need for reform.",
    published: "10/06/2022",
    author: "Rich Lowry",
    image:
      "https://static.politico.com/d3/45/459d9bb240dfa15f2ec590bbc19f/twitter-musk-subpoenas-89516.jpg",
    credit: "Jae C. Hong/AP Photo",
    maz_template: "magazine",
    maz_modified: 1665089102488,
    tag: "politics",
  },
  {
    title: "Opinion | Elon Musk Isn’t About to Light $44 Billion on Fire",
    link: "https://www.politico.com/news/magazine/2022/10/06/elon-musk-twitter-00060824",
    summary: "For all his bloviating, the tech billionaire will want Twitter to succeed.",
    published: "10/06/2022",
    author: "Jack Shafer",
    image:
      "https://static.politico.com/b9/d1/120674b64570881d958fabbd61b6/russia-ukraine-war-musk-52757.jpg",
    credit: "Pool photo by Patrick Pleul",
    maz_template: "magazine",
    maz_modified: 1665100179909,
    tag: "politics",
  },
  {
    title: "Opinion | Debates Are Dangerous. Time to Legislate Them.",
    link: "https://www.politico.com/news/magazine/2022/10/06/debates-are-dangerous-time-to-legislate-them-00060722",
    summary: "If campaigns keep dodging debates, don’t give them a choice.",
    published: "10/06/2022",
    author: "David Knight Legg",
    image:
      "https://static.politico.com/43/3f/84e089d549c6aaf4857222216098/hillary-clinton-crossroads-10782.jpg",
    credit: "David Goldman/AP Photo",
    maz_template: "magazine",
    maz_modified: 1665151089105,
    tag: "politics",
  },
  {
    title: "The Franken Campaign Was Doomed Even Before an Assault Allegation Shook the Race",
    link: "https://www.politico.com/news/magazine/2022/10/06/franken-grassley-abortion-iowa-00057656",
    summary:
      "After years of underinvestment in Iowa, Democrats didn’t stand much of a chance at Grassley’s Senate seat. But they could have been better positioned for this post-Dobbs moment.",
    published: "10/06/2022",
    author: "Lyz Lenz",
    image: "https://static.politico.com/6d/ba/3c58ffe142649eb62f788d063903/franken-speech.jpg",
    credit: "Stephen Maturen/Getty Images",
    maz_template: "magazine",
    maz_modified: 1665064228573,
    tag: "politics",
  },
  {
    title: "Herschel Walker and The Global War Over Sex",
    link: "https://www.politico.com/news/magazine/2022/10/06/herschel-walker-global-war-sex-00060634",
    summary:
      "Across the world, the right doesn’t care what you do in private. It cares what you say in public.",
    published: "10/06/2022",
    author: "John F. Harris",
    image:
      "https://static.politico.com/40/6b/3106b3ad4882990d5576583f6540/gettyimages-1240865646-edit.jpg",
    credit: "Megan Varner/Getty Images",
    maz_template: "magazine",
    maz_modified: 1665045044300,
    tag: "politics",
  },
  {
    title: "Beto O’Rourke Is Making His Last Stand in Texas",
    link: "https://www.politico.com/news/magazine/2022/10/05/beto-orourke-texas-governor-election-00060060",
    summary:
      "The former congressman and Democratic sensation is still trying to prove he can win statewide in his home state.",
    published: "10/05/2022",
    author: "David Siders",
    image:
      "https://static.politico.com/ba/b6/a1b12c994891ad0e6d3c767b1358/fifty.Siders.Beto.lead.jpg",
    credit: "Brandon Bell/Getty Images",
    maz_template: "magazine",
    maz_modified: 1664958611120,
    tag: "politics",
  },
  {
    title: "Memo to Biden and DeSantis: Watch Your (Body) Language",
    link: "https://www.politico.com/news/magazine/2022/10/05/biden-desantis-meeting-body-language-00060339",
    summary: "The optics of their Florida meeting will be impossible to ignore.",
    published: "10/05/2022",
    author: "Jeff Greenfield",
    image:
      "https://static.politico.com/e2/84/d73a85e44380b920c8f6ef77dd92/2022-desantis-biden-sidebyside-getty-773.jpg",
    credit: "Jeff Swensen and Samuel Corum/Getty Images",
    maz_template: "magazine",
    maz_modified: 1664986883263,
    tag: "politics",
  },
  {
    title: "One of the Most Famous Ideas in Economics Is Wrong",
    link: "https://www.politico.com/news/magazine/2022/10/05/free-markets-dont-buy-peace-00060236",
    summary: "History shows that free trade can’t buy world peace.",
    published: "10/05/2022",
    author: "Jacob Soll",
    image:
      "https://static.politico.com/9e/63/948fb3764568b26b749644ea37e8/mag-soll-tradepeacev184.jpg",
    credit: "POLITICO illustration; Wikimedia Commons",
    maz_template: "magazine",
    maz_modified: 1664974529513,
    tag: "politics",
  },
  {
    title: "Barnes takes the fight to Johnson in Wisconsin Senate debate",
    link: "https://www.politico.com/news/2022/10/07/barnes-johnson-wisconsin-senate-debate-00061056",
    summary:
      "The Democrat is seeking to reverse a recent slide in polls with Johnson widely seen as the most vulnerable GOP incumbent senator facing reelection in November.",
    published: "10/07/2022",
    author: "Holly Otterbein and Nancy Vu",
    image:
      "https://static.politico.com/0a/fb/25ccae30452296edd65e79027279/election-2022-wisconsin-senate-debate-42174.jpg",
    credit: "AP",
    maz_template: "core",
    maz_modified: 1665200192029,
    tag: "politics",
  },
  {
    title: "Trump’s super PAC swoops in to boost an ailing Walker",
    link: "https://www.politico.com/news/2022/10/07/trumps-super-pac-swoops-in-to-boost-an-ailing-walker-00061022",
    summary:
      "A new ad buy was placed by MAGA Inc. It doesn’t touch on allegations of abortion payments.",
    published: "10/07/2022",
    author: "Meridith McGraw",
    image:
      "https://static.politico.com/f7/c4/ea3388d64ead98a3da01bcdaf123/election-2022-senate-georgia-93255.jpg",
    credit: "Meg Kinnard/AP Photo",
    maz_template: "core",
    maz_modified: 1665193423734,
    tag: "politics",
  },
  {
    title: "Why Trump’s last-minute super PAC spending doesn’t go as far",
    link: "https://www.politico.com/news/2022/10/07/trumps-super-pac-ads-00061007",
    summary:
      "The new super PAC is the latest outside group to face jacked-up prices for ads ahead of the election.",
    published: "10/07/2022",
    author: "Jessica Piper",
    image:
      "https://static.politico.com/ef/bc/cc4f39ab446e8f2ab45d14f5c240/https-delivery.gettyimages.com/downloads/1243657679",
    credit: "Emily Elconin/Getty Images",
    maz_template: "core",
    maz_modified: 1665175815574,
    tag: "politics",
  },
  {
    title: "Republicans slash ad buys in N.H. Senate race as Hassan leads",
    link: "https://www.politico.com/news/2022/10/07/hassan-senate-republican-ad-buys-00061014",
    summary:
      "The Mitch McConnell-aligned Senate Leadership Fund super PAC, however, is still on the air for GOP nominee Don Bolduc.",
    published: "10/07/2022",
    author: "Natalie Allison and Burgess Everett",
    image:
      "https://static.politico.com/be/06/d6a23813492fa0cd562e0c9a1773/election-2022-what-to-watch-15114.jpg",
    credit: "Charles Krupa/AP Photo",
    maz_template: "core",
    maz_modified: 1665178078313,
    tag: "politics",
  },
  {
    title: "Sasse likely to resign from Senate, putting all eyes on Ricketts",
    link: "https://www.politico.com/news/2022/10/06/sasse-expected-to-resign-from-senate-00060812",
    summary:
      "The Nebraska Republican is likely to accept the University of Florida presidency and leave Congress in the near future, according to two people familiar with his plans.",
    published: "10/06/2022",
    author: "Burgess Everett and Marianne LeVine",
    image:
      "https://static.politico.com/ac/bd/356a6aaf4bd99257307dc09b45f5/senate-federal-agencies-hacked-22174.jpg",
    credit: "Drew Angerer/AP Photo",
    maz_template: "core",
    maz_modified: 1665094847330,
    tag: "politics",
  },
  {
    title: "House GOP confronts its 2023 rift: Impeachments",
    link: "https://www.politico.com/news/2022/10/07/house-gop-2023-impeachments-00060869",
    summary:
      "Conservatives are homing in on two members of Joe Biden's Cabinet — but the biggest schism in any Republican majority will come over the president himself.",
    published: "10/07/2022",
    author: "Jordain Carney",
    image:
      "https://static.politico.com/8a/b4/189aacde4147a61b395017dbf3d6/https-delivery.gettyimages.com/downloads/1177076170",
    credit: "Alex Wong/Getty Images",
    maz_template: "core",
    maz_modified: 1665347257109,
    tag: "politics",
  },
  {
    title: "Buckle up, Congress: A ‘very lame’ lame duck is coming after the election",
    link: "https://www.politico.com/news/2022/10/06/congress-lame-duck-00060569",
    summary:
      "This year’s winter session is jam-packed with minefields and postponed work. Get ready for a holiday session that could spill past Christmas.",
    published: "10/06/2022",
    author: "Sarah Ferris, Burgess Everett and Caitlin Emma",
    image:
      "https://static.politico.com/13/a2/194cd012403299a6c0add3e80071/https-delivery.gettyimages.com/downloads/1238862453",
    credit: "J. Scott Applewhite/AP Photo",
    maz_template: "core",
    maz_modified: 1665045051466,
    tag: "politics",
  },
  {
    title: "Inside the GOP's heated, leaky race to lead a powerful House panel",
    link: "https://www.politico.com/news/2022/10/04/house-gop-ways-and-means-00060135",
    summary:
      "Rep. Vern Buchanan was the supposed frontrunner for the top spot on Ways and Means, but some key lawmakers say his attempts to project support may have backfired.",
    published: "10/04/2022",
    author: "Olivia Beavers",
    image:
      "https://static.politico.com/44/ba/f2400c994027bf99eb849784b3e6/221003-jason-smith-getty.jpg",
    credit: "Anna Moneymaker/Getty Images",
    maz_template: "core",
    maz_modified: 1664872225401,
    tag: "politics",
  },
  {
    title: "Tillerson testifies he wasn't aware of indicted Trump ally's foreign policy advice",
    link: "https://www.politico.com/news/2022/10/03/tillerson-wasnt-aware-of-indicted-trump-allys-foreign-policy-advice-00060145",
    summary:
      "The former secretary of State took the stand in the foreign agent trial of Tom Barrack.",
    published: "10/03/2022",
    author: "Caitlin Oprysko",
    image:
      "https://static.politico.com/38/94/90bf01ec433482394b24ec800c1d/https-delivery.gettyimages.com/downloads/1179042846",
    credit: "Drew Angerer/Getty Images",
    maz_template: "core",
    maz_modified: 1664917038761,
    tag: "politics",
  },
  {
    title: "Proud Boys leader pleads guilty to seditious conspiracy over Jan. 6 actions",
    link: "https://www.politico.com/news/2022/10/06/proud-boys-leader-pleads-guilty-to-seditious-conspiracy-over-jan-6-actions-00060819",
    summary:
      "He's the first member of the group to admit to the charge stemming from the Jan. 6 attack on the Capitol.",
    published: "10/06/2022",
    author: "Kyle Cheney",
    image:
      "https://static.politico.com/2b/ce/b11d252043e79ddca248338d8af6/20221006-proud-boys-jeremy-bertino-wc.jpg",
    credit: "Anthony Crider via Wikimedia Commons",
    maz_template: "core",
    maz_modified: 1665097470789,
    tag: "politics",
  },
  {
    title: "High court seems chilly to ‘race neutral’ reading of Voting Rights Act",
    link: "https://www.politico.com/news/2022/10/04/high-court-race-neutral-voting-rights-act-00060270",
    summary:
      "Lower federal courts agreed and ordered a redraw of the maps ahead of the November elections. But the nation’s highest court stayed that order and agreed to hear Tuesday’s arguments.",
    published: "10/04/2022",
    author: "Zach Montellaro and Josh Gerstein",
    image:
      "https://static.politico.com/0b/19/b20b9a404b42a537cde04967bb38/https-delivery.gettyimages.com/downloads/1430056014",
    credit: "Anna Moneymaker/Getty Images",
    maz_template: "core",
    maz_modified: 1664912909991,
    tag: "politics",
  },
  {
    title: "Trump asks Supreme Court to intervene in Mar-a-Lago documents case",
    link: "https://www.politico.com/news/2022/10/04/trump-asks-supreme-court-to-intervene-in-mar-a-lago-documents-case-00060300",
    summary:
      "Trump's request would put about 100 potentially classified documents back into the special master review.",
    published: "10/04/2022",
    author: "Josh Gerstein, Kyle Cheney and Nicholas Wu",
    image:
      "https://static.politico.com/b7/98/9f11bd624ecc99fed78197abd0c3/https-delivery.gettyimages.com/downloads/1413177094",
    credit: "Scott Olson/Getty Images",
    maz_template: "core",
    maz_modified: 1664926020365,
    tag: "politics",
  },
  {
    title: "Pardons, descheduling and the DEA: Making sense of Biden's weed actions",
    link: "https://www.politico.com/news/2022/10/07/biden-weed-executive-action-dea-00060978",
    summary:
      "Here’s a look at what Joe Biden’s executive actions do — and don’t do — and what it could mean for America’s burgeoning multibillion-dollar marijuana markets.",
    published: "10/07/2022",
    author: "Mona Zhang",
    image: "https://static.politico.com/a0/31/1788811d4517a002919dcddcff8f/biden-41520.jpg",
    credit: "Manuel Balce Ceneta/AP Photo",
    maz_template: "core",
    maz_modified: 1665167474911,
    tag: "politics",
  },
  {
    title: "Biden signs executive order on EU-U.S. data privacy agreement",
    link: "https://www.politico.com/news/2022/10/07/biden-executive-order-eu-data-privacy-agreement-00060872",
    summary:
      "The order will set surveillance limits and establish a new court for European citizens to redress privacy concerns with US intelligence agencies.",
    published: "10/07/2022",
    author: "Alfred Ng, Vincent Manancourt, Mark Scott and Clothilde Goujard",
    image: "https://static.politico.com/17/fd/f3a038c84707b298224946877074/not-real-news-21720.jpg",
    credit: "Evan Vucci/AP Photo",
    maz_template: "core",
    maz_modified: 1665151601865,
    tag: "politics",
  },
];
const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "politico",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: item.author,
      nickname: item.author,
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsPolitico: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsPolitico;
