import API from "@/apis";
import { IEmoji, ISetInfoWithEmojis } from "@/models";
import { LoadingOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import * as React from "react";
import EmojiV2 from "../Emoji/EmojiV2";

interface Props {
  ispc: boolean;
  isActive: boolean;
  fetchNew: boolean;
  set: ISetInfoWithEmojis;
  onFetchNew: () => void;
}

const EmojiSetCollapsePanelDetail: React.FC<Props> = (props: Props) => {
  const [list, setList] = React.useState<IEmoji[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const hasLoaded = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (props.fetchNew) {
    }
  }, [props.fetchNew]);

  React.useEffect(() => {
    const fetchEmojiInSet = async (set: ISetInfoWithEmojis) => {
      const [success, data] = await API.getEmojiList({
        group: `${set.id}`,
      });
      if (success && Array.isArray(data?.data)) {
        setList(data?.data);
        hasLoaded.current = true;
      }
      setLoading(false);
    };
    if (props.fetchNew) {
      setLoading(true);
      fetchEmojiInSet(props.set);
      props.onFetchNew();
      return;
    }
    // if (props.isActive && props.set && !hasLoaded.current) {
    //   fetchEmojiInSet(props.set);
    // }
  }, [props, hasLoaded]);

  const tabContentClass = `w-full pb-2 grid gap-x-3 gap-y-2 ${
    props.ispc ? "grid-cols-6" : "grid-cols-4"
  }`;

  const emojiList = list.length === 0 ? props.set.emojis : list;

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center h-10 w-full">
          <LoadingOutlined style={{ fontSize: 24 }} />
        </div>
      ) : (
        <>
          {emojiList.length === 0 ? (
            <div className="w-20">
              <Empty style={{ margin: 0 }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          ) : (
            <div className={tabContentClass}>
              {emojiList.map((emojiItem, index) => {
                return (
                  <div className="max-w-[72px] w-[72px]" key={emojiItem.code}>
                    <EmojiV2
                      // onClick={handleClickEmoji(emojiItem)}
                      width={42}
                      height={42}
                      emoji={emojiItem}
                      autoScaleBack
                      showName
                    />
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmojiSetCollapsePanelDetail;
