import API from "@/apis";
import ICON_BOOK from "@/assets/icons/icon-book.png";
import ICON_DANGER from "@/assets/icons/icon-danger.png";
import ICON_SHARE from "@/assets/icons/icon-share.png";
import EmojiWithLottie, {
  CommonEmojiList,
  EmojiImageSrcMap,
  LottieName,
  SelectOptionEmojiList,
} from "@/components/Emoji";
import IconFont from "@/components/IconFont";
import { HomeDataDisplayMode, IHomeData } from "@/models/index";
import { useNavigate } from "react-router-dom";

// import { LikeSelect } from "../Like";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { homeActiveEmojiState, homeDataState, tagState } from "@/store";
import { Popover, Tag } from "antd";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import NytimesIcon from "../Icons/Nytimes";
import SeekingAlphaIcon from "../Icons/SeekingAlpha";

const EmojiList = [
  LottieName.Useful,
  LottieName.Funny,
  LottieName.WoW,
  LottieName.Tasty,
  LottieName.Cute,
  LottieName.Crying,
  LottieName.WTF,
  LottieName.Mad,
];

// import "./index.css";

const formatTagsToArray = (tagsStr: string): string[] => {
  try {
    const res = JSON.parse(tagsStr);
    if (Array.isArray(res)) {
      return res;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

// vercel 强制 https 问题
export const formatImageFotVercel = (url?: string) => {
  // return "/uploads/20220522205307.png";
  if (!url) {
    return "";
  }

  if (url.startsWith("/uploads")) {
    return url;
  }

  if (url.startsWith("http")) {
    return url;
  }

  const _url = `https://image.tmdb.org/t/p/w500/${url}`;
  return _url.replace("http://49.235.185.49:3000", "").replace("http://100.24.6.246", "");
};

interface NoteProps {
  data: IHomeData;
  onClickComment?: () => void;
  mode?: HomeDataDisplayMode;
  hideTag?: boolean;
  showTopEmoji?: boolean;
}

interface INoteEmoji {
  name: LottieName;
  count: number;
  index: number;
}

const findMax = (emojis: number[], selectedIdxs: number[]) => {
  let max = 0;
  let index = 0;
  emojis.forEach((item, idx) => {
    if (+item >= max && selectedIdxs.indexOf(idx) === -1) {
      max = +item;
      index = idx;
    }
  });
  return {
    max,
    index,
  };
};

const sortEmojiList = (emojis: number[]) => {
  const list = [...emojis];
  var max = findMax(list, []);
  // list.splice(max.index, 1);
  var second = findMax(list, [max.index]);
  // list.splice(second.index, 1);
  var third = findMax(list, [max.index, second.index]);
  // list.splice(third.index, 1);
  var fourth = findMax(list, [max.index, second.index, third.index]);
  return [max, second, third, fourth];
};

const Note = ({ data, onClickComment, mode, hideTag, showTopEmoji }: NoteProps) => {
  const navigate = useNavigate();
  const homeActiveEmoji = useRecoilValue(homeActiveEmojiState);
  const [tag, setTag] = useRecoilState(tagState);
  const [homeData, setHomeData] = useRecoilState(homeDataState);
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);

  const [emojiList, setEmojiList] = useState<Array<INoteEmoji>>([]);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    // const emojis = data.emoji || [0, 0, 0, 0, 0, 0, 0, 0];
    const emojis = [0, 0, 0, 0, 0, 0, 0, 0];
    const res = sortEmojiList(emojis);

    setEmojiList([
      {
        name: EmojiList[res[0].index],
        count: res[0].max || 0,
        index: res[0].index,
      },
      {
        name: EmojiList[res[1].index],
        count: res[1].max || 0,
        index: res[1].index,
      },
      {
        name: EmojiList[res[2].index],
        count: res[2].max || 0,
        index: res[2].index,
      },
      {
        name: EmojiList[res[3].index],
        count: res[3].max || 0,
        index: res[3].index,
      },
    ]);
  }, [data]);

  const tags = formatTagsToArray(data?.tags);
  const emojiSize = isLargerThan414 ? 28 : 17;
  // const maxEmojiSize = isLargerThan414 ? 32 : 24;

  // const handleClickUser = () => {
  //   navigate("/user?id=" + data.id);
  // };

  const handleClickEmoji = async (index: number) => {
    const res = await API.postEmoji(data.id, (index + 1).toString());
    return +res?.data || 0;
  };

  const handleGoDetail = () => {
    if (data?.isDemo) {
      return;
    }
    navigate("/detail?id=" + data.id);
  };

  const handleClickPopoverEmoji = (name: LottieName, index: number) => async () => {
    const findItem = emojiList.find((i) => i.name === name);
    const newCount = await handleClickEmoji(index);

    if (findItem) {
      const temp = emojiList.filter((i) => i.name !== name);
      const currItemCount = findItem.count + newCount;
      setEmojiList([
        ...temp,
        {
          ...findItem,
          name,
          count: currItemCount > 0 ? currItemCount : 0,
          index,
        },
      ]);
    } else {
      // const emojis = data.emoji || [];
      // const emojis = [];
      const currItemCount = newCount;
      setEmojiList([
        emojiList[1],
        emojiList[2],
        emojiList[3],
        {
          name,
          count: currItemCount > 0 ? currItemCount : 0,
          index,
        },
      ]);
    }

    setVisible(false);
    return 0;
  };

  const handleClickSelectedEmoji = (data: INoteEmoji) => async () => {
    const newCount = await handleClickEmoji(data.index);
    const temp = emojiList.map((item) => {
      if (item.name === data.name) {
        return {
          ...data,
          count: data.count + newCount,
        };
      } else {
        return { ...item };
      }
    });

    setEmojiList(temp);
    return 0;
  };

  const handleClickTag = (name: string) => {
    if (name !== tag) {
      setTag(name);

      setHomeData({
        ...homeData,
        loading: true,
      });
      API.getHomePostList({
        code: homeActiveEmoji ? homeActiveEmoji.code : undefined,
        orderDir: homeActiveEmoji ? "desc" : undefined,
        tag: name,
        page: 1,
      }).then((data) => {
        const newItems = data.data.map((i: IHomeData, idx: number) => {
          return {
            groupKey: 1,
            key: idx,
            data: i,
          };
        });
        setHomeData({
          page: 2,
          total: data.total || 0,
          data: [...newItems],
          loading: false,
        });
      });
    } else {
      setTag("");

      setHomeData({
        ...homeData,
        loading: true,
      });
      API.getHomePostList({
        code: homeActiveEmoji ? homeActiveEmoji.code : undefined,
        orderDir: homeActiveEmoji ? "desc" : undefined,
        page: 1,
      }).then((data) => {
        const newItems = data.data.map((i: IHomeData, idx: number) => {
          return {
            groupKey: 1,
            key: idx,
            data: i,
          };
        });
        setHomeData({
          page: 2,
          total: data.total || 0,
          data: [...newItems],
          loading: false,
        });
      });
    }
  };

  const list: number[] = [];
  let max = 0;
  let maxIndex = -1;
  list.forEach((i, idx) => {
    if (i > max) {
      max = i;
      maxIndex = idx;
    }
  });

  const showEmojiList = isLargerThan414
    ? emojiList
    : emojiList.length === 4
    ? [emojiList[1], emojiList[2], emojiList[3]]
    : [];

  return (
    <div
      className={`note w-full sm:w-80 mb-3 sm:mb-6 relative ${
        !!data.image ? "sm:px-0" : "note-box-shadow p-2"
      }`}
    >
      {data.image ? (
        <div
          onClick={handleGoDetail}
          className="group relative w-full sm:w-80 rounded overflow-hidden cursor-pointer min-h-[200px] note-box-shadow"
        >
          <img
            src={formatImageFotVercel(data.image)}
            alt=""
            className="w-full min-h-[200px] sm:min-h-[320px] sm:max-h-[320px] align-middle object-cover"
          />
          {showTopEmoji ? (
            <div
              className="shadow-lg rounded-full h-6 sm:h-12 w-6 sm:w-12 ml-4 mb-3 right-2 sm:right-5 top-2 sm:top-4 absolute"
              // style={{ top: -maxEmojiSize / 2, left: -maxEmojiSize / 2 }}
            >
              <img
                src={EmojiImageSrcMap[EmojiList[maxIndex]]}
                alt=""
                className="h-6 sm:h-12 w-6 sm:w-12"
                // style={{ width: maxEmojiSize, height: maxEmojiSize }}
              />
            </div>
          ) : null}
          <div
            className={`absolute h-12 right-2 sm:right-5 -top-60 duration-300 ${
              showTopEmoji
                ? "group-hover:top-12 sm:group-hover:top-20"
                : "group-hover:top-2 sm:group-hover:top-4"
            }`}
          >
            {/* <div
            className="h-6 sm:h-12 w-16 sm:w-32 rounded-full flex items-center justify-center"
            style={{ backgroundColor: "#F42E98" }}
          >
            <div className="text-white text-xs sm:text-base font-bold">Love</div>
          </div> */}
            <div className="note-icon-btn h-6 sm:h-12 w-6 sm:w-12 rounded-full flex items-center justify-center ml-4">
              <img className="h-6 sm:h-12 w-6 sm:w-12" src={ICON_SHARE} alt="" />
            </div>
            <div className="note-icon-btn h-6 sm:h-12 w-6 sm:w-12 rounded-full flex items-center justify-center ml-4 mt-3">
              <img className="h-6 sm:h-12 w-6 sm:w-12" src={ICON_BOOK} alt="" />
            </div>
            <div className="note-icon-btn h-6 sm:h-12 w-6 sm:w-12 rounded-full flex items-center justify-center ml-4 mt-3">
              <img className="h-6 sm:h-12 w-6 sm:w-12" src={ICON_DANGER} alt="" />
            </div>
          </div>

          {hideTag ? null : (
            <div
              onClick={() => handleClickTag(tags?.[0] || "")}
              className="absolute bottom-5 right-5 text-white font-bold text-base"
            >
              {tags[0]}
            </div>
          )}
        </div>
      ) : null}

      {/* <div className="w-full flex items-center justify-between mt-4 mb-2">
        <LikeSelect small onSelect={handleLikeSelect} />

        <div className="flex items-center cursor-pointer " onClick={onClickComment}>
          <IconFont
            type="icon-chat"
            className="mr-2"
            style={{
              color: "#777E91",
              fontSize: 18,
            }}
          />
          <div className="text-xs">{data.comment ? data.comment.total : 0}</div>
        </div>
      </div> */}

      <div
        className="flex items-center w-full flex-wrap mt-2 cursor-pointer"
        // onClick={handleGoDetail}
      >
        {data.isDemo ? (
          <div className="text-xs xs:text-base font-bold">
            <DemoTags data={data} />
            {data.title || "Title"}
          </div>
        ) : (
          <div className="text-xs xs:text-base font-bold with-2-col">{data.title || "Title"}</div>
        )}
        {/* <div>{formatTags(data?.tags)}</div> */}
      </div>

      <div className="w-full flex items-center justify-between my-1 py-1 xs:py-2 px-2 xs:px-4 bg-[#FFF8E9] rounded-xl xs:rounded-full">
        {showEmojiList.map((item) => {
          // const count = data.emoji ? +data.emoji[index] : 0;
          return (
            <div
              className="flex items-center border border-yellow-400 px-1.5 py-0.5 rounded-xl"
              key={item.name}
            >
              <EmojiWithLottie
                autoScaleBack
                width={emojiSize}
                height={emojiSize}
                name={item.name}
                onClick={handleClickSelectedEmoji(item)}
              />
              <div className="ml-0.5 xs:ml-1.5 text-xs">{item.count}</div>
            </div>
          );
        })}
        <Popover
          content={
            <div className="w-full grid gap-x-3 gap-y-2 grid-cols-8">
              {[...CommonEmojiList, ...SelectOptionEmojiList].map((item, index) => {
                return (
                  <EmojiWithLottie
                    onClick={handleClickPopoverEmoji(item, index)}
                    width={emojiSize}
                    height={emojiSize}
                    key={item}
                    name={item}
                    autoScaleBack
                  />
                );
              })}
            </div>
          }
          title={null}
          trigger="click"
          // placement="topRight"
          overlayClassName="emoji-popover"
          visible={visible}
          onVisibleChange={setVisible}
        >
          {isLargerThan414 ? (
            <div className="flex items-center justify-center flex-col mx-1 sm:mx-2 relative">
              <IconFont type="icon-biaoqing2" style={{ fontSize: emojiSize, color: "#F4C232" }} />
              <div className="absolute -top-1 -right-1 rounded-full bg-[#FFF8E9] flex itmes-center justify-center p-1">
                <IconFont type="icon-add2" style={{ fontSize: 12, color: "#F4C232" }} />
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center flex-col mx-1 sm:mx-2 relative">
              <IconFont type="icon-biaoqing2" style={{ fontSize: 20, color: "#F4C232" }} />
              <div className="absolute -top-1 -right-1 rounded-full bg-[#FFF8E9] flex itmes-center justify-center p-1">
                <IconFont type="icon-add2" style={{ fontSize: 8, color: "#F4C232" }} />
              </div>
            </div>
          )}
        </Popover>
      </div>

      {/* <div className="text-xs xs:text-sm w-full text-ellipsis whitespace-nowrap overflow-hidden">
        {data.comment
          ? data.comment.content || "我是评论我是评论我是评论我是评论我是评论我是评论我是评论"
          : "我是评论"}
      </div> */}
    </div>
  );
};

const DemoTags = ({ data }: { data: IHomeData }) => {
  if (data.isDemo) {
    if (data.website === "seekingalpha.com") {
      return (
        <>
          <span className="seeking-alpha-icon">
            <SeekingAlphaIcon color="#fff" />
          </span>
          <Tag color="#87d068">{data.tag}</Tag>
        </>
      );
    } else if (data.website === "www.nytimes.com") {
      return (
        <>
          <Tag icon={<NytimesIcon color="#fff" />} color="#55acee">
            nytimes
          </Tag>
          <Tag color="#87d068">{data.tag}</Tag>
        </>
      );
    } else {
      return <span></span>;
    }
  } else {
    return <span></span>;
  }
};

export default Note;
