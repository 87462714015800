const LikeIcon = ({ color, size }: { color: string; size?: number }) => {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6.37607C9.25926 2.61717 3 6.84593 3 12.5873C3 19.5593 10.0398 23.6139 14.9024 26.4145C15.2831 26.6338 15.6504 26.8453 16 27.05C16.3496 26.8453 16.7169 26.6338 17.0976 26.4145C21.9602 23.6139 29 19.5593 29 12.5873C29 6.84593 22.7407 2.61717 16 6.37607Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 6.37607C9.25926 2.61717 3 6.84593 3 12.5873C3 19.5593 10.0398 23.6139 14.9024 26.4145C15.2831 26.6338 15.6504 26.8453 16 27.05C16.3496 26.8453 16.7169 26.6338 17.0976 26.4145C21.9602 23.6139 29 19.5593 29 12.5873C29 6.84593 22.7407 2.61717 16 6.37607Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2256 12.8264C24.2256 11.108 22.8326 9.715 21.1142 9.715"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default LikeIcon;
