import API from "@/apis";
import { IEmoji, ISetInfo } from "@/models";
import { Button, message } from "antd";
import * as React from "react";
import EmojiV2 from "../Emoji/EmojiV2";

interface Props {
  relatedEmojis: IEmoji[];
  info: ISetInfo;
  onSaveSuccess: () => void;
}

const AddEmojiToSet: React.FC<Props> = (props: Props) => {
  const [emojis, setEmojis] = React.useState<IEmoji[]>([]);
  // const [loading, setLoading] = React.useState<boolean>(true);

  const handleClickSave = async () => {
    const promiseList = emojis.map((emoji) => {
      return API.relatedEmoji({
        code: emoji.code,
        id: props.info.id,
      });
    });
    await Promise.all(promiseList);
    message.success("Success");
    props.onSaveSuccess();
  };

  const isActive = (code: string) => emojis.map((i) => i.code).includes(code);
  const handleClickEmoji = (item: IEmoji) => async () => {
    if (emojis.map((i) => i.code).includes(item.code)) {
      setEmojis(emojis.filter((_i) => _i.code !== item.code));
    } else {
      setEmojis([...emojis, item]);
    }
    return 0;
  };
  return (
    <div className="-container">
      <div className=" flex items-center justify-between">
        <h3 className="font-bold text-base">Select your custom emojis to 「{props.info.name}」</h3>
        <Button type="primary" onClick={handleClickSave}>
          Save
        </Button>
      </div>

      <div className="mt-4 emojis grid grid-cols-8 gap-x-2">
        {props.relatedEmojis.map((item) => {
          const emojiIsActive = isActive(item.code);
          return (
            <div
              onClick={handleClickEmoji(item)}
              className={`px-1 py-1 my-1 cursor-pointer border border-transparent rounded-md hover:bg-zinc-200 ${
                emojiIsActive ? "border-rose-500" : ""
              }`}
              key={item.code}
            >
              <EmojiV2 key={item.code} width={40} height={40} emoji={item} noScale />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddEmojiToSet;
