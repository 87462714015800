import EmojiWithLottie, { EmojiImageSrcMap, LottieName } from "@/components/Emoji";
import { Button, Input, InputNumber, message, Select, Space, Switch } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./animate.css";
import "./index.css";

import DEMO_IMAGE from "@/assets/demo.jpg";
import IconFont from "@/components/IconFont";

// const DEFAULT_IMAGE = "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-11.png";
const EMOJI_LIST = [
  {
    type: LottieName.Useful,
    name: "Useful",
  },
  {
    type: LottieName.Funny,
    name: "Funny",
  },
  {
    type: LottieName.Cool,
    name: "Cool",
  },

  {
    type: LottieName.Tasty,
    name: "Tasty",
  },
  {
    type: LottieName.Cute,
    name: "Cute",
  },
  {
    type: LottieName.Crying,
    name: "Crying",
  },
  {
    type: LottieName.WTF,
    name: "WTF",
  },
  {
    type: LottieName.Mad,
    name: "Mad",
  },
];

const getCommentFromLocalStorage = (id: string) => {
  try {
    const listStr = localStorage.getItem(`comment-data-list-${id}`);
    if (!listStr) {
      return [];
    }

    const list = JSON.parse(listStr);
    return Array.isArray(list) ? list : [];
  } catch (error) {
    return [];
  }
};

const appendListWithDuplicate = (list: Array<CommentItem>, item: CommentItem) => {
  const duplicateList = list.filter(
    (i) => i.position.top !== item.position.top || i.position.left !== item.position.left
  );

  return [...duplicateList, item];
};

const saveComment2LocalStorage = (commentData: CommentItem, id: string) => {
  const comments = appendListWithDuplicate(getCommentFromLocalStorage(id), commentData);
  localStorage.setItem(`comment-data-list-${id}`, JSON.stringify(comments));
};

interface Props {}

interface CommentItem {
  position: CommentItemPosition;
  data: string;
  emojiName: LottieName;
  id: number;
  rotateDeg: number;
}

interface CommentItemPosition {
  top: number;
  left: number;
}

let isMouseDown = false;

const Component: React.FC<Props> = (props: Props) => {
  const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [visible, setVisible] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [emoji, setEmoji] = useState<LottieName | null>(null);
  const [commentList, setCommentList] = useState<CommentItem[]>([]);

  const [newCommentList, setNewCommentList] = useState<CommentItem[]>([]);
  // const [id, setId] = useState<string>("default");
  const id = "default-demo";
  const [fileList, setFileList] = useState<FileList | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [maxCommentCount, setMaxCommentCount] = useState<number>(3);
  const [commentType, setCommentType] = useState<string>("emoji+text");
  const [autoHide, setAutoHide] = useState<boolean>(true);

  const [checked, setChecked] = useState<boolean>(false);

  useLayoutEffect(() => {
    setTimeout(handleStart, 1000);
    // document.onmousemove = (event) => {
    //   console.log(event.clientX, event.clientY);
    // };
    document.ondragstart = function () {
      return false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!visible) {
      setComment("");
    }
  }, [visible]);

  useEffect(() => {
    if (commentType === "close") {
      setVisible(false);
    }
  }, [commentType]);

  useEffect(() => {
    const list = getCommentFromLocalStorage(id);
    setCommentList(list);
  }, [id]);

  const handleStart = () => {
    const items = document.getElementsByClassName("comment-item-container");
    console.log(items.length);
    const total = Math.floor(items.length / maxCommentCount);
    console.log(total);
    let count = 0;
    for (let i = 0; i < maxCommentCount; i++) {
      const element = items[i + count * maxCommentCount];
      if (element) {
        element.classList.add("fade-out");
        element.classList.remove("hidden");
      }
    }
    const aInterval = setInterval(() => {
      count++;
      if (count > total) {
        setAutoHide(false);
        aInterval && clearInterval(aInterval);
        return;
      }
      for (let i = 0; i < maxCommentCount; i++) {
        const element = items[i + count * maxCommentCount];
        if (element) {
          element.classList.add("fade-out");
          element.classList.remove("hidden");
        }
      }
    }, 4 * 1000);

    // animationDelay: `${Math.floor(index / maxCommentCount) * 4 + 3}s`,
  };

  const handleClick: React.MouseEventHandler<HTMLImageElement> = (e) => {
    if (commentType === "close") {
      message.info("评论已关闭");
      return;
    }
    if (visible) {
      setVisible(false);
    } else {
      setEmoji(null);
      setPosition({
        top: e.nativeEvent.offsetY,
        left: e.nativeEvent.offsetX,
      });
      setVisible(true);
    }
  };

  const handleClear = () => {
    setCommentList([]);
    localStorage.setItem(`comment-data-list-${id}`, JSON.stringify([]));
  };

  const handleClickEmoji = (name: LottieName) => async () => {
    console.log(name);
    const randomDeg = Math.floor(Math.random() * 50) - 25;
    const params = {
      position,
      data: comment,
      emojiName: name,
      id: commentList.length,
      rotateDeg: randomDeg,
    };
    saveComment2LocalStorage(params, id);
    setVisible(false);
    setNewCommentList(appendListWithDuplicate(newCommentList, params));
    return 0;
  };

  const handleClickComment = (item: CommentItem) => {
    setComment(item.data);
    setPosition(item.position);
    setEmoji(item.emojiName);
    setVisible(true);
  };

  const handleChangeImage: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setFileList(e.target.files);
    console.log(e.target.files);
  };

  const hasFile = fileList !== null;
  let url = "";
  if (hasFile) {
    url = window.URL
      ? window.URL.createObjectURL(fileList[0])
      : window.webkitURL.createObjectURL(fileList[0]);
  }

  let top = 0;
  let left = 0;

  const handleMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (isMouseDown && checked) {
      if (containerRef?.current) {
        const img = document.createElement("img");
        const div = document.createElement("div");
        div.className =
          "w-8 h-8 absolute flex items-center justify-center shadow-md rounded-full bg-white";
        img.src = EmojiImageSrcMap[LottieName.Cute];
        img.className = "w-6 h-6";
        const offsetLeft = containerRef?.current.offsetLeft;
        const offsetTop = containerRef?.current.offsetTop;
        const newTop = event.clientY - offsetTop;
        const newLeft = event.clientX - offsetLeft;

        if (Math.abs(newTop - top) > 16 || Math.abs(newLeft - left) > 16) {
          div.style.top = newTop + "px";
          div.style.left = newLeft + "px";
          const randomDeg = Math.floor(Math.random() * 50) - 25;
          img.style.transform = `rotateZ(${randomDeg}deg)`;
          div.appendChild(img);
          containerRef.current.appendChild(div);
          top = newTop;
          left = newLeft;
        }
      }
    }
  };

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    isMouseDown = true;
  };
  const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = (event) => {
    isMouseDown = false;
  };

  return (
    <div className="flex item-center justify-center bg-gray overflow-y-scroll py-4">
      <div className="w-3/4 flex item-center justify-center flex-col">
        <Space
          className="bg-white p-4 w-[1080px] min-w-[1080px] rounded-md mb-4 mx-auto"
          direction="vertical"
        >
          {/* <Space>
            <div>自动隐藏：</div>
            <Button onClick={() => setAutoHide(!autoHide)} type={autoHide ? "default" : "primary"}>
              {autoHide ? "已开启" : "已关闭"}
            </Button>
          </Space> */}

          <Space>
            <div>清空评论：</div>
            <Button onClick={handleClear} type="primary">
              一键清空
            </Button>
            <div>体验鼠标左键画表情线：</div>
            <Switch checked={checked} onChange={(e) => setChecked(e)} />
          </Space>
        </Space>
        <div
          className="w-[1080px] min-w-[1080px] mx-auto relative cursor-pointer"
          onMouseMove={handleMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          ref={containerRef}
        >
          <img
            src={url || DEMO_IMAGE}
            alt=""
            className="w-[1080px] min-w-[1080px]"
            onClick={handleClick}
          />

          {/* <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-1"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-2"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-3"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-4"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-5"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-6"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-7"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-8"
          />
          <img
            src={EmojiImageSrcMap[LottieName.Cute]}
            alt=""
            className="w-8 h-8 absolute top-[200px] left-[200px] slide-out-top delay-9"
          /> */}

          <Space className="bg-white p-4 w-full rounded-md mt-4" direction="vertical">
            <Space>
              <div>显示数量：</div>
              <InputNumber
                onChange={(e) => setMaxCommentCount(e)}
                value={maxCommentCount}
              ></InputNumber>
            </Space>
            <Space>
              <div>显示内容：</div>
              <Select
                value={commentType}
                onChange={(e) => setCommentType(e)}
                dropdownMatchSelectWidth={196}
              >
                <Select.Option value="emoji+text">同时显示（表情和文本）</Select.Option>
                <Select.Option value="emoji">表情</Select.Option>
                <Select.Option value="text">文本</Select.Option>
                <Select.Option value="close">关</Select.Option>
              </Select>
            </Space>
            <Space>
              <div>显示图片：</div>
              <Button
                type="primary"
                onClick={() => {
                  if (fileInputRef?.current) {
                    fileInputRef.current.click();
                  }
                }}
              >
                更换
              </Button>
              <input
                className="hidden"
                ref={fileInputRef}
                onChange={handleChangeImage}
                type="file"
                accept=".png, .jpg, .jpeg"
              />
            </Space>
          </Space>

          {commentList.map((comment, index) => {
            if (commentType === "close") {
              return null;
            }

            // if (commentList.length - maxCommentCount > index) {
            //   return null;
            // }

            const showEmoji = ["emoji+text", "emoji"].includes(commentType);
            const showText = ["emoji+text", "text"].includes(commentType);

            return (
              <div
                className={`comment-item-container absolute w-12 h-12 ${
                  autoHide ? "hidden" : "fade-out"
                }`}
                style={{
                  top: comment.position.top - 37,
                  left: comment.position.left - 5,
                }}
                key={index}
                onClick={() => handleClickComment(comment)}
              >
                <div
                  className="w-12 h-12 flex items-center justify-center relative"
                  style={{ width: showEmoji ? 48 : 1 }}
                >
                  <div className="absolute left-0 bottom-0 rotate-[45deg]">
                    <IconFont type="icon-arrow2" style={{ color: "red" }} />
                  </div>
                  {showEmoji ? (
                    <img
                      src={EmojiImageSrcMap[comment.emojiName]}
                      alt=""
                      className="w-8 h-8"
                      style={{
                        transform: `rotateZ(${comment.rotateDeg}deg)`,
                      }}
                    />
                  ) : (
                    <div className="h-12"></div>
                  )}
                  {showText ? (
                    <div
                      className="absolute left-12 text-white text-lg font-extrabold stroke-outside"
                      style={{ left: showEmoji ? 48 : 1 }}
                    >
                      {comment.data}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}

          {newCommentList.map((comment, index) => {
            if (commentType === "close") {
              return null;
            }

            if (newCommentList.length - maxCommentCount > index) {
              return null;
            }

            const showEmoji = ["emoji+text", "emoji"].includes(commentType);
            const showText = ["emoji+text", "text"].includes(commentType);

            return (
              <div
                className={`new-comment-item-container absolute w-12 h-12`}
                style={{
                  top: comment.position.top - 37,
                  left: comment.position.left - 5,
                }}
                key={index}
                onClick={() => handleClickComment(comment)}
              >
                <div
                  className="w-12 h-12 flex items-center justify-center relative"
                  style={{ width: showEmoji ? 48 : 1 }}
                >
                  <div className="absolute left-0 bottom-0 rotate-[45deg]">
                    <IconFont type="icon-arrow2" style={{ color: "red" }} />
                  </div>
                  {showEmoji ? (
                    <img
                      src={EmojiImageSrcMap[comment.emojiName]}
                      alt=""
                      className="w-8 h-8"
                      style={{
                        transform: `rotateZ(${comment.rotateDeg}deg)`,
                      }}
                    />
                  ) : (
                    <div className="h-12"></div>
                  )}
                  {showText ? (
                    <div
                      className="absolute left-12 text-white text-lg font-extrabold stroke-outside"
                      style={{ left: showEmoji ? 48 : 1 }}
                    >
                      {comment.data}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}

          <div
            className="comment-container absolute flex items-center h-36"
            style={{
              top: position.top - 85,
              left: position.left - 5,
              display: visible ? "flex" : "none",
            }}
          >
            {emoji ? (
              <div className="w-12 h-12"></div>
            ) : (
              <div className="comment-icon w-12 h-12"></div>
            )}
            <div className="comment-dialog bg-white p-4 rounded-lg w-80 h-36 flex flex-col items-end justify-between relative shadow-lg">
              <div className="comment-dialog-arrow absolute"></div>
              <Input.TextArea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="OPTIONAL"
              />
              <Space className="mb-2">
                {EMOJI_LIST.map((item) => {
                  return (
                    <div className="flex items-center justify-between" key={item.type}>
                      <EmojiWithLottie
                        // isActive={isActive(item.type)}
                        autoScaleBack
                        onClick={handleClickEmoji(item.type)}
                        width={30}
                        height={30}
                        name={item.type}
                      />
                    </div>
                  );
                })}
              </Space>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
