import ICON_POST from "@/assets/icons/icon-post.png";
import ICON_SIGNUP from "@/assets/icons/icon-signup.png";
import HomeIcon from "@/components/Icons/Home";
// import MsgIcon from "@/components/Icons/Msg";
import UserImg from "@/components/UserImg";
import useHome from "@/hooks/useHome";
import { userInfoState } from "@/store";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import "./index.css";

const TabBar = () => {
  const user = useRecoilValue(userInfoState);
  const navigate = useNavigate();
  const location = useLocation();

  const { clearHomeState } = useHome();

  // const scrollDirection = useScrollDirection();

  // const isHome = location.pathname === "/";
  // const isMsg = location.pathname === "/msg";
  const isCanHide = location.pathname.startsWith("/settings");

  const buttonBaseClass =
    "w-10 h-10 rounded-full flex items-center justify-center bg-[#FBFBFB] overflow-hidden";
  // const buttonActiveClass = "border border-[#3772FF]";

  const handleClickUser = () => {
    if (user?.id !== undefined) {
      // 已登录
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  // const handleClickSearch = () => {
  //   navigate("/search");
  // if (user?.id !== undefined) {
  // } else {
  //   message.info("please login before post", 1.5, () => {
  //     navigate("/login");
  //   });
  // }
  // };

  const handleClickHome = () => {
    clearHomeState();
    scrollToTop();
  };

  const scrollToTop = () => {
    let sTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (sTop > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, sTop - sTop / 8);
    }
  };

  const handleClickAdd = () => {
    if (user?.id !== undefined) {
      navigate("/post");
    } else {
      message.info("please login before post", 1.5, () => {
        navigate("/login");
      });
    }
  };

  if (isCanHide) {
    return null;
  }

  const baseCls = "fixed inset-x-0 bottom-2 w-full px-10 z-50 sm:hidden ";

  return (
    <div className={`${baseCls}`}>
      {/* <div className={scrollDirection === "up" ? "slide-in-bottom-one" : "slide-out-bottom"}> */}
      <div
        className="flex items-center justify-around h-14 shadow-md rounded-full bg-white px-2.5 py-2"
        style={{ filter: "drop-shadow(0px -1px 13px rgba(0, 0, 0, 0.13))" }}
      >
        <div className={`${buttonBaseClass}`} onClick={handleClickHome}>
          <HomeIcon
            color="#23262F"
            // color={isHome ? "#3772FF" : "#23262F"}
          />
        </div>
        {/* <div
          className={`${buttonBaseClass} ${isMsg ? buttonActiveClass : null}`}
          onClick={handleClickSearch}
        >
          <SearchIcon color={isMsg ? "#3772FF" : "#23262F"} />
        </div> */}
        {/* <div className={buttonBaseClass}>
          <HeaderEmojiSwitch type="mobile" />
        </div> */}
        {/* <div className={buttonBaseClass} onClick={scrollToTop}>
          <VerticalAlignTopOutlined style={{ fontSize: 22 }} />
        </div> */}
        <div className={buttonBaseClass} onClick={handleClickAdd}>
          {/* <AddIcon color="#2A294F" /> */}
          <img src={ICON_POST} alt="" className="w-10" />
        </div>
        <div className={buttonBaseClass} onClick={handleClickUser}>
          {user ? (
            <div className="w-10 h-10 flex items-center justify-center">
              <UserImg
                style={{ minWidth: 24 }}
                className="w-6 h-6 flex items-center justify-center rounded-full"
                src={user.avatar}
                name={user.nickname || user.username}
              />
            </div>
          ) : (
            <UserImg
              style={{ minWidth: 40 }}
              className="w-10 h-10 flex items-center justify-center"
              src={ICON_SIGNUP}
            />
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
export default TabBar;
