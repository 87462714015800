import IconFont from "@/components/IconFont";
import UserNoteList from "@/components/UserNoteTabs/UserNoteList";
import { UserPostType } from "@/models";
import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { useEffect, useRef, useState } from "react";
import LikeIcon from "../Icons/Like";
import "./index.css";

const UserNoteTabs = (props: { id: number }) => {
  const [tab, setTab] = useState<string>("Post List");
  const carouselRef = useRef<CarouselRef>(null);
  // const activeStyle = { backgroundColor: "#FF8811", color: "white" };

  useEffect(() => {
    if (carouselRef?.current) {
      carouselRef.current?.goTo(1);
    }
  }, [carouselRef]);

  const cls = "flex items-center mb-2 p-2 sm:px-4 sm:pl-3.5 rounded-full cursor-pointer";

  return (
    <div className="sm:-mt-14">
      <div className="flex items-center justify-center pt-4 sm:pt-0 flex-wrap">
        <div
          className={`${cls} ${tab === "Commented" ? "profile-tab-active" : ""}`}
          onClick={() => {
            setTab("Commented");
            carouselRef?.current?.goTo(0);
          }}
          // style={tab === "Commented" ? activeStyle : undefined}
        >
          <IconFont type="icon-chat" />
          <div className="ml-1 text-base font-bold tracking-normal">Commented</div>
        </div>
        <div
          className={`${cls} ${tab === "Post List" ? "profile-tab-active" : ""}`}
          onClick={() => {
            setTab("Post List");
            carouselRef?.current?.goTo(1);
          }}
          // style={tab === "Post List" ? activeStyle : undefined}
        >
          <IconFont type="icon-list" />
          <div className="ml-1 text-base font-bold tracking-normal">Posts</div>
        </div>
        <div
          className={`${cls} ${tab === "Saved" ? "profile-tab-active" : ""}`}
          onClick={() => {
            setTab("Saved");
            carouselRef?.current?.goTo(2);
          }}
          // style={tab === "Saved" ? activeStyle : undefined}
        >
          <LikeIcon color="#000000D9" size={16} />
          <div className="ml-1 text-base font-bold tracking-normal">Liked</div>
        </div>

        {/* <div
          className={cls}
          onClick={() => {
            setTab("Rated");
            carouselRef?.current?.goTo(3);
          }}
          style={tab === "Rated" ? activeStyle : undefined}
        >
          <IconFont type="icon-star1" />
          <div className="ml-1 text-base font-bold tracking-normal">Rated</div>
        </div> */}
        <div
          className={`${cls} ${tab === "Emojis" ? "profile-tab-active" : ""}`}
          onClick={() => {
            setTab("Emojis");
            carouselRef?.current?.goTo(3);
          }}
          // style={tab === "Emojis" ? activeStyle : undefined}
        >
          <IconFont type="icon-emoji" />
          <div className="ml-1 text-base font-bold tracking-normal">Reacted</div>
        </div>
      </div>
      <div style={{ minHeight: "700px" }}>
        <Carousel ref={carouselRef} dots={false} effect="fade">
          {/* <UserNoteList id={props.id} type={UserPostType.Collected} /> */}
          <UserNoteList id={props.id} type={UserPostType.Commented} />
          <UserNoteList id={props.id} type={UserPostType.Created} />
          <UserNoteList id={props.id} type={UserPostType.Liked} />
          <UserNoteList id={props.id} type={UserPostType.Rated} />
          {/* <NoteList dataList={[data_list[0], data_list[1], data_list[2], data_list[3]]} />
          <NoteList dataList={[data_list[5], data_list[6], data_list[7], data_list[3]]} />
          <NoteList dataList={[data_list[4], data_list[1], data_list[9], data_list[3]]} />
          <NoteList dataList={[data_list[10], data_list[2], data_list[9], data_list[3]]} />
          <NoteList dataList={[data_list[5], data_list[3], data_list[1], data_list[9]]} /> */}
        </Carousel>
      </div>
    </div>
  );
};

export default UserNoteTabs;
