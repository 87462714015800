import AvatarA from "@/assets/avatar/A.png";
import AvatarB from "@/assets/avatar/B.png";
import AvatarC from "@/assets/avatar/C.png";
import AvatarD from "@/assets/avatar/D.png";
import AvatarE from "@/assets/avatar/E.png";
import AvatarF from "@/assets/avatar/F.png";
import AvatarG from "@/assets/avatar/G.png";
import AvatarH from "@/assets/avatar/H.png";
import AvatarI from "@/assets/avatar/I.png";
import AvatarJ from "@/assets/avatar/J.png";
import AvatarK from "@/assets/avatar/K.png";
import AvatarL from "@/assets/avatar/L.png";
import AvatarM from "@/assets/avatar/M.png";
import AvatarN from "@/assets/avatar/N.png";
import AvatarO from "@/assets/avatar/O.png";
import AvatarP from "@/assets/avatar/P.png";
import AvatarQ from "@/assets/avatar/Q.png";
import AvatarR from "@/assets/avatar/R.png";
import AvatarS from "@/assets/avatar/S.png";
import AvatarT from "@/assets/avatar/T.png";
import AvatarU from "@/assets/avatar/U.png";
import AvatarV from "@/assets/avatar/V.png";
import AvatarW from "@/assets/avatar/W.png";
import AvatarX from "@/assets/avatar/X.png";
import AvatarY from "@/assets/avatar/Y.png";
import AvatarZ from "@/assets/avatar/Z.png";

import NEWS_ICON_BITCOIN from "@/assets/news/bitcoin.webp";
import NEWS_ICON_BLOOMBERG from "@/assets/news/bloomberg.png";
import NEWS_ICON_cnn from "@/assets/news/cnn.png";
import NEWS_ICON_crunchyroll from "@/assets/news/crunchyroll.png";
import NEWS_ICON_dailymail from "@/assets/news/dailymail.png";
import NEWS_ICON_espn from "@/assets/news/espn.png";
import NEWS_ICON_fox from "@/assets/news/fox.png";
import NEWS_ICON_gematsu from "@/assets/news/gematsu.png";
import NEWS_ICON_nytimes from "@/assets/news/nytimes.png";
import NEWS_ICON_politico from "@/assets/news/politico.png";
import NEWS_ICON_seekingalpha from "@/assets/news/seekingalpha.webp";
import NEWS_ICON_usmagazine from "@/assets/news/usmagazine.png";
import NEWS_ICON_theverge from "@/assets/news/verge.png";

import { useState } from "react";
import IconFont from "./IconFont";
import MovieIcon from "./Icons/Movie";

const NewsIconMap = {
  bitcoin: NEWS_ICON_BITCOIN,
  bloomberg: NEWS_ICON_BLOOMBERG,
  cnn: NEWS_ICON_cnn,
  crunchyroll: NEWS_ICON_crunchyroll,
  dailymail: NEWS_ICON_dailymail,
  espn: NEWS_ICON_espn,
  fox: NEWS_ICON_fox,
  gematsu: NEWS_ICON_gematsu,
  nytimes: NEWS_ICON_nytimes,
  politico: NEWS_ICON_politico,
  seekingalpha: NEWS_ICON_seekingalpha,
  usmagazine: NEWS_ICON_usmagazine,
  theverge: NEWS_ICON_theverge,
};

// import UserIcon from "./Icons/User";
const AvatarMap = {
  A: AvatarA,
  B: AvatarB,
  C: AvatarC,
  D: AvatarD,
  E: AvatarE,
  F: AvatarF,
  G: AvatarG,
  H: AvatarH,
  I: AvatarI,
  J: AvatarJ,
  K: AvatarK,
  L: AvatarL,
  M: AvatarM,
  N: AvatarN,
  O: AvatarO,
  P: AvatarP,
  Q: AvatarQ,
  R: AvatarR,
  S: AvatarS,
  T: AvatarT,
  U: AvatarU,
  V: AvatarV,
  W: AvatarW,
  X: AvatarX,
  Y: AvatarY,
  Z: AvatarZ,
};

const UserImg = (props: {
  src?: string;
  className?: string;
  style?: React.CSSProperties;
  defaultImageWhite?: boolean;
  size?: number;
  name?: string;
  type?: string;
  website?: string;
}) => {
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const handleError = () => {
    setIsDefault(true);
  };

  if (props.type === "movie") {
    return <MovieIcon size={props.size || 18} />;
  }

  const website = (props.website || "").toLowerCase();
  if (!isDefault && props.type === "news" && (NewsIconMap as any)[website]) {
    return (
      <img
        className={props.className}
        style={props.style}
        src={(NewsIconMap as any)[website]}
        alt=""
        onError={handleError}
      />
    );
  }

  if (isDefault || !props.src) {
    if (props.name) {
      if (props.name === "movie") {
        return <MovieIcon size={18} />;
      }

      const firstLetter = props.name.slice(0, 1).toUpperCase();
      const letterAvatar = (AvatarMap as any)[firstLetter];
      if (letterAvatar) {
        return <img className={props.className} style={props.style} src={letterAvatar} alt="" />;
      } else {
        return (
          <div
            className={`flex items-center justify-center bg-slate-400 rounded-full font-bold text-xs ${props.className}`}
            style={props.style}
          >
            {firstLetter}
          </div>
        );
      }
    }
    return (
      <IconFont
        style={{
          ...props.style,
          fontSize: props.size || 28,
        }}
        className={props.className}
        type="icon-emoji"
      />
    );
  }

  return (
    <img
      className={props.className}
      style={props.style}
      src={props.src}
      alt=""
      onError={handleError}
    />
  );
};

// const UserImg = (props: {
//   src?: string;
//   className?: string;
//   style?: React.CSSProperties;
//   defaultImageWhite?: boolean;
//   size?: number;
//   name?: string;
// }) => {
//   if (props.name) {
//     console.log(props.name)
//     return (
//         <UserImgInner {...props} />
//       // </Popover>
//     );
//   } else {
//     return <UserImgInner {...props} />;
//   }
// };

export default UserImg;
