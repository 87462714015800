import IconFont from "@/components/IconFont";
import LikeIcon from "@/components/Icons/Like";
import ReportIcon from "@/components/Icons/Report";
import ShareIcon from "@/components/Icons/Share";
import { IPostDetail } from "@/models";
import { Divider, Space } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { smoothScrollIntoView } from "./PostDetailAuthorInfo";

interface Props {
  detail: IPostDetail;
}

const PostDetailSide: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="post-detail-container float-left -ml-[100px] left-0 top-0 hidden sm:flex flex-col items-center w-20 sticky top-4">
      <Space direction="vertical" size={24} align="center">
        <div
          className="text-2xl cursor-pointer ease-in-out duration-300 hover:scale-125 w-10 h-10 flex items-center justify-center"
          onClick={handleBack}
        >
          <IconFont type="icon-goback" />
        </div>
        {/* {props.detail.media_category ? (
          <div>{props.detail.media_category?.toUpperCase()}</div>
        ) : null} */}

        {/* <div className="text-sm max-w-[80px] merriweather-font overflow-hidden text-ellipsis whitespace-nowrap">
          {props.detail.tags?.[0]?.toLocaleUpperCase()}
        </div> */}
        <div className="h-6 flex items-center">
          <LikeIcon color="#23262F" size={24} />
        </div>
        <div className="h-6 flex items-center">
          <ShareIcon color="#23262F" size={24} />
        </div>
        <div className="h-6 flex items-center">
          <ReportIcon color="#23262F" size={24} />
        </div>
        <div>
          <Divider className="mt-0" />
          <div
            onClick={() => {
              smoothScrollIntoView("comment");
            }}
            className="flex flex-col items-center text-lg leading-4 cursor-pointer pt-0.5 group"
          >
            <div className="border border-[#eee] w-10 h-10 rounded-full flex items-center justify-center ease-in-out duration-300 group-hover:scale-125">
              <IconFont type="icon-comment-2" />
            </div>
            <div className="merriweather-font text-2xl text-red-500">
              {props.detail.comment?.total || 0}
            </div>
          </div>
        </div>
      </Space>
    </div>
  );
};

export default PostDetailSide;
