import API from "@/apis";
import Loading from "@/components/Loading";
import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import { IHomeData } from "@/models";
import * as React from "react";
// import { useNavigate } from "react-router-dom";

interface Props {}

const FollowingPage: React.FC<Props> = (props: Props) => {
  const [list, setList] = React.useState<IHomeData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  // const navigate = useNavigate();

  React.useEffect(() => {
    const fetchList = async () => {
      const [isSuccess, res] = await API.getFollowingPostList(1, 20);
      setLoading(false);
      if (isSuccess) {
        res && setList(res.data);
      } else {
        // if (res?.data.code === 410) {
        //   navigate("/login");
        // }
      }
    };
    fetchList();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center py-20">
        <Loading />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* <div style={{ backgroundColor: "rgb(242, 242, 242)" }}> */}
      <div className="waterfall-container sm:pt-4 pb-6">
        <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={list} />
      </div>
    </div>
  );
};

export default FollowingPage;
