import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    published: "10/10/2022",
    updated: "2022-10-10T18:31:25-04:00",
    updated_parsed:
      "time.struct_time(tm_year=2022, tm_mon=10, tm_mday=10, tm_hour=22, tm_min=31, tm_sec=25, tm_wday=0, tm_yday=283, tm_isdst=0)",
    title: "The Verge Guide to Amazon’s Prime Early Access Sale",
    summary:
      "Amazon Prime Day is no longer a once-per-year shopping event. This year, the company is hosting a second deal extravaganza for Prime members ",
    link: "https://www.theverge.com/23397287/amazon-october-prime-day-early-access-sale-deals-guide-2022",
    author: "The Verge Staff",
    image:
      "https://cdn.vox-cdn.com/thumbor/zUCmKMgWxxxS3vjSri94c511Eqg=/0x0:2040x1360/1310x873/cdn.vox-cdn.com/uploads/chorus_image/image/71478067/acastro_STK103__04.0.jpg",
    tag: "tech",
  },
  {
    published: "10/10/2022",
    updated: "2022-10-10T16:44:43-04:00",
    updated_parsed:
      "time.struct_time(tm_year=2022, tm_mon=10, tm_mday=10, tm_hour=20, tm_min=44, tm_sec=43, tm_wday=0, tm_yday=283, tm_isdst=0)",
    title: "China asked Elon Musk not to sell Starlink within the country",
    summary: "In ",
    link: "https://www.theverge.com/2022/10/10/23397301/elon-musk-starlink-china-great-firewall-censorship",
    author: "Russell Brandom",
    image:
      "https://cdn.vox-cdn.com/thumbor/L6K2BxT1suUvdgr2y4zwVo8svd4=/0x0:2040x1360/1310x873/cdn.vox-cdn.com/uploads/chorus_image/image/71478292/STK171_VRG_Illo_12_Normand_ElonMusk_12.0.jpg",
    tag: "tech",
  },
  {
    published: "10/10/2022",
    updated: "2022-10-10T14:34:01-04:00",
    updated_parsed:
      "time.struct_time(tm_year=2022, tm_mon=10, tm_mday=10, tm_hour=18, tm_min=34, tm_sec=1, tm_wday=0, tm_yday=283, tm_isdst=0)",
    title: "Here are the best Amazon Echo deals right now",
    summary:
      "Below, we’ve rounded up the best deals available on each device in Amazon’s Echo lineup. Many of the current discounts rival those we saw during Amazon Prime Day — which isn’t all that surprising given ",
    link: "https://www.theverge.com/21546591/best-amazon-echo-deals",
    author: "Sheena Vasani",
    image:
      "https://cdn.vox-cdn.com/thumbor/mj7LGifJ1euP-dbrplwPMhD36I4=/0x0:2040x1360/1310x873/cdn.vox-cdn.com/uploads/chorus_image/image/67781485/dseifert_191123_3810_0012.0.jpg",
    tag: "tech",
  },
  {
    published: "10/10/2022",
    updated: "2022-10-10T13:00:00-04:00",
    updated_parsed:
      "time.struct_time(tm_year=2022, tm_mon=10, tm_mday=10, tm_hour=17, tm_min=0, tm_sec=0, tm_wday=0, tm_yday=283, tm_isdst=0)",
    title: "COVID misinfo is the biggest challenge for Twitter’s Birdwatch program, data shows",
    summary: "It’s a big step for Birdwatch, which was officially ",
    link: "https://www.theverge.com/2022/10/10/23393021/twitter-birdwatch-covid-misinformation-data-analysis-misinformation-fact-check",
    author: "Corin Faife",
    image:
      "https://cdn.vox-cdn.com/thumbor/XqXVlZXEGyLv3PANNDJNMqSmrc0=/0x0:2040x1360/1310x873/cdn.vox-cdn.com/uploads/chorus_image/image/71477468/226276_K_Radtke_Birdwatch_Twitter_covid.0.jpg",
    tag: "tech",
  },
  {
    published: "10/10/2022",
    updated: "2022-10-10T12:24:10-04:00",
    updated_parsed:
      "time.struct_time(tm_year=2022, tm_mon=10, tm_mday=10, tm_hour=16, tm_min=24, tm_sec=10, tm_wday=0, tm_yday=283, tm_isdst=0)",
    title: "This is Microsoft’s Xbox game streaming device",
    summary: "Microsoft ",
    link: "https://www.theverge.com/2022/10/10/23396891/microsoft-xbox-keystone-game-streaming-box",
    author: "Tom Warren",
    image:
      "https://cdn.vox-cdn.com/thumbor/7FdpHGzEBJwBBJ2RDILZxvN2siI=/0x0:2040x1360/1310x873/cdn.vox-cdn.com/uploads/chorus_image/image/71477324/acastro_210429_1777_0004.0.jpg",
    tag: "tech",
  },
  {
    published: "10/10/2022",
    updated: "2022-10-10T12:00:00-04:00",
    updated_parsed:
      "time.struct_time(tm_year=2022, tm_mon=10, tm_mday=10, tm_hour=16, tm_min=0, tm_sec=0, tm_wday=0, tm_yday=283, tm_isdst=0)",
    title:
      "All YouTube users will soon have an account handle — but some will get to pick theirs earlier",
    summary:
      "“We want to ensure creators can craft an identity as unique as their content, while giving viewers the confidence that they are interacting with their favorite creators,” YouTube says in its blog. Creators will still have a channel name, but handles will be unique, potentially cutting down on ",
    link: "https://www.theverge.com/2022/10/10/23392552/youtube-unique-handle-rollout-shorts-comment-mentions-tiktok",
    author: "Mia Sato",
    image:
      "https://cdn.vox-cdn.com/thumbor/_acXtANGJ4zrSDCN8foB0Ol1hl8=/0x0:2040x1360/1310x873/cdn.vox-cdn.com/uploads/chorus_image/image/71477255/acastro_STK092_03.0.jpg",
    tag: "tech",
  },
];

const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "theverge",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: item.author,
      nickname: item.author,
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsTheverge: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsTheverge;
