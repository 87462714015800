import IconFont from "@/components/IconFont";
import { Button, Form, Input, Popover } from "antd";
import React from "react";
import "./index.css";

const Feedback = () => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [form] = Form.useForm();
  const handleVisibleChange = (v: boolean) => setVisible(v);

  const handleFinish = (values: any) => {
    console.log(values);
    // TODO
    form.setFieldsValue({ message: "" });
    setVisible(false);
  };

  const handleClose = () => {
    setVisible(false);
  }

  return (
    <div className="feedback-container hidden sm:block">
      <Popover
        content={
          <div className="feedback-popover relative" style={{ padding: 10 }}>
            <div
              onClick={handleClose}
              className="flex z-50 cursor-pointer items-center justify-center w-8 h-8 absolute right-0 top-0"
            >
              <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
            </div>
            <Form style={{ width: 378 }} form={form} layout="vertical" onFinish={handleFinish}>
              <Form.Item label="Feedback" name="message">
                <Input.TextArea rows={5} placeholder="What make you frustrsted" />
              </Form.Item>
              <Button className="feedback-submit-button" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form>
          </div>
        }
        trigger="click"
        placement="topRight"
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <div
          className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer text-2xl pb-2.5 text-white"
          // className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer"
          style={{
            background: "linear-gradient(277.47deg, #F6D365 -38.63%, #FF8811 101.85%)",
          }}
        >
          {/* <img src={ICON_FEED} alt="feed" className="w-5" /> */}
          ...
          {/* <IconFont
              type="icon-headset"
              style={{ fontSize: 26, color: "white" }}
            /> */}
        </div>
      </Popover>
    </div>
  );
};

export default Feedback;
