import { ISetInfoWithEmojis } from "@/models";
import { emojiGroupsState, officialEmojiGroupsState, switchedEmojiListState } from "@/store";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

const useApplyEmojiGroup = () => {
  const [applyedEmojiGroup, setApplyedEmojiGroup] = useState<ISetInfoWithEmojis>();
  const emojiGroupsValue = useRecoilValue(emojiGroupsState);
  const officialEmojiGroupsValue = useRecoilValue(officialEmojiGroupsState);
  const switchedEmojiList = useRecoilValue(switchedEmojiListState);
  useEffect(() => {
    // console.log("enter");
    // console.log(emojiGroupsValue, officialEmojiGroupsValue);
    const applyEmojiGroup = emojiGroupsValue.find((group) => group?.apply === 1);
    if (applyEmojiGroup) {
      setApplyedEmojiGroup(applyEmojiGroup);
    } else {
      const applyed = officialEmojiGroupsValue.find((group) => group?.apply === 1);
      if (applyed) {
        // applyed.emojis = [...switchedEmojiList];
        // console.log(22222, applyed);
        const emojis = applyed.id === 2 ? [...switchedEmojiList] : [];
        console.log(emojis);
        setApplyedEmojiGroup({
          ...applyed,
          emojis: emojis,
        });
      }
    }
  }, [emojiGroupsValue, officialEmojiGroupsValue, switchedEmojiList]);

  return {
    applyedEmojiGroup,
    setApplyedEmojiGroup,
  };
};

export default useApplyEmojiGroup;
