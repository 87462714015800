const ShareIcon = ({ color, size }: { color: string; size?: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 4L29 16L17 28L17 21C10.5 20.5 5.5 23.5 3 29C0.999999 20 6 11 17 11L17 4Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ShareIcon;
