import { Select } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import API from "@/apis";
import ICON_REACTED from "@/assets/icons/icon-reacted.svg";
import { EmojiImageSrcMap, LottieName } from "@/components/Emoji";
import { formatImageFotVercel } from "@/components/Note";
import NoteSquare from "@/components/Note/NoteSquare";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigation } from "swiper";
import { IHomeData } from "../../models/index";

const Option = Select.Option;

// const MOCK = [
//   {
//     backgroundImage: "/uploads/20220522205307.png",
//     id: 1,
//     avatar: "",
//     name: "Payton Kunde",
//     followers: 200,
//     link: "BINANCE",
//   },
//   {
//     backgroundImage: "/uploads/20220522205307.png",
//     id: 2,
//     avatar: "",
//     name: "Payton Kunde",
//     followers: 200,
//     link: "BINANCE",
//   },
//   {
//     backgroundImage: "/uploads/20220522205307.png",
//     id: 3,
//     avatar: "",
//     name: "Payton Kunde",
//     followers: 200,
//     link: "BINANCE",
//   },
//   {
//     backgroundImage: "/uploads/20220522205307.png",
//     id: 4,
//     avatar: "",
//     name: "Payton Kunde",
//     followers: 200,
//     link: "BINANCE",
//   },
//   {
//     backgroundImage: "/uploads/20220522205307.png",
//     id: 5,
//     avatar: "",
//     name: "Payton Kunde",
//     followers: 200,
//     link: "BINANCE",
//   },
//   {
//     backgroundImage: "/uploads/20220522205307.png",
//     id: 6,
//     avatar: "",
//     name: "Payton Kunde",
//     followers: 200,
//     link: "BINANCE",
//   },
//   {
//     backgroundImage: "/uploads/20220522205307.png",
//     id: 7,
//     avatar: "",
//     name: "Payton Kunde",
//     followers: 200,
//     link: "BINANCE",
//   },
//   {
//     backgroundImage: "/uploads/20220522205307.png",
//     id: 8,
//     avatar: "",
//     name: "Payton Kunde",
//     followers: 200,
//     link: "BINANCE",
//   },
// ];

interface ITopPostCard {
  backgroundImage: string;
  avatar: string;
  name: string;
  followers: number;
  link: string;
  id: number;
  emoji?: number[];
  title: string;
}

const TopPostCard = ({ data, rank, size }: { data: ITopPostCard; rank: number; size: number }) => {
  const sizeStyle = { width: size, height: size, minWidth: size };
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/detail?id=" + data.id);
  };

  // const list = data.emoji || [];
  // let max = 0;
  // let maxIndex = -1;
  // list.forEach((i, idx) => {
  //   if (i > max) {
  //     max = i;
  //     maxIndex = idx;
  //   }
  // });

  return (
    <div>
      <div
        className="relative rounded-xl xs:rounded-3xl overflow-hidden cursor-pointer"
        style={sizeStyle}
        onClick={handleClick}
      >
        <img
          src={formatImageFotVercel(data.backgroundImage)}
          alt=""
          style={{ ...sizeStyle, objectFit: "cover" }}
        />
        {/* <div className="absolute w-9 h-9 top-4 left-2 rank-number-container flex items-center justify-center text-white font-bold text-base">
          {rank}
        </div> */}
        <div className="absolute w-8 h-8 top-4 right-2 shadow-lg rounded-full z-50">
          <img src={EmojiImageSrcMap[LottieName.Helpful]} alt="" className="w-8 h-8" />
        </div>
        {/* <div className="absolute bottom-0 left-0 px-2.5 py-2.5 flex items-center w-full">
          <UserImg
            defaultImageWhite
            src={data.avatar}
            // style={{ width: 42, height: 42, minWidth: 42 }}
            className="rounded-full mr-2 w-6 h-6 min-w-[24px] xs:w-[42px] xs:h-[42px] xs:min-w-[42px]"
          />
          <div className="flex items-center justify-between w-full text-white">
            <div className="overflow-hidden text-white">
              <div className="text-sm font-bold text-ellipsis whitespace-nowrap overflow-hidden">
                {data.name || "no name"}
              </div>
              <div className="text-xs">{data.followers} Followers</div>
            </div>
            <div className="font-bold ml-1 text-white">{getHost(data.link)}</div>
          </div>
        </div> */}
      </div>
      <div className="text-base font-bold mt-2 whitespace-nowrap overflow-hidden text-ellipsis">
        {data.title}
      </div>
    </div>
  );
};

const TopPost = ({ hideSelector }: { hideSelector?: boolean }) => {
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  const [list, setList] = useState<IHomeData[]>([]);

  useEffect(() => {
    API.getTopPosts().then((res) => {
      if (Array.isArray(res)) {
        // const topList = res.map((i) => ({
        //   id: i.id,
        //   avatar: i?.author?.avatar || "",
        //   followers: i?.author?.follow_num || 0,
        //   link: i.link,
        //   name: i?.author?.username || "",
        //   backgroundImage: i.image || "",
        //   emoji: i.emoji || [],
        //   title: i?.title || "",
        //   tags: i?.tags || [],
        // }));
        setList(res);
      }
    });
  }, []);

  const size = isLargerThan414 ? 240 : (window.innerWidth - 2 * 8 - 40) / 2;
  const spaceBetween = isLargerThan414 ? 12 : 8;
  const handleChange = () => {};

  return (
    <div className="px-2">
      <div className="flex items-center justify-between h-8 sm:h-16">
        <div className="h-8 flex items-center justify-center" title="Most Reacted">
          <img src={ICON_REACTED} alt="" className="w-6" />
          <div className="ml-2 mt-0.5 text-xl sm:text-[22px] font-bold text-black">
            Most Reacted
          </div>
        </div>
        {/* <h3 className="text-2xl font-bold text-black">Most Reacted</h3> */}
        {hideSelector ? null : (
          <Select
            className="top-post-select"
            defaultValue="Daily"
            style={{ width: 120 }}
            onChange={handleChange}
            bordered={false}
          >
            <Option value="Daily">Daily</Option>
            <Option value="Weekly">Weekly</Option>
            <Option value="Monthly">Monthly</Option>
            <Option value="Year">Year</Option>
          </Select>
        )}
      </div>

      <div className="flex items-center pt-2">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={spaceBetween}
          navigation
          modules={[Navigation]}
          className="mySwiper"
        >
          {list.map((item, index) => {
            return (
              <SwiperSlide key={item.id} style={{ width: size }}>
                <NoteSquare height={size} data={item} />
                {/* <TopPostCard rank={index + 1} data={item} size={size} /> */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default TopPost;
