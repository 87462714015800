import API from "@/apis";
import { WaterfallItem } from "@/components/Waterfall";
import useResponsive from "@/hooks/useResponsive";
import { searchState } from "@/store";
import { Masonry } from "@mui/lab";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRecoilState } from "recoil";

interface Props {}

const HomeSearchPosts: React.FC<Props> = (props: Props) => {
  const [homeSearch, setHomeSearch] = useRecoilState(searchState);
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = useResponsive("down", "sm");
  const now = new Date();
  const hours = now.getHours();
  const showEmojiIndex = hours % 3;

  const handleNext = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const nextPage = homeSearch.page + 1;
      const res = await API.getHomePostList({ keyword: homeSearch.keyword, page: nextPage });
      setHomeSearch({
        keyword: homeSearch.keyword,
        results: [...homeSearch.results, ...res.data],
        total: res.total,
        page: nextPage,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <InfiniteScroll
        style={{ height: "auto", overflow: "unset" }}
        dataLength={homeSearch.results.length}
        next={handleNext}
        hasMore={homeSearch.results.length < homeSearch.total}
        loader={
          <Stack sx={{ height: 50 }} alignItems="center" justifyContent="center">
            <CircularProgress size={20} />
          </Stack>
        }
      >
        <Masonry
          columns={{ xs: 2, sm: 4, md: 4 }}
          defaultColumns={isMobile ? 2 : 4}
          defaultHeight={400}
          defaultSpacing={0}
          spacing={0}
          sx={{
            alignContent: "center",
          }}
        >
          {homeSearch.results.map((item, index) => (
            <WaterfallItem
              useV2
              data={item}
              key={`${item.id}_${index}`}
              showTopEmoji={showEmojiIndex === index % 3}
            />
          ))}
        </Masonry>
      </InfiniteScroll>
    </Box>
  );
};

export default HomeSearchPosts;
