import { IEmoji } from "@/models";
import { emojiSwitchState } from "@/store";
import { GifMap, OfficialMap, iOSMap } from "@/utils/emojis";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { switchedEmojiListState } from "../../store/index";

interface EmojiV2Props {
  emoji: IEmoji;
  applyedEmoji?: IEmoji;
  width?: number;
  height?: number;
  noScale?: boolean;
  count?: number;
  padding?: number;
  isActive?: boolean;
  onClick?: () => Promise<number>;
  autoScaleBack?: boolean;
  boxShadow?: boolean;
  showTitle?: boolean;
  showName?: boolean;
}

// export const getEmojiImageSrcByName = (emoji: IEmoji, emojiSwitch: boolean = false) => {
//   if (emojiSwitch) {
//     return EmojiImageSrcMapSwitched[name];
//   }
//   return EmojiImageSrcMap[name];
// };

const EmojiV2 = (props: EmojiV2Props) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [count, setCount] = useState<number>(props.count || 0);
  const emojiSwitch = useRecoilValue(emojiSwitchState);
  const switchedEmojiListValue = useRecoilValue(switchedEmojiListState);

  useEffect(() => {
    setIsClicked(!!props.isActive);
  }, [props.isActive]);

  const handleClick = async () => {
    setIsClicked(!isClicked);

    if (props.autoScaleBack && !isClicked) {
      const aTimeout = setTimeout(() => {
        setIsClicked(false);
        if (aTimeout) {
          clearTimeout(aTimeout);
        }
      }, 500);
    }

    if (props.onClick) {
      const newCount = await props.onClick();
      setCount(count + newCount);
    }
  };

  // emoji 图片
  let imageSrc = props.emoji.path.startsWith("/") ? props.emoji.path : `/emoji/${props.emoji.path}`;
  if (emojiSwitch) {
    switchedEmojiListValue.forEach((value, index) => {
      if (value.code.replace("-ios", "") === props.emoji.code) {
        imageSrc = value.path;
      }
    });
  }

  if (props.applyedEmoji) {
    if (iOSMap[props.applyedEmoji.code]) {
      imageSrc = iOSMap[props.applyedEmoji.code];
    } else if (OfficialMap[props.applyedEmoji.code]) {
      imageSrc = OfficialMap[props.applyedEmoji.code];
    } else {
      imageSrc = props.applyedEmoji.path;
      imageSrc = imageSrc.startsWith("/") ? imageSrc : `/emoji/${imageSrc}`;
    }
  } else if (iOSMap[props.emoji.code]) {
    imageSrc = iOSMap[props.emoji.code];
  } else if (OfficialMap[props.emoji.code]) {
    imageSrc = OfficialMap[props.emoji.code];
  } else if (GifMap[props.emoji.code]) {
    imageSrc = GifMap[props.emoji.code];
  }

  const width = props.width || 40;
  const height = props.height || 40;

  let cls = "transition duration-300 ease-in-out";
  if (isClicked) {
    cls += " active";
    if (!props.noScale) {
      cls += " scale-150";
    }
  }

  const Image = (
    <img
      className={cls}
      // src={`http://100.24.6.246${imageSrc}`}
      src={imageSrc}
      onClick={handleClick}
      style={{
        width,
        height,
        minWidth: width,
        margin: "0 auto",
        display: "block",
        cursor: "pointer",
        paddingTop: props.padding || 0,
        paddingBottom: props.padding || 0,
      }}
      alt=""
    />
  );

  let name = props.emoji.related || props.emoji.code || "";
  if (name.endsWith("_iOS")) {
    name = name.substring(0, name.length - 4);
  }

  return (
    <div
      className={props.boxShadow ? "relative shadow-md p-2 rounded-full" : "relative"}
      title={props.showTitle ? props.emoji.code : ""}
    >
      {Image}

      {props.showName ? (
        <div className="text-xs leading-4 py-2 font-bold h-8 tracking-tight text-ellipsis whitespace-nowrap overflow-hidden text-center">
          {name}
        </div>
      ) : null}

      {count > 0 ? (
        <div
          className="absolute -top-2 -right-2 rounded-full text-xs w-4 h-4 flex items-center justify-center font-medium tracking-tighter"
          style={{
            backgroundColor: "red",
            fontSize: 10,
            color: "white",
            transform: width <= 20 ? "scale(0.6)" : "scale(1)",
          }}
        >
          {count}
        </div>
      ) : null}
    </div>
  );
};

export default EmojiV2;
