import { useLayoutEffect, useState } from "react";

export function useMediaQuery(query: string | string[]) {
  var queries = Array.isArray(query) ? query : [query];
  var isSupported = "matchMedia" in window;
  var [matches, setMatches] = useState(
    queries.map((query) => (isSupported ? !!window.matchMedia(query).matches : false))
  );

  useLayoutEffect(() => {
    if (!isSupported) return undefined;
    var mediaQueryList = queries.map((query) => window.matchMedia(query));
    var listenerList = mediaQueryList.map((mediaQuery, index) => {
      var listener = () =>
        setMatches((prev) =>
          prev.map((prevValue, idx) => (index === idx ? !!mediaQuery.matches : prevValue))
        );

      mediaQuery.addListener(listener);
      return listener;
    });
    return () => {
      mediaQueryList.forEach((mediaQuery, index) => {
        mediaQuery.removeListener(listenerList[index]);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  return matches;
}
