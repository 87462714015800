import { IHomeData } from "../models";

export const data_list: Array<IHomeData> = [
  {
    id: 0,
    title: "Ms.Audi 闪耀璀璨自我👑做自己的女神",
    image: "",
    tags: '["#动漫", "#苹果"]',
    link: "www.binance.com",
  },
  {
    id: 1,
    title: "Abstract Art",
    image: "",
    tags: '["#动漫", "#苹果"]',
    link: "www.binance.com",
  },
  {
    id: 2,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-2.png",
    tags: '["#Top IOT Porjects"]',
    link: "www.binance.com",
  },
  {
    id: 3,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-3.png",
    tags: '["#Smart Home Automations system"]',
    link: "www.binance.com",
  },
  {
    id: 4,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-4.png",
    tags: '["#Smart Home Automations system"]',
    link: "www.binance.com",
  },
  {
    id: 5,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-5.png",
    tags: '["#Smart Home Automations system"]',
    link: "www.binance.com",
  },
  {
    id: 6,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-6.png",
    tags: '["#Anushka Love Kanha"]',
    link: "www.binance.com",
  },

  {
    id: 7,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-17.png",
    tags: '["#Smart Home Automations system"]',
    link: "www.binance.com",
  },
  {
    id: 8,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-8.png",
    tags: '["#Anushka Love Kanha"]',
    link: "www.binance.com",
  },

  {
    id: 9,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-9.png",
    tags: '["#Anushka Love Kanha"]',
    link: "www.binance.com",
  },
  {
    id: 10,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-10.png",
    tags: '["#Smart Home Automations system"]',
    link: "www.binance.com",
  },

  {
    id: 11,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-12.png",
    tags: '["#Learn web development"]',
    link: "www.binance.com",
  },
  {
    id: 12,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-1.png",
    tags: '["#What is react native"]',
    link: "www.binance.com",
  },

  {
    id: 13,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-13.png",
    tags: '["#What is react native"]',
    link: "www.binance.com",
  },
  {
    id: 14,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-14.png",
    tags: '["#What is react native"]',
    link: "www.binance.com",
  },

  {
    id: 15,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-15.png",
    tags: '["#What is react native"]',
    link: "www.binance.com",
  },
  {
    id: 16,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-16.png",
    tags: '["#What is react native"]',
    link: "www.binance.com",
  },
  {
    id: 17,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-7.png",
    tags: '["#What is react native"]',
    link: "www.binance.com",
  },
  {
    id: 18,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-11.png",
    tags: '["#What is react native"]',
    link: "www.binance.com",
  },
  {
    id: 19,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-19.png",
    tags: '["#What is react native"]',
    link: "www.binance.com",
  },

  {
    id: 20,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-0.png",
    tags: '["#How to make dynamic link in firebase"]',
    link: "www.binance.com",
  },
  {
    id: 21,
    title: "Abstract Art",
    image: "https://bq-frontend.oss-cn-hongkong.aliyuncs.com/upload/pexels-mo-21.png",
    tags: '["#How to make dynamic link in firebase"]',
    link: "www.binance.com",
  },
];

export const MOCK_TAGS = [
  {
    id: "1",
    tag: "Live",
  },
  {
    id: "2",
    tag: "Fire",
  },
  {
    id: "3",
    tag: "上海",
  },
  {
    id: "4",
    tag: "疫情",
  },
  {
    id: "5",
    tag: "www",
  },
  {
    id: "6",
    tag: "Eating",
  },
  {
    id: "7",
    tag: "Lol Games",
  },
  {
    id: "8",
    tag: "Tools",
  },
  {
    id: "9",
    tag: "Sence",
  },
  {
    id: "10",
    tag: "Wood",
  },
];

// export const MOCK_COMMENT: IComment = {
//   id: 1,
//   author: {
//     id: 1,
//     username: "Maude Hall",
//     nickname: "Maude Hall",
//     password: "",
//     avatar: "",
//     bio: "",
//     tags: [],
//   },
//   content:
//     "That's a fantastic new app feature. You and your team did an excellent job of incorporating user testing feedback.",
//   created_at: "July 25, 2021 2:30 PM",
//   updated_at: "July 25, 2021 2:30 PM",
//   replies_count: 2,
//   parent_id: 0,
// };
