import BannerEmojis from "@/components/Banner/Emojis";
import HomePosts from "@/pages/home/HomePosts";

const RankingList = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="pt-4">
        <BannerEmojis />
      </div>
      <div className="py-4 mx-auto" style={{ maxWidth: 1264 }}>
        <HomePosts />
      </div>
    </div>
  );
};
export default RankingList;
