import API from "@/apis";
import IconFont from "@/components/IconFont";
import Loading from "@/components/Loading";
import { SubsWithDropdown } from "@/components/UserBanner";
import UserImg from "@/components/UserImg";
import UserNoteTabs from "@/components/UserNoteTabs";
import { userInfoState } from "@/store";
import { getIDFromUrl, getUserName } from "@/utils";
import { Button, Empty, Input, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import "./index.css";

const initialTags = ["Graphic Design", "Studio Photography", "Portraits", "Moments"];

interface IUser {
  avatar: string;
  bio: string;
  birthday: string;
  createdAt: string;
  email: string;
  id: number;
  nickname: string;
  tags: string[];
  username: string;
  isFollow: number;
  subscriber: number;
  subscriptions: number;
}

const FollowUser = ({ userInfo }: { userInfo: IUser | null }) => {
  const [followed, setFollowed] = React.useState<boolean>(false);
  const [followLoading, setFollowLoading] = useState<boolean>(false);
  const [unFollowLoading, setUnFollowLoading] = useState<boolean>(false);
  const setUserInfo = useSetRecoilState(userInfoState);

  useEffect(() => {
    if (userInfo?.id && userInfo?.isFollow === 1) {
      setFollowed(true);
    }
  }, [userInfo]);

  const handleFollow = async () => {
    if (!userInfo) {
      return;
    }
    if (followLoading) {
      return;
    }
    setFollowLoading(true);
    const success = await API.follow(userInfo?.id.toString());
    setFollowLoading(false);
    if (success) {
      setFollowed(true);
      updateUserInfo();
    }
  };

  const handleUnFollow = async () => {
    if (!userInfo) {
      return;
    }
    if (unFollowLoading) {
      return;
    }
    setUnFollowLoading(true);
    const success = await API.unFollow(userInfo?.id.toString());
    setUnFollowLoading(false);
    if (success) {
      setFollowed(false);
      updateUserInfo();
    }
  };

  const updateUserInfo = async () => {
    const data = await API.getUserInfo();
    setUserInfo(data);
  };

  return (
    <div className="flex items-center">
      {followed ? (
        <Button
          loading={unFollowLoading}
          className="follow-button rounded h-6 px-3 py-0 text-sm"
          type="primary"
          onClick={handleUnFollow}
        >
          {unFollowLoading ? "" : "Followed"}
        </Button>
      ) : followLoading ? (
        <Button
          loading={followLoading}
          className="follow-button rounded h-6 px-3 py-0"
          type="primary"
        />
      ) : (
        <Button
          className="follow-button rounded h-6 px-3 py-0"
          type="primary"
          onClick={handleFollow}
        >
          <div className="flex items-center justify-center">
            <div className="mr-2 font-bold text-sm">Follow</div>
            <IconFont type="icon-user-plus" style={{ fontSize: 16 }} />
          </div>
        </Button>
      )}
    </div>
  );
};

const User = () => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [newTag, setNewTag] = React.useState<string>("tag");
  const [tagList, setTagList] = React.useState<string[]>(initialTags);
  const [id, setID] = React.useState<number | null>(null);
  const [userInfo, setUserInfo] = React.useState<IUser | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetch = async (id: number) => {
      const res = await API.getUserInfoById(id);
      setUserInfo(res);
      setLoading(false);
    };
    const id = getIDFromUrl();
    if (id) {
      fetch(+id);
      setID(+id);
    }
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center py-20">
        <Loading />
      </div>
    );
  }

  if (!userInfo) {
    return (
      <div>
        <div className="hidden xs:block">
          <div className="flex items-center justify-center py-20">
            <Empty description="no user" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
        <div className="block xs:hidden">
          <div className="author-card-wrapper h-[176px] p-4">
            <BackButton />
          </div>
          <div className="flex items-center justify-center py-20">
            <Empty description="no user" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="user-page bg-white">
        <div className="hidden sm:block author-card-wrapper pt-10 pb-20 px-6">
          <div className="author-card flex max-w-[1264px] mx-auto">
            <div className="flex flex-col items-center mr-0 md:mr-6">
              <div className="image-wrapper">
                <UserImg
                  src={userInfo?.avatar}
                  name={userInfo?.nickname || userInfo?.username}
                  className="object-contain shadow"
                  style={{ width: 128, height: 128, minWidth: 128, borderRadius: "50%" }}
                />
              </div>
            </div>

            <div
              className="mt-6 md:mt-0 flex justify-center flex-col text-center md:text-left"
              style={{ maxWidth: 738 }}
            >
              <div className="user-name color-black text-4xl font-bold mb-2">
                {userInfo?.nickname || userInfo?.username || userInfo?.email}
              </div>
              <div className="user-brief text-sm color-black mb-1">
                {userInfo?.bio || "no data"}
              </div>

              <div className="flex items-center justify-center md:justify-start mb-2">
                <div className="flex items-center justify-center md:justify-start">
                  {/* <div>subscribers</div> */}
                  <SubsWithDropdown
                    total={userInfo.subscriber || 0}
                    type="follower"
                    title={userInfo.subscriber > 1 ? "followers" : "follower"}
                    id={userInfo.id}
                  />
                  <div className="mx-4">•</div>
                  <SubsWithDropdown
                    total={userInfo.subscriptions || 0}
                    type="following"
                    title="following"
                    id={userInfo.id}
                  />
                </div>
              </div>

              <div className="flex item-center">
                <div className="flex items-center rounded social-button h-7 px-3 cursor-pointer mr-4">
                  <IconFont type="icon-reddit" style={{ fontSize: 17, color: "black" }} />
                  <span className="ml-2 text-black">Reddit</span>
                </div>
                <div className="flex items-center rounded social-button h-7 px-3 cursor-pointer mr-4">
                  <IconFont type="icon-twitter" style={{ fontSize: 17, color: "black" }} />
                  <span className="ml-2 text-black">Twitter</span>
                </div>
                <FollowUser userInfo={userInfo} />
              </div>

              {/* <div className="mb-2">Interests:</div> */}

              <div className="hidden flex items-center flex-wrap justify-center md:justify-start">
                {userInfo?.tags?.map((tag) => (
                  <div
                    key={tag}
                    className="px-4 py-2 border mr-3 rounded-full bg-white mb-2"
                    style={{ borderColor: "rgba(118, 75, 223, 0.6)" }}
                  >
                    {tag.toUpperCase()}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="block sm:hidden">
          <div className="author-card-wrapper h-[176px] p-4">
            <BackButton />
          </div>
          <div className="p-4">
            <div className="h-7 flex items-center justify-end relative">
              <div className="absolute bottom-0 left-0">
                <UserImg
                  src={userInfo?.avatar}
                  style={{ width: "84px", height: "84px", minWidth: 84 }}
                />
              </div>
              {/* follow button */}
              <FollowUser userInfo={userInfo} />
            </div>
            <div className="text-lg font-bold mt-3">{getUserName(userInfo as any)}</div>
            <div className="mt-2">
              <Typography.Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
                {userInfo?.bio || "no data"}
              </Typography.Paragraph>
            </div>

            <div className="flex items-center justify-start mb-3 md:mb-5">
              <div className="w-7 h-7 rounded-md bg-[#9C99AE] flex items-center justify-center mr-3 cursor-pointer">
                <IconFont type="icon-reddit" style={{ fontSize: 17, color: "#fff" }} />
              </div>
              <div className="w-7 h-7 rounded-md bg-[#9C99AE] flex items-center justify-center mr-3 cursor-pointer">
                <IconFont type="icon-twitter" style={{ fontSize: 17, color: "#fff" }} />
              </div>
            </div>

            <div className="mb-2">Interests:</div>

            <div className="flex items-center flex-wrap justify-start">
              <div
                className="px-5 py-[3px] font-bold text-xs text-white mr-3 rounded-full bg-[#764BDF] mb-2"
                // onClick={() => setVisible(true)}
              >
                +
              </div>
              {initialTags.map((tag) => (
                <div
                  key={tag}
                  className="px-2 py-[3px] text-xs font-bold border mr-3 rounded-full bg-white mb-2"
                  style={{ borderColor: "rgba(118, 75, 223, 0.6)" }}
                >
                  {tag.toUpperCase()}
                </div>
              ))}
            </div>

            <div className="mt-4 flex items-center">
              <div className="text-black">
                <span className="mr-1 font-bold">100к</span>
                <span className="font-bold opacity-50">subscriber</span>
              </div>
              <div className="w-px h-3 opacity-50 bg-black mx-3"></div>
              <div>
                <span className="mr-1 font-bold">12к</span>
                <span className="font-bold">subscriptions</span>
              </div>
            </div>
          </div>
        </div>
        {id !== null ? <UserNoteTabs id={id} /> : null}
      </div>
      <Modal
        visible={visible}
        footer={null}
        title="Create a new tag"
        onCancel={() => setVisible(false)}
        width="400px"
      >
        <div className="newtag-container">
          <Input
            placeholder="Enter a new tag"
            value={newTag}
            onChange={(e) => {
              setNewTag(e.target.value);
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              console.log(newTag);
              setTagList([...tagList, newTag]);
              setNewTag("");
              setVisible(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default User;

const BackButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div
      onClick={handleClick}
      className="rotate-180 bg-white w-11 h-11 flex items-center justify-center rounded-full"
    >
      <IconFont type="icon-right" />
    </div>
  );
};
