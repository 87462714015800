export interface IHomeDataEmoji {
  createdAt: string;
  emoji_code: string;
  emoji_num: number;
  id: number;
  post_id: number;
  updatedAt: string;
}
export interface IHomeData {
  image?: string;
  tags: string;
  tag?: string;
  type?: string;
  website?: string;
  title?: string;
  link?: string;
  author_id?: number;
  emoji?: IHomeDataEmoji[];
  id: number;
  score?: number;
  created_at?: string;
  updatedAt?: string;
  author?: IPostAuthor;
  topComment?: {
    author_id: number;
    avatar: string;
    comment_id: number;
    content: string;
    nickname: string;
    total: number;
  };
  isDemo?: boolean;
  like?: boolean;
  media_category?: string;
  youtubeLink?: string;
  youtubeImage?: string;
  youtube?: string;
  text?: string;
}

export interface IPostDetail {
  id: number;
  title: string;
  website: string;
  image: string;
  link: string;
  tags: string[];
  author: IPostAuthor;
  comment?: {
    total: number;
    content: string;
  };
  emoji?: IHomeDataEmoji[];
  createdAt: string;
  youtube?: string;
  type?: string;
  // demo 新增
  summary?: string;
  credit?: string;
  media_category?: string;
}

export interface IPostComment {
  id: number;
  author: IPostAuthor;
  content: string;
  created_at: string;
  updated_at: string;
  replies_count: number;
  parent_id: number;
}

export interface IPostAuthor {
  id: number;
  username: string;
  nickname: string;
  password: string;
  avatar: string;
  bio: string;
  tags: string[];
  email?: string;
}

export enum HomeDataDisplayMode {
  Grid = "grid",
  Flex = "flex",
}

export interface IUserInfo {
  id: string;
  username: string;
  email: string;
  nickname: string;
  password: string;
  avatar: string;
  bio: string;
  tags: string[];
  subscriber: number;
  subscriptions: number;
}

export interface IHomeSearch {
  keyword: string;
  results: IHomeData[];
  total: number;
  page: number;
}

export interface ICommentAuthor {
  id: number;
  username: string;
  nickname: string;
  password: string;
  avatar: string;
  bio: string;
  tags: string[];
}
export interface IComment extends ICommentContent {
  replyComment?: IComment[];
}

export interface ICommentContent {
  author: string;
  author_id: number;
  author_avatar: string;
  content: string;
  createdAt: string;
  id: number;
  like: number;
  isLike: boolean;
  parent_id?: number;
  post_id: number;
  updatedAt: string;
}

export enum UserPostType {
  Liked = "liked",
  Rated = "emoji",
  Commented = "commented",
  Collected = "collected",
  Created = "created",
}

export interface ITopUser {
  avatar: string;
  bio: string;
  email: string;
  follow_id: string;
  follow_num: number;
  is_follow: number;
  id: number;
  nickname: string;
  username: string;
}

export interface IWalterfallItem {
  groupKey: number;
  key: number;
  data: IHomeData;
}

export interface IEmoji {
  code: string;
  createdAt: string;
  group: number;
  id: number;
  path: string;
  related: string;
  type: number;
}

export interface ISetInfo {
  createdAt: string;
  id: number;
  name: string;
  user_id: number;
}

export interface ISetInfoWithEmojis {
  createdAt: string;
  id: number;
  name: string;
  user_id: number;
  emojis: IEmoji[];
  apply: number; // 1 设置 0 未设置
}
