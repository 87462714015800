import IMG_LOGO from "@/assets/Logo-3.png";
import IMG_LOGO_WITH_TEXT from "@/assets/logo-4.png";
import useHome from "@/hooks/useHome";
import * as React from "react";
import { Link } from "react-router-dom";
interface Props {}

const HeaderLogo: React.FC<Props> = (props: Props) => {
  const { clearHomeState } = useHome();

  return (
    <Link to="/" className="h-12 flex items-center pr-4" onClick={clearHomeState}>
      <img className="h-8 hidden sm:block" alt="" src={IMG_LOGO_WITH_TEXT} />
      <img className="h-8 min-w-[32px] block sm:hidden" alt="" src={IMG_LOGO} />
    </Link>
  );
};

export default HeaderLogo;
