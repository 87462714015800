import Comment from "@/components/Comment";
import IconFont from "@/components/IconFont";
import data from "@emoji-mart/data";
// import Picker from "@emoji-mart/react";
import { Collapse, Input, InputRef, message, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import API from "@/apis";
import ReplyComments from "@/components/Comment/ReplyComments";
import { IComment, IPostDetail } from "@/models";
import { userInfoState } from "@/store";
import { Picker } from "emoji-mart";
import React from "react";

const formatCommentList = (list: IComment[]) => {
  let parentCommentList: IComment[] = [];
  let childCommentList: IComment[] = [];
  list.forEach((i) => {
    if (i.parent_id === null || i.parent_id === undefined) {
      parentCommentList.push(i);
    } else {
      childCommentList.push(i);
    }
  });

  childCommentList.forEach((child) => {
    parentCommentList = parentCommentList.map((parent) => {
      if (child.parent_id === parent.id) {
        return {
          ...parent,
          replyComment: [...(parent.replyComment || []), child],
        };
      } else {
        return parent;
      }
    });
  });

  return parentCommentList;
};

const CommentInput = (props: {
  onKeyDown: (value: string) => Promise<boolean>;
  onClickExpand?: () => void;
  isActive?: boolean;
}) => {
  const [comment, setComment] = useState<string>("");
  // const comment = useRef<string>("");
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  // const user = useRecoilValue(userInfoState);
  const inputRef = useRef<InputRef | null>(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const picker = new Picker({
      data,
      onEmojiSelect: (e: any) => {
        const txt = inputRef.current?.input?.value || "";
        setComment(txt + e.native);
        // comment.current = comment.current + e.native;
      },
    });
    if (emojiPickerRef?.current && emojiPickerRef?.current.children.length === 0) {
      emojiPickerRef.current.appendChild(picker as any);
    }
  }, [setComment, comment]);

  const handleKeyDown = async () => {
    const isSuccess = await props.onKeyDown(comment);
    if (isSuccess) {
      setComment("");
      // comment.current = "";
    }
  };

  return (
    <div className="flex items-center relative pr-8">
      <div
        className="h-12 w-full border flex items-center bg-white rounded-lg pr-4 pl-1"
        style={{ borderColor: "#E6E8EC" }}
      >
        <Input
          ref={inputRef}
          className="h-8 flex-auto text-sm mr-8"
          placeholder="Type your comment here"
          style={{ outline: "none" }}
          bordered={false}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleKeyDown();
            }
          }}
        />
        <div className="relative">
          <IconFont
            type="icon-emoji"
            onClick={() => setShowEmoji(!showEmoji)}
            className="text-xl flex items-center text-gray-400"
          />
          <div
            className="overflow-hidden absolute shadow"
            style={{
              width: showEmoji ? 352 : 0,
              height: showEmoji ? 435 : 0,
              left: -176,
              bottom: 34,
            }}
            ref={emojiPickerRef}
          ></div>
        </div>
        {/* <Picker data={data} onEmojiSelect={console.log} /> */}
      </div>

      <div
        onClick={() => {
          props.onClickExpand?.();
        }}
        className="flex items-center justify-end absolute right-0 top-0 w-8 h-12"
      >
        <IconFont
          type="icon-right"
          style={{ color: "#23262F", fontSize: 20 }}
          rotate={props.isActive ? -90 : 90}
        />
      </div>
    </div>
  );
};

const PostComments = ({ detail }: { detail: IPostDetail }) => {
  const user = useRecoilValue(userInfoState);
  const [commentList, setCommentList] = useState<IComment[]>([]);
  // const [commentCount, setCommentCount] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [replyingComment, setReplyingComment] = useState<IComment | null>(null);
  const [activeKey, setActiveKey] = useState<string>("1");

  useEffect(() => {
    const fetchCommentList = async (id: number) => {
      const response = await API.getCommentList({ post_id: id });
      if (Array.isArray(response)) {
        setCommentList(formatCommentList(response));
        // setCommentCount(response.length);
      }
    };

    if (detail?.id !== undefined) {
      fetchCommentList(detail.id);
    }
  }, [detail]);

  const updateCommentList = async () => {
    const response = await API.getCommentList({ post_id: detail.id });
    if (Array.isArray(response)) {
      setCommentList(formatCommentList(response));
    }
  };

  const handleComment = async (comment: string) => {
    if (!user) {
      message.error("Please login before comment");
      return false;
    }

    if (!comment) {
      return false;
    }

    if (!detail) {
      return false;
    }

    const isSuccess = await API.createCommentToPost({
      post_id: detail.id,
      content: comment,
    });

    if (isSuccess) {
      message.success("successfully comment");
      updateCommentList();
    }
    return isSuccess;
  };

  const handleReplyComment = async (comment: string) => {
    if (!user) {
      message.error("Please login before comment");
      return false;
    }

    if (!replyingComment) {
      return false;
    }

    if (!comment) {
      return false;
    }

    const isSuccess = await API.createCommentToPost({
      post_id: detail.id,
      content: comment,
      comment_id: replyingComment.id,
    });
    if (isSuccess) {
      message.success("successfully reply");
      closeModal();
      updateCommentList();
    }
    return isSuccess;
  };

  const handleClickReply = (data: IComment) => {
    setReplyingComment(data);
    openModal();
  };

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const handleDeleteSuccess = () => {
    updateCommentList();
  };

  return (
    <>
      <Collapse
        bordered={false}
        expandIcon={() => null}
        expandIconPosition="right"
        className="comments-collapse"
        activeKey={activeKey}
      >
        <Collapse.Panel
          header={
            <div className="flex-auto">
              {/* <div className="flex items-center merriweather-font">
                <div className="text-lg sm:text-lg font-bold mr-2">
                  {detail.comment?.total || commentCount || 0}
                </div>
                <div className="text-lg sm:text-lg font-extrabold">Comments</div>
              </div> */}
              <CommentInput
                onKeyDown={handleComment}
                onClickExpand={() => {
                  setActiveKey(activeKey === "1" ? "" : "1");
                }}
                isActive={activeKey === "1"}
              />
            </div>
          }
          key="1"
        >
          {commentList.length > 0 ? (
            <div className="comments-container">
              {commentList.map((c) => {
                if (c.replyComment && c.replyComment.length > 0) {
                  return (
                    <React.Fragment key={c.id}>
                      <Comment
                        data={c}
                        onClickReply={handleClickReply}
                        onDeleteSuccess={handleDeleteSuccess}
                      />
                      <ReplyComments list={c.replyComment} />
                    </React.Fragment>
                  );
                } else {
                  return (
                    <Comment
                      key={c.id}
                      data={c}
                      onClickReply={handleClickReply}
                      onDeleteSuccess={handleDeleteSuccess}
                    />
                  );
                }
              })}
              {/* <div className="flex items-center justify-center mt-6 mb-8">
                <Button className="main-button rounded-full px-14 h-12" style={{ color: "#fff" }}>
                  More comments
                </Button>
              </div> */}
            </div>
          ) : (
            <div className="text-center py-4 sm:py-6">No Comments</div>
          )}
        </Collapse.Panel>
      </Collapse>

      <Modal
        className="post-comment-modal"
        visible={visible}
        title={null}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        closeIcon={
          <div className="flex items-center justify-center w-14 h-14">
            <div className="flex items-center justify-center w-8 h-8">
              <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
            </div>
          </div>
        }
      >
        <div>
          {replyingComment !== null ? (
            <Comment data={replyingComment} hideFooter onDeleteSuccess={handleDeleteSuccess} />
          ) : null}
          <CommentInput onKeyDown={handleReplyComment} />
        </div>
      </Modal>
    </>
  );
};

export default PostComments;
