const ReportIcon = ({ color, size }: { color: string; size?: number }) => {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 18.6882L16 12.1071" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M16 22.7601L16 22.6788" stroke={color} strokeWidth="2.2" strokeLinecap="round" />
      <path
        d="M28.5219 22.3773L19.0817 5.52797C17.7151 3.08875 14.2849 3.08875 12.9183 5.52797L3.47811 22.3773C2.116 24.8085 3.82655 27.8414 6.55982 27.8414H25.4402C28.1734 27.8414 29.884 24.8085 28.5219 22.3773Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ReportIcon;
