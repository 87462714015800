import API from "@/apis";
import { IEmoji, ISetInfo } from "@/models";
import { emojiListState } from "@/store";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Modal, message } from "antd";
import * as React from "react";
import { useRecoilValue } from "recoil";
import EmojiV2 from "../Emoji/EmojiV2";
import AddEmoji from "../EmojiPicker/AddEmoji";
import IconFont from "../IconFont";
import AddEmojiToSet from "./AddEmojiToSet";

interface Props {
  ispc: boolean;
  onSuccess: () => void;
}

interface ISelectedEmoji {
  [code: string]: IEmoji;
}

const AddSet: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [visibleEmoji, setVisibleEmoji] = React.useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = React.useState<boolean>(false);
  const [emojis, setEmojis] = React.useState<IEmoji[]>([]);
  const [customEmojis, setCustomEmojis] = React.useState<IEmoji[]>([]);
  const [setInfo, setSetInfo] = React.useState<ISetInfo | null>(null);
  const [name, setName] = React.useState<string>("");
  const emojiListValue = useRecoilValue(emojiListState);
  const [showRelated, setShowRelated] = React.useState<boolean>(false);
  const [currentEmoji, setCurrentEmoji] = React.useState<IEmoji | null>(null);
  const [currentEmojiList, setCurrentEmojiList] = React.useState<IEmoji[]>([]);
  const [fetchCurEmojiListLoading, setFetchCurEmojiListLoading] = React.useState<boolean>(false);
  const [selectedEmojiMap, setSelectedEmojiMap] = React.useState<ISelectedEmoji>({});

  const isActive = (code: string) => emojis.map((i) => i.code).includes(code);
  const handleClickEmoji = (item: IEmoji) => async () => {
    if (emojis.map((i) => i.code).includes(item.code)) {
      setEmojis(emojis.filter((_i) => _i.code !== item.code));
    } else {
      setEmojis([...emojis, item]);
    }
    setCurrentEmoji(item);
    fetchEmojiByCode(item.code);
    setVisibleEmoji(true);
    return 0;
  };
  const fetchEmojiByCode = async (code: string) => {
    setCurrentEmojiList([]);
    setFetchCurEmojiListLoading(true);
    const [success, data] = await API.getEmojiList({
      related: code,
    });
    if (success && Array.isArray(data?.data)) {
      setCurrentEmojiList(data?.data);
    }
    setFetchCurEmojiListLoading(false);
  };
  const handleClickCreate = () => {
    if (props.ispc) {
      setVisible(true);
    } else {
      setDrawerVisible(true);
    }
  };

  const handleClickSave = async () => {
    if (!name) {
      return;
    }

    const selectedCount = Object.keys(selectedEmojiMap).length;

    if (selectedCount === 0) {
      message.warning("Please select a emoji");
      return;
    }

    const res = await API.addEmijiSet({
      name,
    });
    if (res?.data) {
      const list = Object.keys(selectedEmojiMap).map((key) => {
        return selectedEmojiMap[key];
      });
      handleAddEmojiToSet(list, res.data.id);
    }
  };

  const handleAddEmojiToSet = async (list: IEmoji[], id: number) => {
    const promiseList = list.map((emoji) => {
      return API.relatedEmoji({
        code: emoji.code,
        id,
      });
    });
    await Promise.all(promiseList);
    message.success("Save Successfully!");
    handleSuccess();
  };

  const fetchRelatedEmojis = async () => {
    let list: IEmoji[] = [];
    const promiseList = emojis.map((item) =>
      API.getEmojiList({
        related: item.code,
        type: "2",
      })
    );
    const resList = await Promise.all(promiseList);
    resList.forEach((resItem) => {
      const [success, res] = resItem;
      if (success) {
        list = list.concat(res.data);
      }
    });
    setCustomEmojis(list);
  };

  const handleClose = () => {
    setVisible(false);
    setDrawerVisible(false);
    setEmojis([]);
    setShowRelated(false);
    setName("");
  };

  const handleCloseEmoji = () => {
    setVisibleEmoji(false);
  };

  const handleSelectEmoji = (selectedEmoji: IEmoji) => {
    if (currentEmoji?.code) {
      setSelectedEmojiMap({
        ...selectedEmojiMap,
        [currentEmoji.code]: selectedEmoji,
      });
    }
    setVisibleEmoji(false);
  };

  const handleSuccess = () => {
    handleClose();
    props.onSuccess();
  };

  const handleAddEmojiSuccess = () => {
    if (currentEmoji?.code) {
      fetchEmojiByCode(currentEmoji?.code);
    }
  };

  const createSet = (
    <div>
      <div className="flex items-center">
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter Set name"
          className="mr-2 rounded-lg"
        />
        <Button className="rounded-lg" type="primary" onClick={handleClickSave}>
          Save Set
        </Button>
      </div>

      <div className="mt-4 emojis grid grid-cols-5 gap-x-2">
        {emojiListValue.map((item, index) => {
          const emojiIsActive = isActive(item.code);
          return (
            <div
              key={item.code}
              onClick={handleClickEmoji(item)}
              className={`flex flex-col items-center justify-center p-2 my-1 cursor-pointer border border-transparent rounded-md hover:bg-zinc-200`}
            >
              <div>
                {selectedEmojiMap[item.code] ? (
                  <div>
                    {<EmojiV2 width={40} height={40} emoji={selectedEmojiMap[item.code]} noScale />}
                  </div>
                ) : (
                  <div className="w-10 h-10 p-0.5">
                    <IconFont type="icon-add-2" style={{ fontSize: 36 }} />
                  </div>
                )}
              </div>
              <div className="text-xs truncate">{item.code}</div>
            </div>
          );
        })}
      </div>

      <Modal
        className="post-modal add-set-modal"
        zIndex={2001}
        onCancel={handleCloseEmoji}
        title="Select an Emoji"
        visible={visibleEmoji}
        footer={null}
        width={500}
        closeIcon={
          <div className="flex items-center justify-center w-14 h-14">
            <div className="flex items-center justify-center w-8 h-8">
              <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
            </div>
          </div>
        }
      >
        <div>
          <div className="mb-6 text-slate-600 text-sm">
            if nothing is selected than default will be used
          </div>
          {fetchCurEmojiListLoading ? (
            <div className="flex items-center justify-center h-14 w-full">
              <LoadingOutlined style={{ fontSize: 24 }} />
            </div>
          ) : (
            <div className="emojis flex flex-wrap -mx-3">
              {currentEmojiList.map((curItem) => {
                return (
                  <div
                    className="mx-3 mb-4 cursor-pointer"
                    key={curItem.code}
                    onClick={() => handleSelectEmoji(curItem)}
                  >
                    <EmojiV2 width={40} height={40} emoji={curItem} noScale />
                  </div>
                );
              })}

              <div className="mx-3 mb-4 cursor-pointer">
                <AddEmoji
                  type="icon"
                  linkEmoji={currentEmoji}
                  ispc={true}
                  onSuccess={handleAddEmojiSuccess}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );

  return (
    <div>
      <Button className="rounded-lg" type="primary" onClick={handleClickCreate}>
        Create set
      </Button>
      <Drawer
        closable={false}
        extra={<div>Close</div>}
        placement="bottom"
        onClose={handleClose}
        visible={drawerVisible}
        className="detail-drawer"
        height={440}
      >
        <div className="pt-8">
          {showRelated && setInfo ? (
            <AddEmojiToSet
              info={setInfo}
              relatedEmojis={customEmojis}
              onSaveSuccess={handleSuccess}
            />
          ) : (
            createSet
          )}
        </div>
      </Drawer>
      <Modal
        className="post-modal"
        zIndex={2000}
        onCancel={handleClose}
        title="Create Emoji Set"
        visible={visible}
        footer={null}
        width={500}
        closeIcon={
          <div className="flex items-center justify-center w-14 h-14">
            <div className="flex items-center justify-center w-8 h-8">
              <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
            </div>
          </div>
        }
      >
        {showRelated && setInfo ? (
          <AddEmojiToSet
            info={setInfo}
            relatedEmojis={customEmojis}
            onSaveSuccess={handleSuccess}
          />
        ) : (
          createSet
        )}
      </Modal>
    </div>
  );
};

export default AddSet;
