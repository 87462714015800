import API from "@/apis";
import IconFont from "@/components/IconFont";
import Waterfall, { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import { searchState } from "@/store";
import { Input } from "antd";
import { useState } from "react";
import { useRecoilState } from "recoil";

const SearchPage = () => {
  // const [homeData, setHomeData] = useRecoilState(homeDataState);
  const [homeSearch, setHomeSearch] = useRecoilState(searchState);
  const [value, setValue] = useState<string>("");

  const handleSearch = async () => {
    if (!value) {
      return;
    }

    try {
      const res = await API.getHomePostList({ keyword: value, page: 1 });
      setHomeSearch({
        keyword: value,
        results: res.data,
        total: res.total,
        page: 1,
      });
    } catch (error) {}
  };

  return (
    <div className="pt-4">
      <div className="px-2.5 flex items-center">
        <Input
          suffix={
            <div className="mx-2 flex items-center" onClick={handleSearch}>
              <IconFont type="icon-search" />
            </div>
          }
          className="rounded-full pl-4"
          placeholder="Search ideas or users"
          size="large"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>

      <div className="w-full h-5"></div>
      {homeSearch.keyword ? (
        <WaterfallWithoutInfiniteScroll keyword={homeSearch.keyword} list={homeSearch.results} />
      ) : (
        <Waterfall />
      )}
    </div>
  );
};

export default SearchPage;
