import { IEmoji } from "@/models";
import { emojiSwitchState, switchedEmojiListState } from "@/store";
import { Popover } from "antd";
import * as React from "react";
import { useRecoilValue } from "recoil";

import { useMediaQuery } from "@/hooks/useMediaQuery";
import { GifMap, OfficialMap, iOSMap } from "@/utils/emojis";

interface Props {
  emoji: IEmoji;
  applyedEmoji?: IEmoji;
  count: number;
  width?: number;
  height?: number;
  onClick?: (emoji: IEmoji) => void;
  padding?: number;
  clicked?: boolean;
  dark?: boolean;
  customClass?: string;
  showName?: boolean;
}

const DiscordEmoji: React.FC<Props> = (props: Props) => {
  const emojiSwitch = useRecoilValue(emojiSwitchState);
  const switchedEmojiListValue = useRecoilValue(switchedEmojiListState);
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  // emoji 图片
  let imageSrc = props.emoji.path;
  if (emojiSwitch) {
    switchedEmojiListValue.forEach((value, index) => {
      if (value.code.replace("-ios", "") === props.emoji.code) {
        imageSrc = value.path;
      }
    });
  }

  if (props.applyedEmoji) {
    imageSrc = props.applyedEmoji.path;
    imageSrc = imageSrc.startsWith("/") ? imageSrc : `/emoji/${imageSrc}`;
  } else if (iOSMap[props.emoji.code]) {
    imageSrc = iOSMap[props.emoji.code];
  } else if (OfficialMap[props.emoji.code]) {
    imageSrc = OfficialMap[props.emoji.code];
  } else if (GifMap[props.emoji.code]) {
    imageSrc = GifMap[props.emoji.code];
  }

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    props.onClick?.(props.emoji);
  };

  const style = {
    width: props.width || 16,
    height: props.height || 16,
    minWidth: props.width || 16,
    margin: "0 auto",
    display: "block",
    cursor: "pointer",
  };

  const baseCls =
    "discord-emoji-container flex items-center transition duration-300 ease-in-out border mr-0.5 mb-1 px-1.5 py-0.5 rounded-lg cursor-pointer hover:border-[#ffffff33] border-transparent";
  // let dynamicCls = "bg-[#eff0f2] border-[]";
  // if (props.clicked) {
  //   dynamicCls = "bg-[#e7e9fd]";
  // }

  const bgColor = props.dark ? "#2F3136" : "#eff0f2";
  const clickedBgColor = props.dark ? "#000" : "#000";

  const countStringLength = `${props.count}`.length || 1;
  const containerStyle = {
    backgroundColor: props.clicked ? clickedBgColor : bgColor, //props.clicked ? "#e7e9fd" : "#eff0f2",
    // backgroundColor: bgColor,
    borderColor: props.clicked ? "#000" : "",
    color: props.clicked ? "#fff" : "",
    fontWeight: props.clicked ? 700 : 400,
  };

  const EmojiView = (
    <div onClick={handleClick} className={`${baseCls} ${props.customClass}`} style={containerStyle}>
      <img src={imageSrc} style={style} alt="" />
      {props.showName ? <div className="text-xs mx-1">{props.emoji.code}</div> : null}

      <div
        style={{ minWidth: countStringLength * 9 }}
        className="min-w-[9px] ml-1 text-base leading-1 flex items-center"
      >
        <div className="text-sm pb-0.5">{props.showName ? "×" : null}</div>
        <div>{props.count}</div>
      </div>
    </div>
  );

  if (isLargerThan414) {
    const popoverImageStyle = {
      ...style,
      width: 32,
      height: 32,
    };

    const PopoverContent = (
      <div className="flex items-center p-1">
        <img src={imageSrc} style={popoverImageStyle} alt="" />
        <div className="ml-2 text-base bg-slate-700 text-white">{props.emoji.code}</div>
      </div>
    );

    return (
      <Popover
        title={null}
        content={PopoverContent}
        trigger="hover"
        className="home-emoji-popover"
        overlayClassName="home-emoji-popover"
      >
        {EmojiView}
      </Popover>
    );
  }

  return EmojiView;
};

export default DiscordEmoji;
