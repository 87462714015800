export const EmbedYouTubeVideo = ({ height, src }: { height: number; src: string }) => {
  const isEmbed = src.startsWith("https://www.youtube.com/embed");
  let _src = src;
  if (!isEmbed) {
    // https://www.youtube.com/watch?v=iu4JodMRbEw&ab_channel=Mallikas01
    const url = new URL(src);
    const search = new URLSearchParams(url.search);
    const v = search.get("v");
    if (v) {
      _src = `https://www.youtube.com/embed/${v}`;
    }
  }

  return (
    <iframe
      width="100%"
      height={height}
      // src="https://www.youtube.com/embed/J442-ti-Dhg"
      // src="https://www.youtube.com/embed/eivj7wj8GTc"
      // src='https://www.youtube.com/embed/cGEuqQ4AQ0E'
      src={_src || "https://www.youtube.com/embed/eivj7wj8GTc"}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};
