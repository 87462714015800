import API from "@/apis";
import useApplyEmojiGroup from "@/hooks/useApplyEmojiGroup";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { IHomeData } from "@/models";
import { emojiListState, searchState, searchWordState } from "@/store";
import { findMaxNumEmoji, setInfo2Map } from "@/utils";
import { OfficialMap, iOSMap } from "@/utils/emojis";
import { Popover, message } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { EmbedYouTubeVideo } from "../EmbedYouTubeVideo";
import UserImg from "../UserImg";
import NoteButtons from "./NoteButtons";

interface NoteProps {
  data: IHomeData;
  onClickComment?: () => void;
  hideTag?: boolean;
  showTopEmoji?: boolean;
  hideBottomEmoji?: boolean;
  deletable?: boolean;
  onDelete?: (id: number) => void;
  size?: "small" | "medium" | "large";
  height: number;
}

// vercel 强制 https 问题
export const formatImage = (url?: string) => {
  if (!url) {
    return "";
  }

  if (url.startsWith("/uploads")) {
    return url;
  }

  if (url.startsWith("http")) {
    return url;
  }

  const _url = `https://image.tmdb.org/t/p/w500/${url}`;
  return _url.replace("http://49.235.185.49:3000", "").replace("http://100.24.6.246", "");
};

const NoteSquare = ({
  data,
  deletable,
  height,
  hideTag,
  showTopEmoji,
  hideBottomEmoji,
  onDelete,
}: NoteProps) => {
  const navigate = useNavigate();
  const emojiListValue = useRecoilValue(emojiListState);
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  const setSearchWord = useSetRecoilState(searchWordState);
  const setHomeSearch = useSetRecoilState(searchState);
  const handleSearch = async (word: string) => {
    if (!word) {
      return;
    }

    try {
      const res = await API.getHomePostList({ keyword: word, page: 1 });
      setHomeSearch({
        keyword: word,
        results: res.data,
        total: res.total,
        page: 1,
      });
    } catch (error) {}
  };
  // const emojiGroupsValue = useRecoilValue(emojiGroupsState);
  // apply的表情组
  const { applyedEmojiGroup } = useApplyEmojiGroup();
  // const [applyedEmojiGroup, setApplyedEmojiGroup] = useState<ISetInfoWithEmojis>();

  // useEffect(() => {
  //   const applyEmojiGroup = emojiGroupsValue.find((group) => group?.apply === 1);
  //   applyEmojiGroup && setApplyedEmojiGroup(applyEmojiGroup);
  // }, [emojiGroupsValue]);

  const handleGoDetail = () => {
    if (data.website === "nytimes") {
      navigate("/news-detail/nytimes?id=" + data.id);
      return;
    }

    if (data.website === "movies") {
      navigate("/news-detail/movie?id=" + data.id);
      return;
    }

    if (data.website === "funny") {
      navigate("/news-detail/funny?id=" + data.id);
      return;
    }

    if (data?.isDemo) {
      return;
    }
    navigate("/detail?id=" + data.id);
  };

  const handleDelete = async () => {
    try {
      await API.delPost({ id: data.id });
      message.success("Successfully");
      onDelete?.(data.id);
    } catch (error) {
      console.log(error);
    }
  };

  const imageSrc =
    data.youtubeLink && data.youtubeImage ? data.youtubeImage : formatImage(data.image);
  // const length = SelectOptionEmojiList.length;
  // const randomNum = Math.floor(Math.random() * length);

  const maxEmoji = findMaxNumEmoji(data.emoji || [], emojiListValue);
  let maxEmojiPath = maxEmoji?.path || "";
  if (maxEmoji) {
    const applyEmojiGroupMap = setInfo2Map(applyedEmojiGroup);
    if (applyEmojiGroupMap[maxEmoji.code]) {
      if (iOSMap[applyEmojiGroupMap[maxEmoji.code].code]) {
        maxEmojiPath = iOSMap[applyEmojiGroupMap[maxEmoji.code].code];
      } else if (OfficialMap[applyEmojiGroupMap[maxEmoji.code].code]) {
        maxEmojiPath = OfficialMap[applyEmojiGroupMap[maxEmoji.code].code];
      } else {
        maxEmojiPath = applyEmojiGroupMap[maxEmoji.code].path;
        maxEmojiPath = maxEmojiPath.startsWith("/emoji") ? maxEmojiPath : `/emoji/${maxEmojiPath}`;
      }
    } else {
      if (iOSMap[maxEmoji.code]) {
        maxEmojiPath = iOSMap[maxEmoji.code];
      } else if (OfficialMap[maxEmoji.code]) {
        maxEmojiPath = OfficialMap[maxEmoji.code];
      }
    }
  }

  return (
    <div
      style={{ height: height, width: height }}
      className="bg-white rounded-lg overflow-hidden pb-0 mb-2 sm:mb-6 relative group sm:px-0"
    >
      {data.youtube ? (
        <EmbedYouTubeVideo src={data.youtube} height={isLargerThan414 ? 240 : 200} />
      ) : data.text ? (
        <div
          className="text-xl p-2 font-bold flex items-center justify-center pb-12"
          style={{ height: height }}
        >
          {data.text}
        </div>
      ) : (
        <img
          src={imageSrc}
          alt=""
          style={{ height: height, width: height }}
          className="cursor-pointer note-img w-full min-h-[200px] sm:min-h-[240px] align-middle object-cover"
          onClick={handleGoDetail}
        />
      )}

      <div
        className="rounded-full h-8 w-10 ml-4 mb-3 right-2 sm:right-5 top-2 sm:top-4 absolute"
        // style={{ top: -maxEmojiSize / 2, left: -maxEmojiSize / 2 }}
      >
        <div className="flex items-center justify-center relative">
          {/* 可能存在旧数据没有表情的情况 */}
          {maxEmojiPath && <img src={maxEmojiPath} alt="" className="mb-1 h-7 w-7 sm:w-8 sm:h-8" />}
        </div>
      </div>

      <NoteButtons data={data} showTopEmoji onDelete={deletable ? handleDelete : undefined} />

      <div
        className="absolute cursor-pointer bottom-0 left-0 w-full text-white linear-bg p-2"
        onClick={handleGoDetail}
      >
        <div className="text-base font-bold with-2-col mb-1">{data.title || "Title"}</div>
        <div className="flex items-center">
          <Popover
            trigger="hover"
            content={<div className="p-0">{data?.author?.nickname || data?.author?.username}</div>}
            title={null}
            getPopupContainer={(trigger) => trigger.parentElement!}
          >
            <div>
              <UserImg
                name={data?.author?.nickname || data?.author?.username}
                src={data.author?.avatar}
                size={12}
                className="w-3 h-3 rounded-full z-10"
                type={data.type}
              />
            </div>
          </Popover>
          {data.type === "movie" && data.tags?.length > 0 ? (
            <div
              className="ml-2 text-sm"
              onClick={(e) => {
                e.stopPropagation();
                setSearchWord(`#${data.tags[0]}`);
                handleSearch(data.tags[0]);
              }}
            >
              {data.tags[0]}
            </div>
          ) : null}
          <div className={`ml-2 text-sm`}>
            {data.created_at
              ? dayjs(data.created_at).format("MM/DD/YY")
              : dayjs().format("MM/DD/YY")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteSquare;
