import ICON_REACTED from "@/assets/icons/icon-reacted.svg";
import { useState } from "react";
// import { LottieName } from "../Emoji";
import "./index.css";

const BannerEmojis = () => {
  // const [emojiName, setEmojiName] = useState<LottieName | "all">("all");
  const [time, setTime] = useState<String>("24h");

  // const handleClickEmoji = (name: LottieName) => async () => {
  //   setEmojiName(name);
  //   return 0;
  // };

  const baseCls = "w-11 h-9 flex items-center justify-center cursor-pointer font-bold";
  const activeCls = "bg-[#F0F0F3] text-sm text-black";
  const deactiveCls = "text-[#646675] text-xs";

  return (
    <div className="mx-auto px-1 sm:px-2" style={{ maxWidth: 1264, margin: "0px auto" }}>
      <div className="flex items-start sm:items-center justify-between flex-col sm:flex-row">
        {/* <div className="mb-2 flex h-10 rounded-lg border-[#DEE3E5] border-2 overflow-hidden">
          <div
            className={`${baseCls} ${emojiName === "all" ? activeCls : deactiveCls}`}
            onClick={() => setEmojiName("all")}
          >
            All
          </div>
          {EMOJI_LIST.map((item) => {
            return (
              <div
                key={item.name}
                className={`${baseCls} ${emojiName === item.type ? activeCls : deactiveCls}`}
                onClick={() => setEmojiName(item.type)}
              >
                <EmojiWithLottie
                  // isActive={isActive(item.type)}
                  autoScaleBack
                  onClick={handleClickEmoji(item.type)}
                  width={24}
                  height={24}
                  name={item.type}
                />
              </div>
            );
          })}
        </div> */}
        <div className="flex items-center mb-2">
          <img src={ICON_REACTED} alt="" className="w-6 mr-2" />
          <div className="text-black text-xl sm:text-2xl font-bold mt-0.5">Most Reacted</div>
        </div>

        <div className="mb-2 flex h-10 rounded-lg border-[#DEE3E5] border-2 overflow-hidden">
          {/* <div
            className={`${baseCls} ${time === "1h" ? activeCls : deactiveCls}`}
            onClick={() => setTime("1h")}
          >
            1h
          </div> */}
          <div
            className={`${baseCls} ${time === "24h" ? activeCls : deactiveCls}`}
            onClick={() => setTime("24h")}
          >
            24h
          </div>
          <div
            className={`${baseCls} ${time === "7d" ? activeCls : deactiveCls}`}
            onClick={() => setTime("7d")}
          >
            7d
          </div>
          <div
            className={`${baseCls} ${time === "30d" ? activeCls : deactiveCls}`}
            onClick={() => setTime("30d")}
          >
            30d
          </div>
        </div>
      </div>
    </div>
  );
};
export default BannerEmojis;
