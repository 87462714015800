import { userInfoState } from "@/store";
import { useRecoilValue, useSetRecoilState } from "recoil";

import IconFont from "@/components/IconFont";
import UserImg from "@/components/UserImg";
import { getUserName } from "@/utils";
import { DeleteOutlined, ExclamationCircleFilled, LeftOutlined } from "@ant-design/icons";
import { Button, Divider, Empty, Form, Input, Modal, Space, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useState } from "react";
import API from "@/apis";
import IMAGE_AVATAR_1 from "@/assets/users/avatar-1.png";
import IMAGE_AVATAR_2 from "@/assets/users/avatar-2.png";
import IMAGE_AVATAR_3 from "@/assets/users/avatar-3.png";
import IMAGE_AVATAR_4 from "@/assets/users/avatar-4.png";
import IMAGE_AVATAR_5 from "@/assets/users/avatar-5.png";
import IMAGE_AVATAR_6 from "@/assets/users/avatar-6.png";
import Loading from "../Loading";

const initialTags = ["Studio", "Moments", "Graphic", "Portraits"];
const imageList = [
  IMAGE_AVATAR_1,
  IMAGE_AVATAR_2,
  IMAGE_AVATAR_3,
  IMAGE_AVATAR_4,
  IMAGE_AVATAR_5,
  IMAGE_AVATAR_6,
];

// const getMockData = () => {
//   return [
//     {
//       name: `Catherine Harmon`,
//       id: 0,
//     },
//     {
//       name: `Brian Short`,
//       id: 1,
//     },
//     {
//       name: `Rachel Hodges`,
//       id: 2,
//     },
//     {
//       name: `Bernard Welch`,
//       id: 3,
//     },
//     {
//       name: `Elizabeth Bennett`,
//       id: 4,
//     },
//     {
//       name: `Leonard OBrien`,
//       id: 5,
//     },
//   ];
// };
interface SubsWithDropdownProps {
  title: string;
  type: "follower" | "following";
  total: number;
  id: number;
}

export const SubsWithDropdown = (props: SubsWithDropdownProps) => {
  const [subs, setSubs] = useState<
    Array<{
      name: string;
      id: number;
      avatar: string;
    }>
  >([]);
  const navigate = useNavigate();
  const [subVisible, setSubVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const data = await API.getFollowList({ id: props.id });
    if (props.type === "follower") {
      setSubs(
        data.data.user_follows.map((i: any) => {
          return {
            name: i.nickname || i.username,
            id: i.id,
            avatar: i.avatar,
          };
        })
      );
    } else if (props.type === "following") {
      setSubs(
        data.data.follow_users.map((i: any) => {
          return {
            name: i.nickname || i.username,
            id: i.id,
            avatar: i.avatar,
          };
        })
      );
    }
    setLoading(false);
  }, [props.id, props.type]);

  useEffect(() => {
    if (subVisible) {
      fetchData();
    }
  }, [subVisible]);

  const handleClickUser = (id: number) => () => {
    navigate("/user?id=" + id);
  };

  return (
    <>
      <div className="font-bold pr-1 cursor-pointer" onClick={() => setSubVisible(true)}>
        {props.total}
      </div>
      <div className="cursor-pointer" onClick={() => setSubVisible(true)}>
        {props.title}
      </div>
      <Modal
        className="edit-tags-modal"
        visible={subVisible}
        title={null}
        footer={null}
        onCancel={() => setSubVisible(false)}
        destroyOnClose
        width={560}
        closable={false}
      >
        <div>
          <div className="flex items-center justify-between">
            <div className="text-2xl font-extrabold">
              {props.title.replace(props.title[0], props.title[0].toUpperCase())}
            </div>
            <div
              className="flex items-center justify-center w-14 h-14 cursor-pointer"
              onClick={() => setSubVisible(false)}
            >
              <div className="flex items-center justify-center w-8 h-8">
                <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
              </div>
            </div>
          </div>
          <Divider />
          {loading ? (
            <div className="flex items-center justify-center py-20">
              <Loading />
            </div>
          ) : (
            <div>
              <Space direction="vertical" size={32} className="w-full">
                {subs.map((sub) => {
                  return (
                    <div key={sub.id}>
                      <div className="flex items-center justify-between">
                        <Space className="cursor-pointer" onClick={handleClickUser(sub.id)}>
                          <div>
                            <UserImg
                              src={sub.avatar}
                              name={sub.name}
                              className="w-14 h-14 rounded-full overflow-hidden"
                            />
                          </div>
                          <div className="text-lg font-extrabold">{sub.name}</div>
                        </Space>

                        {props.type === "following" ? (
                          <Space
                            className="cursor-pointer"
                            onClick={() => {
                              const newSubs = subs.filter((i) => i.id !== sub.id);
                              setSubs(newSubs);
                            }}
                          >
                            <DeleteOutlined style={{ fontSize: 18 }} />
                            <div className="text-md text-black pt-1">Delete</div>
                          </Space>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Space>

              {subs.length === 0 && <Empty />}
            </div>
          )}
        </div>
      </Modal>
    </>
  );

  // return (
  //   <Dropdown
  //     overlay={menu}
  //     trigger={["click"]}
  //     arrow={{ pointAtCenter: true }}
  //     visible={subVisible}
  //     onVisibleChange={(e: boolean) => {
  //       setSubVisible(e);
  //     }}
  //   >

  //   </Dropdown>
  // );
};

const UserBanner = () => {
  const user = useRecoilValue(userInfoState);
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [interests, setInterests] = useState<string[]>([]);

  const handleDelete = (index: number) => {
    const newList = interests.filter((_i, idx) => idx !== index);
    setInterests(newList);
  };

  const handleFinish = (values: any) => {
    setInterests([...interests, values.tagvalue]);
    // TODO
    form.setFieldsValue({ tagvalue: "" });
    setVisible(false);
  };

  const setUserInfo = useSetRecoilState(userInfoState);

  const handleLogout = () => {
    const modal = Modal.confirm({
      className: "post-modal logout-modal",
      title: (
        <div className="relative">
          <div>Do You Want to Log Out?</div>
          <div
            className="flex cursor-pointer items-center justify-center w-8 h-8 absolute -right-4 -top-4"
            onClick={() => {
              modal.destroy();
            }}>
            <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
          </div>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      onOk() {
        localStorage.setItem("access_token", "");
        setUserInfo(null);
        navigate("/");
      },
    });
  };

  if (!user?.id) {
    return null;
  }

  return (
    <>
      {/* web */}
      <div className="hidden sm:block author-card-wrapper pt-10 pb-20 px-6">
        <div className="author-card max-w-[1264px] mx-auto flex">
          <div className="flex flex-col items-center mr-6">
            <div className="image-wrapper">
              <UserImg
                src={user.avatar}
                name={user?.nickname || user?.username || user?.email}
                className="flex items-center justify-center object-contain"
                style={{ width: "128px", height: "128px", minWidth: 128, borderRadius: "50%" }}
              />
            </div>
          </div>

          <div
            className="flex justify-center flex-col mt-0 text-center md:text-left"
            style={{ maxWidth: 738 }}
          >
            <div className="flex items-center">
              <div className="user-name color-black text-4xl font-bold mb-2">
                {getUserName(user)}
              </div>
              <div className="inline-flex items-center">
                <div
                  className="flex items-center justify-center rounded-full w-8 h-8 ml-4 cursor-pointer"
                  onClick={handleLogout}
                >
                  <IconFont type="icon-logout" style={{ fontSize: 16, color: "#FF8811" }} />
                </div>
                <div
                  className="flex items-center justify-center rounded-full w-8 h-8 cursor-pointer"
                  onClick={() => navigate("/settings/public_profile")}
                >
                  <IconFont
                    type="icon-options-vertical"
                    style={{ fontSize: 20, color: "#FF8811" }}
                  />
                </div>
              </div>
            </div>
            <div className="user-brief text-sm color-black mb-1">{user.bio || "no data"}</div>
            <div className="flex items-center justify-start mb-2">
              <div className="flex items-center justify-start">
                {/* <div>subscribers</div> */}
                <SubsWithDropdown
                  total={user.subscriber || 0}
                  type="follower"
                  title={user?.subscriber > 1 ? "followers" : "follower"}
                  id={+user.id}
                />
                <div className="mx-4">•</div>
                <SubsWithDropdown
                  total={user.subscriptions || 0}
                  type="following"
                  title="following"
                  id={+user.id}
                />
              </div>
            </div>
            <div className="flex item-center">
              <div className="flex items-center rounded social-button h-7 px-3 cursor-pointer mr-4">
                <IconFont type="icon-reddit" style={{ fontSize: 17, color: "black" }} />
                <span className="ml-2 text-black">Reddit</span>
              </div>
              <div className="flex items-center rounded social-button h-7 px-3 cursor-pointer">
                <IconFont type="icon-twitter" style={{ fontSize: 17, color: "black" }} />
                <span className="ml-2 text-black">Twitter</span>
              </div>
            </div>

            {/* <div className="mb-2">Interests:</div> */}

            <div className="hidden flex items-center flex-wrap justify-center md:justify-start">
              {interests.map((tag, index) => (
                <div
                  key={`${tag}-${index}`}
                  className="px-4 py-2 border mr-3 rounded-full bg-white mb-2"
                  style={{ borderColor: "#FF8811" }}
                >
                  {tag.toUpperCase()}
                </div>
              ))}

              {interests.length === 0 ? (
                <div className="flex items-center">
                  <div className="text-slate-500 text-xs">
                    Add tags for others to see your interests
                  </div>
                  <div
                    className="h-10 flex items-center px-2 cursor-pointer"
                    onClick={() => setVisible(true)}
                  >
                    <IconFont type="icon-add-2" style={{ fontSize: 18, color: "#FF8811" }} />
                  </div>
                </div>
              ) : (
                <div
                  className="h-10 flex items-center px-5 mr-3 rounded-full mb-2 cursor-pointer"
                  onClick={() => setVisible(true)}
                >
                  <IconFont type="icon-setting" style={{ fontSize: 18, color: "#FF8811" }} />
                </div>
              )}
            </div>

            <Modal
              className="edit-tags-modal"
              visible={visible}
              title={null}
              footer={null}
              onCancel={() => setVisible(false)}
              destroyOnClose
              width={660}
              closeIcon={
                <div className="flex items-center justify-center w-14 h-14">
                  <div className="flex items-center justify-center w-8 h-8">
                    <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
                  </div>
                </div>
              }
            >
              <div className="p-4">
                <div className="text-2xl font-extrabold">Interest</div>
                <div className="flex items-center flex-wrap my-8">
                  {interests.map((tag, index) => (
                    <div
                      key={`${tag}-${index}`}
                      className="px-4 py-2 border mr-3 rounded-full mb-2 flex items-center"
                      style={{ borderColor: "#FF8811" }}
                    >
                      <div className="text-xs font-bold">{tag.toUpperCase()}</div>
                      <div
                        className="flex items-center justify-center ml-2 cursor-pointer"
                        onClick={() => handleDelete(index)}
                      >
                        <IconFont type="icon-close" style={{ color: "#777E91" }} />
                      </div>
                    </div>
                  ))}
                </div>

                <Form form={form} layout="vertical" onFinish={handleFinish}>
                  <Form.Item
                    name="tagvalue"
                    extra="Your interests are generated from the types of photos you like, collect, and contribute."
                  >
                    <Input
                      className="rounded-lg px-5"
                      size="large"
                      placeholder="Enter here"
                      type="text"
                    />
                  </Form.Item>

                  <Button className="feedback-submit-button" type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      {/* h5 */}
      <div className="block sm:hidden">
        <div className="author-card-wrapper">
          <div className="h-11 px-2 flex items-center justify-between">
            <div
              className="w-8 h-8 flex items-center justify-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              <LeftOutlined style={{ color: "#000" }} />
            </div>
            <SettingsButton />
          </div>
          <div className="px-4 pb-5 pt-2">
            <div className="h-30 flex items-center relative">
              <UserImg
                src={user?.avatar}
                name={user?.nickname || user?.username}
                size={88}
                className="flex items-center justify-center rounded-full overflow-hidden"
                style={{ width: 88, height: 88, minWidth: 88, objectFit: "cover" }}
              />
              <div className="ml-2 flex-auto">
                <div className="font-bold text-xl sm:text-2xl text-black">{getUserName(user)}</div>
                <div className="text-xs opacity-75 hidden mt-2">
                  <div>name: {user.username || "-"}</div>
                  <div>IP Address: 新加坡</div>
                </div>
              </div>
              <div className="absolute -right-2 top-0">
                <div
                  className="flex items-center justify-center rounded-full w-8 h-8 ml-4 cursor-pointer"
                  onClick={handleLogout}
                >
                  <IconFont type="icon-logout" style={{ fontSize: 16, color: "#FF8811" }} />
                </div>
              </div>
            </div>
            {/* <div className="my-2 flex items-center">
              <div className="text-lg font-bold mr-4">{getUserName(user)}</div>
              <SettingsButton />
              <div className="bg-white w-8 h-8 flex items-center justify-center rounded-full">
                <IconFont type="icon-logout" style={{ color: "#FF8811" }} />
              </div>
            </div> */}
            <div className="py-4 text-base">
              <Typography.Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "more" }}>
                {user.bio || "no data"}
              </Typography.Paragraph>
            </div>

            {/* <div className="flex items-center justify-start mb-3 md:mb-5">
              <div className="w-7 h-7 rounded-md bg-[#9C99AE] flex items-center justify-center mr-3 cursor-pointer">
                <IconFont type="icon-discord" style={{ fontSize: 17, color: "#fff" }} />
              </div>
              <div className="w-7 h-7 rounded-md bg-[#9C99AE] flex items-center justify-center mr-3 cursor-pointer">
                <IconFont type="icon-twitter" style={{ fontSize: 17, color: "#fff" }} />
              </div>
            </div> */}

            {/* <div className="mb-2">Interests:</div> */}

            <div className="flex items-center flex-wrap justify-start">
              <div
                className="px-3 py-[3px] text-sm font-bold text-[#FF8811] mr-2 rounded-full bg-[#FF8811] mb-2"
                // onClick={() => setVisible(true)}
                style={{ background: "rgba(87, 79, 77, 0.3)", lineHeight: "14px", height: 22 }}
              >
                +
              </div>
              {initialTags.map((tag) => (
                <div
                  key={tag}
                  className="px-2 py-[3px] text-xs font-bold text-white mr-2 rounded-full mb-2"
                  style={{ background: "rgba(87, 79, 77, 0.3)" }}
                >
                  {tag.toUpperCase()}
                </div>
              ))}
            </div>

            <div className="flex items-center justify-between mt-5">
              <Space direction="horizontal" size={8}>
                <div className="text-center">
                  <div className="font-bold">100к</div>
                  <div className="text-xs opacity-50">Following</div>
                </div>
                <div className="text-center">
                  <div className="font-bold">12к</div>
                  <div className="text-xs opacity-50">Followers</div>
                </div>
                <div className="text-center">
                  <div className="font-bold">789</div>
                  <div className="text-xs opacity-50">Interactions</div>
                </div>
              </Space>

              <Space direction="horizontal" size={8}>
                <div className="h-8 px-6 flex items-center justify-center bg-[#FF8811] text-white font-bold rounded-full">
                  Follow
                </div>
              </Space>
            </div>

            {/* <div className="mt-4 flex items-center">
              <div className="text-black">
                <span className="mr-1 font-bold">100к</span>
                <span className="font-bold opacity-50">subscriber</span>
              </div>
              <div className="w-px h-3 opacity-50 bg-black mx-3"></div>
              <div>
                <span className="mr-1 font-bold">12к</span>
                <span className="font-bold">subscriptions</span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const SettingsButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/settings/public_profile");
  };

  return (
    <div onClick={handleClick} className="w-8 h-8 flex items-center justify-center rounded-full">
      <IconFont type="icon-setting" style={{ color: "#000" }} />
    </div>
  );
};

export default UserBanner;
