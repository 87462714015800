import API from "@/apis";
import IconFont from "@/components/IconFont";
import { MOCK_TAGS } from "@/mocks";
import { IHomeData } from "@/models";
import {
  homeActiveEmojiState,
  homeDataState,
  searchState,
  searchWordState,
  tagState,
} from "@/store";
import { Input, Select } from "antd";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const HeaderSearch = () => {
  // const [value, setValue] = useState<string>("");
  const [searchWord, setSearchWord] = useRecoilState(searchWordState);
  const setHomeSearch = useSetRecoilState(searchState);
  const [tag, setTag] = useRecoilState(tagState);
  const [homeData, setHomeData] = useRecoilState(homeDataState);
  const homeActiveEmoji = useRecoilValue(homeActiveEmojiState);

  useEffect(() => {
    if (!searchWord) {
      setHomeSearch({
        keyword: "",
        results: [],
        total: 0,
        page: 1,
      });
    }
  }, [searchWord, setHomeSearch]);

  const handleSelectTag = async (name: string) => {
    if (name === tag) {
      return;
    }

    if (name === "All" && tag !== "") {
      setTag("");

      setHomeData({
        ...homeData,
        loading: true,
      });
      API.getHomePostList({
        code: homeActiveEmoji ? homeActiveEmoji.code : undefined,
        orderDir: homeActiveEmoji ? "desc" : undefined,
        page: 1,
      }).then((data) => {
        const newItems = data.data.map((i: IHomeData, idx: number) => {
          return {
            groupKey: 1,
            key: idx,
            data: i,
          };
        });
        setHomeData({
          page: 2,
          total: data.total || 0,
          data: [...newItems],
          loading: false,
        });
      });
    }

    if (name !== "All") {
      setTag(name);

      setHomeData({
        ...homeData,
        loading: true,
      });
      API.getHomePostList({
        code: homeActiveEmoji ? homeActiveEmoji.code : undefined,
        orderDir: homeActiveEmoji ? "desc" : undefined,
        tag: name,
        page: 1,
      }).then((data) => {
        const newItems = data.data.map((i: IHomeData, idx: number) => {
          return {
            groupKey: 1,
            key: idx,
            data: i,
          };
        });
        setHomeData({
          page: 2,
          total: data.total || 0,
          data: [...newItems],
          loading: false,
        });
      });
    }
  };

  const handleSearch = async () => {
    if (!searchWord) {
      return;
    }

    let keyword = searchWord.trim();
    if (keyword.startsWith("#")) {
      keyword = keyword.replace("#", "");
    }
    try {
      const res = await API.getHomePostList({ keyword, page: 1 });
      setHomeSearch({
        keyword: searchWord,
        results: res.data,
        total: res.total,
        page: 1,
      });
    } catch (error) {}
  };

  return (
    <div
      style={{ borderColor: "#E6E8EC" }}
      className="h-10 sm:h-[52px] w-96 sm:border flex items-center bg-slate-200 sm:bg-white rounded sm:rounded-full overflow-hidden mr-0 sm:mr-4 lg:mr-0"
    >
      <div className="h-[52px] hidden sm:flex items-center pl-2">
        <Select
          defaultValue={"All"}
          // bordered={false}
          onChange={(value: string) => {
            handleSelectTag(value);
          }}
          dropdownMatchSelectWidth={false}
          className="header-select"
          size="large"
          dropdownStyle={{
            borderRadius: 12,
          }}
        >
          {[{ id: "all", tag: "All" }, ...MOCK_TAGS].map((item) => {
            return (
              <Select.Option key={item.id} value={item.tag} className="111">
                {item.tag}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <Input
        className="h-[32px] search-input flex-auto ml-2"
        placeholder="Search for stuffs or #tag"
        style={{ outline: "none" }}
        onChange={(e) => setSearchWord(e.target.value.trim())}
        value={searchWord}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        bordered={false}
        suffix={
          <IconFont
            onClick={handleSearch}
            type="icon-search"
            className="text-base text-gray-400 px-2"
          />
        }
      />
    </div>
  );
};

export default HeaderSearch;
