import UserBanner from "@/components/UserBanner";
import UserNoteTabs from "@/components/UserNoteTabs";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { loginVisibleState, userInfoState } from "@/store";
import { Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

const Profile = () => {
  const user = useRecoilValue(userInfoState);
  const setLoginVisible = useSetRecoilState(loginVisibleState);
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  const navigate = useNavigate();
  const handleClickSignin = () => {
    if (isLargerThan414) {
      setLoginVisible(true);
    } else {
      navigate("/login");
    }
  };
  if (!user) {
    return (
      <div className="py-10">
        <Empty description="You have not logged in, please log in">
          <Button type="primary" shape="round" onClick={handleClickSignin}>
            Sign in
          </Button>
        </Empty>
      </div>
    );
  }
  return (
    <div>
      <UserBanner />
      {user?.id ? <UserNoteTabs id={+user.id} /> : null}
    </div>
  );
};
export default Profile;
