import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title: 'Meet Kirito & Asuna in Sword Art Online Live Show "-DIVE TO STAGE-" Key Visual',
    author: "Mikikazu Komatsu",
    summary: "The original anime VAs provide their voices",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/1ede55e2909e5c65afdcfeb7784c604d1665366592_thumb.jpg",
    published: "10/10/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09/meet-kirito-asuna-in-sword-art-online-live-show-dive-to-stage-key-visual",
    tag: "news",
  },
  {
    title:
      "The Maid I Hired Recently Is Mysterious & The Little Lies We All Tell Heroines Wear School Uniform in Collab Visual",
    author: "Mikikazu Komatsu",
    summary: "Studio Flad-animated comedy will arrive on Crunchyroll this fall",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/ec59203c5ab4f852bab80eba88e5d1131665363783_thumb.jpg",
    published: "10/10/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09-1/the-maid-i-hired-recently-is-mysterious-the-little-lies-we-all-tell-heroines-wear-school-uniform-in-collab-visual",
    tag: "news",
  },
  {
    title:
      "Anime Singer Konomi Suzuki Tests Positive for COVID-19 Postponing Heaven Burns Red Event",
    author: "Daryl Harding",
    summary: "The Heaven Burns Red event was scheduled to be held today",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/2ebb896c8f8d38f5128b52a96b2d28621665362328_thumb.jpg",
    published: "10/10/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09-1/anisong-singer-konomi-suzuki-tests-positive-for-covid-19-postponing-heaven-burns-red-event",
    tag: "news",
  },
  {
    title: 'Shouta Aoi Digitally Releases Pop Team Epic Season 2 Opening Theme "PSYCHO:LOGY"',
    author: "Mikikazu Komatsu",
    summary: '"You can enjoy various Shota Aoi\'s voices in one song!"',
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/844ca1fb167cc275c3f811df749d13e81665361297_thumb.jpg",
    published: "10/10/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09/shouta-aoi-digitally-releases-pop-team-epic-season-2-opening-theme-psychology",
    tag: "news",
  },
  {
    title: "Yuki Yuna Is A Hero Mobile Game To Be Ported To Consoles In The Future",
    author: "Humberto Saabedra",
    summary: "Game first launched in 2017 shuts down later this month in Japan",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/13c90553a54e8d910be8107a68a391a91665353421_thumb.jpg",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09-1/yuki-yuna-is-a-hero-mobile-game-to-be-ported-to-consoles-in-the-future",
    tag: "news",
  },
  {
    title:
      "FEATURE: 5 Anime Where the Protagonists Acknowledge the Difficulties of Pursuing Their Dreams",
    author: "AsusWay",
    summary:
      "From going against societal norms to believing in the true magic of people’s imaginations",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/e0aee5c61d0bf6fff8263fea5506c19d1664838178_thumb.jpg",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-feature/2022/10/09/feature-5-anime-where-the-protagonists-acknowledge-the-difficulties-of-pursuing-their-dreams",
    tag: "news",
  },
  {
    title: "Love Live! Superstar!! Season 3 is Officially in the Works",
    author: "Joseph Luster",
    summary: "General public auditions for the next member of Liella! begin in November",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/d3736afda2eb046c009b19bbb5da2dda1665324671_thumb.jpg",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09-1/love-live-superstar-season-3-is-officially-in-the-works",
    tag: "news",
  },
  {
    title:
      'BEASTARS Author Paru Itagaki Illustrates Chara\'s "PRIVATE BEACH" for EGAKU -draw the song- Web Series',
    author: "Paul Chapman",
    summary:
      "Series that combines music and manga art is currently in its second season on Youtube",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/32f6d098ef5253691a0c1d5a711592bb1665321120_thumb.jpg",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09/beastars-author-paru-itagaki-illustrates-charas-private-beach-for-egaku-draw-the-song-web-series",
    tag: "news",
  },
  {
    title: "Heroes Are Reborn in Kamen Rider Black Sun Trailer",
    author: "Paul Chapman",
    summary:
      "10 new cast members and theme song info revealed for upcoming tokusatsu series on Amazon Prime in October",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/3ff02d8efeb2e0000f4b39edce8a93001665319279_thumb.jpg",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09/heroes-are-reborn-in-kamen-rider-black-sun-trailer",
    tag: "news",
  },
  {
    title: "Regional Mascot Kumamon Cameos in Delicious Party Pretty Cure TV Anime",
    author: "Paul Chapman",
    summary:
      "Bear mascot character from Kumamoto makes a guest appearance at a noodle festival in Episode 32",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/294aab5aedc575ed38a7b970d77e3d371665317251_thumb.jpg",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09-1/regional-mascot-kumamon-cameos-in-delicious-party-pretty-cure-tv-anime",
    tag: "news",
  },
  {
    title:
      "Mobile Suit Gundam: The Witch from Mercury Debuts New Artist for Ending Theme Produced by Supercell's Ryo",
    author: "Liam Dempsey",
    summary: 'Shiyui makes her full musical debut with "Kimi yo Kedakaku Are"',
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/3e4e8578a72dcfc25dc73983abc463721665306701_thumb.png",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/09-1/mobile-suit-gundam-the-witch-from-mercury-debuts-new-artist-for-ending-theme-produced-by-supercells-ryo",
    tag: "news",
  },
  {
    title: "BOCCHI THE ROCK! Anime Strikes Up the Band with Opening and Ending Theme Song Videos",
    author: "Liam Dempsey",
    summary: "The music comedy anime is now streaming on Crunchyroll",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/cff397e40c340f4e91c6bc43fb3d84a11665289952_thumb.png",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08/bocchi-the-rock-anime-strikes-up-the-band-with-opening-and-ending-theme-song-videos",
    tag: "news",
  },
  {
    title: "Girls und Panzer Anime Gets a Blast from the Past with 10th Anniversary Video",
    author: "Liam Dempsey",
    summary: "Original series first premiered on October 9, 2012",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/cb9733e6b49e29e8864c6cdb1f4f1df01665287040_thumb.png",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08-1/girls-und-panzer-anime-gets-a-blast-from-the-past-with-10th-anniversary-video",
    tag: "news",
  },
  {
    title:
      "The IDOLM@STER Million Live! TV Anime Sets Fall 2023 Broadcast with Teaser Visual, Video",
    author: "Liam Dempsey",
    summary: "Shinya Watada helms idol series at studio Shirogumi",
    image:
      "https://img1.ak.crunchyroll.com/i/spire3/8f3bbaa32741b49c39d9f9ad51cefbc41665285868_thumb.png",
    published: "10/09/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08-1/the-idolmster-million-live-tv-anime-sets-fall-2023-broadcast-with-teaser-visual-video",
    tag: "news",
  },
  {
    title: "Yen Press Announces Massive Slate Of Licenses At NYCC",
    author: "Humberto Saabedra",
    summary:
      "Notable pickups include Words Bubble Up Like Soda Pop, Reborn as a Vending Machine, I Now Wander the Dungeon manga",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/bdc1307e23468dd00b60ccd5e9d2e8281665267089_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08/yen-press-announces-massive-slate-of-licenses-at-nycc",
    tag: "news",
  },
  {
    title: "Dragon Ball Xenoverse 2 Trailer Swoops in with Gamma 1 and Gohan",
    author: "Joseph Luster",
    summary: "Dragon Ball Super: SUPER HERO content heads to the brawler",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/911cbe538db969c6661648bc1028ef141665235317_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08/dragon-ball-xenoverse-2-trailer-swoops-in-with-gamma-1-and-gohan",
    tag: "news",
  },
  {
    title: "PHOTOS: 20 Awesome Cosplays from Crunchyroll Expo Australia 2022",
    author: "Guest Author",
    summary: "What's a convention without cosplayers?",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/84411646a45560f21576b92ab682f98f1664991043_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-feature/2022/10/08-1/photos-20-awesome-cosplays-from-crunchyroll-expo-australia-2022",
    tag: "news",
  },
  {
    title: "BLUELOCK - Crunchyroll Fall 2022 Spotlight",
    author: "Joseph Luster",
    summary: "The award-winning soccer series starts its anime run today! ",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/500a0f790db0ac7f760e130c741da9921664811924_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08/bluelock-crunchyroll-fall-2022-spotlight",
    tag: "news",
  },
  {
    title:
      "RECAP: Crunchyroll Expo Australia Debuts With Anime World Premieres, Special Guests and… Attack on Titan Trampoline Jumping?",
    author: "Guest Author",
    summary:
      "Panels, cosplay, concerts, premieres, and something about dipping a TimTam into a jar of Vegemite",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/91e4fe69c4b838cec776386bb50e41511664830700_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-feature/2022/10/08/recap-crunchyroll-expo-australia-debuts-with-anime-world-premieres-special-guests-and-attack-on-titan-trampoline-jumping",
    tag: "news",
  },
  {
    title: "Aikatsu! Unveils Key Visual for 10th Anniversary Theatrical Release",
    author: "Kara Dennison",
    summary: "Next installment of Aikatsu! 10th Story ~STARWAY to the Future~ debuts in spring",
    image:
      "https://img1.ak.crunchyroll.com/i/spire3/5a753f585b6d5209c498740cfa8ac3191665238473_thumb.png",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08-1/aikatsu-unveils-key-visual-for-10th-anniversary-theatrical-release",
    tag: "news",
  },
  {
    title: "Rock Out with the Non-Credit OP for The Little Lies We All Tell TV Anime",
    author: "Paul Chapman",
    summary:
      "Adaptation based on based on comedy manga by Madoka Kashihara streams on Crunchyroll this fall season",
    image:
      "https://img1.ak.crunchyroll.com/i/spire3/c5d19c3449cd19006d406d97227270fd1665238313_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08-1/rock-out-with-the-non-credit-op-for-the-little-lies-we-all-tell-tv-anime",
    tag: "news",
  },
  {
    title: "Management of Novice Alchemist Manga Celebrates 3rd Volume with TV CM",
    author: "Paul Chapman",
    summary:
      "Voice actor Kanon Takao of the TV anime lends her talents to video promoting the manga adaptation",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/1b155ff4e42001e0eea1b5eff164a89e1665228119_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08/management-of-novice-alchemist-manga-celebrates-3rd-volume-with-tv-cm",
    tag: "news",
  },
  {
    title: "Persona 3 Portable, Persona 4 Golden Make the Leap to Current Platforms in January",
    author: "Joseph Luster",
    summary: "ATLUS sets a date for the promised new versions",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/f05e15b57310c36af99d9f633d38498e1665228889_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/08-1/persona-3-portable-persona-4-golden-make-the-leap-to-current-platforms-in-january",
    tag: "news",
  },
  {
    title:
      "Tomokazu Sugita Voices The Lead in Kaiko Sareta Ankoku Heishi (30-Dai) no Slow na Second Life",
    author: "Mikikazu Komatsu",
    summary: '"Let\'s loosen the tension and have a good laugh for both people and demons."',
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/179eeee07f5f1bfddae078141385cb031665205199_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/tomokazu-sugita-voices-the-lead-in-kaiko-sareta-ankoku-heishi-30-dai-no-slow-na-second-life-tv-anime",
    tag: "news",
  },
  {
    title: "Viz Media Announces Slate Of New Licenses At NYCC",
    author: "Humberto Saabedra",
    summary:
      "More Tatsuki Fujimoto, cookbooks, horror and more autobiographical manga round out substantial slate",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/162379a81c4bffe12c5caea5c91093d41665195961_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/viz-media-announces-slate-of-new-licenses-at-nycc",
    tag: "news",
  },
  {
    title: "Chainsaw Man Takes Out ‘Best Manga’ at Harvey Awards for 2nd Year in a Row",
    author: "Daryl Harding",
    summary: "Shuzo Oshimi's Blood on the Tracks manga wins fan-award",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/a235d191fd18da8c0f6fcb9022200b371665195784_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/chainsaw-man-takes-out-best-manga-at-harvey-awards-for-2nd-year-in-a-row",
    tag: "news",
  },
  {
    title:
      "South Korea-born Singer Raon Posts Lucifer and the Biscuit Hammer 2nd Cour Opening Theme MV",
    author: "Mikikazu Komatsu",
    summary:
      '"I am so happy that my original song was finally chosen as the theme song for an anime!"',
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/7dcc679cf9dc0cc807198ada67e4e3a71665194295_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/south-korea-born-singer-raon-posts-lucifer-and-the-biscuit-hammer-2nd-cour-opening-theme-mv",
    tag: "news",
  },
  {
    title:
      "Tension Rises in Legend of the Galactic Heroes: Die Neue These Season 4 2nd Chapter Trailer",
    author: "Mikikazu Komatsu",
    summary: "Stage greeting event for the first chapter will be held on October 16",
    image:
      "https://img1.ak.crunchyroll.com/i/spire3/d9d0ffeba9df9a4bb07cf11f0d26067b1665190984_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/tension-rises-in-legend-of-the-galactic-heroes-die-neue-these-season-4-2nd-chapter-trailer",
    tag: "news",
  },
  {
    title: 'Voice Actor Soma Saito to Release His 3rd EP "Yin/Yang" on December 7',
    author: "Mikikazu Komatsu",
    summary: 'Digital album "Secret Tracks" will also be available on November 7 ',
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/ba0daa91a55fe425c621751ab5f53d481665188959_thumb.jpg",
    published: "10/08/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/voice-actor-soma-saito-to-release-his-3rd-ep-yinyang-on-december-7",
    tag: "news",
  },
  {
    title: "ROUNDUP: Fans Share Their Favorite Naruto Memories for the 20th Anniversary",
    author: "Guest Author",
    summary: "There are just so many great moments to choose from",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/227c64b3d06a5b1ce1f0db941807ff6e1665174018_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-feature/2022/10/07-1/roundup-fans-share-their-favorite-naruto-memories-for-the-20th-anniversary",
    tag: "news",
  },
  {
    title: "EXCLUSIVE: Emanon Manga Continues the Saga on June 14, 2023",
    author: "Joseph Luster",
    summary:
      "Dark Horse Manga reveals release date for the third part of the Emanon Wanderer storyline",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/ca2a9afb8914cc227e7ed64ec89f7def1665170351_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/exclusive-emanon-manga-continues-the-saga-on-june-14-2023",
    tag: "news",
  },
  {
    title: "INTERVIEW: Meet the ADR Director Behind Fruits Basket!",
    author: "Guest Author",
    summary: "We spoke with ADR Director Caitlin Glass about her career and favorite anime",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/592093c183e41e3a80b3cf412b6d882d1665081053_thumb.png",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-feature/2022/10/07/interview-meet-the-adr-director-behind-fruits-basket",
    tag: "news",
  },
  {
    title: "XSEED Reveals Physical Editions for Loop8: Summer of Gods and Trinity Trigger",
    author: "George Yang",
    summary: "Two upcoming RPGs from XSEED will be available in a physical format!",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/4431bfc44bfa68fce56ad04d46abedfc1665157906_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/xseed-reveals-physical-editions-for-loop8-summer-of-gods-and-trinity-trigger",
    tag: "news",
  },
  {
    title:
      "Milan Records Announces International Release Of Bleach Anime Soundtracks By Shiro Sagisu",
    author: "Humberto Saabedra",
    summary:
      "BLEACH: Thousand-Year Blood War opening theme song “Scar” by Tatsuya Kitani to be released October 18",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/cc70c0c615e14c01557d02a7c63e13da1665159014_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/milan-records-announces-international-release-of-bleach-anime-soundtracks-by-shiro-sagisu",
    tag: "news",
  },
  {
    title: "FEATURE: Everything You Need to Know Before Watching Chainsaw Man",
    author: "Briana Lawrence",
    summary: "You bring the bread, we’ll provide the jam and chainsaw pooch",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/b7ec6e890de8b9392e5458dd4b8b4f041664481834_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-feature/2022/10/07-1/feature-everything-you-need-to-know-before-watching-chainsaw-man",
    tag: "news",
  },
  {
    title: "New Bleach: Thousand-Year Blood War PV Highlights the 13 Court Guard Squads",
    author: "Kara Dennison",
    summary: "New series starts October 10 on Disney+ and Hulu",
    image:
      "https://img1.ak.crunchyroll.com/i/spire3/9c8d3dc58f4a0b117c0415a6f4c003951665155597_thumb.png",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/new-bleach-thousand-year-blood-war-pv-highlights-the-13-court-guard-squads",
    tag: "news",
  },
  {
    title: "Crunchyroll to Stream By the Grace of the Gods Season 2 Anime in January 2023",
    author: "Nicholas Friedman",
    summary: "Season 2 of the fantasy series was previously announced in June 2021",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/dcf62df5aa9185d3dbdadfc1304515e51665152539_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/crunchyroll-to-stream-by-the-grace-of-the-gods-season-2-anime-in-january-2023",
    tag: "news",
  },
  {
    title: "Crunchyroll to Stream The Iceblade Sorcerer Shall Rule the World Anime in January 2023",
    author: "Nicholas Friedman",
    summary: "Masahiko Takata to direct the anime production at Cloud Hearts",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/cee0814a0e4d558d148014fae13592571665150637_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/crunchyroll-to-stream-the-iceblade-sorcerer-shall-rule-the-world-anime-in-january-2023",
    tag: "news",
  },
  {
    title: "Crunchyroll to Stream New Revenger Anime, Subtitled Teaser Trailer Released",
    author: "Nicholas Friedman",
    summary: "News of the series streaming was confirmed at Crunchyroll's NYCC 2022 Industry Panel",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/542dd69def1c251409c25ab1ed31b0601665149657_thumb.png",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/crunchyroll-to-stream-new-revenger-anime-subtitled-teaser-trailer-released",
    tag: "news",
  },
  {
    title: "ZOMBIE LAND SAGA's Franchouchou Announces First 3D Virtual Concert",
    author: "Kara Dennison",
    summary: "Franchouchou ~Live & Illusion!!~ will take place later this month",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/6d0452ed3682f8dcaad310f7d0dcbed71665154701_thumb.png",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/zombie-land-sagas-franchouchou-announces-first-3d-virtual-concert",
    tag: "news",
  },
  {
    title: "Check Out Creditless Opening for Adorably Violent Akiba Maid War Anime",
    author: "Kara Dennison",
    summary: "Opening/ending single drops November 9",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/df94e77d4ddfc507ee5ec8bf4168c0751665153384_thumb.png",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/check-out-creditless-opening-for-adorably-violent-akiba-maid-war-anime",
    tag: "news",
  },
  {
    title: "Splatoon 3 Lets Squids Choose Their Favorite Starter Pokémon Type For Next Splatfest",
    author: "Daryl Harding",
    summary: "The next Spatfest is scheduled for November 11",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/18666857d209de39f416570d374f052d1665149714_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/splatoon-3-lets-squids-choose-their-favorite-starter-pokmon-type-for-next-splatfest",
    tag: "news",
  },
  {
    title: "JoJo's Bizarre Adventure Stone Ocean Anime Reveals Final Part With Epic New Visual",
    author: "Daryl Harding",
    summary: "Netflix will stream the final part on December 1",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/a1bef2bfa567f2f602ab6227820a0d6b1665148553_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/jojos-bizarre-adventure-stone-ocean-anime-reveals-final-part-with-epic-new-visual",
    tag: "news",
  },
  {
    title: "ZOIDS Celebrates 40th Anniversary with Motorized Model Kit Toys",
    author: "Paul Chapman",
    summary: "1/72 scale AZ-01 Blade Liger will be released in Japan in April of 2023",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/10f7d1a6e051d701dee1bfc4327b54b61665146711_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/zoids-celebrates-40th-anniversary-with-motorized-model-kit-toys",
    tag: "news",
  },
  {
    title: "Original Short Animation Penpe to Pinpi Hits Youtube on October 9th",
    author: "Paul Chapman",
    summary:
      "Main staff and cast revealed for project about a pair of easy-going mascot characters",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/69d3377671048c7f1a1b9692d39d86581665145186_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/original-short-animation-penpe-to-pinpi-hits-youtube-on-october-9th",
    tag: "news",
  },
  {
    title: "Sasaki and Miyano Anime Film Sets February 17 Premiere with Teaser Visual, Trailer",
    author: "Liam Dempsey",
    summary: "Hirano and Kagiura short will be screened alongside Sasaki and Miyano: Graduation",
    image:
      "https://img1.ak.crunchyroll.com/i/spire4/ef2d819e02cdde473240224a46ac18551665138876_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/sasaki-and-miyano-anime-film-sets-february-17-premiere-with-teaser-visual-trailer",
    tag: "news",
  },
  {
    title: "Japanese Fans Name Chainsaw Man the Anime They're Most Excited for in Fall 2022",
    author: "Liam Dempsey",
    summary: "Filmarks ranked the top 20 anime that their users are going to watch",
    image:
      "https://img1.ak.crunchyroll.com/i/spire3/0165377c76a6d839dbc42f11165ec96b1665142430_thumb.png",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07/japanese-fans-name-chainsaw-man-the-anime-theyre-most-excited-for-in-fall-2022",
    tag: "news",
  },
  {
    title: "Meet the Guitarist in New Character PV for BOCCHI THE ROCK! TV Anime",
    author: "Liam Dempsey",
    summary: "Crunchyroll will be simulcasting the series from October 8",
    image:
      "https://img1.ak.crunchyroll.com/i/spire2/c989bcb196ed941f2f104fae8b3203101665141203_thumb.jpg",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/meet-the-guitarist-in-new-character-pv-for-bocchi-the-rock-tv-anime",
    tag: "news",
  },
  {
    title: "Bibliophile Princess Anime Turns Up the Charm with Opening Theme Song Video",
    author: "Liam Dempsey",
    summary: "The romantic drama series is now airing",
    image:
      "https://img1.ak.crunchyroll.com/i/spire3/e6ff08bc2d85465c32a3556f42d5512b1665137611_thumb.png",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/bibliophile-princess-anime-turns-up-the-charm-with-opening-theme-song-video",
    tag: "news",
  },
  {
    title: "SPY x FAMILY's Anya Inspires 'GOOD DAY' Song by (K)NoW_NAME for Anime Music Video",
    author: "Liam Dempsey",
    summary: "Cour 2 of the hit anime series is now streaming on Crunchyroll",
    image:
      "https://img1.ak.crunchyroll.com/i/spire1/ff490122bd7164eff4692eba0a00184c1665136088_thumb.png",
    published: "10/07/2022",
    link: "https://www.crunchyroll.com/anime-news/2022/10/07-1/spy-x-familys-anya-inspires-good-day-song-by-know-name-for-anime-music-video",
    tag: "news",
  },
];

const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "crunchyroll",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: item.author,
      nickname: item.author,
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsCrunchyroll: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsCrunchyroll;
