const AboutUs = () => {
  return (
    <div className="relative" style={{ backgroundColor: "white" }}>
      {/* <div style={{ backgroundColor: "rgb(242, 242, 242)" }}> */}
      <div className="text-2xl font-bold text-center py-20">
        For any questions or potential copyright infringement
        <br />
        Please contact{" "}
        <a className="text-sky-400" href="mailto:mail.mojiverse.io">
          mail.mojiverse.io
        </a>
      </div>

      <div className="fixed bottom-10 text-center w-full">
        © 2023 Shanghai Shengxi Investment Development Co., Ltd. All Rights Reserved.
      </div>
    </div>
  );
};
export default AboutUs;
