const MovieIcon = ({ size }: { size: number }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_403_4)">
        <path
          d="M32 8.6V29.42C32 29.9504 31.7893 30.4591 31.4142 30.8342C31.0391 31.2093 30.5304 31.42 30 31.42H2C1.46957 31.42 0.960859 31.2093 0.585786 30.8342C0.210714 30.4591 0 29.9504 0 29.42V6.33C0.000462471 6.01043 0.114275 5.70139 0.32119 5.45785C0.528104 5.21432 0.814704 5.05208 1.13 5L31.06 0L31.64 3.44L6.3 7.69V8.62L32 8.6Z"
          fill="#333333"
        />
        <path
          d="M16 28C19.4407 28 22.23 25.2107 22.23 21.77C22.23 18.3293 19.4407 15.54 16 15.54C12.5593 15.54 9.76999 18.3293 9.76999 21.77C9.76999 25.2107 12.5593 28 16 28Z"
          fill="#EA2D3B"
        />
        <path
          d="M14.82 18.89C14.7502 18.852 14.6718 18.8327 14.5924 18.834C14.513 18.8353 14.4352 18.8572 14.3668 18.8975C14.2983 18.9378 14.2414 18.9951 14.2017 19.0639C14.1619 19.1327 14.1407 19.2106 14.14 19.29V24.29C14.1407 24.3694 14.1619 24.4473 14.2017 24.5161C14.2414 24.5849 14.2983 24.6422 14.3668 24.6825C14.4352 24.7228 14.513 24.7447 14.5924 24.746C14.6718 24.7473 14.7502 24.728 14.82 24.69L19.17 22.2C19.241 22.1613 19.3002 22.1042 19.3414 22.0347C19.3827 21.9652 19.4044 21.8858 19.4044 21.805C19.4044 21.7242 19.3827 21.6448 19.3414 21.5753C19.3002 21.5058 19.241 21.4487 19.17 21.41L14.82 18.89Z"
          fill="white"
        />
        <path d="M3.87 12.09H7.31L9.74 8.60001H6.3L3.87 12.09Z" fill="#C3C3C3" />
        <path d="M11.29 12.09H14.73L17.16 8.60001H13.71L11.29 12.09Z" fill="#C3C3C3" />
        <path d="M18.7 12.09H22.14L24.57 8.60001H21.13L18.7 12.09Z" fill="#C3C3C3" />
        <path d="M28.55 8.60001L26.12 12.09H29.56L31.99 8.60001H28.55Z" fill="#C3C3C3" />
        <path
          d="M9.69001 7.10001L6.72001 4.06001L3.32001 4.63001L6.30001 7.67001L9.69001 7.10001Z"
          fill="#C3C3C3"
        />
        <path d="M17.01 5.87L14.03 2.83L10.64 3.4L13.61 6.44L17.01 5.87Z" fill="#C3C3C3" />
        <path
          d="M24.32 4.64001L21.35 1.60001L17.95 2.17001L20.93 5.21001L24.32 4.64001Z"
          fill="#C3C3C3"
        />
        <path d="M31.64 3.41L28.66 0.370003L25.27 0.940003L28.24 3.98L31.64 3.41Z" fill="#C3C3C3" />
      </g>
      <defs>
        <clipPath id="clip0_403_4">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MovieIcon;
