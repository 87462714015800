import IconFont from "@/components/IconFont";
import { IComment } from "@/models";
import dayjs from "dayjs";
import UserImg from "../UserImg";

const ReplyComments = (props: { list: IComment[] }) => {
  return (
    <div className="border-b py-5" style={{ borderColor: "#E6E8EC" }}>
      <div className="pl-8 border-l border-dashed" style={{ borderColor: "#E6E8EC" }}>
        {props.list.map((comment) => {
          return (
            <div key={comment.id} className="comment pb-5 last:pb-0">
              <div className="flex items-center">
                <div className="overflow-hidden rounded-full w-11 h-11 mr-2.5">
                  <UserImg
                    style={{ minWidth: 44 }}
                    size={32}
                    className="w-11 h-11 flex items-center justify-center"
                    src={comment.author_avatar}
                  />
                </div>
                <div className="text-base font-bold mr-3">{comment.author}</div>
                <div className="text-sm" style={{ color: "#777E91" }}>
                  {dayjs(comment.createdAt).format("MMM DD, YYYY h:mm A")}
                </div>
              </div>
              <div className="text-[15px] my-3">{comment.content}</div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <IconFont type="icon-like" style={{ color: "#23262F", fontSize: 16 }} />
                  <div className="text-sm ml-2">{comment.like}</div>
                </div>

                <div className="flex items-center">
                  <IconFont type="icon-reply" style={{ color: "#23262F", fontSize: 16 }} />
                  <div className="text-sm ml-2">Reply</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ReplyComments;
