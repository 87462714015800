import API from "@/apis";
import { IHomeData } from "@/models";
import { homeActiveEmojiState, homeDataState, mostReactedState, searchState } from "@/store";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

const useHome = () => {
  const setHomeSearch = useSetRecoilState(searchState);
  const [homeData, setHomeData] = useRecoilState(homeDataState);
  const setMostReacted = useSetRecoilState(mostReactedState);
  const setHomeActiveEmoji = useSetRecoilState(homeActiveEmojiState);
  const navigate = useNavigate();
  const clearHomeState = () => {
    navigate("/");
    setHomeSearch({
      keyword: "",
      results: [],
      total: 0,
      page: 1,
    });
    setHomeActiveEmoji(null);
    setMostReacted(true);

    setHomeData({
      ...homeData,
      loading: true,
    });

    API.getHomePostList({
      page: 1,
    }).then((data) => {
      const newItems = data.data.map((i: IHomeData, idx: number) => {
        return {
          groupKey: 1,
          key: idx,
          data: i,
        };
      });
      setHomeData({
        page: 2,
        total: data.total || 0,
        data: [...newItems],
        loading: false,
      });
    });
  };

  return {
    clearHomeState,
  };
};
export default useHome;
