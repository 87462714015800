import Gif_Actionpacked from "@/assets/emoji/Action-packed.gif";
import Gif_Ahhhh from "@/assets/emoji/Ahhhh.gif";
import Gif_Awesome from "@/assets/emoji/Awesome.gif";
import Gif_Based from "@/assets/emoji/Based.gif";
import Gif_Boring from "@/assets/emoji/Boring.gif";
import Gif_Cashgrabbing from "@/assets/emoji/Cash-grabbing.gif";
import Gif_Cautious from "@/assets/emoji/Cautious.gif";
import Gif_Challenging from "@/assets/emoji/Challenging.gif";
import Gif_Clown from "@/assets/emoji/Clown.gif";
import Gif_Confusing from "@/assets/emoji/Confusing.gif";
import Gif_Cool from "@/assets/emoji/Cool.gif";
import Gif_Cope from "@/assets/emoji/Cope.gif";
import Gif_Cozy from "@/assets/emoji/Cozy.gif";
import Gif_Cringe from "@/assets/emoji/Cringe.gif";
import Gif_Curse from "@/assets/emoji/Curse.gif";
import Gif_Cute from "@/assets/emoji/Cute.gif";
import Gif_Damn from "@/assets/emoji/Damn.gif";
import Gif_Dance from "@/assets/emoji/Dance.gif";
import Gif_Dead from "@/assets/emoji/Dead.gif";
import Gif_Discomfort from "@/assets/emoji/Discomfort.gif";
import Gif_Doom from "@/assets/emoji/Doom.gif";
import Gif_Doubting from "@/assets/emoji/Doubting.gif";
import Gif_Downvote from "@/assets/emoji/Down-vote.gif";
import Gif_Easy from "@/assets/emoji/Easy.gif";
import Gif_Emotional from "@/assets/emoji/Emotional.gif";
import Gif_Fax from "@/assets/emoji/Fax.gif";
import Gif_GJ from "@/assets/emoji/GJ.gif";
import Gif_Goat from "@/assets/emoji/Goat.gif";
import Gif_Heart from "@/assets/emoji/Heart.gif";
import Gif_Helpful from "@/assets/emoji/Helpful.gif";
import Gif_Hi from "@/assets/emoji/Hi.gif";
import Gif_Hype from "@/assets/emoji/Hype.gif";
import Gif_L from "@/assets/emoji/L.gif";
import Gif_Lit from "@/assets/emoji/Lit.gif";
import Gif_LOL from "@/assets/emoji/LOL.gif";
import Gif_Luxurious from "@/assets/emoji/Luxurious.gif";
import Gif_Mad from "@/assets/emoji/Mad.gif";
import Gif_Nod from "@/assets/emoji/Nod.gif";
import Gif_ok from "@/assets/emoji/ok.gif";
import Gif_OMG from "@/assets/emoji/OMG.gif";
import Gif_Ononononono from "@/assets/emoji/Ononononono.gif";
import Gif_Pain from "@/assets/emoji/Pain.gif";
import Gif_Perfect from "@/assets/emoji/Perfect.gif";
import Gif_Pet from "@/assets/emoji/Pet.gif";
import Gif_Pleasant from "@/assets/emoji/Pleasant.gif";
import Gif_Please from "@/assets/emoji/Please.gif";
import Gif_Pog from "@/assets/emoji/Pog.gif";
import Gif_Pretty from "@/assets/emoji/Pretty.gif";
import Gif_Question from "@/assets/emoji/Question.gif";
import Gif_RIP from "@/assets/emoji/RIP.gif";
import Gif_Sad from "@/assets/emoji/Sad.gif";
import Gif_Scary from "@/assets/emoji/Scary.gif";
import Gif_Shutup from "@/assets/emoji/Shut-up.gif";
import Gif_Shy from "@/assets/emoji/Shy.gif";
import Gif_Smile from "@/assets/emoji/Smile.gif";
import Gif_Smug from "@/assets/emoji/Smug.gif";
import Gif_Speechless from "@/assets/emoji/Speechless.gif";
import Gif_Staystrong from "@/assets/emoji/Stay-strong.gif";
import Gif_Sus from "@/assets/emoji/Sus.gif";
import Gif_Tasty from "@/assets/emoji/Tasty.gif";
import Gif_This from "@/assets/emoji/This.gif";
import Gif_Upvote from "@/assets/emoji/Up-vote.gif";
import Gif_Vibing from "@/assets/emoji/Vibing.gif";
import Gif_W from "@/assets/emoji/W.gif";
import Gif_Waaah from "@/assets/emoji/Waaah.gif";
import Gif_Weird from "@/assets/emoji/Weird.gif";
import Gif_Whoa from "@/assets/emoji/Whoa.gif";
import Gif_Why from "@/assets/emoji/Why.gif";
import Gif_WTF from "@/assets/emoji/WTF.gif";

import official_Actionpacked from "@/assets/emoji-official/Action-packed.png";
import official_Ahhhh from "@/assets/emoji-official/Ahhhh.png";
import official_Awesome from "@/assets/emoji-official/Awesome.png";
import official_Based from "@/assets/emoji-official/Based.png";
import official_Boring from "@/assets/emoji-official/Boring.png";
import official_Cashgrabbing from "@/assets/emoji-official/Cash-grabbing.png";
import official_Cautious from "@/assets/emoji-official/Cautious.png";
import official_Challenging from "@/assets/emoji-official/Challenging.png";
import official_Clown from "@/assets/emoji-official/Clown.png";
import official_Confusing from "@/assets/emoji-official/Confusing.png";
import official_Cool from "@/assets/emoji-official/Cool.png";
import official_Cope from "@/assets/emoji-official/Cope.png";
import official_Cozy from "@/assets/emoji-official/Cozy.png";
import official_Cringe from "@/assets/emoji-official/Cringe.png";
import official_Curse from "@/assets/emoji-official/Curse.png";
import official_Cute from "@/assets/emoji-official/Cute.png";
import official_Damn from "@/assets/emoji-official/Damn.png";
import official_Dance from "@/assets/emoji-official/Dance.png";
import official_Dead from "@/assets/emoji-official/Dead.png";
import official_Discomfort from "@/assets/emoji-official/Discomfort.png";
import official_Doom from "@/assets/emoji-official/Doom.png";
import official_Doubting from "@/assets/emoji-official/Doubting.png";
import official_Downvote from "@/assets/emoji-official/Downvote.png";
import official_Easy from "@/assets/emoji-official/Easy.png";
import official_Emotional from "@/assets/emoji-official/Emotional.png";
import official_Fax from "@/assets/emoji-official/Fax.png";
import official_GJ from "@/assets/emoji-official/GJ.png";
import official_Goat from "@/assets/emoji-official/Goat.png";
import official_Heart from "@/assets/emoji-official/Heart.png";
import official_Helpful from "@/assets/emoji-official/Helpful.png";
import official_Hi from "@/assets/emoji-official/Hi.png";
import official_Hype from "@/assets/emoji-official/Hype.png";
import official_Lit from "@/assets/emoji-official/Lit.png";
import official_LOL from "@/assets/emoji-official/LOL.png";
import official_Lose from "@/assets/emoji-official/Lose.png";
import official_Luxurious from "@/assets/emoji-official/Luxurious.png";
import official_Mad from "@/assets/emoji-official/Mad.png";
import official_Nod from "@/assets/emoji-official/Nod.png";
import official_Ok from "@/assets/emoji-official/Ok.png";
import official_OMG from "@/assets/emoji-official/OMG.png";
import official_Onononono from "@/assets/emoji-official/Onononono.png";
import official_Pain from "@/assets/emoji-official/Pain.png";
import official_Perfect from "@/assets/emoji-official/Perfect.png";
import official_Pet from "@/assets/emoji-official/Pet.png";
import official_Pleasant from "@/assets/emoji-official/Pleasant.png";
import official_Please from "@/assets/emoji-official/Please.png";
import official_Pog from "@/assets/emoji-official/Pog.png";
import official_Pretty from "@/assets/emoji-official/Pretty.png";
import official_Question from "@/assets/emoji-official/Question.png";
import official_RIP from "@/assets/emoji-official/RIP.png";
import official_Sad from "@/assets/emoji-official/Sad.png";
import official_Scary from "@/assets/emoji-official/Scary.png";
import official_Shutup from "@/assets/emoji-official/Shutup.png";
import official_Shy from "@/assets/emoji-official/Shy.png";
import official_Smile from "@/assets/emoji-official/Smile.png";
import official_Smug from "@/assets/emoji-official/Smug.png";
import official_Speechless from "@/assets/emoji-official/Speechless.png";
import official_Staystrong from "@/assets/emoji-official/Stay-strong.png";
import official_Sus from "@/assets/emoji-official/Sus.png";
import official_Tasty from "@/assets/emoji-official/Tasty.png";
import official_This from "@/assets/emoji-official/This.png";
import official_Upvote from "@/assets/emoji-official/Upvote.png";
import official_Vibing from "@/assets/emoji-official/Vibing.png";
import official_Waaah from "@/assets/emoji-official/Waaah.png";
import official_Waku from "@/assets/emoji-official/Waku.png";
import official_Weird from "@/assets/emoji-official/Weird.png";
import official_Whoa from "@/assets/emoji-official/Whoa.png";
import official_Why from "@/assets/emoji-official/Why.png";
import official_Win from "@/assets/emoji-official/Win.png";
import official_WTF from "@/assets/emoji-official/WTF.png";

import iOS_Actionpacked_iOS from "@/assets/emoji-iOS/Action-packed_iOS.png";
import iOS_Ahhhh_iOS from "@/assets/emoji-iOS/Ahhhh_iOS.png";
import iOS_Awesome_iOS from "@/assets/emoji-iOS/Awesome_iOS.png";
import iOS_Based_iOS from "@/assets/emoji-iOS/Based_iOS.png";
import iOS_Boring_iOS from "@/assets/emoji-iOS/Boring_iOS.png";
import iOS_Cashgrabbing_iOS from "@/assets/emoji-iOS/Cash-grabbing_iOS.png";
import iOS_Cautious_iOS from "@/assets/emoji-iOS/Cautious_iOS.png";
import iOS_Challenging_iOS from "@/assets/emoji-iOS/Challenging_iOS.png";
import iOS_Clown_iOS from "@/assets/emoji-iOS/Clown_iOS.png";
import iOS_Confusing_iOS from "@/assets/emoji-iOS/Confusing_iOS.png";
import iOS_Cool_iOS from "@/assets/emoji-iOS/Cool_iOS.png";
import iOS_cope_iOS from "@/assets/emoji-iOS/cope_iOS.png";
import iOS_Cozy_iOS from "@/assets/emoji-iOS/Cozy_iOS.png";
import iOS_Cringe_iOS from "@/assets/emoji-iOS/Cringe_iOS.png";
import iOS_Curse_iOS from "@/assets/emoji-iOS/Curse_iOS.png";
import iOS_Cute_iOS from "@/assets/emoji-iOS/Cute_iOS.png";
import iOS_Damn_iOS from "@/assets/emoji-iOS/Damn_iOS.png";
import iOS_Dance_iOS from "@/assets/emoji-iOS/Dance_iOS.png";
import iOS_Dead_iOS from "@/assets/emoji-iOS/Dead_iOS.png";
import iOS_Discomfort_iOS from "@/assets/emoji-iOS/Discomfort_iOS.png";
import iOS_Doom_iOS from "@/assets/emoji-iOS/Doom_iOS.png";
import iOS_Doubting_iOS from "@/assets/emoji-iOS/Doubting_iOS.png";
import iOS_Downvote_iOS from "@/assets/emoji-iOS/Down vote_iOS.png";
import iOS_Easy_iOS from "@/assets/emoji-iOS/Easy_iOS.png";
import iOS_Emotional_iOS from "@/assets/emoji-iOS/Emotional_iOS.png";
import iOS_Fax_iOS from "@/assets/emoji-iOS/Fax_iOS.png";
import iOS_GJ_iOS from "@/assets/emoji-iOS/GJ_iOS.png";
import iOS_Goat_iOS from "@/assets/emoji-iOS/Goat_iOS.png";
import iOS_Heart_iOS from "@/assets/emoji-iOS/Heart_iOS.png";
import iOS_Helpful_iOS from "@/assets/emoji-iOS/Helpful_iOS.png";
import iOS_Hi_iOS from "@/assets/emoji-iOS/Hi_iOS.png";
import iOS_Hype_iOS from "@/assets/emoji-iOS/hype_iOS.png";
import iOS_L_iOS from "@/assets/emoji-iOS/L_iOS.png";
import iOS_Lit_iOS from "@/assets/emoji-iOS/Lit_iOS.png";
import iOS_LOL_iOS from "@/assets/emoji-iOS/LOL_iOS.png";
import iOS_Luxurious_iOS from "@/assets/emoji-iOS/Luxurious_iOS.png";
import iOS_Mad_iOS from "@/assets/emoji-iOS/Mad_iOS.png";
import iOS_Nod_iOS from "@/assets/emoji-iOS/Nod_iOS.png";
import iOS_ohnono_iOS from "@/assets/emoji-iOS/ohnono_iOS.png";
import iOS_OK_iOS from "@/assets/emoji-iOS/OK_iOS.png";
import iOS_OMG_iOS from "@/assets/emoji-iOS/OMG_iOS.png";
import iOS_Pain_iOS from "@/assets/emoji-iOS/Pain_iOS.png";
import iOS_Perfect_iOS from "@/assets/emoji-iOS/Perfect_iOS.png";
import iOS_Pet_iOS from "@/assets/emoji-iOS/Pet_iOS.png";
import iOS_Pleasant_iOS from "@/assets/emoji-iOS/Pleasant_iOS.png";
import iOS_Please_iOS from "@/assets/emoji-iOS/Please_iOS.png";
import iOS_Pog_iOS from "@/assets/emoji-iOS/Pog_iOS.png";
import iOS_Pretty_iOS from "@/assets/emoji-iOS/Pretty_iOS.png";
import iOS_Question_iOS from "@/assets/emoji-iOS/Question_iOS.png";
import iOS_RIP_iOS from "@/assets/emoji-iOS/RIP_iOS.png";
import iOS_Sad_iOS from "@/assets/emoji-iOS/Sad_iOS.png";
import iOS_Scary_iOS from "@/assets/emoji-iOS/Scary_iOS.png";
import iOS_Shutup_iOS from "@/assets/emoji-iOS/Shut up_iOS.png";
import iOS_Shy_iOS from "@/assets/emoji-iOS/Shy_iOS.png";
import iOS_Smile_iOS from "@/assets/emoji-iOS/Smile_iOS.png";
import iOS_Smug_iOS from "@/assets/emoji-iOS/Smug_iOS.png";
import iOS_Speechless_iOS from "@/assets/emoji-iOS/Speechless_iOS.png";
import iOS_Staystrong_iOS from "@/assets/emoji-iOS/Stay-strong_iOS.png";
import iOS_Sus_iOS from "@/assets/emoji-iOS/Sus_iOS.png";
import iOS_Tasty_iOS from "@/assets/emoji-iOS/Tasty_iOS.png";
import iOS_This_iOS from "@/assets/emoji-iOS/this_iOS.png";
import iOS_Upvote_iOS from "@/assets/emoji-iOS/Up vote_iOS.png";
import iOS_Vibing_iOS from "@/assets/emoji-iOS/Vibing_iOS.png";
import iOS_w_iOS from "@/assets/emoji-iOS/w_iOS.png";
import iOS_Waaah_iOS from "@/assets/emoji-iOS/Waaah_iOS.png";
import iOS_Waku_iOS from "@/assets/emoji-iOS/Waku_iOS.png";
import iOS_Weird_iOS from "@/assets/emoji-iOS/Weird_iOS.png";
import iOS_Whoa_iOS from "@/assets/emoji-iOS/Whoa_iOS.png";
import iOS_Why_iOS from "@/assets/emoji-iOS/Why_iOS.png";
import iOS_WTF_iOS from "@/assets/emoji-iOS/WTF_iOS.png";

export const GifMap: any = {
  "Action-packed": Gif_Actionpacked,
  Ahhhh: Gif_Ahhhh,
  Awesome: Gif_Awesome,
  Based: Gif_Based,
  Boring: Gif_Boring,
  "Cash-grabbing": Gif_Cashgrabbing,
  Cautious: Gif_Cautious,
  Challenging: Gif_Challenging,
  Clown: Gif_Clown,
  Confusing: Gif_Confusing,
  Cool: Gif_Cool,
  Cope: Gif_Cope,
  Cozy: Gif_Cozy,
  Cringe: Gif_Cringe,
  Curse: Gif_Curse,
  Cute: Gif_Cute,
  Damn: Gif_Damn,
  Dance: Gif_Dance,
  Dead: Gif_Dead,
  Discomfort: Gif_Discomfort,
  Doom: Gif_Doom,
  Doubting: Gif_Doubting,
  "Down-vote": Gif_Downvote,
  Easy: Gif_Easy,
  Emotional: Gif_Emotional,
  Fax: Gif_Fax,
  GJ: Gif_GJ,
  Goat: Gif_Goat,
  Heart: Gif_Heart,
  Helpful: Gif_Helpful,
  Hi: Gif_Hi,
  Hype: Gif_Hype,
  L: Gif_L,
  Lit: Gif_Lit,
  LOL: Gif_LOL,
  Luxurious: Gif_Luxurious,
  Mad: Gif_Mad,
  Nod: Gif_Nod,
  ok: Gif_ok,
  OMG: Gif_OMG,
  Ononononono: Gif_Ononononono,
  Pain: Gif_Pain,
  Perfect: Gif_Perfect,
  Pet: Gif_Pet,
  Pleasant: Gif_Pleasant,
  Please: Gif_Please,
  Pog: Gif_Pog,
  Pretty: Gif_Pretty,
  Question: Gif_Question,
  RIP: Gif_RIP,
  Sad: Gif_Sad,
  Scary: Gif_Scary,
  "Shut-up": Gif_Shutup,
  Shy: Gif_Shy,
  Smile: Gif_Smile,
  Smug: Gif_Smug,
  Speechless: Gif_Speechless,
  "Stay-strong": Gif_Staystrong,
  Sus: Gif_Sus,
  Tasty: Gif_Tasty,
  This: Gif_This,
  "Up-vote": Gif_Upvote,
  Vibing: Gif_Vibing,
  W: Gif_W,
  Waaah: Gif_Waaah,
  Weird: Gif_Weird,
  Whoa: Gif_Whoa,
  Why: Gif_Why,
  WTF: Gif_WTF,
};

export const OfficialMap: any = {
  "Action-packed": official_Actionpacked,
  Ahhhh: official_Ahhhh,
  Awesome: official_Awesome,
  Based: official_Based,
  Boring: official_Boring,
  "Cash-grabbing": official_Cashgrabbing,
  Cautious: official_Cautious,
  Challenging: official_Challenging,
  Clown: official_Clown,
  Confusing: official_Confusing,
  Cool: official_Cool,
  Cope: official_Cope,
  Cozy: official_Cozy,
  Cringe: official_Cringe,
  Curse: official_Curse,
  Cute: official_Cute,
  Damn: official_Damn,
  Dance: official_Dance,
  Dead: official_Dead,
  Discomfort: official_Discomfort,
  Doom: official_Doom,
  Doubting: official_Doubting,
  "Down vote": official_Downvote,
  Easy: official_Easy,
  Emotional: official_Emotional,
  Fax: official_Fax,
  GJ: official_GJ,
  Goat: official_Goat,
  Heart: official_Heart,
  Helpful: official_Helpful,
  Hi: official_Hi,
  Hype: official_Hype,
  Lit: official_Lit,
  LOL: official_LOL,
  Lose: official_Lose,
  Luxurious: official_Luxurious,
  Mad: official_Mad,
  Nod: official_Nod,
  Ok: official_Ok,
  OMG: official_OMG,
  Onononono: official_Onononono,
  Pain: official_Pain,
  Perfect: official_Perfect,
  Pet: official_Pet,
  Pleasant: official_Pleasant,
  Please: official_Please,
  Pog: official_Pog,
  Pretty: official_Pretty,
  Question: official_Question,
  RIP: official_RIP,
  Sad: official_Sad,
  Scary: official_Scary,
  "Shut up": official_Shutup,
  Shy: official_Shy,
  Smile: official_Smile,
  Smug: official_Smug,
  Speechless: official_Speechless,
  "Stay-strong": official_Staystrong,
  Sus: official_Sus,
  Tasty: official_Tasty,
  This: official_This,
  "Up vote": official_Upvote,
  Vibing: official_Vibing,
  Waaah: official_Waaah,
  Waku: official_Waku,
  Weird: official_Weird,
  Whoa: official_Whoa,
  Why: official_Why,
  Win: official_Win,
  WTF: official_WTF,
};

export const iOSMap: any = {
  "ction-packed_iOS": iOS_Actionpacked_iOS,
  Ahhhh_iOS: iOS_Ahhhh_iOS,
  Awesome_iOS: iOS_Awesome_iOS,
  Based_iOS: iOS_Based_iOS,
  Boring_iOS: iOS_Boring_iOS,
  "Cash-grabbing_iOS": iOS_Cashgrabbing_iOS,
  Cautious_iOS: iOS_Cautious_iOS,
  Challenging_iOS: iOS_Challenging_iOS,
  Clown_iOS: iOS_Clown_iOS,
  Confusing_iOS: iOS_Confusing_iOS,
  Cool_iOS: iOS_Cool_iOS,
  cope_iOS: iOS_cope_iOS,
  Cozy_iOS: iOS_Cozy_iOS,
  Cringe_iOS: iOS_Cringe_iOS,
  Curse_iOS: iOS_Curse_iOS,
  Cute_iOS: iOS_Cute_iOS,
  Damn_iOS: iOS_Damn_iOS,
  Dance_iOS: iOS_Dance_iOS,
  Dead_iOS: iOS_Dead_iOS,
  Discomfort_iOS: iOS_Discomfort_iOS,
  Doom_iOS: iOS_Doom_iOS,
  Doubting_iOS: iOS_Doubting_iOS,
  "Down vote_iOS": iOS_Downvote_iOS,
  Easy_iOS: iOS_Easy_iOS,
  Emotional_iOS: iOS_Emotional_iOS,
  Fax_iOS: iOS_Fax_iOS,
  GJ_iOS: iOS_GJ_iOS,
  Goat_iOS: iOS_Goat_iOS,
  Heart_iOS: iOS_Heart_iOS,
  Helpful_iOS: iOS_Helpful_iOS,
  Hi_iOS: iOS_Hi_iOS,
  Hype_iOS: iOS_Hype_iOS,
  L_iOS: iOS_L_iOS,
  Lit_iOS: iOS_Lit_iOS,
  LOL_iOS: iOS_LOL_iOS,
  Luxurious_iOS: iOS_Luxurious_iOS,
  Mad_iOS: iOS_Mad_iOS,
  Nod_iOS: iOS_Nod_iOS,
  ohnono_iOS: iOS_ohnono_iOS,
  OK_iOS: iOS_OK_iOS,
  OMG_iOS: iOS_OMG_iOS,
  Pain_iOS: iOS_Pain_iOS,
  Perfect_iOS: iOS_Perfect_iOS,
  Pet_iOS: iOS_Pet_iOS,
  Pleasant_iOS: iOS_Pleasant_iOS,
  Please_iOS: iOS_Please_iOS,
  Pog_iOS: iOS_Pog_iOS,
  Pretty_iOS: iOS_Pretty_iOS,
  Question_iOS: iOS_Question_iOS,
  RIP_iOS: iOS_RIP_iOS,
  Sad_iOS: iOS_Sad_iOS,
  Scary_iOS: iOS_Scary_iOS,
  "Shut up_iOS": iOS_Shutup_iOS,
  Shy_iOS: iOS_Shy_iOS,
  Smile_iOS: iOS_Smile_iOS,
  Smug_iOS: iOS_Smug_iOS,
  Speechless_iOS: iOS_Speechless_iOS,
  "Stay-strong_iOS": iOS_Staystrong_iOS,
  Sus_iOS: iOS_Sus_iOS,
  Tasty_iOS: iOS_Tasty_iOS,
  This_iOS: iOS_This_iOS,
  "Up vote_iOS": iOS_Upvote_iOS,
  Vibing_iOS: iOS_Vibing_iOS,
  w_iOS: iOS_w_iOS,
  Waaah_iOS: iOS_Waaah_iOS,
  Waku_iOS: iOS_Waku_iOS,
  Weird_iOS: iOS_Weird_iOS,
  Whoa_iOS: iOS_Whoa_iOS,
  Why_iOS: iOS_Why_iOS,
  WTF_iOS: iOS_WTF_iOS,
};

var a = {
  code: 200,
  msg: "success",
  data: {
    data: [
      {
        id: 140,
        code: "Action-packed",
        path: "/emoji/Action/emoji/packed.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 141,
        code: "Ahhhh",
        path: "/emoji/Ahhhh.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 142,
        code: "Awesome",
        path: "/emoji/Awesome.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 143,
        code: "Based",
        path: "/emoji/Based.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 144,
        code: "Boring",
        path: "/emoji/Boring.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 145,
        code: "Cash-grabbing",
        path: "/emoji/Cash/emoji/grabbing.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 146,
        code: "Cautious",
        path: "/emoji/Cautious.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 147,
        code: "Challenging",
        path: "/emoji/Challenging.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 148,
        code: "Clown",
        path: "/emoji/Clown.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 149,
        code: "Confusing",
        path: "/emoji/Confusing.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 150,
        code: "Cool",
        path: "/emoji/Cool.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 151,
        code: "Cope",
        path: "/emoji/Cope.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 152,
        code: "Cozy",
        path: "/emoji/Cozy.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 153,
        code: "Cringe",
        path: "/emoji/Cringe.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 154,
        code: "Curse",
        path: "/emoji/Curse.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 155,
        code: "Cute",
        path: "/emoji/Cute.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 156,
        code: "Damn",
        path: "/emoji/Damn.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 157,
        code: "Dance",
        path: "/emoji/Dance.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 158,
        code: "Dead",
        path: "/emoji/Dead.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 159,
        code: "Discomfort",
        path: "/emoji/Discomfort.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 160,
        code: "Doom",
        path: "/emoji/Doom.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 161,
        code: "Doubting",
        path: "/emoji/Doubting.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 162,
        code: "Down vote",
        path: "/emoji/Down vote.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 163,
        code: "Easy",
        path: "/emoji/Easy.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 164,
        code: "Emotional",
        path: "/emoji/Emotional.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 165,
        code: "Fax",
        path: "/emoji/Fax.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 166,
        code: "GJ",
        path: "/emoji/GJ.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 167,
        code: "Goat",
        path: "/emoji/Goat.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 168,
        code: "Heart",
        path: "/emoji/Heart.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 169,
        code: "Helpful",
        path: "/emoji/Helpful.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 170,
        code: "Hi",
        path: "/emoji/Hi.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 171,
        code: "Hype",
        path: "/emoji/Hype.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 172,
        code: "Lit",
        path: "/emoji/Lit.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 173,
        code: "LOL",
        path: "/emoji/LOL.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 174,
        code: "Lose",
        path: "/emoji/Lose.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 175,
        code: "Luxurious",
        path: "/emoji/Luxurious.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 176,
        code: "Mad",
        path: "/emoji/Mad.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 177,
        code: "Nod",
        path: "/emoji/Nod.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 178,
        code: "Ok",
        path: "/emoji/Ok.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 179,
        code: "OMG",
        path: "/emoji/OMG.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 180,
        code: "Onononono",
        path: "/emoji/Onononono.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 181,
        code: "Pain",
        path: "/emoji/Pain.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 182,
        code: "Perfect",
        path: "/emoji/Perfect.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 183,
        code: "Pet",
        path: "/emoji/Pet.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 184,
        code: "Pleasant",
        path: "/emoji/Pleasant.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 185,
        code: "Please",
        path: "/emoji/Please.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 186,
        code: "Pog",
        path: "/emoji/Pog.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 187,
        code: "Pretty",
        path: "/emoji/Pretty.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 188,
        code: "Question",
        path: "/emoji/Question.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 189,
        code: "RIP",
        path: "/emoji/RIP.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 190,
        code: "Sad",
        path: "/emoji/Sad.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 191,
        code: "Scary",
        path: "/emoji/Scary.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 192,
        code: "Shut up",
        path: "/emoji/Shut up.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 193,
        code: "Shy",
        path: "/emoji/Shy.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 194,
        code: "Smile",
        path: "/emoji/Smile.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 195,
        code: "Smug",
        path: "/emoji/Smug.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 196,
        code: "Speechless",
        path: "/emoji/Speechless.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 197,
        code: "Stay-strong",
        path: "/emoji/Stay/emoji/strong.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 198,
        code: "Sus",
        path: "/emoji/Sus.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 199,
        code: "Tasty",
        path: "/emoji/Tasty.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 200,
        code: "This",
        path: "/emoji/This.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 201,
        code: "Up vote",
        path: "/emoji/Up vote.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 202,
        code: "Vibing",
        path: "/emoji/Vibing.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 203,
        code: "Waaah",
        path: "/emoji/Waaah.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 204,
        code: "Waku",
        path: "/emoji/Waku.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 205,
        code: "Weird",
        path: "/emoji/Weird.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 206,
        code: "Whoa",
        path: "/emoji/Whoa.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 207,
        code: "Why",
        path: "/emoji/Why.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 208,
        code: "Win",
        path: "/emoji/Win.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
      {
        id: 209,
        code: "WTF",
        path: "/emoji/WTF.png",
        group: 1,
        type: 1,
        related: null,
        name: null,
        description: null,
        userid: null,
        createdAt: "2023-05-01T10:00:00.000Z",
      },
    ],
    total: 70,
  },
};
