import API from "@/apis";
import { IHomeData } from "@/models";
import { homeActiveEmojiState, homeDataState, tagState } from "@/store";
import { MasonryInfiniteGrid } from "@egjs/react-infinitegrid";
import { useRecoilState, useRecoilValue } from "recoil";
import Note from "../Note";

import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Empty } from "antd";
import NoteV2 from "../Note/NoteV2";
import "./index.css";

export const WaterfallItem = ({
  data,
  showTopEmoji,
  useV2,
  deletable,
  onDelete,
}: {
  data: IHomeData;
  showTopEmoji?: boolean;
  useV2?: boolean;
  deletable?: boolean;
  onDelete?: (id: number) => void;
}) => {
  const handleOpen = () => {};
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);

  const baseWidth = isLargerThan414 ? 336 - 20 : "50%";

  return (
    <div
      className="waterfall-item flex items-center justify-center px-1 sm:px-0"
      style={{ width: baseWidth }}
    >
      {useV2 ? (
        <NoteV2
          deletable={deletable}
          hideTag
          data={data}
          onClickComment={handleOpen}
          showTopEmoji={showTopEmoji}
          onDelete={onDelete}
        />
      ) : (
        <Note hideTag data={data} onClickComment={handleOpen} showTopEmoji={showTopEmoji} />
      )}
    </div>
  );
};

const Waterfall = () => {
  const [homeData, setHomeData] = useRecoilState(homeDataState);
  const homeActiveEmoji = useRecoilValue(homeActiveEmojiState);
  const tag = useRecoilValue(tagState);

  const now = new Date();
  const hours = now.getHours();
  const showEmojiIndex = hours % 3;

  const getParams = () => {
    if (homeActiveEmoji) {
      return {
        code: homeActiveEmoji.code,
        orderDir: "desc" as const,
        page: homeData.page,
      };
    } else {
      return {
        page: homeData.page,
      };
    }
  };

  const handleRequestAppend = (e: any) => {
    if (homeData.loading || homeData.isError) {
      return;
    }

    if (homeData.total === 0 && homeData.page > 1) {
      return;
    }

    if (homeData.total > 0 && homeData.data.length >= homeData.total) {
      return;
    }

    e.wait();
    setHomeData({
      ...homeData,
      loading: true,
    });

    API.getHomePostList(getParams())
      .then((data) => {
        if (data) {
          const newItems = data.data.map((i: IHomeData, idx: number) => {
            return {
              groupKey: homeData.page,
              key: idx,
              data: i,
            };
          });
          setHomeData({
            page: homeData.page + 1,
            total: data.total || 0,
            data: [...homeData.data, ...newItems],
            loading: false,
          });
        } else {
          setHomeData({
            ...homeData,
            loading: false,
            isError: true,
          });
        }
      })
      .catch(() => {
        setHomeData({
          ...homeData,
          loading: false,
        });
      })
      .finally(() => {
        e.ready();
      });
  };

  return (
    <div>
      {homeData.loading && homeData.data.length === 0 ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div>
          <MasonryInfiniteGrid
            gap={0}
            loading={<div className="loading">Loading...</div>}
            onRequestAppend={handleRequestAppend}
            align="start"
          >
            {homeData.data.map((item, index) => (
              <WaterfallItem
                data-grid-groupkey={item.groupKey}
                key={`${item.groupKey}-${item.key}`}
                data={item.data}
                showTopEmoji={showEmojiIndex === index % 3}
                useV2
              />
            ))}
          </MasonryInfiniteGrid>
        </div>
      )}

      {!homeData.loading && homeData.total === 0 && homeData.page > 1 ? (
        <div>{tag !== "" ? <EmptyResult keyword={tag} /> : "no data"}</div>
      ) : null}

      {!homeData.loading && homeData.total > 0 && homeData.total <= homeData.data.length ? (
        <div className="text-center">no more</div>
      ) : null}

      {homeData.isError ? <div className="text-center">Network Error</div> : null}
    </div>
  );
};

const EmptyResult = ({ keyword }: { keyword: string }) => {
  return (
    <div style={{ textAlign: "center", padding: "20px", fontSize: "24px", opacity: "0.8" }}>
      Empty results for
      <span className="text-blue-600 mx-2" style={{ fontWeight: "bold" }}>
        {keyword}
      </span>
      .
    </div>
  );
};

export const WaterfallWithoutInfiniteScroll = ({
  list,
  keyword,
  useV2,
  deletable,
  onDelete,
}: {
  list: IHomeData[];
  keyword?: string;
  useV2?: boolean;
  deletable?: boolean;
  onDelete?: (id: number) => void;
}) => {
  const now = new Date();
  const hours = now.getHours();
  const showEmojiIndex = hours % 3;

  if (list.length === 0) {
    if (keyword) {
      return <EmptyResult keyword={keyword} />;
    } else {
      return <Empty description="no data" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
  }

  return (
    <MasonryInfiniteGrid
      gap={0}
      align="center"
      loading={<div className="loading">Loading...</div>}
      onRequestAppend={(e) => {
        return;
      }}
    >
      {list.map((item, index) => (
        <WaterfallItem
          useV2={useV2}
          deletable={deletable}
          data={item}
          key={`${item.id}_${index}`}
          onDelete={onDelete}
          showTopEmoji={showEmojiIndex === index % 3}
        />
      ))}
    </MasonryInfiniteGrid>
  );
};

export default Waterfall;
