import API from "@/apis";
import { IEmoji } from "@/models";
import { LoadingOutlined } from "@ant-design/icons";
import * as React from "react";
import EmojiV2 from "../Emoji/EmojiV2";

interface Props {
  ispc: boolean;
  code: string;
  isActive: boolean;
  emoji: IEmoji;
  fetchNew: boolean;
  onFetchNew: () => void;
}

const EmojiCollapsePanelDetail: React.FC<Props> = (props: Props) => {
  const [list, setList] = React.useState<IEmoji[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  // const [hasLoaded, setHasLoaded] = React.useState<boolean>(false);
  const hasLoaded = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (props.fetchNew) {
    }
  }, [props.fetchNew]);

  React.useEffect(() => {
    const fetchEmojiByCode = async (code: string) => {
      const [success, data] = await API.getEmojiList({
        related: code,
      });
      if (success && Array.isArray(data?.data)) {
        setList(data?.data);
        hasLoaded.current = true;
      }
      setLoading(false);
    };
    if (props.fetchNew) {
      fetchEmojiByCode(props.code);
      props.onFetchNew();
      return;
    }
    if (props.isActive && props.code && !hasLoaded.current) {
      fetchEmojiByCode(props.code);
    }
  }, [props.code, props.isActive, props.onFetchNew, props.fetchNew, hasLoaded]);

  const tabContentClass = `w-full pb-2 grid gap-x-3 gap-y-2 ${
    props.ispc ? "grid-cols-6" : "grid-cols-4"
  }`;

  return (
    <div className={tabContentClass}>
      {loading ? (
        <div className="flex items-center justify-center h-10 w-full">
          <LoadingOutlined style={{ fontSize: 24 }} />
        </div>
      ) : (
        <>
          {[props.emoji, ...list].map((emojiItem, index) => {
            return (
              <div className="max-w-[72px] w-[72px]" key={emojiItem.code}>
                <EmojiV2
                  // onClick={handleClickEmoji(emojiItem)}
                  width={42}
                  height={42}
                  emoji={emojiItem}
                  autoScaleBack
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default EmojiCollapsePanelDetail;
