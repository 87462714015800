import "./index.css";

const BannerContainer = (props: { children: React.ReactNode; style?: React.CSSProperties }) => {
  return (
    <div className="banner-container" style={props.style}>
      {props.children}
    </div>
  );
};
export default BannerContainer;
