const HomeIcon = ({ color }: { color: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.29105 7.99982L8.29105 0.999818C8.47857 0.812347 8.73288 0.707031 8.99805 0.707031C9.26321 0.707031 9.51752 0.812347 9.70505 0.999818L16.705 7.99982C16.8926 8.18731 16.998 8.44162 16.998 8.70682V18.2928C16.998 18.558 16.8927 18.8124 16.7052 18.9999C16.5176 19.1875 16.2633 19.2928 15.998 19.2928H10.998V12.2928H6.99805V19.2928H1.99805C1.73283 19.2928 1.47848 19.1875 1.29094 18.9999C1.1034 18.8124 0.998047 18.558 0.998047 18.2928V8.70682C0.998104 8.44162 1.1035 8.18731 1.29105 7.99982Z"
        fill={color}
      />
    </svg>
  );
};

export default HomeIcon;
