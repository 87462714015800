import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    id: 589754,
    title: "The Last Warrior: Root of Evil",
    original_title: "Последний богатырь: Корень зла",
    date: "01/01/2021",
    tag: "Comedy",
    vote_average: 7,
    backdrop_path: "/dIibeeq4QMay5bTJ2vjr72IFFRo.jpg",
    overview:
      "Peace and tranquility have set in Belogorie. The evil was defeated and Ivan is now enjoying his well-deserved fame. He is surrounded by his family, friends and small wonders from the modern world that help him lead a comfortable life. Luckily, he has his Magic Sword to cut a gap between the worlds to get some supplies quite regularly. But when an ancient evil rises and the existence of the magic world is put to danger, Ivan has to team up with his old friends and his new rivals. They will set out on a long journey beyond the known world to find a way to defeat the enemies and to return peace to Belogorie.",
    emoji_primary: "Funny",
    emoji_primary_count: 70,
    emoji_secondary: "Action-packed",
    emoji_secondary_count: 70,
    emoji_boring_count: 30,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 781766,
    title: "Beck 41 - Death in Samarra",
    original_title: "Beck 41 - Döden i Samarra",
    date: "01/01/2021",
    tag: "Thriller",
    vote_average: 5.3,
    backdrop_path: "/aNkQTIdhBB9IKi695T8Njm9Lyku.jpg",
    overview:
      "A candidate for the Nobel Peace Prize is brutally stabbed to death in central Stockholm just after a meeting with two lawyers from the war tribunal in The Hague. One of the witnesses worked with Alex when she was in the Middle East.",
    emoji_primary: "WTF",
    emoji_primary_count: 53,
    emoji_secondary: "Confusing",
    emoji_secondary_count: 53,
    emoji_boring_count: 47,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 774098,
    title: "Nail Polish",
    original_title: "Nail Polish",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 6.7,
    backdrop_path: "/osOvyg2ccbjFiQsmiZK2vw8f7zs.jpg",
    overview:
      "A high profile defence lawyer is promised a Rajya Sabha seat if he wins a case of an accused with charges of killing two migrant children. However, there's much more than what meets the eye in this courtroom drama that explores the uncertainty of human mind and the journey of criminal discovery.",
    emoji_primary: "Emotional",
    emoji_primary_count: 67,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 67,
    emoji_boring_count: 33,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 853030,
    title: "Salt Water Town",
    original_title: "Salt Water Town",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/uBYtBBQZ5Sb8Qf9r3J6gjcgk6cC.jpg",
    overview:
      "Liam spends his days drinking with friends and racing his motorbike across the roads of his Welsh coastal town. He helps run a failing caravan park with his father, Glenn, who clings to the belief that prosperity will soon return. When an insurance inspector reveals the land they own is worthless as a result of rising sea levels, Liam must convince his father to abandon his lifelong home. Their conflict over control of the park leads to desperate measures.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 783481,
    title: "Media Mafia: a Tale of Two Newspapers",
    original_title: "족벌-두 신문 이야기",
    date: "01/01/2021",
    tag: "Documentary",
    vote_average: 5.5,
    backdrop_path: "/mye11gtT3aoKkswn9vpiENWFbEr.jpg",
    overview:
      "The 100 years of history of the Chosun Ilbo and the Dong-A Ilbo show that wrong press can be a social weapon.",
    emoji_primary: "Helpful",
    emoji_primary_count: 55,
    emoji_secondary: "WOW",
    emoji_secondary_count: 55,
    emoji_boring_count: 45,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 782118,
    title: "Mr. Boop #217",
    original_title: "Mr. Boop #217",
    date: "01/01/2021",
    tag: "Romance",
    vote_average: 0,
    backdrop_path: "/dYUOWcKyzWEnB1PH4VbxLeR0QBV.jpg",
    overview: "Video finale to the webcomic Mr. Boop",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Cozy",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 784617,
    title: "Highway",
    original_title: "Highway",
    date: "01/01/2021",
    tag: "Thriller",
    vote_average: 10,
    backdrop_path: "/8PImQzfr8xUwuXcGHZUkTY1Axkz.jpg",
    overview:
      "A man suffers from the effects of a mysterious psychedelic in a seemingly endless forest.",
    emoji_primary: "WTF",
    emoji_primary_count: 100,
    emoji_secondary: "Confusing",
    emoji_secondary_count: 100,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 1,
  },
  {
    id: 781801,
    title: "Steve McQueen: The Lost Movie",
    original_title: "Steve McQueen: The Lost Movie",
    date: "01/01/2021",
    tag: "Documentary",
    vote_average: 6.8,
    backdrop_path: "/3aJinGYBeofF8r7KYqAUav3KriO.jpg",
    overview:
      "The story of the abandoned production of 'Day of the Champion', a movie about Formula 1 which was set to film in 1966.",
    emoji_primary: "Helpful",
    emoji_primary_count: 68,
    emoji_secondary: "WOW",
    emoji_secondary_count: 68,
    emoji_boring_count: 32,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 813965,
    title: "Paul Burrell: Royal Service, Scandal & Celebrity",
    original_title: "Paul Burrell: Royal Service, Scandal & Celebrity",
    date: "01/01/2021",
    tag: "Documentary",
    vote_average: 0,
    backdrop_path: "/cCosGfV6fVhva35TKFvMGcFqSDz.jpg",
    overview:
      "The story of Princess Diana's former butler, charting his rise and the events of 2002, when he stood trial and was cleared of stealing items belonging to the Princess of Wales. Interviews with those who know Burrell best, including his brother and his ghost writer - as well as royal journalists and insiders - reveal how he went from footman to household name.",
    emoji_primary: "Helpful",
    emoji_primary_count: 0,
    emoji_secondary: "WOW",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 828422,
    title: "Being Brave",
    original_title: "Being Brave",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/aVx3smzKVxgkBgfl1YhKEes0RKF.jpg",
    overview:
      "This short film tells the story of Beckett, a smart and intriguing twelve-year-old, and his relationship with his divorced parents, James and Cindy. The separation took place when James came out as gay man.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 776904,
    title: "Selva Oscura",
    original_title: "Selva Oscura",
    date: "01/01/2021",
    tag: "Fantasy",
    vote_average: 0,
    backdrop_path: "/dM1Uf79Cs0UCMeiBCt4xFmvDh45.jpg",
    overview:
      "A woman finds a corpse along a familiar path. She begins to investigate and soon becomes wrapped up in a mystery.",
    emoji_primary: "Action-packed",
    emoji_primary_count: 0,
    emoji_secondary: "WOW",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 989841,
    title: "Little Hurts",
    original_title: "Little Hurts",
    date: "01/01/2021",
    tag: "Animation",
    vote_average: 0,
    backdrop_path: "/qNwxCmi5faRZ1HHRTgNYnXndQpP.jpg",
    overview:
      "A routine errand at the dry cleaners unleashes a free-for-all of fat-shaming, body image demons and family dieting obsessions.",
    emoji_primary: "Cute",
    emoji_primary_count: 0,
    emoji_secondary: "Funny",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 912499,
    title: "Kiss the Cook",
    original_title: "Kiss the Cook",
    date: "01/01/2021",
    tag: "Family",
    vote_average: 6,
    backdrop_path: "/cDkGC96DzaZ39VWg6lRws0W3beL.jpg",
    overview:
      "When Kacey Cooke, food blogger extraordinaire, with an impressive following, is approached with the opportunity to write her own cookbook, she jumps at it… Only problem is, she isn’t actually a very good cook. With a little help from handsome chef, Gavin Sawyer, and just a dash of undeniable chemistry, they may just have the recipe for success.",
    emoji_primary: "Cozy",
    emoji_primary_count: 60,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 60,
    emoji_boring_count: 40,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 987017,
    title: "Death in the Tower: King Richard and the Two Princes",
    original_title: "Death in the Tower: King Richard and the Two Princes",
    date: "01/01/2021",
    tag: "Documentary",
    vote_average: 0,
    backdrop_path: "/dK1lbm7d41rlWi3T7X3badYy1nJ.jpg",
    overview:
      "Two boys are waiting in the Tower of London for their big day of celebration. The older boy is just 12 years old in the summer of 1483 and is to be crowned King of England. But suddenly doubts arise among the nobles about the succession to the throne and his uncle receives the crown. After the coronation, the two young heirs to the throne disappear from the face of the earth. Have they been kidnapped or murdered? The new King Richard III remains silent on the matter. But the doubts about his accession to the throne remain. In the 17th century, bones are found during building work, confirming the old suspicion against Richard III as a child murderer. What happened to the two boys in the Tower? A cold case from the age of the knights in England, in which numerous new clues have been found in recent years.",
    emoji_primary: "Helpful",
    emoji_primary_count: 0,
    emoji_secondary: "WOW",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 946804,
    title: "Glam, Sweat & Tears",
    original_title: "Glam, Sweat & Tears",
    date: "01/01/2021",
    tag: "Documentary",
    vote_average: 1,
    backdrop_path: "/1jwHpOVZAkob0eZHawgpBBOR0UC.jpg",
    overview:
      "Tears were formed in the small town of Katrineholm, Sweden, back in 1968. A band from the same era as Sweet, David Bowie and T-Rex. In the middle of the prog wave, the band chose to invest in an extroverted expression with make-up, glitter and spectacular stage shows. A unique band in Sweden of that time not only for how they looked but also for their sound. Nowadays, the members of Tears are older men, but it still happens that they put on make-up and take to the stage. Ola Salo (The Arc) is one of Tears biggest fans and speaks passionately about the band's history and glam rock in general.",
    emoji_primary: "Helpful",
    emoji_primary_count: 10,
    emoji_secondary: "WOW",
    emoji_secondary_count: 10,
    emoji_boring_count: 90,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 846652,
    title: "Hannibal Hopkins & Sir Anthony",
    original_title: "Hannibal Hopkins & Sir Anthony",
    date: "01/01/2021",
    tag: "Documentary",
    vote_average: 2,
    backdrop_path: "/vMvAc88StCTlnen3waWVCuKwA0q.jpg",
    overview:
      "Hopkins’ career has spanned several decades, which is why we will also use many interviews that he gave throughout his life, allowing us to put him back into the context of each period and will be helpful in understanding his role in the history of cinema, because he was far from following the trends. He never belonged to any film movement; he is a chameleon that has always preferred natural acting, ‘non-acting’ when method acting was the fashion.",
    emoji_primary: "Helpful",
    emoji_primary_count: 20,
    emoji_secondary: "WOW",
    emoji_secondary_count: 20,
    emoji_boring_count: 80,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 972969,
    title: "To Turn Off the Stars",
    original_title: "To Turn Off the Stars",
    date: "01/01/2021",
    tag: "Romance",
    vote_average: 0,
    backdrop_path: "/9QsZS4UJu34jDu2jnQ22itHsY4v.jpg",
    overview:
      "Every day, Renard turns on and off his curious lamppost that seems to control the cycle of night and day. One day, his routine collapse, when he discovers that Louve and the moon have fallen in love. Should he accomplish his duty despite everything and force the lovers to separate, by triggering the break of day?",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Cozy",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 972966,
    title: "Love",
    original_title: "Love",
    date: "01/01/2021",
    tag: "Fantasy",
    vote_average: 0,
    backdrop_path: "/gkvZqeAU9Q5p6ODNtROSNLuUcoN.jpg",
    overview:
      'The love story of Paolo and Francesca in the Divine Comedy based on the Divine Comedy, Inferno, Chapter 5 \'Legend Love story of "Paolo and Francesca". This is a forbidden love that is not blessed. Francesca fell in love with her husband\'s brother Paolo. Her husband found an affair and killed them. After their death, they were tortured by the wind every day in hell, but they still loved each other and hugged each other tightly. "Love" presents a new style for this shining love with Chinese traditional painting style after 700 years.',
    emoji_primary: "Action-packed",
    emoji_primary_count: 0,
    emoji_secondary: "WOW",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 968651,
    title: "Headlights",
    original_title: "Headlights",
    date: "01/01/2021",
    tag: "Thriller",
    vote_average: 0,
    backdrop_path: "/liWqA6XSxM9vBLM6hTOJoRGm8KB.jpg",
    overview:
      "Joe is having an affair and getting away with it, until he witnesses a brutal attack. Will he drive away from the scene, or will he do the right thing? Either way, his life will never be the same again.",
    emoji_primary: "WTF",
    emoji_primary_count: 0,
    emoji_secondary: "Confusing",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 653858,
    title: "Seitokai Yakuindomo the Movie 2",
    original_title: "劇場版 生徒会役員共２",
    date: "01/01/2021",
    tag: "Comedy",
    vote_average: 6.3,
    backdrop_path: "/pqlyXoZCmswsFfpPdkmJ7B7rCBq.jpg",
    overview: "Second theatrical movie of Seitokai Yakuindomo series.",
    emoji_primary: "Funny",
    emoji_primary_count: 63,
    emoji_secondary: "Action-packed",
    emoji_secondary_count: 63,
    emoji_boring_count: 37,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 958618,
    title: "Hiraeth",
    original_title: "Hiraeth",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/g6dTl4L5GhlKVkhHafqzE5kQ3rb.jpg",
    overview:
      "Hiraeth is a heart-breaking film that explores one woman's grief at the edge of the solar system. Amber Jones, a British astronaut on the first manned mission to Europa. She's a controversial choice with a checkered background.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 936597,
    title: "Kerbe",
    original_title: "Kerbe",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/jDcMUjTXKAbHSmyLf0Rs0ZGoZGB.jpg",
    overview:
      "A family welcomes an uncle into their home that was released from prison. They later find out that he has sexually assaulted their young daughter Fiona.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 931985,
    title: "Incident 437",
    original_title: "Инцидент 437",
    date: "01/01/2021",
    tag: "Horror",
    vote_average: 0,
    backdrop_path: "/k2BUbwmUm9IDOObXdQZoayDzdtA.jpg",
    overview:
      "8-year-old Vanya's mother hanged herself, and he decides to find out why it happened.",
    emoji_primary: "Scary",
    emoji_primary_count: 0,
    emoji_secondary: "WTF",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 931100,
    title: "Meet the Numbers 2",
    original_title: "Meet the Numbers 2",
    date: "01/01/2021",
    tag: "Animation",
    vote_average: 0,
    backdrop_path: "/i19ovfT2Gm39blbouxjcnKyrUnf.jpg",
    overview:
      'Children will fall in love with these wonderful characters as they "Meet the Numbers." With Meet the Numbers children learned numbers from 0 to 10. Now children can learn numbers from 11 to 20! Preschool Prep Series™ DVDs have won hundreds of awards and are used in millions of homes and schools around the world. You will be amazed at how easily your little one can learn numbers!™',
    emoji_primary: "Cute",
    emoji_primary_count: 0,
    emoji_secondary: "Funny",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 926951,
    title: "Dirdir",
    original_title: "Dirdir",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/2KgPGsdgZSzbcrchKggmGiwFpgY.jpg",
    overview:
      "Tizeta is a cheerful and bright little girl full of ambitions adored by both her parents. She gets diagnosed with a heart condition that seeks fast treatment. Time is  ticking and her parents fail to find adequate financial and community support.  Dirdir is a story of an ordinary dad that takes extraordinary measures to save his daughter's life.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 922478,
    title: "Dolly: The Sheep That Changed the World",
    original_title: "Dolly: The Sheep That Changed the World",
    date: "01/01/2021",
    tag: "Documentary",
    vote_average: 0,
    backdrop_path: "/589XLC8FeEsxavohy6sboo20Tep.jpg",
    overview:
      "Tells the story of the first mammal to be cloned and the impact this monumental scientific achievement in 1996 had on the world. Featuring previously unseen footage, the film reveals how a handful of the world's best genetic scientists worked in secret on a small Scottish farm to crack the holy grail of replicating life.",
    emoji_primary: "Helpful",
    emoji_primary_count: 0,
    emoji_secondary: "WOW",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 920472,
    title: "Malibu Coke",
    original_title: "Malibu Coke",
    date: "01/01/2021",
    tag: "Romance",
    vote_average: 0,
    backdrop_path: "/gp0QXjPkpi52D7Jd4cEY0fV3bt2.jpg",
    overview: "A young man is in love with his cigarette - until he dies",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Cozy",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 985078,
    title: "In Twos",
    original_title: "Zu Zweit",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/gTCZLH4LIMSDP8FuvaIXXqkAoNY.jpg",
    overview:
      "Unexpressed feelings, unfulfilled dreams and a secretly purchased morning-after pill spark a young couple's fight.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 863718,
    title: "Votamos",
    original_title: "Votamos",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 8,
    backdrop_path: "/aiOCWF3vOv4dqIFjHB3StE6Ph3L.jpg",
    overview:
      "What begins as an ordinary board in a traditional apartment building to vote the renewal of the elevator, turns into an unexpected debate about the limits of pacific coexistence.",
    emoji_primary: "Emotional",
    emoji_primary_count: 80,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 80,
    emoji_boring_count: 20,
    "IsBetterThan90%_emoji_primary": 1,
  },
  {
    id: 966884,
    title: "JESSY",
    original_title: "Jessy",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 6,
    backdrop_path: "/9mzVTQ7FsTWqvS62U61XyhfBoG8.jpg",
    overview:
      "13-year-old Jessy can hardly remember that her father Miro used to be an important part of her life. His unexpected return after seven years in prison and his intrusion into the safe space she and her mother have built severely disrupts their symbiotic relationship. Forced to deal with this stranger to her, Jessy increasingly trusts Miro. She learns to open up and set new boundaries. Then she meets Max, who strengthens her urge for freedom. Stirred up by new relationships and feelings, she begins to listen to herself and enjoys a sense of independence. (attention Berlin)",
    emoji_primary: "Emotional",
    emoji_primary_count: 60,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 60,
    emoji_boring_count: 40,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 834644,
    title: "Peeking",
    original_title: "Peeking",
    date: "01/01/2021",
    tag: "Thriller",
    vote_average: 0,
    backdrop_path: "/e1vS7jMvypRdp2xJ9sPQ9rc8Io8.jpg",
    overview:
      'A young, aspiring videographer (Wyatt) is encouraged by a documentary filmmaker (Thomas) to follow and video strangers in a process called "Peeking".',
    emoji_primary: "WTF",
    emoji_primary_count: 0,
    emoji_secondary: "Confusing",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 782565,
    title: "Guardian",
    original_title: "ഗാർഡിയൻ",
    date: "01/01/2021",
    tag: "Crime",
    vote_average: 5.2,
    backdrop_path: "/sX8jL3Q7UnosP4VSp24UR7qrSJL.jpg",
    overview:
      "The story revolves around a doctor who tries to save his wife when her past comes back to haunt her.",
    emoji_primary: "WTF",
    emoji_primary_count: 52,
    emoji_secondary: "Action-packed",
    emoji_secondary_count: 52,
    emoji_boring_count: 48,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 894263,
    title: "Moscow - Onde o Inesperado Sempre Acontece",
    original_title: "Moscow - Onde o Inesperado Sempre Acontece",
    date: "01/01/2021",
    tag: "Mystery",
    vote_average: 7,
    backdrop_path: "/lsCo0IpcbOwCE00QkJKNbjxjHfj.jpg",
    overview:
      'Val is a woman with a strong personality, living in a turbulent city plagued by gangs and violence. "Moscow" takes place in a single night, in a jazz club in São Paulo.',
    emoji_primary: "Confusing",
    emoji_primary_count: 70,
    emoji_secondary: "WTF",
    emoji_secondary_count: 70,
    emoji_boring_count: 30,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 616801,
    title: "The Northwoods",
    original_title: "The Northwoods",
    date: "01/01/2021",
    tag: "Horror",
    vote_average: 0,
    backdrop_path: "/cGAm3MeVNf0JvrfXVPLDbhEmkpa.jpg",
    overview:
      "Two sisters get lost in the Norwegian wilderness while being chased by a hunter. It turns out he's far from the only one hunting them.",
    emoji_primary: "Scary",
    emoji_primary_count: 0,
    emoji_secondary: "WTF",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 883022,
    title: "A Fight With Shadow",
    original_title: "A Fight With Shadow",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 7,
    backdrop_path: "/tfYOtXRsyqx618tzvcqfE6hHajb.jpg",
    overview:
      "Tiwa, an abused orphan, meets a helpful stranger who might know more about her story than she realizes.",
    emoji_primary: "Emotional",
    emoji_primary_count: 70,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 70,
    emoji_boring_count: 30,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 710413,
    title: "Erotic Fire of the Unattainable",
    original_title: "Erotic Fire of the Unattainable",
    date: "01/01/2021",
    tag: "Romance",
    vote_average: 5,
    backdrop_path: "/2PCudqQLG90xGeKJpsFTD18uuSy.jpg",
    overview:
      "One successful writer, one eccentric Wall Streeter, one feeling ex con turned businessman, a witty literary agent and a sensitive woman – all of a certain age and then some -- try to figure out this thing called love.",
    emoji_primary: "Emotional",
    emoji_primary_count: 50,
    emoji_secondary: "Cozy",
    emoji_secondary_count: 50,
    emoji_boring_count: 50,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 957269,
    title: "Checkpoint",
    original_title: "Checkpoint",
    date: "01/01/2021",
    tag: "Science Fiction",
    vote_average: 0,
    backdrop_path: "/6bIB4WVWuKkTttNlRiJZkKx6KMd.jpg",
    overview:
      "After mysteriously awakening in a barren alien landscape, a self-serving prisoner must conquer a series of grueling trials in order to reunite with his lost love.",
    emoji_primary: "Cool",
    emoji_primary_count: 0,
    emoji_secondary: "Action-packed",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 794581,
    title: "Take Me to a Nice Place",
    original_title: "Take Me to a Nice Place",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/lHfxpsLS5x2rdBuavbBLysWpYj6.jpg",
    overview:
      "After mankind drove itself to the brink of extinction, a zealous lone survivor hunts his way across remote Canadian landscapes in search of knowledge and materials in order to reshape humanity. Ten years later, a woman raises 10 same-age children and prepares them for the sacrifices they must make in order to save the human race.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 774791,
    title: "Into the Flames",
    original_title: "Into the Flames",
    date: "01/01/2021",
    tag: "Comedy",
    vote_average: 0,
    backdrop_path: "/myEZd2fbTpOyNAbEZgWNJEpCpH6.jpg",
    overview:
      "Two robbers flee from a robbery into a neighborhood where they seek safety. Spotting two guys arriving to a house, the robbers pull into the driveway and use a car cover to hide. Inside the house, they discover that it's a gathering of cannabis entrepreneurs. In short , their robbery is discovered. And now the robbers make them eat edibles while they hold them hostage. Chaos ensues.",
    emoji_primary: "Funny",
    emoji_primary_count: 0,
    emoji_secondary: "Action-packed",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 928791,
    title: "god@mail.com",
    original_title: "dieu@mail.com",
    date: "01/01/2021",
    tag: "Comedy",
    vote_average: 0,
    backdrop_path: "/dY8P46MU1omgehdwHdBp4xHS72X.jpg",
    overview:
      "Réal Gendron is an unremarkable old man. He believes in God. He believes in friendship. And he believes in life. So when he receives an e-mail from a total stranger in Africa informing him that he’s the sole legatee of an immense fortune, he believes that too.",
    emoji_primary: "Funny",
    emoji_primary_count: 0,
    emoji_secondary: "Action-packed",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 781871,
    title: "BLANKO",
    original_title: "BLANKO",
    date: "01/01/2021",
    tag: "Mystery",
    vote_average: 0,
    backdrop_path: "/eGTMZNj7XKIL8GtLMqyIk4CgBNf.jpg",
    overview:
      'Following the success of his miniseries, Marc "Kemar" Jarousseau brings back his character Detective le Blanko in a bigger and longer adventure five years after the last episode aired.',
    emoji_primary: "Confusing",
    emoji_primary_count: 0,
    emoji_secondary: "WTF",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 781849,
    title: "Bloody Nun 2: The Curse",
    original_title: "Bloody Nun 2: The Curse",
    date: "01/01/2021",
    tag: "Horror",
    vote_average: 0,
    backdrop_path: "/2AgqLHzAGM5CckJa6SeFpQTZfRA.jpg",
    overview:
      "The Bloody Nun is back, this time unleashed on a brothel, will the unholy victims survive the night?",
    emoji_primary: "Scary",
    emoji_primary_count: 0,
    emoji_secondary: "WTF",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 831266,
    title: "An Approximation of their Barbarous Manners",
    original_title: "An Approximation of their Barbarous Manners",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/sa6N2yRxSXcWC6p3rAaye1Eosk3.jpg",
    overview:
      "Tangier, Morocco. December 2019  87-year-old American character actor Bruce Glover (Chinatown) goes missing from a film set on the first day of shooting.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 787916,
    title: "Munkie",
    original_title: "Munkie",
    date: "01/01/2021",
    tag: "Thriller",
    vote_average: 10,
    backdrop_path: "/aGwqZeyHw7CTFehC7zUeG5teF9w.jpg",
    overview:
      'Munkie follows a vengeful daughter whose violent plan of revenge against her domineering "tiger parents" spins out of control.',
    emoji_primary: "WTF",
    emoji_primary_count: 100,
    emoji_secondary: "Confusing",
    emoji_secondary_count: 100,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 1,
  },
  {
    id: 883012,
    title: "The Widow of Nkanu",
    original_title: "The Widow of Nkanu",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 8,
    backdrop_path: "/qEqMST7msnyg7fZxIo9GEHaOl4K.jpg",
    overview:
      "Muna, the king's betrothed left for dead by her friend, embarks on a quest for revenge.",
    emoji_primary: "Emotional",
    emoji_primary_count: 80,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 80,
    emoji_boring_count: 20,
    "IsBetterThan90%_emoji_primary": 1,
  },
  {
    id: 775430,
    title: "Den deprimerte klovnen",
    original_title: "Den deprimerte klovnen",
    date: "01/01/2021",
    tag: "Documentary",
    vote_average: 0,
    backdrop_path: "/t63fv1KT47QK6quIe0Oz5MTeDD1.jpg",
    overview:
      "Drama teacher Knut has always been interested in clowns. He has researched them and taught about them, and he has played the clown. But as a 61-year-old, he became deeply depressed and disappeared into his own darkness for over two years.",
    emoji_primary: "Helpful",
    emoji_primary_count: 0,
    emoji_secondary: "WOW",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 783588,
    title: "An Intervention",
    original_title: "An Intervention",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 10,
    backdrop_path: "/u5bYFIZi1dMOLCxJUuD283Xbct1.jpg",
    overview:
      "Chelsea Bledsoe and her husband Graig throw a surprise intervention for her old high school boyfriend, Henry, with a mismatched group of acquaintances from back in the day to fill out the guest list.",
    emoji_primary: "Emotional",
    emoji_primary_count: 100,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 100,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 1,
  },
  {
    id: 746586,
    title: "Under Spanish Skies",
    original_title: "Under Spanish Skies",
    date: "01/01/2021",
    tag: "Drama",
    vote_average: 0,
    backdrop_path: "/grWddWQhy7A5vQdfk184ZKVRp8d.jpg",
    overview:
      "After the death of her husband, a reclusive artist invites lifelong friends to her farm in Spain and reveals a shocking plan. Before the weekend ends, the friends must share secrets from the past and force her to make a choice.",
    emoji_primary: "Emotional",
    emoji_primary_count: 0,
    emoji_secondary: "Supportive",
    emoji_secondary_count: 0,
    emoji_boring_count: 0,
    "IsBetterThan90%_emoji_primary": 0,
  },
  {
    id: 874011,
    title: "Happiness",
    original_title: "해피니스",
    date: "01/01/2021",
    tag: "Romance",
    vote_average: 6,
    backdrop_path: "/eZv2svdO5jqX8xBGgHgHALU7PzU.jpg",
    overview:
      "Jeong-hoon, who is looking back on his life because of his wife who committed suicide without a will, finds the meaning of happiness that his wife longed for, and visits the sea of memories where he found Geon-hee. A short but intense relationship continues with Geon-hee, who had been struggling with her daughter after divorce with her husband, and she smiles for the first time, and Jeong-hoon feels the small happiness Geon-hee gave him on his bus returning home.",
    emoji_primary: "Emotional",
    emoji_primary_count: 60,
    emoji_secondary: "Cozy",
    emoji_secondary_count: 60,
    emoji_boring_count: 40,
    "IsBetterThan90%_emoji_primary": 0,
  },
];

export const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    image: `https://image.tmdb.org/t/p/w500${item.backdrop_path}`,
    createdAt: item.date,
    tags: "",
    website: "movies",
    isDemo: true,
    link: "",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    author: {
      id: 400 + index,
      username: "",
      nickname: "movie",
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
    media_category: "movie",
  };
});

interface Props {}

const NewsMovies: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsMovies;
