import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title: "Singapore's Life Expectancy Falls For First Time Records Began in 1957",
    link: "https://www.bloomberg.com/news/articles/2022-10-10/singapore-s-life-expectancy-falls-for-first-time-on-covid-deaths",
    published: "10/10/2022",
    tag: "business",
  },
  {
    title: "EM Weekly Podcast: Peak Yields Spell Opportunity for Risk Assets",
    link: "https://www.bloomberg.com/news/audio/2022-10-10/em-weekly-podcast-peak-yields-spell-opportunity-for-risk-assets",
    published: "10/10/2022",
    tag: "business",
  },
  {
    title: "Grady Wulff on the Markets (Audio)",
    link: "https://www.bloomberg.com/news/audio/2022-10-10/grady-wulff-on-the-markets-audio",
    published: "10/10/2022",
    tag: "business",
  },
  {
    title: "Stocks Face Brutal Earnings Season With All Eyes on Apple: MLIV Pulse",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/apple-aapl-in-focus-for-us-stocks-facing-brutal-earnings-season",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Five Things You Need to Know to Start Your Day",
    link: "https://www.bloomberg.com/news/newsletters/2022-10-09/five-things-you-need-to-know-to-start-your-day",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "China Says Biden's New Chip Curbs Will Harm Recovery",
    link: "https://www.bloomberg.com/news/videos/2022-10-09/china-says-biden-s-new-chip-curbs-will-harm-recovery-video",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "KKR-Backed Livspace Sets Aside $100 Million for Acquisitions",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/kkr-backed-livspace-sets-aside-100-million-for-acquisitions",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Screening Procedure Fails to Prevent Colon Cancer Deaths in Large Study",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/colonoscopy-screening-exams-fail-to-prevent-cancer-deaths-in-large-study",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Voting Rights and Veterans Benefits at High Court (Podcast)",
    link: "https://www.bloomberg.com/news/audio/2022-10-09/voting-rights-and-veterans-benefits-at-high-court-podcast",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "All South Korean Men Must Serve in the Army. Does That Include BTS?",
    link: "https://www.bloomberg.com/news/newsletters/2022-10-09/all-korean-men-must-serve-in-the-army-does-that-include-bts",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Venezuela Deaths Climb to 22 After Heavy Rain Brings Mudslides",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/venezuela-military-in-emergency-mode-after-rain-brings-mudslides",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Kanye's Return to Twitter Lasts One Day as Account Is Locked",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/kanye-s-return-to-twitter-lasts-one-day-as-account-is-locked",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Huge Barge Backup Eases on Mississippi, Freeing Tons of Cargo",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/huge-barge-backup-eases-on-mississippi-freeing-tons-of-cargo",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "EM Weekly Podcast: Peak Yields Spell Opportunity for Risk Asset",
    link: "https://www.bloomberg.com/news/audio/2022-10-09/em-weekly-podcast-peak-yields-spell-opportunity-for-risk-asset",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Food Inflation on Path to Slowing, Says Bank of Canada's Macklem",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/bank-of-canada-s-macklem-sees-food-inflation-on-path-to-slowing",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Era of Peak Yields Signals Entry Point for Emerging-Market Bets",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/era-of-peak-yields-signals-entry-point-for-emerging-market-bets",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Investors on Guard as Stocks Rally Sputters Ahead of Data Deluge",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/investors-on-guard-as-stocks-rally-sputters-ahead-of-data-deluge",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "El-Erian Blames Fed for 'Very High' Risk of a Damaging Recession",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/el-erian-blames-fed-for-very-high-risk-of-a-damaging-recession",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Your Sunday Briefing: The World's Finance Chiefs Are Coming to Town",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/what-to-expect-this-week-your-sunday-briefing",
    published: "10/09/2022",
    tag: "business",
  },
  {
    title: "Taiwan to Pledge More Economic Resilience, Democratic Ties",
    link: "https://www.bloomberg.com/news/articles/2022-10-09/taiwan-to-pledge-more-economic-resilience-democratic-ties",
    published: "10/09/2022",
    tag: "business",
  },
];

const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "bloomberg",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsBloomberg: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsBloomberg;
