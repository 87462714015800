import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title: "Follow live: Ravens and Bengals try to find a groove in their Week 5 matchup",
    summary: null,
    link: "https://www.espn.com/nfl/game/_/gameId/401437775",
    published: "10/09/2022",
    tag: "nfl",
  },
  {
    title: "Musgrove, Padres blank Mets, prevail in Game 3",
    summary:
      "Joe Musgrove baffled the New York Mets in Game 3 on Sunday night at Citi Field, leading the San Diego Padres to a 6-0 victory, sealing a berth in the NLDS against the Los Angeles Dodgers.",
    link: "https://www.espn.com/mlb/story/_/id/34765150/san-diego-padres-eliminate-new-york-mets-secure-nlds-date-los-angeles-dodgers",
    published: "10/09/2022",
    tag: "mlb",
  },
  {
    title: "Referee: DE's throw of Brady led to late penalty",
    summary:
      'Referee Jerome Boger said Falcons defensive lineman Grady Jarrett "unnecessarily" threw Buccaneers quarterback Tom Brady to the ground, resulting in the roughing the passer call on a key third down late in Tampa Bay\'s win.',
    link: "https://www.espn.com/nfl/story/_/id/34763681/falcons-lineman-unnecessarily-threw-tom-brady-ground-ref-says",
    published: "10/09/2022",
    tag: "nfl",
  },
  {
    title: "Pickett: Lawson 'went after my knee' after throw",
    summary:
      'In his starting debut for the Steelers, Kenny Pickett threw for 327 yards in losing to the Bills, showing some fire after he felt Shaq Lawson\'s hit was too low after a pass, saying, "From my opinion, I felt like he went after my knee after I threw it."',
    link: "https://www.espn.com/nfl/story/_/id/34763815/pittsburgh-steelers-qb-kenny-pickett-calls-low-hit-loss-felt-went-my-knee",
    published: "10/09/2022",
    tag: "nfl",
  },
  {
    title: "Lightning suspend Cole pending investigation",
    summary:
      "The Lightning suspended defenseman Ian Cole pending an investigation into allegations made on social media that he sexually abused a woman when she was a minor.",
    link: "https://www.espn.com/nhl/story/_/id/34764790/tampa-bay-lightning-suspend-ian-cole-pending-investigation-allegations-sexual-abuse",
    published: "10/09/2022",
    tag: "nhl",
  },
  {
    title: "Bridgewater put in protocol in Fins' lopsided loss",
    summary:
      "Dolphins quarterback Teddy Bridgewater is in the concussion protocol and was ruled out against the New York Jets after leaving the game in the first quarter.",
    link: "https://www.espn.com/nfl/story/_/id/34761662/dolphins-teddy-bridgewater-protocol-hard-hit",
    published: "10/09/2022",
    tag: "nfl",
  },
  {
    title: "Rodgers not happy with talk in Pack locker room",
    summary:
      "Aaron Rodgers said after the Packers' loss to the Giants that he wasn't happy with players already talking about what a loss next week to the Jets would mean for Green Bay.",
    link: "https://www.espn.com/nfl/story/_/id/34762226/aaron-rodgers-not-happy-talk-packers-locker-room",
    published: "10/09/2022",
    tag: "nfl",
  },
  {
    title: "UGA reclaims No. 1 spot as Bama slides to third",
    summary:
      "Georgia took back the No. 1 spot in The Associated Press college football poll from Alabama after being bumped out last week by the Crimson Tide, who slid to No. 3 behind Ohio State.",
    link: "https://www.espn.com/college-football/story/_/id/34761326/georgia-returns-top-ap-poll-ahead-ohio-state-alabama",
    published: "10/09/2022",
    tag: "college-football",
  },
  {
    title: "Chapman off Yanks' roster after missing workout",
    summary:
      "Reliever Aroldis Chapman was dropped from the Yankees' American League Division Series roster because he missed Friday's workout.",
    link: "https://www.espn.com/mlb/story/_/id/34760715/aroldis-chapman-left-yankees-roster-missing-workout",
    published: "10/09/2022",
    tag: "mlb",
  },
  {
    title: "Padres took over the Citi: Highlights and takeaways from San Diego's Game 3 win",
    summary: "MLB's first-ever wild-card series weekend concluded with a Padres win over the Mets.",
    link: "https://www.espn.com/mlb/story/_/id/34753555/mets-padres-mlb-wild-card-series-2022-game-3-highlights",
    published: "10/09/2022",
    tag: "mlb",
  },
  {
    title:
      "Week 5 takeaways: Everything we learned from wins by the Eagles, Cowboys, Jets and Vikings",
    summary:
      "NFL Nation recaps every game and answers the biggest questions from around the league in Week 5.",
    link: "https://www.espn.com/nfl/story/_/id/34741109/nfl-week-5-takeaways-2022-big-questions-every-game",
    published: "10/09/2022",
    tag: "nfl",
  },
  {
    title:
      "Padres-Dodgers NLDS is set: Everything you need to know about the eight remaining MLB playoff teams",
    summary:
      "We're on to the division series after a thrilling wild-card weekend. Here's why your squad could be playing into November -- or be sent home next.",
    link: "https://www.espn.com/mlb/story/_/id/34730926/2022-mlb-playoff-preview-world-series-odds-matchup-keys",
    published: "10/09/2022",
    tag: "mlb",
  },
  {
    title: "Insects invade stadium ahead of Chargers-Browns clash",
    summary:
      "The insects, which famously were a factor during the 2007 ALDS between Cleveland and New York, were back in force at FirstEnergy Stadium.",
    link: "https://www.espn.com/nfl/story/_/id/34760953/midges-invade-stadium-ahead-los-angeles-chargers-cleveland-browns-clash",
    published: "10/09/2022",
    tag: "nfl",
  },
  {
    title: "Alabama, Georgia, Tennessee show why college football still runs through the SEC",
    summary:
      "It was a Saturday in which the SEC offered a reminder that it is still the chief power broker in the country, with the Tide, Bulldogs, Rebels and Vols now accounting for more than a quarter of the country's remaining undefeated teams.",
    link: "https://www.espn.com/college-football/story/_/page/gamedayfinal100822/college-football-week-6-highlights-top-plays-games-takeaways",
    published: "10/09/2022",
    tag: "college-football",
  },
  {
    title: "Where is Verstappen's championship trophy?",
    summary:
      "Verstappen clinched the title Sunday, but won't get his F1 trophy until the end of the year.",
    link: "https://www.espn.com/f1/story/_/id/34759614/where-max-verstappen-championship-trophy",
    published: "10/09/2022",
    tag: "f1",
  },
  {
    title: "Gasly: 'I was 2 meters from passing away'",
    summary:
      "Pierre Gasly believes he was just 2 meters away from a fatal accident after a near-miss with a crane.",
    link: "https://www.espn.com/f1/story/_/id/34759155/pierre-gasly-crane-incident-was-two-metres-passing-away",
    published: "10/09/2022",
    tag: "f1",
  },
  {
    title: "Liverpool's title tilt in tatters, Dortmund's still alive: Weekend Review",
    summary:
      "From Liverpool dropping out of the title race in England to a dramatic Klassiker draw that gives Dortmund hope, there is no shortage of drama to review from the weekend in Europe.",
    link: "https://www.espn.com/soccer/english-premier-league/story/4764669/arsenal-leave-liverpools-title-tilt-in-tatters-dortmund-keep-theirs-alive-weekend-review",
    published: "10/09/2022",
    tag: "soccer",
  },
  {
    title: "Cristiano Ronaldo proves himself in Man United's much-needed win over Everton",
    summary: "An injury to Anthony Martial cleared the way for Ronaldo to come off the bench.",
    link: "https://www.espn.com/soccer/blog-the-match/story/4765344/cristiano-ronaldo-proves-value-in-man-united-win-vs-everton",
    published: "10/09/2022",
    tag: "soccer",
  },
  {
    title: "NHL roundtable: Breakout stars, teams that will shock, Avs' repeat chances",
    summary:
      "Which rookies are we most excited to see? Which teams will surprise -- or disappoint -- their fans the most?",
    link: "https://www.espn.com/nhl/story/_/id/34740517/predictions-breakout-nhl-stars-avs-cup-chances-more",
    published: "10/09/2022",
    tag: "nhl",
  },
  {
    title: "Lapsed fan's guide to 2022-23 season: Trades, signings, hirings and more you missed",
    summary:
      "Get caught up with quick-hitting intel on everything that happened the past four months.",
    link: "https://www.espn.com/nhl/story/_/id/34742763/lapsed-fan-guide-nhl-season-trades-signings-more",
    published: "10/09/2022",
    tag: "nhl",
  },
  {
    title: "Can Kawhi Leonard and Paul George stay healthy enough to win in L.A.?",
    summary:
      "There are enormous expectations in Year 4 of the Kawhi Leonard-Paul George era. Despite having the deepest roster in the league, health will be the key factor for the Clippers.",
    link: "https://www.espn.com/nba/story/_/id/34732857/the-biggest-questions-surrounding-la-clippers",
    published: "10/07/2022",
    tag: "nba",
  },
  {
    title: "Family is everything: How the Fundoras are building a boxing dynasty",
    summary:
      "Sebastian and Gabriela Fundora fight on the same card for the first time on Saturday.",
    link: "https://www.espn.com/boxing/story/_/id/34731795/for-fundoras-boxing-championships-run-family",
    published: "10/07/2022",
    tag: "boxing",
  },
  {
    title:
      "Best plays by top college football recruits: Alabama QB commit stars; new Texas A&M commit crushes RB",
    summary:
      "From uncommitted recruits making big plays to future Alabama, Texas and Texas A&amp;M players collecting great tape, these are the week's top highlights from the high school ranks.",
    link: "https://www.espn.com/college-football/story/_/id/34747955/best-plays-highlights-top-college-football-recruits-alabama-qb-commit-eli-holstein-stars",
    published: "10/08/2022",
    tag: "college-football",
  },
  {
    title: "Transfer Talk: Mount uncertainty has Chelsea looking at Pellegrini, Pino",
    summary:
      "With Mason Mount's contract talks reportedly on hold, Chelsea are looking at alternatives in the transfer market. Transfer Talk has the latest.",
    link: "https://www.espn.com/soccer/blog-transfer-talk/story/4765800/transfer-talk-mason-mount-impasse-forces-chelsea-to-look-at-lorenzo-pellegrini-and-yeremi-pino",
    published: "10/09/2022",
    tag: "soccer",
  },
  {
    title: "Which deep sleepers should you target in your fantasy basketball draft?",
    summary:
      "Jim McCormick highlights the relatively unknown players that are worth taking a flier on later in your fantasy basketball draft.",
    link: "https://www.espn.com/fantasy/basketball/story/_/id/34746230/fantasy-basketball-draft-nba-deep-sleepers-gordon-hayward-herbert-jones-bones-hyland",
    published: "10/07/2022",
    tag: "fantasy",
  },
  {
    title: "Draft kit: Rankings, mock drafts, sleepers, projections and analysis",
    summary:
      "Mock drafts, points and category rankings, projections, player profiles -- we've got everything you need.",
    link: "https://www.espn.com/fantasy/basketball/story/_/id/34672259/fantasy-basketball-draft-kit-rankings-mock-drafts-sleepers-projections-analysis",
    published: "10/07/2022",
    tag: "fantasy",
  },
];

const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "espn",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsEspn: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsEspn;
