import API from "@/apis";
import IMG_LOGO from "@/assets/Logo-3.png";
import IconFont from "@/components/IconFont";
import { ISetInfo, ISetInfoWithEmojis } from "@/models";
import {
  emojiGroupsState,
  emojiListState,
  officialEmojiGroupsState,
  switchedEmojiListState,
} from "@/store";
import { RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Collapse, Empty, Input, Tag } from "antd";
import * as React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import EmojiV2 from "../Emoji/EmojiV2";
import AddSet from "./AddSet";
import EmojiSetCollapsePanelDetail from "./EmojiSetCollapsePanelDetail";

import DeleteGroupIcon from "./DeleteGroupIcon";
import "./index.css";

interface Props {
  type: string;
}

const EmojiSets: React.FC<Props> = (props: Props) => {
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
  const [addSuccessCode, setAddSuccessCode] = React.useState<string>("");
  const emojiList = useRecoilValue(emojiListState);
  const switchedEmojiList = useRecoilValue(switchedEmojiListState);
  const [showSearch, setShowSearch] = React.useState<boolean>(false);
  const [searchedList, setSelectedList] = React.useState<ISetInfoWithEmojis[]>([]);
  const [searchWord, setSearchWord] = React.useState<string>("");
  const [emojiGroups, setEmojiGroups] = useRecoilState(emojiGroupsState);
  const [officialEmojiGroups, setOfficalEmojiGroups] = useRecoilState(officialEmojiGroupsState);
  const [applyList, setApplyList] = React.useState<number[]>([1]);

  React.useEffect(() => {
    const applyOfficialEmojiGroup = officialEmojiGroups.find((group) => group.apply === 1);
    if (applyOfficialEmojiGroup) {
      setApplyList([applyOfficialEmojiGroup.id]);
    }
    const applyEmojiGroup = emojiGroups.find((group) => group.apply === 1);
    if (applyEmojiGroup) {
      setApplyList([applyEmojiGroup.id]);
    }
  }, [emojiGroups, officialEmojiGroups]);

  const handleAddSuccess = () => {
    // setAddSuccessCode(code);
    fetchGroups();
  };

  const handleFetched = () => {
    setAddSuccessCode("");
  };

  const fetchGroups = async () => {
    const res = await API.getEmojiGroups();
    if (Array.isArray(res?.data)) {
      const promiseList = res.data
        .filter((i: any) => +i.id !== 1 && +i.id !== 2)
        .map((item: ISetInfo) => {
          return API.getEmojiListByGroup(item);
        });

      const officialEmojiGroups = res.data.filter((i: any) => +i.id === 1 || +i.id === 2);
      setOfficalEmojiGroups(officialEmojiGroups);

      const resList = await Promise.all(promiseList);
      setEmojiGroups(resList);
    }
  };

  const handleChangeActive = (key: string | string[]) => {
    if (Array.isArray(key)) {
      setActiveKeys(key);
    } else {
      setActiveKeys([key]);
    }
  };

  const handleApply = async (id: number) => {
    try {
      API.applySet({ group_id: id.toString() });
      setApplyList([id]);
      setTimeout(fetchGroups, 300);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = () => {
    if (!searchWord) {
      return;
    }
    const res = emojiGroups.filter((set) => {
      return set.name.toUpperCase().includes(searchWord.toUpperCase());
    });
    setSelectedList(res);
    setShowSearch(true);
  };

  const ispc = props.type === "pc";
  // const size = ispc ? 42 : 24;

  // const hasEmojiGroups = emojiGroups.filter((i) => i.emojis.length > 0);
  const tabContentClass = `w-full pb-2 grid gap-x-3 gap-y-2 overflow-y-scroll ${
    ispc ? "grid-cols-6" : "grid-cols-4"
  }`;

  const FrequentlyView = (
    <Collapse.Panel
      header={
        <div className="flex items-center">
          <IconFont type="icon-recent" style={{ fontSize: 20 }} />
          <div className="mx-3">FREQUENTLY USED</div>
          <RightOutlined style={{ fontSize: 12 }} />
        </div>
      }
      key={0}
      className="site-collapse-custom-panel"
    >
      <p>TODO</p>
    </Collapse.Panel>
  );
  const MojiserveView = (
    <Collapse.Panel
      header={
        <div className="w-full h-6 flex group items-center">
          <img className="w-5 h-5" alt="" src={IMG_LOGO} />
          <div className="mx-3">MOJIVERSE</div>
          <RightOutlined style={{ fontSize: 12 }} />

          <div
            className={`ease-in-out duration-300 overflow-hidden flex-auto text-right ${
              applyList.includes(1) ? "h-6" : "h-0 group-hover:h-6"
            }`}
            style={{
              color: "rgba(128, 128, 128)",
              // height: isApply ? 24 : "auto",
            }}
          >
            <Tag
              className="rounded-md"
              color={applyList.includes(1) ? "default" : "success"}
              onClick={(e) => {
                e.stopPropagation();
                if (applyList.includes(1)) {
                  return;
                }
                handleApply(1);
              }}
            >
              {applyList.includes(1) ? "Applied" : "Apply"}
            </Tag>
          </div>
        </div>
      }
      key={1}
      className="site-collapse-custom-panel"
    >
      <div className={tabContentClass}>
        {emojiList.map((item) => {
          return (
            <div className="max-w-[72px] w-[72px]" key={item.code}>
              <EmojiV2
                // onClick={handleClickEmoji(emojiItem)}
                width={42}
                height={42}
                emoji={item}
                autoScaleBack
                showName
              />
            </div>
          );
        })}
      </div>
    </Collapse.Panel>
  );
  const iOSView = (
    <Collapse.Panel
      header={
        <div className="w-full h-6 group flex items-center">
          <EmojiV2 width={20} height={20} emoji={switchedEmojiList[0]} noScale autoScaleBack />
          <div className="mx-3">iOS</div>
          <RightOutlined style={{ fontSize: 12 }} />

          <div
            className={`ease-in-out duration-300 overflow-hidden flex-auto text-right justify-end ${
              applyList.includes(2) ? "h-6" : "h-0 group-hover:h-6"
            }`}
            style={{
              color: "rgba(128, 128, 128)",
              // height: isApply ? 24 : "auto",
            }}
          >
            <Tag
              className="rounded-md"
              color={applyList.includes(2) ? "default" : "success"}
              onClick={(e) => {
                e.stopPropagation();
                if (applyList.includes(2)) {
                  return;
                }
                handleApply(2);
              }}
            >
              {applyList.includes(2) ? "Applied" : "Apply"}
            </Tag>
          </div>
        </div>
      }
      key={2}
      className="site-collapse-custom-panel"
    >
      <div className={tabContentClass}>
        {switchedEmojiList.map((item) => {
          return (
            <div className="max-w-[72px] w-[72px]" key={item.code}>
              <EmojiV2
                // onClick={handleClickEmoji(emojiItem)}
                width={42}
                height={42}
                emoji={item}
                autoScaleBack
                showName
              />
            </div>
          );
        })}
      </div>
    </Collapse.Panel>
  );
  const AllEmojisView = (
    <div className="h-[400px] overflow-y-scroll">
      <Collapse
        expandIcon={() => null}
        bordered={false}
        className="site-collapse-custom-collapse"
        ghost
        onChange={handleChangeActive}
      >
        {FrequentlyView}
        {MojiserveView}
        {iOSView}
        {emojiGroups.map((set, index) => {
          const itemKey = `${index + 3}`;
          const isApply = applyList.includes(set.id);
          const cls = isApply ? "h-6" : "h-0 group-hover:h-6 ";
          return (
            <Collapse.Panel
              header={
                <div className="w-full h-6 group flex items-center">
                  {set.emojis?.length > 0 ? (
                    <EmojiV2 width={20} height={20} emoji={set.emojis[0]} noScale autoScaleBack />
                  ) : (
                    <IconFont type="icon-emoji" style={{ fontSize: 20 }} />
                  )}
                  <div className="mx-3">{set.name}</div>
                  <RightOutlined style={{ fontSize: 12 }} />
                  <div
                    className={`ease-in-out duration-300 overflow-hidden flex-auto flex items-center justify-end text-right ${cls}`}
                    style={{ color: "rgba(128, 128, 128)" }}
                  >
                    <Tag
                      className="rounded-md"
                      color={isApply ? "default" : "success"}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isApply) {
                          return;
                        }
                        handleApply(set.id);
                      }}
                    >
                      {isApply ? "Applied" : "Apply"}
                    </Tag>

                    {isApply ? null : <DeleteGroupIcon uid={set.user_id} id={set.id} />}
                  </div>
                </div>
              }
              key={itemKey}
              className="site-collapse-custom-panel"
            >
              <EmojiSetCollapsePanelDetail
                ispc={ispc}
                set={set}
                isActive={activeKeys.includes(itemKey)}
                fetchNew={addSuccessCode === `${set.id}`}
                onFetchNew={handleFetched}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );

  const cls = `w-full pb-2 grid gap-x-3 gap-y-2 ${ispc ? "grid-cols-6" : "grid-cols-4"}`;
  const SearchEmojisView = (
    <div className="pt-3">
      {searchedList.length === 0 ? (
        <div>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} imageStyle={{ fontSize: 300 }} />
        </div>
      ) : (
        <div className={cls}>
          <Collapse
            expandIcon={() => null}
            bordered={false}
            className="site-collapse-custom-collapse"
            ghost
            onChange={handleChangeActive}
          >
            {searchedList.map((set, index) => {
              const itemKey = `${index + 3}`;
              return (
                <Collapse.Panel
                  header={
                    <div className="flex items-center">
                      <IconFont type="icon-emoji" style={{ fontSize: 20 }} />
                      <div className="mx-3">{set.name}</div>
                      <RightOutlined style={{ fontSize: 12 }} />
                    </div>
                  }
                  key={itemKey}
                  className="site-collapse-custom-panel"
                >
                  <EmojiSetCollapsePanelDetail
                    ispc={ispc}
                    set={set}
                    isActive={activeKeys.includes(itemKey)}
                    fetchNew={addSuccessCode === `${set.id}`}
                    onFetchNew={handleFetched}
                  />
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </div>
      )}
    </div>
  );

  return (
    <div className="px-0 pb-4">
      <div className="pb-3 w-full flex items-center">
        <Input
          className="mr-2 rounded-lg"
          value={searchWord}
          onChange={(e) => {
            setSearchWord(e.target.value);
            if (e.target.value === "") {
              setShowSearch(false);
              setSelectedList([]);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          placeholder="Search set name..."
          suffix={<SearchOutlined onClick={handleSearch} className="cursor-pointer" />}
        />
        <AddSet ispc={ispc} onSuccess={handleAddSuccess} />
      </div>

      {showSearch ? SearchEmojisView : AllEmojisView}
    </div>
  );
};

export default EmojiSets;
