import ICON_Foryou from "@/assets/icons/For-you.png";

import { mostReactedState, searchState } from "@/store";
import { useRecoilValue } from "recoil";
import HomePosts from "./HomePosts";
import HomeSearchPosts from "./HomeSearchPosts";
import TopPost from "./TopPost";
import "./index.css";

const Home = () => {
  return (
    <div className="App">
      <Content />
    </div>
  );
};

export default Home;

const Content = () => {
  const homeSearch = useRecoilValue(searchState);
  const showMostReacted = useRecoilValue(mostReactedState);

  return (
    <div className="waterfall-container pb-6">
      <div style={{ display: showMostReacted ? "block" : "none" }}>
        <TopPost />
      </div>

      <div className="h-8 flex items-center mb-2 sm:mb-4 px-2" title="Feed">
        <img src={ICON_Foryou} alt="" className="w-6 h-6" />
        <div className="ml-2 mt-0.5 text-xl sm:text-[22px] font-bold text-black">For You</div>
      </div>

      {homeSearch.keyword ? <HomeSearchPosts /> : <HomePosts />}
    </div>
  );
};
