import { useEffect, useState } from "react";

import ICON_HOME_BANNER_1 from "@/assets/icons/home-banner-icon-1.png";
import ICON_HOME_BANNER_2 from "@/assets/icons/home-banner-icon-2.png";
import ICON_HOME_BANNER_3 from "@/assets/icons/home-banner-icon-3.png";
import ICON_HOME_BANNER_4 from "@/assets/icons/home-banner-icon-4.png";
import IMAGE_BALL from "@/assets/images/home-banner-ball.png";
import IMG_LOGO from "@/assets/Logo-3.png";
import IconFont from "@/components/IconFont";

import "./animate.css";

const HomeBannerItem = (props: {
  icon: React.ReactNode;
  style?: React.CSSProperties;
  desc: string;
}) => {
  return (
    <div
      style={props.style}
      className="home-banner-item my-1 md:my-2 px-1 sm:px-4 h-[66px] md:h-[92px] flex items-center justify-start bg-white rounded-lg w-full md:w-[300px]"
    >
      <div className="w-[46px] md:w-[92px] h-[46px] md:h-[92px] min-w-[46px] md:min-w-[92px]">
        {props.icon}
      </div>
      <div className="pl-2 text-xs md:text-base font-bold">{props.desc}</div>
    </div>
  );
};

const WebHeaderBanner = () => {
  return (
    <div className="pt-10 pb-4 text-center">
      <h2 className="text-2xl tracking-normal font-bold">
        Engage with content creatively and discover funny, <br />
        cute and useful stuffs by a single tap
      </h2>
      <div className="flex items-center justify-center mt-8">
        <div className="mx-2">
          <HomeBannerItem
            icon={<img src={ICON_HOME_BANNER_1} alt="" />}
            desc="React to stuffs you love seamlessly"
          />
        </div>
        <div className="mx-2">
          <HomeBannerItem
            icon={<img src={ICON_HOME_BANNER_3} alt="" />}
            desc="No signups required to react"
          />
        </div>
        <div className="mx-2">
          <HomeBannerItem
            icon={<img src={ICON_HOME_BANNER_2} alt="" />}
            desc="Post by cute emojis/stickers "
          />
        </div>
        <div className="mx-2">
          <HomeBannerItem
            icon={<img src={ICON_HOME_BANNER_4} alt="" />}
            desc="Add and custimize your favorite emojis and stickers"
          />
        </div>
      </div>
    </div>
  );
};

const HIDE_HOME_BANNER = "hideHomeBanner";

const HeaderBanner = () => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const value = window.localStorage.getItem(HIDE_HOME_BANNER);
    if (!value) {
      setVisible(true);
    }
  }, []);

  const handleClickClose = () => {
    setVisible(false);
    window.localStorage.setItem(HIDE_HOME_BANNER, Date.now().toString());
  };

  if (!visible) {
    return null;
  }

  return (
    <div>
      <div className="hidden sm:block">
        <div className="relative max-w-[1248px] mx-auto">
          <WebHeaderBanner />
          <div
            onClick={handleClickClose}
            className="absolute top-2 right-1 md:right-2 md:top-4 w-6 md:w-10 h-6 md:h-10 rounded-full bg-white flex items-center justify-center cursor-pointer"
          >
            <IconFont type="icon-close" style={{ fontSize: 18 }} />
          </div>
        </div>
      </div>
      <div className="block sm:hidden bg-white relative pb-0">
        <div className="home-banner-container">
          <div className="relative py-2 md:py-0 px-4 md:px-2 max-w-[1248px] mx-auto md:h-[400px] flex items-center justify-between flex-col md:flex-row">
            <div
              onClick={handleClickClose}
              className="absolute top-2 right-2 md:right-4 w-6 md:w-10 h-6 md:h-10 rounded-full bg-white flex items-center justify-center cursor-pointer"
            >
              <IconFont type="icon-close" style={{ fontSize: 18 }} />
            </div>
            <div className="flex-auto w-full sm:w-auto md:mr-2 md:h-[400px] flex items-end justify-center md:flex-col order-2">
              <div className="flex items-center flex-1 md:flex-auto">
                <HomeBannerItem
                  icon={<img src={ICON_HOME_BANNER_1} alt="" />}
                  desc="React to stuffs you love seamlessly"
                />
              </div>
              <div className="w-full ml-4 md:ml-0 flex items-center flex-1 md:flex-auto">
                <HomeBannerItem
                  icon={<img src={ICON_HOME_BANNER_3} alt="" />}
                  desc="No signups required to react"
                />
              </div>
            </div>
            <div className="py-2 md:px-2 max-w-[335px] hidden md:flex items-center justify-center order-1 md:order-2 md:flex-col">
              <div className="relative w-[80px] min-w-[80px] h-[80px] md:w-[180px] md:w-[180px] md:h-[180px]">
                <img
                  src={IMAGE_BALL}
                  alt=""
                  className="w-[80px] min-w-[80px] h-[80px] md:w-[180px] md:h-[180px] rounded-full overflow-hidden"
                />
                <img
                  className="w-8 h-8 md:w-20 md:h-20 absolute top-4 left-6 md:top-8 md:left-[50px]"
                  alt=""
                  src={IMG_LOGO}
                />
              </div>
              <div className="text-base md:text-xl md:text-center font-bold mt-1 md:mt-4 ml-4 md:ml-0">
                Discover funny, cute, useful content by a single tap
              </div>
            </div>
            <div className="flex-auto w-full sm:w-auto md:ml-2 md:h-[400px] flex justify-center md:flex-col order-2">
              <div className="flex items-center flex-1 md:flex-auto">
                <HomeBannerItem
                  icon={<img src={ICON_HOME_BANNER_2} alt="" />}
                  desc="Post by cute emojis/stickers "
                />
              </div>
              <div className="w-full flex items-center justify-end ml-4 md:ml-0 flex-1 md:flex-auto">
                <HomeBannerItem
                  icon={<img src={ICON_HOME_BANNER_4} alt="" />}
                  desc="Add and custimize your favorite emojis and stickers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBanner;
