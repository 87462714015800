import API from "@/apis";
import ICON_SIGNUP from "@/assets/icons/icon-signup.png";
import IconFont from "@/components/IconFont";
import { Button, DatePicker, Form, Input, Modal, Popover, Select } from "antd";
import React from "react";

const SignupButton = (props: { onSignupSuccess: () => void }) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleSignup = async (values: any) => {
    const success = await API.register(values);

    if (success) {
      setVisible(false);
      props.onSignupSuccess();
    }
  };

  return (
    <>
      {/* <div
        // className="w-32 h-full text-white font-bold flex items-center justify-center bg-gray-900 rounded-full cursor-pointer"
        className="w-32 border-2 border-black h-full flex items-center justify-center rounded-full cursor-pointer"
        onClick={() => setVisible(true)}
      >
        <img src={ICON_SIGNUP} alt="sign up" className="w-8" />
      </div> */}
      <Popover overlayClassName="menu-popover" content="Sign up">
        <div className="cursor-pointer" onClick={() => setVisible(true)}>
          <div className="h-12 flex items-center justify-center">
            <img
              src={ICON_SIGNUP}
              alt=""
              className="w-11 transition duration-300 ease-in-out hover:scale-125"
            />
            <div className="text-sm font-bold">Sign up</div>
          </div>
        </div>
      </Popover>
      <Modal
        visible={visible}
        className="signup-modal"
        style={{ top: 40 }}
        title={null}
        footer={null}
        width={560}
        onCancel={() => setVisible(false)}
        closeIcon={
          <div className="flex items-center justify-center w-14 h-14">
            <div className="flex items-center justify-center w-8 h-8">
              <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
            </div>
          </div>
        }
      >
        <div className="signup-modal-content p-4">
          <SignupFormTitle />
          <SignupForm onSubmit={handleSignup} />
        </div>
      </Modal>
    </>
  );
};

const SignupFormTitle = () => {
  return (
    <>
      <div className="hidden xs:block">
        <h3 className="text-center" style={{ fontSize: 42, lineHeight: "140%" }}>
          Create Account
        </h3>
      </div>
      <div className="block xs:hidden">
        <h3 className="text-center text-2xl mb-4">Create Account</h3>
      </div>
    </>
  );
};

export const SignupForm = (props: { onSubmit: (values: any) => void }) => {
  const onFinish = (values: any) => {
    const birthday = values?.birthday?.format("YYYY-MM-DD") || "";
    const params = {
      password: values.password,
      username: values.username,
      birthday,
      sex: values.gender,
      email: values.email,
    };
    props.onSubmit(params);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
      style={{ width: "100%" }}
      className="login-form"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        dependencies={["password"]}
        rules={[
          { required: true, message: "Please input your password again!" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("The two passwords that you entered do not match!"));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item label="Gender" name="gender">
        <Select placeholder="select your gender">
          <Select.Option value={1}>Male</Select.Option>
          <Select.Option value={2}>Female</Select.Option>
          <Select.Option value={3}>secrecy</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Birthday" name="birthday">
        <DatePicker />
      </Form.Item>

      <Form.Item style={{ textAlign: "center", marginBottom: 0 }}>
        <Button className="login-button" type="primary" htmlType="submit">
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignupButton;
