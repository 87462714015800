import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title: "Putin to chair Russia Security Council meeting",
    link: "https://www.cnn.com/2022/10/09/europe/putin-russia-ukraine-security-council-intl/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009111200-16-kerch-bridge-crimea-1008-super-169.jpg",
    summary:
      "Russian President Vladimir Putin will hold an operational meeting of his Security Council on Monday, just two days after a massive explosion on a key strategic bridge linking Crimea and Russia.",
    published: "10/09/2022",
    tag: "europe",
  },
  {
    title: "Watch: Russian official admits to severity of Crimea bridge explosion",
    link: "https://www.cnn.com/videos/world/2022/10/09/russia-supply-line-crimea-bridge-explosion-aftermath-pleitgen-dnt-ndwknd-vpx.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009081250-crimea-explosion-aftermath-pleitgen-dnt-thumb-vpx-super-169.jpg",
    summary:
      "A large explosion heavily damaged the Kerch bridge that links Russia's mainland with annexed Crimea, in a devastating blow to Vladimir Putin's war effort in Ukraine. CNN's Fred Pleitgen reports on the aftermath.",
    published: "10/09/2022",
    tag: "world",
  },
  {
    title: "Russians trying to avoid being drafted pour into Kazakhstan",
    link: "https://www.cnn.com/2022/10/09/europe/russia-draft-dodgers-kazakhstan-intl-hnk/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009002717-06-russia-draft-dodgers-kazakhstan-intl-hnk-super-169.jpg",
    summary:
      "Vadim says he plunged into depression last month after Russian President Vladimir Putin announced a military draft to send hundreds of thousands of conscripts to fight in Ukraine.",
    published: "10/09/2022",
    tag: "europe",
  },
  {
    title: "Opinion: Biden's extraordinary comment",
    link: "https://www.cnn.com/2022/10/09/opinions/biden-eye-opening-warning-weekly-column-galant/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221006160113-01-biden-0906-super-169.jpg",
    summary: '"Can you tell me where we\'re headin\'?" Bob Dylan asks in his 1978 song "Señor."',
    published: "10/09/2022",
    tag: "opinions",
  },
  {
    title:
      "Colonoscopies may not reduce cancer deaths, study finds -- but experts say you should still get one",
    link: "https://www.cnn.com/2022/10/09/health/colonoscopy-cancer-death-study/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221007192334-endoscope-stock-super-169.jpg",
    summary:
      "• US breast cancer death rate drops 43% in three decades, but racial disparities remain, report finds",
    published: "10/09/2022",
    tag: "health",
  },
  {
    title:
      "Texas District Attorney names San Antonio officer who shot 17-year-old in a McDonald's parking lot",
    link: "https://www.cnn.com/2022/10/09/us/san-antonio-officer-named/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221007133615-san-antonio-cop-fired-teen-shooting-mcdonalds-super-169.jpg",
    summary: "• Video: Teen shot by police officer while eating in McDonald's parking lot",
    published: "10/09/2022",
    tag: "us",
  },
  {
    title: "Shooting outside Rep. Zeldin's home in NY; family unhurt",
    link: "https://www.cnn.com/2022/10/09/politics/lee-zeldin-shooting-new-york/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221009150953-lee-zeldin-file-super-169.jpg",
    summary:
      "A shooting on the Shirley, New York, property of Rep. Lee Zeldin on Sunday left two injured, the congressman and Republican gubernatorial candidate said in a statement. Zeldin's family was unhurt.",
    published: "10/10/2022",
    tag: "politics",
  },
  {
    title: "A landslide in Venezuela has killed at least 22 people and 50 others are missing",
    link: "https://www.cnn.com/2022/10/09/americas/venezuela-landslide-aragua/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009161802-01-venezuela-landslide-1009-super-169.jpg",
    summary:
      "A landslide in Venezuela on Sunday has killed at least 22 people and left more than 50 missing in the north central state of Aragua, according to officials.",
    published: "10/09/2022",
    tag: "americas",
  },
  {
    title: "Why many Floridians living in Ian's path can't count on insurance",
    link: "https://www.cnn.com/2022/10/09/us/hurricane-ian-central-florida-flood-insurance-invs/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221007125457-01-central-fl-flooding-ian-orlando-super-169.jpg",
    summary:
      "• Opinion: Why Ian killed so many people\n• DeSantis and Biden's joint appearance has shifted Florida's governor race",
    published: "10/09/2022",
    tag: "us",
  },
  {
    title:
      "Trump campaigns for Arizona election deniers in a state that will test his power in 2022 and beyond",
    link: "https://www.cnn.com/2022/10/09/politics/arizona-trump-rally-2022-election/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009132529-01-trump-rally-arizona-1009-super-169.jpg",
    summary:
      'There is perhaps nowhere in the country where former President Donald Trump had more success elevating his slate of "Make American Great Again" candidates into formidable 2022 contenders than Arizona, a state he narrowly lost in 2020 where he has relentlessly sought to overturn the presidential election results.',
    published: "10/10/2022",
    tag: "politics",
  },
  {
    title: "See the moment hackers took over a nightly news program",
    link: "https://www.cnn.com/videos/world/2022/10/09/iran-television-hacked-bashir-ndwknd-vpx.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221008230509-02-iran-state-broadcaster-hacked-intl-hnk-super-169.jpg",
    summary:
      "Iran's state broadcaster IRINN (Islamic Republic of Iran News Network) was allegedly hacked during its nightly news program, according to the pro-reform IranWire outlet, which shared a clip of the hacking. CNN reporter Nada Bashir has more.",
    published: "10/09/2022",
    tag: "world",
  },
  {
    title: "Opinion: Saudi Arabia and the UAE aren't vassal states of US",
    link: "https://www.cnn.com/2022/10/09/opinions/opec-us-ties-saudi-arabia-uae-greenblatt/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221007075735-opec-meeting-1005-super-169.jpg",
    summary:
      "After being offline for 25 hours observing Yom Kippur, I opened my phone, and the news reports flooded in. The most compelling story was OPEC+'s decision to reduce oil production significantly, which understandably caused much consternation in Washington.",
    published: "10/09/2022",
    tag: "opinions",
  },
  {
    title: "'Bulls**t': Sen. Tuberville's response on reparations",
    link: "https://www.cnn.com/videos/politics/2022/10/09/senator-tuberville-comments-reparations-crime-black-people-sotu-sot-vpx.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009103206-senator-tommy-tuberville-nevada-rally-head-on-1008-super-169.jpeg",
    summary:
      "Senator Tommy Tuberville (R-AL) addressed crime and reparations while speaking at a rally held by former President Trump in Minden, Nevada. CNN's Jake Tapper and the State of the Union panel discuss senator's racially charged comments.",
    published: "10/09/2022",
    tag: "politics",
  },
  {
    title: "Single-use plastic is wreaking havoc on the planet. Here's how to minimize your impact",
    link: "https://www.cnn.com/2022/09/16/us/plastic-recycling-climate-impact-lbg/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220915151748-01-single-use-plastic-super-169.jpg",
    summary:
      "The life cycle of plastic begins underground, where oil and gas are extracted from deep below the surface of the planet. These fossil fuels are then refined in facilities, using extreme temperatures and significant amount of water and energy, where they are transformed into pellets that are eventually melted and molded into things like water bottles, packaging, garbage bags and clothes.",
    published: "09/27/2022",
    tag: "us",
  },
  {
    title: "Bill Richardson on what he saw inside Russia",
    link: "https://www.cnn.com/videos/politics/2022/10/09/richardson-on-russia.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009122428-bill-richardson-thumb-sotu-vpx-super-169.jpg",
    summary:
      "Former Governor Bill Richardson tells CNN's Jake Tapper about his recent trip inside Russia to advocate for the release of detained Americans Brittney Griner and Paul Whelan.",
    published: "10/09/2022",
    tag: "politics",
  },
  {
    title: "Kanye West's Twitter account locked for antisemitic tweet",
    link: "https://www.cnn.com/2022/10/09/entertainment/kanye-west-twitter-account-locked/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221009164748-kanye-west-sept-12-super-169.jpg",
    summary:
      "Twitter locked rapper Kanye West's Twitter account over an anti-Semitic tweet posted on the account on Saturday.",
    published: "10/10/2022",
    tag: "entertainment",
  },
  {
    title: "Mark Cuban responds to Elizabeth Warren calling out 'his buddies' over tax law",
    link: "https://www.cnn.com/videos/business/2022/10/10/mark-cuban-elizabeth-warren-tax-law-politics-intv-wtcw-vpx.cnn",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221009202716-mark-cuban-super-169.jpg",
    summary:
      "Mark Cuban talks to CNN's Chris Wallace about Sen. Elizabeth Warren's (D-MA) comments about Cuban and \"his buddies\" paying their fair share in taxes and affecting tax law.",
    published: "10/10/2022",
    tag: "business",
  },
  {
    title:
      "Police say they're working 18- to 20-hour days in hopes of finding missing Ga. toddler alive",
    link: "https://www.cnn.com/2022/10/09/us/georgia-savannah-missing-toddler/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009135415-01-quinton-simon-missing-toddler-super-169.jpg",
    summary:
      "Authorities are scrambling to find a toddler who was last seen Wednesday morning at his home in Savannah, Georgia, according to Facebook posts from the Chatham County Police Department.",
    published: "10/09/2022",
    tag: "us",
  },
  {
    title: "Clive Davis reveals how he discovered Whitney Houston",
    link: "https://www.cnn.com/videos/entertainment/2022/10/10/whitney-houston-clive-davis-wtcw-intv-vpx-cprog.cnn",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/120211230734-wh-03-super-169.jpg",
    summary:
      "CNN's Chris Wallace sits down with Grammy-winning record producer Clive Davis about how he discovered Whitney Houston and what it was like hearing her perform for the first time.",
    published: "10/10/2022",
    tag: "entertainment",
  },
  {
    title:
      "NFL's new concussion protocol triggered Miami Dolphins QB Teddy Bridgewater's removal Sunday, team says",
    link: "https://www.cnn.com/2022/10/09/us/teddy-bridgewater-miami-dolphins-nfl-concussion-spt-intl/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009141423-teddy-bridgewater-sacked-by-sauce-gardner-1009-super-169.jpg",
    summary:
      "Miami Dolphins quarterback Teddy Bridgewater took a big hit Sunday and was unable to return to the game because of the new concussion protocol that went into effect the same day, making Bridgewater one of the first players to be sidelined under the new rules.",
    published: "10/09/2022",
    tag: "us",
  },
  {
    title: "A car was stolen with a toddler inside. See how bus drivers sprang to action",
    link: "https://www.cnn.com/videos/us/2022/10/08/bus-drivers-rescue-toddler-stolen-car-orig-jc.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221008143757-01-bus-drivers-rescue-toddler-orig-super-169.jpg",
    summary: "The bus drivers from Kentwood, Michigan, returned the 2-year-old boy to his parents.",
    published: "10/08/2022",
    tag: "us",
  },
  {
    title: "All the restaurants Tucci visited in season 2",
    link: "https://www.cnn.com/travel/article/stanley-tucci-searching-for-italy-restaurants-season-2/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221003131405-01-stanley-tucci-searching-for-italy-super-169.jpg",
    summary:
      'After watching the wanderlust-inducing "Stanley Tucci: Searching for Italy," you\'ll undoubtedly be hungry.',
    published: "10/10/2022",
    tag: "travel",
  },
  {
    title: "Italian town where half the population is called Tucci",
    link: "https://www.cnn.com/travel/article/tucci-marzi-homecoming-calabria-amanpour/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221006162229-tucci-marzi-2-super-169.jpg",
    summary:
      "What's it like visiting a town in Italy where more than half the population has the same name as you?",
    published: "10/07/2022",
    tag: "travel",
  },
  {
    title: "'Very emotional': Stanley Tucci returns to his ancestral homeland",
    link: "https://www.cnn.com/videos/world/2022/10/05/amanpour-stanley-tucci-searching-for-italy-season-two.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221006085944-amanpour-stanley-tucci-searching-for-italy-season-two-00045127-super-169.png",
    summary:
      'Stanley Tucci talks with Christiane Amanpour about his time in Calabria, his ancestral homeland, and his upcoming film. All-new episodes of "Stanley Tucci: Searching for Italy" air Sundays at 9 p.m. ET.',
    published: "10/06/2022",
    tag: "world",
  },
  {
    title: "Tucci shares the secret to classic Italian cooking",
    link: "https://www.cnn.com/videos/travel/2022/10/07/stanley-tucci-searching-for-italy-origseriesfilms-family-tips.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221007132323-tucci-intv-10-07-2022-super-169.jpg",
    summary:
      'While in southern Italy, Stanley Tucci made a feast with his Italian relatives. He learned the importance of sticking to traditional techniques. "Stanley Tucci: Searching for Italy" airs Sundays at 9 p.m. ET.',
    published: "10/07/2022",
    tag: "travel",
  },
  {
    title: "The Italian onions so delicious you can eat them raw",
    link: "https://www.cnn.com/travel/article/tropea-onions-cipolla-rossa/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220929104854-05-tropea-red-onions-restricted-super-169.jpg",
    summary:
      "Eating a plate of onions doesn't usually top anyone's food cravings or dreams there's one succulent, niche Italian variety that might make even die-hard no-onion eaters change their minds.",
    published: "10/08/2022",
    tag: "travel",
  },
  {
    title: "A look at who's who in the potential line of succession",
    link: "https://www.cnn.com/2022/04/17/media/murdoch-family-origseriesfilms/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/200528003240-rupert-murdoch-file-super-169.jpg",
    summary:
      "Over the past 70 years, media mogul Rupert Murdoch built a small Australian family newspaper company into arguably the most powerful conservative media empire in history. With a market cap of $13.50 billion, News Corp is one of the most influential companies on the planet.",
    published: "09/27/2022",
    tag: "media",
  },
  {
    title: "Rupert Murdoch's stinging rebuke that led his son to resign",
    link: "https://www.cnn.com/videos/business/2022/09/29/lachlan-murdoch-roger-ailes-fox-news-origseriesfilms.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220929142756-lachlan-murdoch-roger-ailes-fox-news-origseriesfilms-00001614-super-169.png",
    summary:
      'After a disagreement between Lachlan Murdoch and Roger Ailes, Rupert tells Ailes: "Don\'t worry about the boy." This rebuke leads Lachlan to leave the company and the country. "The Murdochs: Empire of Influence" air Sundays at 10 p.m. ET.',
    published: "09/29/2022",
    tag: "business",
  },
  {
    title: "In pictures: Media mogul Rupert Murdoch",
    link: "https://www.cnn.com/2022/09/22/world/gallery/rupert-murdoch/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220412183518-restricted-01a-rupert-murdoch-unf-super-169.jpg",
    summary: "Rupert Murdoch's business began in the 1950s with one local newspaper in Australia.",
    published: "09/22/2022",
    tag: "world",
  },
  {
    title: "Experts explain Murdoch's playbook",
    link: "https://www.cnn.com/videos/business/2022/09/16/rupert-murdoch-newspaper-power-origseriesfilms.cnn",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220916112612-rupert-murdoch-newspaper-power-origseriesfilms-00004719-super-169.png",
    summary:
      '"The Murdochs: Empire of Influence" explores one of the world\'s most powerful media families and it complicated history. The series premieres Sunday, September 25, at 9 p.m. ET only on CNN.',
    published: "09/22/2022",
    tag: "business",
  },
  {
    title: "Why the moon will look bigger tonight",
    link: "https://www.cnn.com/2022/10/08/world/full-moon-october-2022-scn/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220930150748-hunters-moon-october-2021-file-restricted-super-169.jpg",
    summary:
      "October's full moon is just around the corner and serves as a reminder: the eerie season is fast approaching.",
    published: "10/08/2022",
    tag: "world",
  },
  {
    title: "Dumpy, the giant frog that went viral on TikTok, is actually fake -- well, kinda",
    link: "https://www.cnn.com/2022/10/09/us/dumpy-huge-frog-tiktok-trnd/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009120842-dumpy-huge-frog-tiktok-super-169.jpg",
    summary:
      "A video of a massive, banana-guzzling frog, easily the size of its owner's head, garnered over 20 million views on TikTok. But it was all thanks to movie magic, according to its creator.",
    published: "10/09/2022",
    tag: "us",
  },
  {
    title: "Study shows Chick-fil-A has the slowest drive-thru. Here's why",
    link: "https://www.cnn.com/2022/10/09/business-food/drive-thru-rankings-fast-food-wendys-chick-fil-a/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221009121635-chick-fil-a-drive-thru-super-169.jpg",
    summary:
      "Every second counts in drive-thru lanes for fast food chains. A recent study showed Chick-fil-A had the slowest one — but only because it's so popular and there are so many cars in line.",
    published: "10/09/2022",
    tag: "business-food",
  },
  {
    title: "Customers are turning away from mainstream grocers",
    link: "https://www.cnn.com/2022/10/08/business/aldi-discount-grocery-inflation/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221007033030-01-aldi-discount-grocery-inflation-super-169.jpg",
    summary:
      "With grocery prices soaring, consumers are changing the way they shop for food. That's great news for discount grocers like bare-bones supermarket Aldi.",
    published: "10/08/2022",
    tag: "business",
  },
  {
    title: "The simple reason why Trader Joe's doesn't deliver",
    link: "https://www.cnn.com/2022/10/08/business/trader-joes-delivery/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221005214231-01-trader-joes-online-delivery-super-169.jpg",
    summary:
      "Online ordering is kind of a big thing these days, if you haven't heard yet, and just about every supermarket chain in America offers delivery and curbside pickup.",
    published: "10/08/2022",
    tag: "business",
  },
  {
    title: "Biden's student loan forgiveness application is coming soon. What you need to know",
    link: "https://www.cnn.com/2022/10/04/politics/student-loan-forgiveness-application-rules/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220113173514-02-graduation-caps-stock-super-169.jpg",
    summary:
      "The application for President Joe Biden's student loan forgiveness plan is expected to go live as soon as this week.",
    published: "10/07/2022",
    tag: "politics",
  },
  {
    title: "'Dahmer' debate is finally saying the quiet part about true crime out loud",
    link: "https://www.cnn.com/2022/10/07/opinions/jeffrey-dahmer-netflix-backlash-true-crime-stewart/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221007082411-02-dahmer-netflix-super-169.jpg",
    summary:
      "Has true crime jumped the shark? The heated debate over Netflix's new \"Dahmer - Monster: The Jeffrey Dahmer Story\" suggests an evolution is in order for the gruesome genre if it's going to survive.",
    published: "10/07/2022",
    tag: "opinions",
  },
  {
    title: "Fired professor shows what's wrong with academia",
    link: "https://www.cnn.com/2022/10/04/opinions/nyu-chemistry-professor-student-complaint-filipovic/index.html",
    image: "https://cdn.cnn.com/cnnnext/dam/assets/221004144550-nyu-campus-stock-super-169.jpeg",
    summary:
      "The dismissal of a renowned chemistry professor from NYU after a spate of student complaints about his teaching has reinvigorated a series of long-standing questions about the modern academy: Are academic standards dropping? Are professors and administrators too beholden to students' fragile emotions -- and their parents' tuition dollars? And what's wrong with kids these days, anyway?",
    published: "10/05/2022",
    tag: "opinions",
  },
  {
    title: "What 'fancy a pint?' really means",
    link: "https://www.cnn.com/2022/10/08/opinions/british-pub-culture-economic-crisis-uk-brown/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/221006115602-04-british-pub-history-super-169.jpg",
    summary:
      "Back in the 1990s, a new comedy sketch show on British TV laid bare a nation's habits and quirks.",
    published: "10/08/2022",
    tag: "opinions",
  },
  {
    title: "The GOP should never have bet on Herschel Walker",
    link: "https://www.cnn.com/2022/10/06/opinions/herschel-walker-scandal-georgia-senate-gop-duncan/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220526112110-herschel-walker-athens-speech-super-169.jpg",
    summary:
      "The October surprise involving Herschel Walker, the Republican nominee in Georgia's US Senate race, has upended the political landscape, throwing one of the nation's closest midterm races into turmoil five weeks before Election Day, but it never had to be this way. Just as there should not be two Democrats representing a center-right state like Georgia in the US Senate, the Republican Party should not have found its chance of regaining a Senate majority hanging on an untested and unproven first-time candidate.",
    published: "10/06/2022",
    tag: "opinions",
  },
  {
    title: "Biden's fist bump turned slap in the face",
    link: "https://www.cnn.com/2022/10/07/opinions/biden-mbs-oil-opec-fist-bump-andelman/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220715123924-01b-biden-mbs-fist-bump-super-169.jpg",
    summary:
      "So much for cozying up to the Saudis -- President Joe Biden's much-hyped fist bump with Mohammed bin Salman during a trip to the Middle East back in July has turned into something of a slap across the face from the crown prince.",
    published: "10/07/2022",
    tag: "opinions",
  },
  {
    title: "Putin's dream is headed toward an inglorious end",
    link: "https://www.cnn.com/2022/10/06/opinions/putin-make-russia-weak-history-bergen/index.html",
    image:
      "https://cdn.cnn.com/cnnnext/dam/assets/220929224044-01-putin-annexation-dangerous-for-the-world-analysis-super-169.jpg",
    summary:
      "Russian President Vladimir Putin had a plan to seize Ukraine quickly. Those plans dissolved from the first days of the Russians' invasion with their failure to capture Kyiv.",
    published: "10/06/2022",
    tag: "opinions",
  },
];

const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "cnn",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsCnn: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsCnn;
