import { IEmoji, IHomeDataEmoji, ISetInfoWithEmojis, IUserInfo } from "@/models";

export const HttpHost = "http://100.24.6.246/";

export const getIDFromUrl = () => {
  const search = new URLSearchParams(window.location.search);
  const id = search.get("id") || "";
  return id;
};

export const getHost = (url?: string) => {
  if (!url) {
    return "";
  }
  try {
    const u = new URL(url);
    return u.host;
  } catch (error) {
    return url;
  }
};

export const getUserName = (user: IUserInfo): string => {
  return user.nickname || user.username || user.email;
};

export const findMaxNumEmojiPath = (postEmojis: IHomeDataEmoji[], emojiList: IEmoji[]) => {
  const maxEmoji = findMaxNumEmoji(postEmojis, emojiList);
  return maxEmoji?.path || emojiList?.[0]?.path || "";
};

export const findMaxNumEmoji = (postEmojis: IHomeDataEmoji[], emojiList: IEmoji[]) => {
  let maxNum = -1;
  let maxCode = emojiList?.[0]?.code || "";
  postEmojis.forEach((item: IHomeDataEmoji) => {
    if (item.emoji_num > maxNum) {
      maxNum = item.emoji_num;
      maxCode = item.emoji_code;
    }
  });
  return emojiList.find((i) => i.code === maxCode);
};

export const setInfo2Map = (setInfo: ISetInfoWithEmojis | undefined) => {
  if (setInfo !== undefined) {
    let res: {
      [x: string]: IEmoji;
    } = {};
    setInfo.emojis.forEach((item) => {
      if (item.related) {
        res[item.related] = {
          ...item,
          code: item.related,
        };
      } else {
        let code = item.code;
        if (code.endsWith("_iOS")) {
          code = code.substring(0, code.length - 4);
        }
        res[code] = { ...item };
      }
    });
    return res;
  } else {
    return {};
  }
};
