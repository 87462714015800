import { Layout, message, Popover } from "antd";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import API from "@/apis";
import LoginButton from "@/layout/Header/Login";
import MenuButton from "@/layout/Header/MenuButton";
import SignupButton from "@/layout/Header/SignUp";
import {
  emojiGroupsState,
  emojiListState,
  officialEmojiGroupsState,
  switchedEmojiListState,
  userInfoState,
} from "@/store";

import UserImg from "@/components/UserImg";
import HeaderSearch from "@/layout/Header/HeaderSearch";
import PostNew from "../PostNew";
import HeaderBanner from "./HeaderBanner";
import { HomeEmoji } from "./HeaderEmoji";
import HeaderLogo from "./HeaderLogo";
import "./index.css";

import ICON_ABOUT from "@/assets/icons/icon-about.svg";
import ICON_FOLLOWING from "@/assets/icons/icon-following.png";
import ICON_HOME from "@/assets/icons/icon-home.svg";
import ICON_RANKING from "@/assets/icons/icon-ranking.png";
import useHome from "@/hooks/useHome";
import { ISetInfo } from "../../models/index";

const Header = () => {
  const location = useLocation();
  const setEmojiList = useSetRecoilState(emojiListState);
  const setSwitchedEmojiList = useSetRecoilState(switchedEmojiListState);
  const setEmojiGroups = useSetRecoilState(emojiGroupsState);
  const setOfficalEmojiGroups = useSetRecoilState(officialEmojiGroupsState);

  useEffect(() => {
    const fetchEmojiList = async () => {
      const [success, res] = await API.getEmojiList({ type: "1", group: "1" });
      if (success) {
        setEmojiList(res.data);
      }
    };
    const fetchSwitchedEmojiList = async () => {
      const [success, res] = await API.getEmojiList({ type: "1", group: "2" });
      if (success) {
        setSwitchedEmojiList(res.data);
      }
      API.getEmojiList({ type: "2" });
    };

    const fetchGroups = async () => {
      const res = await API.getEmojiGroups();
      if (Array.isArray(res?.data)) {
        const promiseList = res.data
          .filter((i: any) => +i.id !== 1 && +i.id !== 2)
          .map((item: ISetInfo) => {
            return API.getEmojiListByGroup(item);
          });

        const officialEmojiGroups = res.data.filter((i: any) => +i.id === 1 || +i.id === 2);
        setOfficalEmojiGroups(officialEmojiGroups);

        const resList = await Promise.all(promiseList);
        setEmojiGroups(resList);
      }
    };
    fetchEmojiList();
    fetchSwitchedEmojiList();
    fetchGroups();
  }, [setEmojiList, setSwitchedEmojiList, setEmojiGroups, setOfficalEmojiGroups]);

  const isHideHomeEmoji =
    location.pathname === "/profile" ||
    location.pathname === "/about-us" ||
    location.pathname === "/ranking/creators" ||
    location.pathname === "/ranking/emoji" ||
    location.pathname === "/ranking/collection" ||
    location.pathname === "/ranking/commented" ||
    location.pathname === "/ranking/pick" ||
    location.pathname === "/search" ||
    location.pathname === "/following" ||
    location.pathname.startsWith("/settings") ||
    location.pathname.startsWith("/ranking") ||
    location.pathname.startsWith("/detail") ||
    location.pathname.startsWith("/news-detail") ||
    location.pathname.startsWith("/user");

  const canHideMainHeader =
    location.pathname === "/profile" ||
    location.pathname === "/search" ||
    location.pathname.startsWith("/user") ||
    location.pathname.startsWith("/settings");

  const isShowHomeBanner = location.pathname === "/";

  return (
    <Layout.Header
      className="flex flex-col"
      style={{
        height: "inherit",
        padding: 0,
      }}
    >
      <MainHeader canHideMainHeader={canHideMainHeader} />
      {isShowHomeBanner && <HeaderBanner />}
      {isHideHomeEmoji ? null : <HomeEmoji />}
    </Layout.Header>
  );
};

const MainHeader = (props: { canHideMainHeader: boolean }) => {
  const { clearHomeState } = useHome();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const location = useLocation();
  const navigate = useNavigate();
  const isHideSearch = location.pathname === "/me";

  React.useEffect(() => {
    const fetch = async () => {
      const data = await API.getUserInfo();
      setUserInfo(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginSuccess = async () => {
    // setIsLogin(true);
    const data = await API.getUserInfo();
    setUserInfo(data);
    message.success("Login success!");
  };

  const handleSignupSuccess = () => {
    // setIsLogin(true);
    message.success("Sign up success!");
  };

  const handleGoProfile = () => {
    navigate("/profile");
  };

  const mainCls =
    "h-[56px] sm:h-[80px] max-w-[1264px] mx-auto items-center justify-between px-2 no-dark-mode";

  return (
    <div className="relative border-0 border-b border-[#F4F4F5]">
      <div
        className={`${mainCls} ${props.canHideMainHeader ? "hidden xs:flex" : "flex"}`}
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div className="flex items-center flex-1">
          <HeaderLogo />

          <div className="hidden sm:block w-[1px] bg-[#E6E8EC] h-12 mr-1"></div>

          <div className="flex items-center mt-1">
            <Popover overlayClassName="menu-popover" content="Home">
              <Link to="/" onClick={clearHomeState} className="hidden lg:block">
                <div className="flex items-center justify-center h-11 w-11">
                  <img
                    src={ICON_HOME}
                    alt="about"
                    className="w-5 transition duration-300 ease-in-out hover:scale-125"
                  />
                </div>
              </Link>
            </Popover>
            <Popover overlayClassName="menu-popover" content="Following">
              <Link to="/following" className="hidden lg:block">
                <div className="flex items-center justify-center h-11 w-11">
                  <img
                    src={ICON_FOLLOWING}
                    alt="about"
                    className="w-[22px] transition duration-300 ease-in-out hover:scale-125"
                  />
                </div>
              </Link>
            </Popover>
            <Popover overlayClassName="menu-popover" content="Ranking">
              <Link to="/ranking" className="hidden lg:block">
                <div className="flex items-center justify-center h-11 w-11">
                  <img
                    src={ICON_RANKING}
                    alt="about"
                    className="w-8 transition duration-300 ease-in-out hover:scale-125"
                  />
                </div>
              </Link>
            </Popover>
            <Popover overlayClassName="menu-popover" content="Info">
              <Link to="/about-us" className="hidden lg:block">
                <div className="flex items-center justify-center h-11 w-11">
                  <img
                    src={ICON_ABOUT}
                    alt="about"
                    className="w-5 transition duration-300 ease-in-out hover:scale-125"
                  />
                </div>
              </Link>
            </Popover>
          </div>
        </div>
        {isHideSearch ? null : <HeaderSearch />}

        <div className="h-12 flex items-center flex-1 justify-end">
          {userInfo?.id ? (
            <>
              <div className="hidden md:flex">
                <PostNew />
              </div>
              <Popover overlayClassName="menu-popover" content="Profile">
                <div
                  className="w-12 h-12 hidden sm:flex items-center justify-center cursor-pointer"
                  onClick={handleGoProfile}
                >
                  <UserImg
                    style={{ minWidth: 28 }}
                    className="rounded-full overflow-hidden w-7 h-7"
                    src={userInfo.avatar}
                    name={userInfo.nickname || userInfo.username}
                  />
                </div>
              </Popover>
            </>
          ) : (
            <div className="h-12 hidden md:flex text-base items-center ">
              <PostNew />
              <LoginButton onLoginSuccess={handleLoginSuccess} />
              <SignupButton onSignupSuccess={handleSignupSuccess} />
            </div>
          )}
          <div className="lg:hidden pl-4 flex items-center">
            <MenuButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
