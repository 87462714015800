import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title: "Jamie Lee Curtis, Sarah Silverman, and Josh Gad lead celebrities denouncing Kanye West",
    link: "https://www.dailymail.co.uk/news/article-11296623/Real-Housewives-star-Lizzy-Savetsky-leads-backlash-Kanye-West-antisemitic-tweets.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Comedian Sarah Silverman has spoken out on the lack of outrage from non-Jews following Kanye West's anti-Semitic statements on Twitter by declaring: 'The silence is so loud.'",
    published: "10/10/2022",
    credit: "Pichichipixx / SplashNews.com / Elizabeth Savetsky Instagram / INSTAGRAM",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63286769-0-image-a-11_1665353168022.jpg",
    tag: "celebrity",
  },
  {
    title:
      "PICTURED: New 50p coin featuring Queen Elizabeth II is released to mark the BBC's centenary",
    link: "https://www.dailymail.co.uk/news/article-11297819/PICTURED-New-50p-coin-featuring-Queen-Elizabeth-II-released-mark-BBCs-centenary.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "It is a gleaming tribute to Britain's national broadcaster, depicting a mast emerging from the Earth. On the flip side of this commemorative coin is another special homage - to the late Queen.",
    published: "10/10/2022",
    credit: "PA",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/00/63288665-0-image-a-75_1665358389245.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Lena Headey is MARRIED! Game Of Thrones actress exchanges vows with Ozark star Marc Menchaca",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11297749/Lena-Headey-MARRIED-Game-Thrones-actress-exchanges-vows-Ozark-star-Marc-Menchaca.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "In photos circulating across social media, Headey, 49, looks radiant in a white bridal gown and veil while walking alongside her new husband.",
    published: "10/10/2022",
    credit: "FilmMagic for HBO",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/03/63291693-0-image-m-73_1665368283716.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Historians fear smaller Coronation could 'waste opportunity to advertise Britain on a global stage'",
    link: "https://www.dailymail.co.uk/news/article-11297291/Historians-fear-smaller-Coronation-waste-opportunity-advertise-Britain-global-stage.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Historians said it could be a lost opportunity to exert the 'soft power' of the monarchy, as scenes of pomp and pageantry would draw the eyes of the world to Britain.",
    published: "10/10/2022",
    credit: "Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63285207-0-image-m-19_1665350756783.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Truss to meet with restive MPs this week in 'concerted effort' to get her premiership back on track",
    link: "https://www.dailymail.co.uk/news/article-11297327/Truss-meet-restive-MPs-week-concerted-effort-premiership-track.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "After the Conservatives' annual conference was marred by infighting, rebellions and U-turns, the Prime Minister wants to use the return of Parliament from recess to settle nerves.",
    published: "10/10/2022",
    credit: "REUTERS",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284765-0-image-a-9_1665348343904.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Netflix sparks fury with plans to show Prince Philip 'pursuing an affair' with friend in The Crown",
    link: "https://www.dailymail.co.uk/news/article-11297891/Netflix-sparks-fury-plans-Prince-Philip-pursuing-affair-friend-Crown.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The hit show, which is one of the streaming giant's most popular programmes, is set to return to screens on November 9 with concern from some royal observers about this season's content.",
    published: "10/10/2022",
    credit:
      "David Hartley/REX/Shutterstock / Rob Latour/Variety/REX/Shutterstock / Click News & Photos / SplashNews.com / MICHAEL DUNLEA",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/01/63289975-0-image-a-45_1665362248799.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Just Stop Oil: Met slam activists for causing 'unreasonable disruption' during London protests",
    link: "https://www.dailymail.co.uk/news/article-11297951/Just-Stop-Oil-Met-slam-activists-causing-unreasonable-disruption-London-protests.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The force said it had to put a 'significant' policing operation to deal with the eco-warriors, who blocked roads in the capital over the weekend as part of a month of climate protests.",
    published: "10/10/2022",
    credit: "AFP via Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/02/63290525-0-image-a-4_1665363928218.jpg",
    tag: "celebrity",
  },
  {
    title: "Madonna suggests she is gay in playful TikTok video",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11297605/Madonna-suggests-gay-playful-TikTok-video.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Madonna teased her fans with a new TikTok video on Sunday, where she playfully hinted at being gay.",
    published: "10/10/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63287641-0-image-m-15_1665355034814.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Prince Harry's tell-all memoir will 'never see the light of day', claims former Vanity Fair editor",
    link: "https://www.dailymail.co.uk/news/article-11297903/Prince-Harrys-tell-memoir-never-light-day-claims-former-Vanity-Fair-editor.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Royal biographer Tina Brown, author of The Diana Chronicles and The Palace Papers, does not think the contentious biography, due to be released this autumn, will ever 'see the light of day'.",
    published: "10/10/2022",
    credit: "Dave Benett/Getty Images / WireImage",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/02/63290777-0-image-a-9_1665365295989.jpg",
    tag: "celebrity",
  },
  {
    title: "Putin to sit down with security chiefs TODAY to plot revenge for Crimea bridge blast",
    link: "https://www.dailymail.co.uk/news/article-11297917/Putin-sit-security-chiefs-TODAY-plot-revenge-Crimea-bridge-blast.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Putin's former adviser Sergei Markov, has urged his former chief to 'punish' Ukraine as well as its allies, following the destruction of the Kerch bridge in Crimea.",
    published: "10/10/2022",
    credit: "EPA",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/02/63282175-0-image-a-30_1665364377625.jpg",
    tag: "celebrity",
  },
  {
    title: "Post Office reports record handling of £3.45bn in cash in August",
    link: "https://www.dailymail.co.uk/news/article-11297911/Post-Office-reports-record-handling-3-45bn-cash-August.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The cost of living crisis has sparked a return to the use of cash, figures from the Post Office revealed yesterday.",
    published: "10/10/2022",
    credit: "Shutterstock / shawnwil23",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/01/63289747-0-image-a-3_1665361564201.jpg",
    tag: "celebrity",
  },
  {
    title:
      "GCSE exam halls are ditching analogue clocks because children are struggling to read the time",
    link: "https://www.dailymail.co.uk/news/article-11297885/GCSE-exam-halls-ditching-analogue-clocks-children-struggling-read-time.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Education leaders say digital clocks on smartphones are the main driver behind youngsters losing the skill. Teachers worry that pupils could miscalculate the time left during exams.",
    published: "10/10/2022",
    credit: "Shutterstock / STILLFX",
    image:
      "https://i.dailymail.co.uk/1s/2022/10/10/01/63289535-0-Children_have_to_learn_to_tell_the_time_using_an_analogue_clock_-a-51_1665360723312.jpg",
    tag: "celebrity",
  },
  {
    title: "Oxbridge colleges ramp up 'unconscious bias' and 'race workshops' for freshers",
    link: "https://www.dailymail.co.uk/news/article-11297883/Oxbridge-colleges-ramp-unconscious-bias-race-workshops-freshers.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Freshers at Keble College (pictured above) had to undertake hours of courses on consent, race, LGBTQ+ and disability.",
    published: "10/10/2022",
    credit: "Alamy Stock Photo",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/01/63289951-0-image-a-67_1665362449019.jpg",
    tag: "celebrity",
  },
  {
    title:
      "The FA are set to review an on-field confrontation between Arsenal and Liverpool players",
    link: "https://www.dailymail.co.uk/sport/football/article-11297285/The-FA-set-review-field-confrontation-Arsenal-Liverpool-players.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The FA are probing a angry on-field confrontation between Arsenal and Liverpool players during Sunday's pulsating clash.",
    published: "10/10/2022",
    credit: "Liverpool FC via Getty Images / Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63285061-0-image-a-82_1665349850206.jpg",
    tag: "celebrity",
  },
  {
    title: "Woman's body is found by dog walker six hours after car 'ploughed into tree'",
    link: "https://www.dailymail.co.uk/news/article-11297645/Womans-body-dog-walker-six-hours-car-ploughed-tree.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Police were called to the scene after a car left the road, ploughed through a fence and collided with a tree in the early hours of Saturday morning.",
    published: "10/10/2022",
    credit: "StaffordshireLive/BPM Media",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/00/63288581-0-image-a-8_1665357851797.jpg",
    tag: "celebrity",
  },
  {
    title: "DAILY MAIL COMMENT: The woke brigade have hijacked academia",
    link: "https://www.dailymail.co.uk/news/article-11297635/DAILY-MAIL-COMMENT-woke-brigade-hijacked-academia.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Time was when our universities were strongholds of free speech and academic rigour. These days, sadly, they more closely resemble ugly factories of ideological conformism.",
    published: "10/10/2022",
    credit: "Shutterstock / Enrico Della Pietra",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/00/63287825-0-image-a-7_1665356607150.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Number of dog attack victims needing hospital treatment in England soars by 18% in a year",
    link: "https://www.dailymail.co.uk/news/article-11297915/Number-dog-attack-victims-needing-hospital-treatment-England-soars-18-year.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Total admissions for dog bites, which included injuries not needing surgery, also rose. It meant hospitals in England dealt with 8,819 cases in 2021-22.",
    published: "10/10/2022",
    credit: "Shutterstock / otsphoto",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/01/63289739-0-image-a-5_1665361581256.jpg",
    tag: "celebrity",
  },
  {
    title: "Homes are evacuated after 'suspicious packages' were found at pub in Bristol",
    link: "https://www.dailymail.co.uk/news/article-11296743/Homes-evacuated-suspicious-packages-pub-Bristol.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Police were called to The Three Lions pub in Bedminster at 12.40pm on Sunday following reports of 'suspicious packages' being left at the property. They issued an evacuation order for the area.",
    published: "10/10/2022",
    credit: "Tom Wren SWNS.com",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/16/63279739-0-image-a-51_1665330866376.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Huge landslide in Venezuela kills four while at least 50 people are believed to be missing",
    link: "https://www.dailymail.co.uk/news/article-11297157/Huge-landslide-Venezuela-kills-four-50-people-believed-missing.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "A huge landslide has killed at least four people and 50 are believed to be missing after the El Pato River in central Venezuela flooded following heavy rains. The river flooded and swept away several houses.",
    published: "10/10/2022",
    credit: "AP",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/20/63282977-0-image-a-38_1665344121012.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Desperate pet owners want to track down mystery cat killer after spate of 'up to 13' poisonings",
    link: "https://www.dailymail.co.uk/news/article-11296965/Desperate-pet-owners-want-track-mystery-cat-killer-spate-13-poisonings.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Leanne Shaw, 30, is trying to catch a mystery cat killer after a spate of 'up to 13' poisonings took place in 'a very small area of Flint' in north Wales - including her own, Binx.",
    published: "10/10/2022",
    credit: "Leanne Shaw/WALES NEWS SERVICE",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/18/63281187-0-image-a-23_1665337262084.jpg",
    tag: "celebrity",
  },
  {
    title: "DOMINIC LAWSON: Our Government SHOULD be telling us how to use less energy",
    link: "https://www.dailymail.co.uk/debate/article-11297869/DOMINIC-LAWSON-Government-telling-use-energy.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Rees-Mogg had signed off on a £15m public information campaign to inform how to save energy as we head into winter. But Truss blocked the scheme, 'because we are not a nanny state government'.",
    published: "10/10/2022",
    credit: "Anadolu Agency via Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/01/63289173-0-image-a-34_1665360061155.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Tory MP sacked for allegedly 'touching a man's thigh' at hotel bar is 'in line for a knighthood'",
    link: "https://www.dailymail.co.uk/news/article-11297895/Tory-MP-sacked-allegedly-touching-mans-thigh-hotel-bar-line-knighthood.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Minister Conor Burns who was sacked over allegations of misconduct is said to be in line for a knighthood in Boris Johnson's resignation honours.",
    published: "10/10/2022",
    credit: "PA",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/01/63289967-0-image-a-16_1665362216350.jpg",
    tag: "celebrity",
  },
  {
    title: "Labour accused of flip-flopping over allowing workers to keep new income tax cut",
    link: "https://www.dailymail.co.uk/news/article-11297897/Labour-accused-flip-flopping-allowing-workers-new-income-tax-cut.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Leader Sir Keir Starmer previously said that it was 'correct' for the Government to reduce the basic rate from 20p to 19p next year, a measure announced in Kwasi Kwarteng's mini-Budget.",
    published: "10/10/2022",
    credit: "PA",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/01/63289125-0-image-a-84_1665360112556.jpg",
    tag: "celebrity",
  },
  {
    title: "Strictly's Tony Adams' sexy samba boosts viewing figures to over 8.3Million",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11297757/Strictlys-Tony-Adams-sexy-samba-boosts-viewing-figures-8-3Million.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "An average of 7.6M watched the seres' latest episode however figures peaked during the 55-year-old's sexy samba.",
    published: "10/10/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/08/63271767-0-image-a-16_1665301727616.jpg",
    tag: "celebrity",
  },
  {
    title:
      "In 1960, the decade that convulsed the nation saw a revolution in food, culture and sex",
    link: "https://www.dailymail.co.uk/news/article-11297741/In-1960-decade-convulsed-nation-saw-revolution-food-culture-sex.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "During the early days of the M1 - Britain's first significant motorway, which had opened in 1959 - it had no crash barriers, lighting, or hard shoulder, and very little traffic. There was also no speed limit.",
    published: "10/10/2022",
    credit: "Tony Buckingham / ANL/Shutterstock / Popperfoto via Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/00/63288831-0-image-a-24_1665358696938.jpg",
    tag: "celebrity",
  },
  {
    title:
      "William Shatner says going to space on Blue Origin rocket filled him with 'overwhelming sadness'",
    link: "https://www.dailymail.co.uk/news/article-11297145/William-Shatner-says-going-space-Blue-Origin-rocket-filled-overwhelming-sadness.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "William Shatner, 91, said that seeing Earth from space aboard Jeff Bezos' Blue Origin rocket last fall was a profoundly saddening experience.",
    published: "10/10/2022",
    credit: "Blue Origin / Getty Images / Richard Shotwell/Invision/AP",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63283399-0-image-a-11_1665345728748.jpg",
    tag: "celebrity",
  },
  {
    title: "Women ARE choosing careers over having children as new study reveals",
    link: "https://www.dailymail.co.uk/news/article-11297761/Women-choosing-careers-having-children-new-study-reveals.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Women in Britain who want to have children are delaying pregnancy principally to focus on their careers, a study has found. Factors such as financial strain were found to be less important.",
    published: "10/10/2022",
    credit: "Alamy Stock Photo",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/00/63288461-0-image-a-6_1665358423293.jpg",
    tag: "celebrity",
  },
  {
    title:
      "75 brain-damaged former rugby league players to launch legal action against the governing body",
    link: "https://www.dailymail.co.uk/sport/rugbyleague/article-11297527/75-brain-damaged-former-rugby-league-players-launch-legal-action-against-governing-body.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Former Great Britain and England scrum-half Bobbie Goulding is among the stars suing the Rugby Football League over an alleged failure to protect them from brain injuries caused by concussion.",
    published: "10/10/2022",
    credit: "Ian Hodgson",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63285941-0-image-m-15_1665352167755.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Mental health patients are calling 999 as almost a quarter wait more than THREE MONTHS for treatment",
    link: "https://www.dailymail.co.uk/news/article-11297811/Mental-health-patients-calling-999-quarter-wait-THREE-MONTHS-treatment.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The Royal College of Psychiatrists (RCP). is calling for medical school places to rise from 7,000 to 15,000 by 2029 to tackle a shortage of psychiatrists.",
    published: "10/10/2022",
    credit: "Shutterstock / Stock-Asso",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/00/63288669-0-image-a-31_1665358325233.jpg",
    tag: "celebrity",
  },
  {
    title: "Mum-of-two shares the top five rules her children's partners will have to follow",
    link: "https://www.dailymail.co.uk/femail/parenting/article-11297415/Mum-two-shares-five-rules-childrens-partners-follow.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Aussie mum and TikTok star Kat Zoe Clark listed the dating rules she has set in place for her two daughters. Her oldest daughter Latisha is 19 and has started dating.",
    published: "10/10/2022",
    credit: "kat_clark_ TikTok / kat_clark_ Instagram",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63287711-0-image-a-12_1665355152672.jpg",
    tag: "celebrity",
  },
  {
    title: "Eating disorders in the over-60s DOUBLE while self-harm incidents soar by up to 50%",
    link: "https://www.dailymail.co.uk/news/article-11297699/Eating-disorders-60s-DOUBLE-self-harm-incidents-soar-50.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Hospital admissions for eating disorders have doubled among women over-60 since 2015, says Age UK. The number of self-harming incidents are up by almost 50 per cent in the same period.",
    published: "10/10/2022",
    credit: "Shutterstock / Inside Creative House",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/00/63288585-0-image-a-18_1665357838013.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Who gets the ring after a break up? Woman sparks debate after selling her Tiffany engagement ring",
    link: "https://www.dailymail.co.uk/femail/relationships/article-11297511/Who-gets-ring-break-Woman-sparks-debate-selling-Tiffany-engagement-ring.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "A woman has sparked a heating debate online after listing her $23,600 Tiffany engagement ring for sale following a relationship break up.",
    published: "10/10/2022",
    credit: "Facebook",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63288073-0-image-a-1_1665356063840.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Katie Piper's acid attacker goes on the run after being recalled to prison for breaching licence",
    link: "https://www.dailymail.co.uk/news/article-11297667/Katie-Pipers-acid-attacker-goes-run-recalled-prison-breaching-licence.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Stefan Sylvestre, from Shepherd's Bush, west London, was released on parole in 2018, at age 30, but has now been warned that he faces more time in jail after going missing while on licence.",
    published: "10/10/2022",
    credit: "Dave Benett/Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/10/00/63288659-0-image-m-50_1665359482993.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Sprinkle CARROTS on your children's Kellogg's: Latest advice warns kids not eating enough vegetables",
    link: "https://www.dailymail.co.uk/news/article-11297611/Sprinkle-CARROTS-childrens-Kelloggs-Latest-advice-warns-kids-not-eating-vegetables.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Grating carrot into porridge or adding spinach to a morning smoothie might sound a little unappetising. But ideas like this could help parents incorporate vegetables into their children's breakfasts.",
    published: "10/10/2022",
    credit: "Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63287531-0-image-a-38_1665354727633.jpg",
    tag: "celebrity",
  },
  {
    title:
      "NSC spokesperson John Kirby says Kim Jong-un has REJECTED Washington's proposal for talks",
    link: "https://www.dailymail.co.uk/news/article-11294927/North-Korea-fires-missiles-eight-two-weeks.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Kim Jong-un has refused to come to the negotiation table with the U.S. despite Washington offering talks with no preconditions, according to the National Security Council.",
    published: "10/10/2022",
    credit: "ABC / AFP via Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63281465-0-image-a-22_1665338439131.jpg",
    tag: "celebrity",
  },
  {
    title: "Hurricane Julia hits Nicaragua with torrential rainfall",
    link: "https://www.dailymail.co.uk/news/article-11295979/Hurricane-Julia-bears-Nicaraguas-Caribbean-coast.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Hurricane Julia hit Nicaragua's central Caribbean coast on Sunday after lashing Colombia's San Andres island. Julia hit as a Category 1 hurricane.",
    published: "10/10/2022",
    credit: "REUTERS / NOAA/AFP via Getty Images / AP",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282719-0-image-a-47_1665341756546.jpg",
    tag: "celebrity",
  },
  {
    title: "Conor Benn's doctor faces probe over claims boxers can dope and beat tests",
    link: "https://www.dailymail.co.uk/sport/boxing/article-11297231/Conor-Benns-doctor-faces-probe-claims-boxers-dope-beat-tests.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "EXCLUSIVE: The BBBoC will investigate comments made last year by a doctor who worked with Conor Benn in which he discussed how it would be possible for a boxer to dope",
    published: "10/10/2022",
    credit: "Action Images via Reuters",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63287223-0-image-a-5_1665354108670.jpg",
    tag: "celebrity",
  },
  {
    title:
      "King Charles wants to continue his work as a 'peace broker' amid recent tensions in Leicester",
    link: "https://www.dailymail.co.uk/news/article-11297301/King-Charles-wants-continue-work-peace-broker-amid-recent-racial-tensions-Leicester.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The King plans to continue his work as a 'peace broker'. As Prince of Wales, Charles devoted much of his energy to promoting interfaith harmony in the UK and abroad.",
    published: "10/10/2022",
    credit: "POOL/AFP via Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284391-0-image-a-49_1665347411277.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Celebrity SAS: Who Dares Wins 'thrown into chaos as six stars are forced to quit on medical grounds'",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11297491/Celebrity-SAS-Dares-Wins-thrown-chaos-six-stars-forced-quit-medical-grounds.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The upcoming series of Celebrity SAS: Who Dares Wins has been plunged into chaos after six stars were forced to pull out on medical grounds.",
    published: "10/10/2022",
    credit: "Ken McKay/ITV/Shutterstock / PA / Alberto Terenghi/ip/Shutterstock",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63287455-0-image-a-132_1665354565626.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Chris Fountain reveals he was left speaking 'like a child' after suffering terrifying mini-stroke",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11297239/Chris-Fountain-reveals-left-speaking-like-child-suffering-terrifying-mini-stroke.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The actor was admitted to Hackney's Homerton University Hospital following the collapse in August, after which he was left speaking 'like a toddler' and unable to articulate his words.",
    published: "10/10/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63287039-0-image-a-45_1665353690568.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Plans to rename Oxford College after a billionairess in exchange for £155m donation 'are in doubt'",
    link: "https://www.dailymail.co.uk/news/article-11296765/Plans-rename-Oxford-College-billionairess-exchange-155m-donation-doubt.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "A University of Oxford college's name change is in doubt after the first payment of a £155million donation pledged by a Vietnamese billionaire didn't arrive.",
    published: "10/10/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/16/49976549-0-image-m-8_1665328740879.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Idris Elba and Kit Harington lead the stars as the NFL game at Tottenham Hotspur Stadium",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11296861/Idris-Elba-Kit-Harington-lead-stars-NFL-game-Tottenham-Hotspur-Stadium.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The Luther actor, 50, cheered from the stands as the New York Giants took on the Green Bay Packers before touring the pitch.",
    published: "10/10/2022",
    credit: "PA / USA TODAY Sports",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/18/63280493-0-image-a-29_1665335266450.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Hammersmith Apollo is evacuated during performance by Iranian pop singer after 'bomb threat'",
    link: "https://www.dailymail.co.uk/news/article-11297435/Hammersmith-Apollo-evacuated-performance-Iranian-pop-singer-bomb-threat.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "People attending a concert by Dariush Eghbali were told to leave the famous arena tonight after a 'credible threat of a potential bomb', it is being reported.",
    published: "10/10/2022",
    credit: "Twitter",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63285521-0-image-m-38_1665355831199.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Joleon Lescott urges Premier League players to continue taking the knee to 'keep an impact'",
    link: "https://www.dailymail.co.uk/sport/football/article-11297093/Joleon-Lescott-urges-Premier-League-players-continue-taking-knee-impact.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Players in the Premier League performed the gesture before matches this weekend after games were signposted as dedicated 'No Room For Racism' fixtures across the top flight.",
    published: "10/10/2022",
    credit: "PA / Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282547-0-image-a-42_1665341088865.jpg",
    tag: "celebrity",
  },
  {
    title: "Princess Mary attends UNICEF event as the Danish royal family meet for 'peace talks'",
    link: "https://www.dailymail.co.uk/femail/real-life/article-11297365/Princess-Mary-attends-UNICEF-event-Danish-royal-family-meet-peace-talks.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Princess Mary pushed royal tension aside to attend the 60th anniversary of the UNICEF Supply Division in Copenhagen.",
    published: "10/09/2022",
    credit: "Ritzau Scanpix/AFP via Getty Ima / EPA",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/23/63285717-0-image-a-145_1665353453318.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Carles Puyol apologises for his role in Iker Casillas Twitter storm after legend 'joked' he was gay",
    link: "https://www.dailymail.co.uk/sport/football/article-11296977/Carles-Puyol-apologises-role-Iker-Casillas-Twitter-storm-legend-joked-gay.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Puyol has admitted his response to Iker Casillas' now-deleted Tweet was 'totally out of place' and insisted he 'supports and respects the LGBTQ+ community'.",
    published: "10/09/2022",
    credit: "Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282297-0-image-a-23_1665340314864.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Delightful new photos show Mrs Johnson with her children as Boris's shares never-before-seen snaps",
    link: "https://www.dailymail.co.uk/news/article-11297375/Delightful-new-photos-Mrs-Johnson-children-Boriss-shares-never-seen-snaps.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "A series of black and white photographs offers a glimpse into Carrie Johnson's playful relationship with her two children. Four unseen pictures show Mrs Johnson on a blanket.",
    published: "10/09/2022",
    credit: "Andrew Parsons / No10 Downing Street",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63285025-0-image-a-6_1665348893278.jpg",
    tag: "celebrity",
  },
  {
    title: "Man shot dead at Derby police station wielding 'samurai-like sword' is named",
    link: "https://www.dailymail.co.uk/news/article-11297057/Man-shot-dead-Derby-police-station-wielding-samurai-like-sword-named.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Marius Ciolac, 35, was gunned down on Friday, October 7, after breaching the entrance to the car park of the Ascot Drive police station in Derby.",
    published: "10/09/2022",
    credit: "Tom Maddick / SWNS",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282241-0-image-a-11_1665340892984.jpg",
    tag: "celebrity",
  },
  {
    title: "JAN MOIR: I hope Jeremy Clarkson wins the war of Diddly Squat",
    link: "https://www.dailymail.co.uk/femail/article-11297461/JAN-MOIR-hope-Jeremy-Clarkson-wins-war-Diddly-Squat.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "JAN MOIR: Diddly Squat has become world famous after featuring in the Amazon Prime series Clarkson's Farm, which charts the agri-disasters that befall the celebrity farmer.",
    published: "10/09/2022",
    credit: "Amazon",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63286013-0-image-m-24_1665352153130.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Universities adopt stricter vetting rules for speakers and suggest trigger warnings and safe spaces",
    link: "https://www.dailymail.co.uk/news/article-11297519/Universities-adopt-stricter-vetting-rules-speakers-suggest-trigger-warnings-safe-spaces.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "A record 200 requests for events and speakers were rejected by universities and colleges in 2020-21, more than double the previous year.",
    published: "10/09/2022",
    credit: "Ken McKay/ITV/Shutterstock",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63252099-0-image-m-19_1665352647876.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Huge nuclear power plant in Ukraine is RECONNECTED to the grid after shelling raised Chernobyl fears",
    link: "https://www.dailymail.co.uk/news/article-11297217/Huge-nuclear-power-plant-Ukraine-RECONNECTED-grid-shelling-raised-Chernobyl-fears.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "There had been fears that the Zaporizhzhia atomic energy plant in the southeast of the country could go into meltdown after it lost its last source of external power yesterday.",
    published: "10/09/2022",
    credit: "AP",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284169-0-image-a-4_1665347065420.jpg",
    tag: "celebrity",
  },
  {
    title:
      "From Di to Chatterley, but Emma's romp is all too tame: BRIAN VINER reviews Lady Chatterley's Lover",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11297395/From-Di-Chatterley-Emmas-romp-tame-BRIAN-VINER-reviews-Lady-Chatterleys-Lover.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "BRIAN VINER: To say screen adaptations of Lady Chatterley's Lover are ten a penny might be stretching a point. There have certainly been worse adaptations.",
    published: "10/09/2022",
    credit: "Courtesy of Netflix / Parisa Taghizadeh/Netflix",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63285055-0-image-a-13_1665348896411.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Campus wokery on the march: Universities are 'decolonising' courses to mollify activists",
    link: "https://www.dailymail.co.uk/news/article-11297237/Campus-wokery-march-Universities-decolonising-courses-mollify-activists.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The Mail investigation uncovers how 'fat cat' university bosses are pandering to extremists, according to John Hayes, chairman of the Common Sense Group of Tory MPs.",
    published: "10/09/2022",
    credit: "Peter MacDiarmid/REX/Shutterstock",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284783-0-image-m-12_1665349090060.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Moment Florida high school student, 15, BODY SLAMS and punches a cop trying to break up fight",
    link: "https://www.dailymail.co.uk/news/article-11296459/Moment-Florida-high-school-student-15-BODY-SLAMS-punches-cop-trying-break-fight.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Video shows the moment a 15-year-old high school student in St. Cloud, Florida wraps his arms around a school resource officer and flips him to the ground.",
    published: "10/09/2022",
    credit: "St. Cloud High School / St. Cloud Police Department",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/13/63276121-0-image-a-10_1665317964918.jpg",
    tag: "celebrity",
  },
  {
    title: "CHRISTOPHER STEVENS reviews the weekend's TV",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11297247/CHRISTOPHER-STEVENS-reviews-weekends-TV.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "CHRISTOPHER STEVENS: Wildlife presenter Ray Mears dismisses panoramic nature documentaries as 'wallpaper'.",
    published: "10/09/2022",
    credit: "Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63285533-0-image-m-55_1665350677171.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Mother hugs tearful daughter at vigil for the 10 victims who died in Irish petrol station explosion",
    link: "https://www.dailymail.co.uk/news/article-11297001/Mother-hugs-tearful-daughter-vigil-10-victims-died-Irish-petrol-station-explosion.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "There were tearful scenes in the town of Milford this afternoon as residents were left struggling to come to terms with Friday's gas explosion at an Applegreen Service Station in Creeslough.",
    published: "10/09/2022",
    credit: "PA",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/18/63281211-0-image-m-8_1665337817209.jpg",
    tag: "celebrity",
  },
  {
    title:
      "PROFESSOR ARIF AHMED: Fearful academics are cancelling THEMSELVES to avoid backlash from woke mob",
    link: "https://www.dailymail.co.uk/debate/article-11297449/PROFESSOR-ARIF-AHMED-Fearful-academics-cancelling-avoid-backlash-woke-mob.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "PROFESSOR ARIF AHMED: Last week I heard from a professor of medicine at a top university. He usually gives an introductory lecture on some interesting, possibly controversial topic.",
    published: "10/09/2022",
    credit: "Shutterstock / PoohFotoz",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63285705-0-image-a-4_1665351267436.jpg",
    tag: "celebrity",
  },
  {
    title:
      "PICTURED: Student, 22, killed in hit-and-run as police arrest man, 28, on suspicion of murder",
    link: "https://www.dailymail.co.uk/news/article-11297283/PICTURED-Student-22-killed-hit-run-police-arrest-man-28-suspicion-murder.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Rebecca Steer, 22, died shortly after she and a second pedestrian were struck by a Volvo yesterday morning.",
    published: "10/09/2022",
    credit: "PA",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63284403-0-image-m-32_1665350280593.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Sam Cox bought a Kent mansion - then covered every inch with black squiggles, as JANE FRYER reports",
    link: "https://www.dailymail.co.uk/news/article-11297271/Sam-Cox-bought-Kent-mansion-covered-inch-black-squiggles-JANE-FRYER-reports.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "JANE FRYER: From the age of 15, Sam Cox knew that one day he had to become a famous doodle artist, buy a big white mansion and doodle all over it.",
    published: "10/09/2022",
    credit: "MURRAY SANDERS",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284845-0-image-a-33_1665349123634.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Pull out all the stops, King Charles, and give Britain a day to remember, writes DANIEL JOHNSON",
    link: "https://www.dailymail.co.uk/debate/article-11297341/Pull-stops-King-Charles-Britain-day-remember-writes-DANIEL-JOHNSON.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "King Charles's reported plans for a slimmed down Coronation next year are in keeping with his vision of a modernised monarchy. But would it really be wise?",
    published: "10/09/2022",
    credit: "Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/22/63284885-0-image-a-29_1665349446902.jpg",
    tag: "celebrity",
  },
  {
    title: "John Lewis' is determined to create clothes want to wear and have a hot new designer",
    link: "https://www.dailymail.co.uk/femail/article-11296927/John-Lewis-determined-create-clothes-want-wear-hot-new-designer.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Fashion designer turned blogger Erica Davies has created an inclusive range of clothing for women, designed with how clothes are worn in mind. Her collection will now be available in John Lewis",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282143-0-image-a-40_1665339759746.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Chris Dawson brainwashed and gaslighted daughters with cruel lie mother Lynette's murder",
    link: "https://www.dailymail.co.uk/news/article-11296559/Chris-Dawson-brainwashed-gaslighted-daughters-cruel-lie-mother-Lynettes-murder.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The daughter of convicted murderer Chris Dawson has revealed the cruel lie she and her sister were told after their mother Lynette disappeared.",
    published: "10/09/2022",
    credit: "Nine",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/12/63274801-0-image-m-39_1665315225462.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Today's alignment of 'angel numbers' at 10.10 on 10/10 could be the time to realise your dreams",
    link: "https://www.dailymail.co.uk/femail/article-11296971/Todays-alignment-angel-numbers-10-10-10-10-time-realise-dreams.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Kyle Gray decodes the most meaningful 'angel numbers'. One of the most spiritually powerful moments of the year, happens at 10.10am on the 10th day of the 10th month.",
    published: "10/09/2022",
    credit: "Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282341-0-image-a-91_1665340483557.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Lay on a spread with berry butter ...from the chef whose videos have been watched 200 million times",
    link: "https://www.dailymail.co.uk/femail/article-11296907/Lay-spread-berry-butter-chef-videos-watched-200-million-times.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Thomas Starker, 32, started making cooking videos in March 2020. The young chef has been blending shop-bought butter with quirky ingredients such as salt and vinegar crisps",
    published: "10/09/2022",
    credit: "Alamy Stock Photo",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282267-0-image-a-104_1665341288513.jpg",
    tag: "celebrity",
  },
  {
    title: "ANDREW PIERCE: Now scandal-hit major Tory donor is tipped for the House of Lords",
    link: "https://www.dailymail.co.uk/debate/article-11297235/ANDREW-PIERCE-scandal-hit-major-Tory-donor-tipped-House-Lords.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "ANDREW PIERCE: Mega-rich property developer Christopher Moran is tipped to be on the new list of peers to be announced within days.",
    published: "10/09/2022",
    credit: "REX/Shutterstock",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284303-0-image-m-14_1665347346744.jpg",
    tag: "celebrity",
  },
  {
    title:
      "A leading health expert's new book explains why we should be careful about 'healthy' food",
    link: "https://www.dailymail.co.uk/femail/article-11296983/A-leading-health-experts-new-book-explains-careful-healthy-food.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Research has found that no two people will metabolise any food or drink in precisely the same way. Professor Tim Spector lifts the lid on supposedly healthy food in his new book",
    published: "10/09/2022",
    credit: "Getty Images / Getty Images/StockFood",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284099-0-image-a-156_1665346334290.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Scooter tycoon, 62, dubbed the 'Swiss Elon Musk' reinvents THAT iconic 1960s runaround car",
    link: "https://www.dailymail.co.uk/news/article-11297273/Scooter-tycoon-62-dubbed-Swiss-Elon-Musk-reinvents-iconic-1960s-runaround-car.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "They fell out of fashion in the 1960s with the rise of the Fiat 500. But bubble cars are floating off the production line again - and this time they're electric.",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284343-0-image-m-10_1665347861693.jpg",
    tag: "celebrity",
  },
  {
    title: "More than 500 emergency call handlers to go on strike in row over pay",
    link: "https://www.dailymail.co.uk/news/article-11297419/More-500-emergency-call-handlers-strike-row-pay.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The CWU said around 40,000 of its members, including BT Group's 999 emergency call handlers, will walk out following previous stoppages in recent months.",
    published: "10/09/2022",
    credit: "PA",
    image:
      "https://i.dailymail.co.uk/1s/2022/10/09/22/63146051-0-The_Communication_Workers_Union_CWU_has_said_that_the_strikes_ar-a-13_1665349968005.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Inge van Lotringen's Better... not younger: Want to stop looking so tired? These tips work for me",
    link: "https://www.dailymail.co.uk/femail/article-11296985/Inge-van-Lotringens-Better-not-younger-Want-stop-looking-tired-tips-work-me.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Age, stress, exhaustion - call it want you want, it's not a look that anyone wants. Inge van Lotringen explains how she tackles the tired look from eyes to skin",
    published: "10/09/2022",
    credit: "Shutterstock / popcorner",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63284641-0-image-a-181_1665347777543.jpg",
    tag: "celebrity",
  },
  {
    title: "Different dog breeds attract owners with certain personality traits, study finds",
    link: "https://www.dailymail.co.uk/news/article-11297251/Dog-breeds-attract-owners-certain-personality-traits-study-finds.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Those who have border terriers are likely to be charming, lively and exuberant while Staffordshire bull terrier fans are trustworthy and the most affectionate.",
    published: "10/09/2022",
    credit: "Shutterstock / Inna Skaldutska",
    image:
      "https://i.dailymail.co.uk/1s/2022/10/09/22/63284691-0-It_is_often_said_of_dogs_that_they_look_like_their_doting_owners-a-43_1665349250597.jpg",
    tag: "celebrity",
  },
  {
    title: "Wild moment strippers perform for nursing home residents in unhinged stunt",
    link: "https://www.dailymail.co.uk/news/article-11297171/Wild-moment-strippers-perform-nursing-home-residents-unhinged-stunt.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Video shows the moment an old lady seems to suffer a heart attack after grinding up against three half-naked men at a strip party for residents of a nursing home in Colombia.",
    published: "10/09/2022",
    credit: "instagram",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63283635-0-image-a-104_1665345978463.jpg",
    tag: "celebrity",
  },
  {
    title:
      "The 'Einstein of Love' and his wife, have created a 7 day plan to divorce proof your marriage",
    link: "https://www.dailymail.co.uk/femail/article-11296877/The-Einstein-Love-wife-created-7-day-plan-divorce-proof-marriage.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "For 50 years Dr John and Dr Julie Gottman have put love under the microscope. The couple have now curated a seven day plan that will divorce proof marriage",
    published: "10/09/2022",
    credit: "Getty Images/iStockphoto / Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/20/63283753-0-image-a-138_1665345538859.jpg",
    tag: "celebrity",
  },
  {
    title:
      "BEL MOONEY explains how her mother taught her that love was more important than appearances",
    link: "https://www.dailymail.co.uk/femail/article-11296949/BEL-MOONEY-explains-mother-taught-love-important-appearances.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Bel Mooney describes the pain she felt at packing away her parents home. UK-based author said goodbye to her 98-year-old mother after ill-health",
    published: "10/09/2022",
    credit: "Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282277-0-image-a-79_1665340267487.jpg",
    tag: "celebrity",
  },
  {
    title:
      "States that will have SLAVERY on the midterm ballot: Alabama, Louisiana, Oregon, Tennessee, Vermont",
    link: "https://www.dailymail.co.uk/news/article-11280379/States-SLAVERY-midterm-ballot-Alabama-Louisiana-Oregon-Tennessee-Vermont.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Vermont was the first state to ban slavery in 1777 - but its state constitution has an exception for people 'bound by law for the payment of debts, damages, fines, costs, or the like.'",
    published: "10/09/2022",
    credit: "AFP via Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/06/14/63182853-0-image-a-6_1665063636643.jpg",
    tag: "celebrity",
  },
  {
    title: "Demands building for independent inquiry into pensions turmoil",
    link: "https://www.dailymail.co.uk/money/markets/article-11296969/Demands-building-independent-inquiry-pensions-turmoil.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Politicians and industry experts have called for a probe into the meltdown which drove some so-called Liability Driven Investment funds to the brink.",
    published: "10/09/2022",
    credit: "Shutterstock / Khongtham",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282435-0-image-m-30_1665340717249.jpg",
    tag: "celebrity",
  },
  {
    title: "Boom in private jet sales being fuelled by demand from rich youngsters",
    link: "https://www.dailymail.co.uk/money/markets/article-11296953/Boom-private-jet-sales-fuelled-demand-rich-youngsters.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Younger tycoons switched to private jets in the pandemic to avoid infection, Jetcraft, which specialises in selling pre-owned private planes, said.",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282289-0-image-m-22_1665340262318.jpg",
    tag: "celebrity",
  },
  {
    title:
      "'Funny feeling' convinced Philippa Langley that she was standing on the lost grave of Richard III",
    link: "https://www.dailymail.co.uk/femail/article-11289899/Funny-feeling-convinced-Philippa-Langley-standing-lost-grave-Richard-III.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The discovery of the body of Richard III in a Leicester council car park in 2012 made headline news around the world. Now a major new film tells the story.",
    published: "10/09/2022",
    credit: "Pathÿ©/PLANET PHOTOS",
    image: "https://i.dailymail.co.uk/1s/2022/10/07/01/63207451-0-image-m-49_1665104031227.jpg",
    tag: "celebrity",
  },
  {
    title: "Detectives launch murder investigation after man dies following assault",
    link: "https://www.dailymail.co.uk/news/article-11297105/Detectives-launch-murder-investigation-man-dies-following-assault.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Police were called to Tenters Street, Bishop Auckland shortly after 5am today following reports of an assault. The news comes after officers arrested another man in his 40s.",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282387-0-image-a-6_1665341020434.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Trump Secretary of State Mike Pompeo tears into Biden for 'reckless' nuclear armageddon comments",
    link: "https://www.dailymail.co.uk/news/article-11297029/Trump-Secretary-State-Mike-Pompeo-tears-Biden-reckless-nuclear-armageddon-comments.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "'I think that, even more importantly, they demonstrate maybe one of the greatest foreign policy failures of the last decades,' Pompeo said of President Biden's remarks.",
    published: "10/09/2022",
    credit: "ABC News / Fox News / CNN",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/21/63282253-0-image-a-54_1665345886913.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Harvey Weinstein accuser warns #MeToo movement at stake if producer's LA trial fails to convict",
    link: "https://www.dailymail.co.uk/news/article-11296853/Harvey-Weinstein-accuser-warns-MeToo-movement-stake-producers-LA-trial-fails-convict.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Harvey Weinstein accusers, including actress Caitlin Dulany cautioned that the fate of the #MeToo movement could hinge on his LA trial, which is set to begin on Monday.",
    published: "10/09/2022",
    credit: "AFP via Getty Images / Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/18/63280745-0-image-a-5_1665336220489.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Ohio police are deployed to roller coaster after woman's iPhone falsely alerts them of car crash",
    link: "https://www.dailymail.co.uk/news/article-11297061/Ohio-police-deployed-roller-coaster-womans-iPhone-falsely-alerts-car-crash.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Sara White, a 39-year-old dentist, was riding the Mystic Timbers rollercoaster at Kings Island last month when her phone's crash detection software went off.",
    published: "10/09/2022",
    credit: "Apple / King's Island",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/20/63282795-0-image-a-14_1665342032024.jpg",
    tag: "celebrity",
  },
  {
    title: "Strictly Come Dancing results: Richie Anderson is the second contestant voted off",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11296441/Strictly-Come-Dancing-results-Richie-Anderson-second-contestant-voted-off.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary: "Movie Week on Strictly Come Dancing had a shock twist.",
    published: "10/09/2022",
    credit: "PA",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63282285-0-image-m-16_1665340244370.jpg",
    tag: "celebrity",
  },
  {
    title:
      "PayPal backtracks on policy that would have fined users for 'misinformation' following backlash",
    link: "https://www.dailymail.co.uk/news/article-11296589/PayPal-backtracks-policy-fined-users-misinformation-following-backlash.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "PayPal has reversed course on its policy to fine users $2,500 for spreading misinformation after receiving backlash from even its former president.",
    published: "10/09/2022",
    credit: "Getty Images / Twitter / Getty Images for Fast Company",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/15/63277881-0-image-a-42_1665324405114.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Police charge woman, 26, and man, 40, on suspicion of criminal damage after Animal Rebellion protest",
    link: "https://www.dailymail.co.uk/news/article-11297107/Police-charge-woman-26-man-40-suspicion-criminal-damage-Animal-Rebellion-protest.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Stephen Bone, 40, from Essex, and Sofia Fernandes Pontes, 26, from Islington, were both charged this morning with two counts of criminal damage and two counts of theft.",
    published: "10/09/2022",
    credit: "Animal Rebellion",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/19/63224211-0-image-m-16_1665341358999.jpg",
    tag: "celebrity",
  },
  {
    title: "Meet the Queen Consort: Royal Family Instagram account launches video",
    link: "https://www.dailymail.co.uk/femail/article-11296575/Meet-Queen-Consort-Royal-Family-Instagram-account-launches-video.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The Royal Family Instagram account has launched a video explaining the role of the Queen Consort and what she does. The video gives a snapshot of Camilla's personal and working life.",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/15/63278511-0-image-a-29_1665326361401.jpg",
    tag: "celebrity",
  },
  {
    title: "Two drivers jailed after one ploughed into cyclist catapulting him 140 feet",
    link: "https://www.dailymail.co.uk/news/article-11296933/Two-drivers-jailed-one-ploughed-cyclist-catapulting-140-feet.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Motorists Jacob Reginald Titt, 23, and Thomas Lycett, 21, of Hampshire, who sped at 100mph and used road 'like a racetrack' have been jailed after one ploughed into a cyclist, throwing him 140 feet.",
    published: "10/09/2022",
    credit: "Google maps",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/18/63280927-0-image-m-21_1665337653428.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Air France and Airbus face trial for manslaughter over crash that saw 228 people die in 2009",
    link: "https://www.dailymail.co.uk/news/article-11296869/Air-France-Airbus-face-trial-manslaughter-crash-saw-228-people-die-2009.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Flight AF447 went crashing down into the Atlantic on June 1, 2009, after three pilots panicked and failed to deal with malfunctioning equipment during a storm.",
    published: "10/09/2022",
    credit: "EPA",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/17/42887074-0-image-a-13_1665334557712.jpg",
    tag: "celebrity",
  },
  {
    title: "Diwali on the Square! 200 colourfully dressed dancers flock to Westminster",
    link: "https://www.dailymail.co.uk/news/article-11296717/Diwali-Square-200-colourfully-dressed-dancers-flock-Westminster.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Westminster was draped in colour for the festival of light, which included 200 dancers and performers from the Hindu, Sikh and Jain communities.",
    published: "10/09/2022",
    credit: "PA",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/17/63280287-0-image-a-4_1665331986584.jpg",
    tag: "celebrity",
  },
  {
    title: "Who Wants To Be A Millionaire contestant breaks 14-year UK show record",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11296867/Who-Wants-Millionaire-contestant-breaks-14-year-UK-record.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Jeremy Clarkson, 62, was stunned during Saturday's Who Wants to be a Millionaire? as the contestants broke a 14-year record.",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/17/63280795-0-image-a-14_1665334492552.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Royal Navy seizes record-breaking £15.5million worth of crystal methamphetamine in Arabian Sea",
    link: "https://www.dailymail.co.uk/news/article-11296181/Royal-Navy-seizes-record-breaking-15-5million-worth-crystal-methamphetamine-Arabian-Sea.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "In its fifth narcotics raid of the year, HMS Montrose targeted the small vessel after the ship's Wildcat helicopter tracked the movements of the suspect boat.",
    published: "10/09/2022",
    credit: "UK MOD Crown copyright/APEX",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/11/63274563-0-image-a-27_1665311434201.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Two former pupils who claimed they were sexually abused at Gordonstoun win £500,000 in settlements",
    link: "https://www.dailymail.co.uk/news/article-11296777/Two-former-pupils-claimed-sexually-abused-Gordonstoun-win-500-000-settlements.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "John Findlay and a second pupil, who has not been named, alleged they and others had suffered abuse Aberlour House, a nearby school which acts as the junior school of Gordonstoun in Moray.",
    published: "10/09/2022",
    credit: "PA",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/16/63279711-0-image-a-67_1665330839566.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Mother of boy, 3, and girl, 4, killed in crash is pregnant and due on her late daughter's birthday",
    link: "https://www.dailymail.co.uk/news/article-11296673/Mother-boy-3-girl-4-killed-crash-pregnant-late-daughters-birthday.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The mother of two young children who were killed in a crash on the M4 has shared the news that she is pregnant with another child - with the due date being the same day as her late daughter's birthday.",
    published: "10/09/2022",
    credit: "PA",
    image:
      "https://i.dailymail.co.uk/1s/2022/10/09/16/54185583-0-Jayden_Lee_Lucas_and_four_year_old_Gracie_Ann_Wheaton_died_in_ho-a-111_1665329765452.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Boy who was stabbed and shot twice during Thai nursery massacre kept alive as families pray",
    link: "https://www.dailymail.co.uk/news/article-11296461/Boy-stabbed-shot-twice-Thai-nursery-massacre-kept-alive-families-pray.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Three-year-old Sumaee (left) survived the deadly shooting at a nursery in Uthai Sawan, Thailand, despite being shot at twice and stabbed in the head. Panya Kamrap killed 36 people in total.",
    published: "10/09/2022",
    credit: "Sky News",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/14/63275849-0-image-m-88_1665322627791.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Sister of schoolgirl who was murdered by Peter Tobin says she fears he had more victims",
    link: "https://www.dailymail.co.uk/news/article-11296633/Sister-schoolgirl-murdered-Peter-Tobin-says-fears-victims.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Lindsay Brown (pictured) said her family was torn apart by the disappearance of 15-year-old schoolgirl Vicky in 1991. Vicky was murdered by Peter Tobin who died in hospital on Saturday",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/16/63279473-0-image-a-39_1665331123149.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Trump mocks Biden at Nevada rally with two-minute WWE-style highlight reel of his gaffes",
    link: "https://www.dailymail.co.uk/news/article-11296529/Trump-mocks-Biden-Nevada-rally-two-minute-WWE-style-highlight-reel-gaffes.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "'You could take the five worst presidents in American history and put them together, and they would not have done the damage Joe Biden has done,' Trump said at his Nevada rally.",
    published: "10/09/2022",
    credit: "C-SPAN / AP / RSBN",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/14/63276905-0-image-a-4_1665320933605.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Forget WFH... try working from the PUB! Chains offer remote workers WFP deals from just £10 a day",
    link: "https://www.dailymail.co.uk/news/article-11296593/Forget-WFH-try-working-PUB-Chains-offer-remote-workers-WFP-deals-just-10-day.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Pubs will be desperate to attract customers amid the ongoing cost of living crisis, which is only set to worsen this winter, with the customers themselves also seeking to escape their own energy bills.",
    published: "10/09/2022",
    credit: "Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/16/63279189-0-image-a-10_1665328353797.jpg",
    tag: "celebrity",
  },
  {
    title:
      "King Charles and the Queen Consort greet well-wishers en route to Crathie Church in Balmoral",
    link: "https://www.dailymail.co.uk/femail/article-11296479/King-Charles-Queen-Consort-greet-wishers-en-route-Crathie-Church-Balmoral.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The King and Queen Consort acknowledged members of the public this morning as they made their way to Crathie Church in Balmoral to attend a Sunday service.",
    published: "10/09/2022",
    credit: "Stuart Wallace/Shutterstock",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/14/63277531-0-image-a-4_1665323085403.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Fly-tipping in England surges by 38% since 2010 with more than 1million incidents recorded last year",
    link: "https://www.dailymail.co.uk/news/article-11296613/Fly-tipping-England-surges-38-2010-1million-incidents-recorded-year.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "New data shows the fly-tipping in England has surged by 38 per cent. Over 1 million fly-tipping incidents were recorded last year in comparison to the 820,000 incidents recorded in 2010.",
    published: "10/09/2022",
    credit: "Maureen McLean/Shutterstock",
    image:
      "https://i.dailymail.co.uk/1s/2022/10/09/16/63278299-0-The_Environment_Secretary_has_promised_to_tackle_waste_crime_as_-a-31_1665328018478.jpg",
    tag: "celebrity",
  },
  {
    title:
      "How to stay in Villa Surin in Cattai which featured on Luxe Listings Sydney with Delta Goodrem",
    link: "https://www.dailymail.co.uk/femail/travel/article-11285969/How-stay-Villa-Surin-Cattai-featured-Luxe-Listings-Sydney-Delta-Goodrem.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The lavish Villa Surin in Cattai, Sydney has been catching the attention of celebrities for its extravagant features including a treehouse, infinity pool and luxurious bathtub.",
    published: "10/09/2022",
    credit: "airbnb.com.au / Amazon Prime Video",
    image: "https://i.dailymail.co.uk/1s/2022/10/06/22/63202067-0-image-a-7_1665092399306.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Trump throws support behind Spain's far-right political group in video message at Vox Party rally",
    link: "https://www.dailymail.co.uk/news/article-11296631/Trump-speaks-video-rally-global-far-right-Spain.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Donald Trump expressed his support for Spain's far-right in a video message played at a rally in Madrid on Sunday where he thre flattery at the Vox Party and its leader Santiago Abascal.",
    published: "10/09/2022",
    credit: "Twitter / Europa Press via Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/15/63278685-0-image-a-6_1665326978612.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Police arrest seven people after man is stabbed and two attacked in 'dispute between biker clubs'",
    link: "https://www.dailymail.co.uk/news/article-11296287/Police-arrest-seven-people-man-stabbed-two-attacked-dispute-biker-clubs.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Violence erupted onto the streets of a quiet West Sussex town leaving a man stabbed, and two others battered and bleeding, in what is believed to be a dispute between rival biker clubs.",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/10/63273881-0-image-a-15_1665308880710.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Putin propagandist calls for total war and Stalinist-era crackdowns amid Crimea bridge explosion",
    link: "https://www.dailymail.co.uk/news/article-11296155/Putin-propagandist-calls-total-war-Stalinist-era-crackdowns-amid-Crimea-bridge-explosion.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Alluding to an enemy within, Solovyov called for a return of the brutal Stalin-era SMERSH counter-intelligence to crush all internal opposition to a full-scale war against Ukraine",
    published: "10/09/2022",
    credit: "Solovyov Live/east2west news",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/09/63272293-0-image-m-79_1665304707989.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Father, 56, horrified to discover what he thought was a pulled muscle was a cancerous tumour",
    link: "https://www.dailymail.co.uk/femail/article-11296443/Father-56-horrified-discover-thought-pulled-muscle-cancerous-tumour.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The father-of-three from Denton, Greater Manchester, is now part of a clinical trial following his diagnosis last October, Manchester Evening News (MEN) reports.",
    published: "10/09/2022",
    credit: "The Christie charity",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/13/63276371-0-image-a-51_1665318419237.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Craig David blasts Leigh Francis as a 'bully' for comedian's 'racist' Bo' Selecta! impression",
    link: "https://www.dailymail.co.uk/tvshowbiz/article-11296467/Craig-David-blasts-Leigh-Francis-bully-comedians-racist-Bo-Selecta-impression.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Bo' Selecta! came out in 2002 and ran on TV until 2009 and saw comedian Leigh do deliberately ridiculous impressions to make fun of celebrities.",
    published: "10/09/2022",
    credit: "GC Images / Dave Benett/Getty Images",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/13/63276613-0-image-a-44_1665320247079.jpg",
    tag: "celebrity",
  },
  {
    title: "Ten-week-old tot is born with  hair that sticks up in every direction",
    link: "https://www.dailymail.co.uk/femail/article-11296183/Ten-week-old-tot-born-hair-sticks-direction.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Aliyah Lewis, from Barry Island, Wales, gave birth to baby Arla 10 weeks ago. But she was astonished when - after Arla's first bath - her hair kept growing outwards, and hasn't stopped.",
    published: "10/09/2022",
    credit: "Aliyah Lewis / SWNS",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/11/63272361-0-image-a-12_1665312331666.jpg",
    tag: "celebrity",
  },
  {
    title:
      "British tourist, 27, who admitted trying to get Majorcan hotel worker to perform sex act avoids jail",
    link: "https://www.dailymail.co.uk/news/article-11296211/British-tourist-27-admitted-trying-Majorcan-hotel-worker-perform-sex-act-avoids-jail.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "The 27-year-old man, who has not been named, is understood to have made the indecent proposal after knocking on random doors when he returned to the hotel in the resort of Puerto de Alcudia.",
    published: "10/09/2022",
    credit: "Shutterstock / fernandobosch",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/10/63273233-0-image-a-4_1665308499434.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Minecraft YouTuber with millions of subscribers meets his fans face-to-face for the first time",
    link: "https://www.dailymail.co.uk/femail/article-11296317/Minecraft-YouTuber-millions-subscribers-meets-fans-face-face-time.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Adoring supporters screamed in excitement as they saw the influencer - real name Clay, 23 - in person at platform Twitch's annual convention in San Diego on Friday, Bloomberg reports.",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/12/63275005-0-image-a-3_1665313578465.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Ukraine claims warring leaders of Russia's security factions were behind Crimea bridge blast",
    link: "https://www.dailymail.co.uk/news/article-11296343/Ukraine-claims-warring-leaders-Russias-security-factions-Crimea-bridge-blast.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Russia's FSB secret service and private military organisations are at loggerheads with the defence ministry and are trying to undermine one another's credibility, Zelensky's adviser claimed",
    published: "10/09/2022",
    credit: "REUTERS",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/11/63274509-0-image-a-105_1665312963675.jpg",
    tag: "celebrity",
  },
  {
    title:
      "Sister of Brit shot dead by ex-girlfriend is in 'shock' after seeing killer enjoying a Spanish beach",
    link: "https://www.dailymail.co.uk/news/article-11296261/Sister-Brit-shot-dead-ex-girlfriend-shock-seeing-killer-enjoying-Spanish-beach.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Mayka Kukucova sparked outrage by posting Instagram selfies of her topping up her tan in skimpy bikinis after serving just over half her murder sentence behind bars.",
    published: "10/09/2022",
    credit: "None",
    image: "https://i.dailymail.co.uk/1s/2022/10/09/10/63229281-0-image-m-44_1665307331980.jpg",
    tag: "celebrity",
  },
  {
    title: "A wee adventure: Friends travel from Scotland to Iran in 1999 blue Mazda",
    link: "https://www.dailymail.co.uk/femail/article-11290493/A-wee-adventure-Friends-travel-Scotland-Iran-1999-blue-Mazda.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "Three friends - Daniel Patton, Joe Fisher and Callum Kitson - have travelled 1,800-miles from Scotland to Iran in an old Mazda.They then went across India in a tiny rickshaw.",
    published: "10/09/2022",
    credit: "PA Real Life",
    image: "https://i.dailymail.co.uk/1s/2022/10/07/08/63215285-0-image-a-4_1665129019920.jpg",
    tag: "celebrity",
  },
  {
    title: "The secrets of King Charles III's slimmed down coronation revealed",
    link: "https://www.dailymail.co.uk/news/article-11295049/The-secrets-King-Charles-IIIs-slimmed-coronation-revealed.html?ns_mchannel=rss&ito=1490&ns_campaign=1490",
    summary:
      "King Charles is understood to want his Coronation to set the tone for a streamlined monarchy, while retaining some of the pomp that stunned the world during the Queen's funeral.",
    published: "10/09/2022",
    credit: "Alpha Press",
    image: "https://i.dailymail.co.uk/1s/2022/10/08/22/63262577-0-image-m-43_1665263054838.jpg",
    tag: "celebrity",
  },
];

const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "usmagazine",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: item.credit,
      nickname: item.credit,
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsUsmagazine: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsUsmagazine;
