import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title: "Truss Defied the Markets, and They Ruthlessly Sealed Her Fate",
    link: "https://www.nytimes.com/2022/10/20/world/europe/liz-truss-britain-resigns.html",
    summary:
      "World leaders buffeted by economic challenges are watching the turmoil in Britain with anything but relish, hoping that Liz Truss’s woes won’t be a harbinger for other countries.",
    author: "周杰伦",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/20/world/20uk-analysis_2/20uk-analysis_2-moth.jpg",
    credit: "Sam Bush for The New York Times",
    tag: "world",
    youtubeLink: "https://www.youtube.com/watch?v=eivj7wj8GTc&ab_channel=GTX1050Ti",
    youtubeImage: "https://img.youtube.com/vi/eivj7wj8GTc/maxresdefault.jpg",
  },
  {
    title: "Liberated Ukrainians Share Tales of Russian Occupation",
    link: "https://www.nytimes.com/2022/10/20/world/europe/ukraine-civilans-russia-occupation.html",
    summary:
      "With Russian soldiers pushed out of parts of the Kharkiv region, Ukrainian investigators have been overwhelmed with accounts of detentions, torture and missing relatives, as well as collaboration and property theft.",
    author: "Carlotta Gall and Ivor Prickett",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/18/world/18Ukraine-occupation-promo/18Ukraine-occupation-promo-moth.jpg",
    credit: "Ivor Prickett for The New York Times",
    tag: "world",
  },
  {
    title: "Berlusconi, Caught on Tape Gushing Over Putin, Heightens Anxiety About Italy",
    link: "https://www.nytimes.com/2022/10/20/world/europe/silvio-berlusconi-vladimir-putin-russia-italy.html",
    summary:
      "Silvio Berlusconi, the former Italian leader and linchpin of the incoming right-wing government, blames Ukraine’s president for pushing President Vladimir V. Putin to invade.",
    author: "Jason Horowitz",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/20/world/20Berlusconi-Russia01/20Berlusconi-Russia01-moth.jpg",
    credit: "Gianni Cipriano for The New York Times",
    tag: "world",
  },
  {
    title: "Ahead of Midterms, Disinformation Is Even More Intractable",
    link: "https://www.nytimes.com/2022/10/20/technology/disinformation-spread.html",
    summary:
      "Ahead of the midterm elections, the proliferation of alternative social media sites has helped cement false and misleading information as a defining feature of American politics.",
    author: "Steven Lee Myers and Sheera Frenkel",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/12/business/00MisinfoToday-trump/00MisinfoToday-trump-moth.jpg",
    credit: "Todd Anderson for The New York Times",
    tag: "technology",
  },
  {
    title: "Walker Says His Mental Illness Is Healed. Experts Say It’s Not So Simple.",
    link: "https://www.nytimes.com/2022/10/20/us/politics/herschel-walker-mental-illness.html",
    summary:
      "Most patients manage dissociative identity disorder, according to experts, rather than cure it. Herschel Walker, the G.O.P. Senate candidate in Georgia, says he has “overcome” his illness.",
    author: "Sheryl Gay Stolberg",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/18/multimedia/00pol-walkerhealth-1-1dfa/00pol-walkerhealth-1-1dfa-moth.jpg",
    credit: "Gabriela Bhaskar for The New York Times",
    tag: "us",
  },
  {
    title: "Biden Promotes Infrastructure Law in Pennsylvania Swing With Fetterman",
    link: "https://www.nytimes.com/2022/10/20/business/biden-fetterman-pennsylvania-infrastructure.html",
    summary:
      "The fate of a Senate race in President Biden’s home state could determine the prospects of the rest of his legislative agenda.",
    author: "Alan Rappeport",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/20/us/politics/20dc-biden-2/merlin_215199045_773961dc-4f84-4736-98ae-4f8c20496cbe-moth.jpg",
    credit: "Haiyun Jiang/The New York Times",
    tag: "business",
  },
  {
    title: "The Mysterious Patient in Room 23: The Hermit Baroness",
    link: "https://www.nytimes.com/2022/10/20/nyregion/baroness-birgit-thyssen-bornemisza.html",
    summary:
      "Birgit Thyssen-Bornemisza led a life of eccentric anonymity and shabby gentility. Then her money got cut off. Then she had a stroke.",
    author: "George Rush, John Leland and Rutu Modan",
    published: "10/20/2022",
    image: "https://static01.nyt.com/images/2022/10/23/nyregion/23BIRGIT1/23BIRGIT1-moth.jpg",
    credit: "Rutu Modan",
    tag: "nyregion",
  },
  {
    title: "Virginia Uses Treated Wastewater to Shore Up a Drinking Water Aquifer",
    link: "https://www.nytimes.com/2022/10/20/climate/treated-sewage-virginia-aquifer.html",
    summary:
      "A crucial aquifer is running low, so officials are pumping in treated sewage. It’s an increasingly common strategy as heavy demand and climate change strain water supplies.",
    author: "Elena Shao",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/20/science/20cli-drinkingwater-05/merlin_215142009_b65e3b45-3503-4dc0-84ab-0db509cc68a0-moth.jpg",
    credit: "Kristen Zeis for The New York Times",
    tag: "climate",
  },
  {
    title: "How the Emerald Ash Borer Took Ash Bats Out of Major League Baseball",
    link: "https://www.nytimes.com/2022/10/20/sports/baseball/ash-bats-baseball.html",
    summary:
      "Invasive insects and batter preferences have led to the elimination of the wood that dominated the sport for generations. There may not be a single ash bat used in this postseason.",
    author: "Zach Schonbrun",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/19/multimedia/19mlb-ashbats3-1-9340/19mlb-ashbats3-1-9340-moth.jpg",
    credit: "The New York Times",
    tag: "sports",
  },
  {
    title: "A  Town With a Heart, but Few Options, for Migrants",
    link: "https://www.nytimes.com/2022/10/20/opinion/el-paso-migrant-buses-republicans.html",
    summary:
      "It’s fruitless to lecture El Paso about the hardship of receiving buses. The government sent it hundreds upon hundreds of people every day.",
    author: "Megan K. Stack",
    published: "10/20/2022",
    image: "https://static01.nyt.com/images/2022/10/23/opinion/19stack-1/19stack-1-moth.jpg",
    credit: "Ivan Pierre Aguirre for The New York Times",
    tag: "opinion",
  },
  {
    title: "Why Republicans Are Winning Swing Voters",
    link: "https://www.nytimes.com/2022/10/20/podcasts/the-daily/midterms-polling-swing-voters.html",
    summary:
      "The G.O.P. is gaining an edge in midterm elections that will determine control of Congress.",
    author:
      "Michael Barbaro, Rachelle Bonja, Sydney Harper, Patricia Willens, Lisa Chow, Marion Lozano, Elisheba Ittoop and Chris Wood",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2022/10/20/multimedia/20daily-1/20daily-midterm-audio-app-2-moth-v5.jpg",
    credit: "Rebecca Noble for The New York Times",
    tag: "podcasts",
  },
  {
    title: "Affordable Oysters and More Reader Requests",
    link: "https://www.nytimes.com/2022/10/18/dining/affordable-oysters-and-more-reader-requests.html",
    summary: "Looking for the best happy hour deals with quality food? We have recommendations.",
    author: "Nikita Richardson",
    published: "10/18/2022",
    image:
      "https://static01.nyt.com/images/2022/10/19/dining/19wte-questions/merlin_194881806_87022120-bb65-4b4f-ae91-b7298d1184bc-moth.jpg",
    credit: "Lanna Apisukh for The New York Times",
    tag: "dining",
  },
  {
    title: "Orionid Meteor Shower 2022: How to Watch It Peak in Night Skies",
    link: "https://www.nytimes.com/2022/10/20/science/orionid-meteor-shower.html",
    summary:
      "Thursday night into Friday morning will be one of the dates scattered throughout each year when skywatchers can catch a meteor shower.",
    author: "Adam Mann",
    published: "10/20/2022",
    image:
      "https://static01.nyt.com/images/2021/12/23/science/00meteorshowers-orionids/merlin_195784041_7b981c29-0269-4df6-849c-3c20e144aa9a-moth.jpg",
    credit: "Ibrahim Chalhoub/Agence France-Presse — Getty Images",
    tag: "science",
  },
  {
    title: "In His Podcast, Andrew Cuomo Seeks Absolution Without Apology",
    link: "https://www.nytimes.com/2022/10/20/nyregion/andrew-cuomo-podcast-return.html",
    summary:
      "The former governor, who resigned after accusations of sexual harassment, ushers in his new podcast in the month that marks five years after the #MeToo movement began.",
    author: "Ginia Bellafante",
    published: "10/20/2022",
    image: "https://static01.nyt.com/images/2022/10/20/nyregion/20Cuomo/20Cuomo-moth.jpg",
    credit: "Seth Wenig/Associated Press",
    tag: "nyregion",
  },
  {
    title: "How to Run During the Winter",
    link: "https://www.nytimes.com/2020/11/19/at-home/winter-running.html",
    summary:
      "If you’re forgoing the treadmill and want to keep logging miles, here’s what you need to keep in mind.",
    author: "Jen A. Miller",
    published: "11/13/2021",
    image:
      "https://static01.nyt.com/images/2020/11/22/multimedia/22ah-winterrunning/22ah-winterrunning-moth.jpg",
    credit: "Getty Images",
    tag: "at-home",
  },
  {
    title: "What Kenji López-Alt Cooks His Family for Dinner",
    link: "https://www.nytimes.com/2022/10/19/dining/what-kenji-lopez-alt-cooks-family-dinner-udon.html",
    summary:
      "If you can boil water, slice an onion and use a strainer, you can make niku udon, a Japanese beef noodle soup that is the cookbook author’s go-to weeknight dinner.",
    author: "J. Kenji López-Alt",
    published: "10/19/2022",
    image: "https://static01.nyt.com/images/2022/10/19/dining/19kenji3/19kenji3-moth.jpg",
    credit: "Lennart Weibull for The New York Times. Food Stylist: Victoria Granof.",
    tag: "dining\n",
  },
];

export const NYTIMES_MOCK_DATA = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "nytimes",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: item.author,
      nickname: item.author,
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
    media_category: "news",
    youtubeLink: item.youtubeLink,
    youtubeImage: item.youtubeImage,
  };
});
interface Props {}

const NYTimes: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={NYTIMES_MOCK_DATA} />
    </div>
  );
};

export default NYTimes;
