import API from "@/apis";
import EmojiV2 from "@/components/Emoji/EmojiV2";
import HeaderEmojiSwitch from "@/components/HeadEmojiSwitch";
import useApplyEmojiGroup from "@/hooks/useApplyEmojiGroup";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { IEmoji, IHomeData, ISetInfoWithEmojis } from "@/models";
import {
  emojiListState,
  homeActiveEmojiState,
  homeDataState,
  mostReactedState,
  tagState,
} from "@/store";
import { DownOutlined } from "@ant-design/icons";
import { Drawer, Popover } from "antd";
import { useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

const setInfo2Map = (setInfo: ISetInfoWithEmojis | undefined) => {
  if (setInfo !== undefined) {
    let res: {
      [x: string]: IEmoji;
    } = {};
    setInfo.emojis?.forEach((item) => {
      if (item.related) {
        res[item.related] = {
          ...item,
          code: item.related,
        };
      } else {
        let code = item.code;
        if (code.endsWith("_iOS")) {
          code = code.substring(0, code.length - 4);
        }
        res[code] = { ...item };
      }
    });
    return res;
  } else {
    return {};
  }
};

export interface IHomeEmojiProps {}

export function HomeEmoji(props: IHomeEmojiProps) {
  const [homeActiveEmoji, setHomeActiveEmoji] = useRecoilState(homeActiveEmojiState);
  const emojiListValue = useRecoilValue(emojiListState);
  const setMostReacted = useSetRecoilState(mostReactedState);

  const tag = useRecoilValue(tagState);
  const [homeData, setHomeData] = useRecoilState(homeDataState);
  const rightArrowRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);

  const { applyedEmojiGroup } = useApplyEmojiGroup();

  const isSelectedOne = !!homeActiveEmoji?.code;
  const applyEmojiGroupMap = setInfo2Map(applyedEmojiGroup);

  const handleClickEmoji = (val: IEmoji, idx: number) => async () => {
    if (val.code !== homeActiveEmoji?.code) {
      setHomeActiveEmoji(val);
      setMostReacted(false);
      //
      setHomeData({
        ...homeData,
        loading: true,
      });

      API.getHomePostList({
        code: val.code,
        orderDir: "desc",
        tag: tag || undefined,
        page: 1,
      }).then((data) => {
        const newItems = data.data.map((i: IHomeData, idx: number) => {
          return {
            groupKey: 1,
            key: idx,
            data: i,
          };
        });
        setHomeData({
          page: 2,
          total: data.total || 0,
          data: [...newItems],
          loading: false,
        });
      });
    } else {
      setHomeActiveEmoji(null);
      setMostReacted(true);

      setHomeData({
        ...homeData,
        loading: true,
      });

      API.getHomePostList({
        tag: tag || undefined,
        page: 1,
      }).then((data) => {
        const newItems = data.data.map((i: IHomeData, idx: number) => {
          return {
            groupKey: 1,
            key: idx,
            data: i,
          };
        });
        setHomeData({
          page: 2,
          total: data.total || 0,
          data: [...newItems],
          loading: false,
        });
      });
    }

    if (containerRef?.current) {
      if (idx > 4) {
        const offset = isLargerThan414 ? 64 : 48;
        containerRef.current.scrollLeft = (idx - 4) * offset;
      } else {
        containerRef.current.scrollLeft = 0;
      }
    }
    return 0;
  };

  const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    if (!rightArrowRef?.current) {
      return;
    }

    const { clientWidth, scrollLeft, scrollWidth } = e.currentTarget;
    // 滚动到底了
    if (clientWidth + scrollLeft >= scrollWidth) {
      rightArrowRef.current.style.display = "none";
    } else {
      rightArrowRef.current.style.display = "flex";
    }
  };

  return (
    <div className="pb-2 sm:pb-4">
      <div className="flex items-center bg-main justify-center h-[88px] sm:h-auto">
        <div className="relative w-full max-w-[568px] overflow-hidden h-[88px]">
          <div
            className="inline-block max-w-[568px] whitespace-nowrap overflow-x-scroll sm:pl-0"
            style={{
              maxWidth: isLargerThan414 ? 568 : "100%",
              paddingLeft: isLargerThan414 ? 0 : "calc(50vw - 168px)",
              scrollBehavior: "smooth",
            }}
            onScroll={handleScroll}
            ref={containerRef}
          >
            {/* {isLargerThan414 ? <HeaderEmojiSwitch type="pc" /> : null} */}
            <HeaderEmojiSwitch type={isLargerThan414 ? "pc" : "mobile"} />
            {emojiListValue.map((emojiItem, index) => {
              const active = homeActiveEmoji?.code === emojiItem.code;
              return (
                <Popover
                  key={emojiItem.code}
                  content={<div className="bg-slate-700 text-white">{emojiItem.code}</div>}
                  title={null}
                  className="home-emoji-popover"
                  overlayClassName="home-emoji-popover"
                >
                  <div
                    className={`inline-flex items-center justify-center flex-col mx-0.5 sm:mx-1 mt-4 ${
                      active ? "opacity-100" : isSelectedOne ? "opacity-50" : "opacity-100"
                    }`}
                  >
                    <div className="h-10 flex items-center">
                      <EmojiV2
                        emoji={emojiItem}
                        applyedEmoji={applyEmojiGroupMap[emojiItem.code]}
                        isActive={active}
                        onClick={handleClickEmoji(emojiItem, index + 1)}
                        width={isLargerThan414 ? 40 : 28}
                        height={isLargerThan414 ? 40 : 28}
                      />
                    </div>
                    <div className="text-xs leading-4 font-bold h-5 sm:h-8 text-center tracking-tight text-ellipsis whitespace-nowrap overflow-hidden pb-1 sm:py-2 w-14">
                      {emojiItem.code}
                    </div>
                  </div>
                </Popover>
              );
            })}
          </div>
          {isLargerThan414 ? (
            <Popover
              content={
                <div className="w-full grid gap-x-3 gap-y-2 grid-cols-8">
                  {emojiListValue.map((emojiItem, index) => {
                    return (
                      <div
                        key={emojiItem.code}
                        className="transition duration-300 ease-in-out hover:scale-125"
                      >
                        <EmojiV2
                          // isActive={isActive(index + 1)}
                          onClick={handleClickEmoji(emojiItem, index + 1)}
                          width={28}
                          height={28}
                          emoji={emojiItem}
                          applyedEmoji={applyEmojiGroupMap[emojiItem.code]}
                          showTitle
                        />
                      </div>
                    );
                  })}
                </div>
              }
              title={null}
              trigger="click"
              // placement="topRight"
              overlayClassName="emoji-popover"
              visible={visible}
              onVisibleChange={setVisible}
            >
              <div
                className="right-arrow absolute right-0 top-1 sm:top-4 cursor-pointer"
                ref={rightArrowRef}
              >
                <div
                  className="flex items-center justify-start h-[72px] w-7"
                  // style={{ background: "rgb(242, 242, 242)" }}
                  style={{ background: "white" }}
                >
                  <DownOutlined />
                </div>
              </div>
            </Popover>
          ) : (
            <>
              <div
                className="right-arrow absolute right-0 top-1 sm:top-4 cursor-pointer"
                ref={rightArrowRef}
                onClick={() => setDrawerVisible(true)}
              >
                <div
                  className="flex items-center justify-start h-[72px] w-7"
                  // style={{ background: "rgb(242, 242, 242)" }}
                  style={{ background: "white" }}
                >
                  <DownOutlined />
                </div>
              </div>
              <Drawer
                closable={false}
                extra={<div>Close</div>}
                placement="bottom"
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
                className="detail-drawer"
                height={372}
              >
                <div className="relative">
                  <div className="text-center text-base pr-3">Emojis</div>
                  <div
                    onClick={() => setDrawerVisible(false)}
                    className="absolute text-base font-bold top-0 right-0"
                  >
                    close
                  </div>
                  <div className="w-full mt-4 grid gap-x-3 gap-y-2 grid-cols-5 overflow-y-scroll h-[300px]">
                    {emojiListValue.map((item, index) => {
                      return (
                        <EmojiV2
                          onClick={handleClickEmoji(item, index + 1)}
                          width={42}
                          height={42}
                          key={item.code}
                          emoji={item}
                          applyedEmoji={applyEmojiGroupMap[item.code]}
                          autoScaleBack
                          showName
                        />
                      );
                    })}
                  </div>
                </div>
              </Drawer>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
