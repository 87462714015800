import { IEmoji, IHomeSearch, ISetInfoWithEmojis, IUserInfo, IWalterfallItem } from "@/models";
import { atom } from "recoil";

export const userInfoState = atom<IUserInfo | null>({
  key: "userinfo",
  default: null,
});

export const loginVisibleState = atom<boolean>({
  key: "loginVisible",
  default: false,
});

export const mostReactedState = atom<boolean>({
  key: "mostReactedState",
  default: true,
});

export const searchState = atom<IHomeSearch>({
  key: "homeSearch",
  default: {
    keyword: "",
    results: [],
    total: 0,
    page: 1,
  },
});

export const searchWordState = atom<string>({
  key: "homeSearchWord",
  default: "",
});

export const emojiState = atom<number>({
  key: "emoji",
  default: 0,
});

export const homeActiveEmojiState = atom<IEmoji | null>({
  key: "homeActiveEmoji",
  default: null,
});

export const emojiListState = atom<IEmoji[]>({
  key: "emojiList",
  default: [],
});

export const switchedEmojiListState = atom<IEmoji[]>({
  key: "switchedEmojiList",
  default: [],
});

export const tagState = atom<string>({
  key: "tag",
  default: "",
});

export const homeDataState = atom<{
  page: number;
  total: number;
  loading: boolean;
  data: IWalterfallItem[];
  isError?: boolean;
}>({
  key: "homeDataState",
  default: {
    page: 1,
    total: 0,
    loading: false,
    data: [],
    isError: false,
  },
});

export const emojiSwitchState = atom<boolean>({
  key: "emojiSwitchState",
  default: false,
});

// 用户创建表情
export const emojiGroupsState = atom<ISetInfoWithEmojis[]>({
  key: "emojiGroupsState",
  default: [],
});

// 官方表情
export const officialEmojiGroupsState = atom<ISetInfoWithEmojis[]>({
  key: "officialEmojiGroupsState",
  default: [],
});
