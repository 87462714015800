const LikeFullIcon = ({ color }: { color: string }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 5.65576C8.75475 1.61553 2.02701 6.16079 2.02701 12.3319C2.02701 19.8257 9.59373 24.1837 14.8203 27.194C15.2295 27.4296 15.6243 27.657 16 27.877C16.3757 27.657 16.7705 27.4296 17.1797 27.194C22.4063 24.1837 29.973 19.8257 29.973 12.3319C29.973 6.16079 23.2453 1.61553 16 5.65576Z"
        fill={color}
      />
    </svg>
  );
};
export default LikeFullIcon;
