import API from "@/apis";
import DiscordEmoji from "@/components/DiscordEmoji";
import useApplyEmojiGroup from "@/hooks/useApplyEmojiGroup";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { IEmoji, IHomeDataEmoji } from "@/models";
import { emojiListState } from "@/store";
import { setInfo2Map } from "@/utils";
import * as React from "react";
import { useRecoilValue } from "recoil";

const useEffect = React.useEffect;
const useState = React.useState;
const useRef = React.useRef;

const defaultEmojiCodes = ["LOL", "Boring", "WTF", "Helpful", "Useful", "Tasty", "Cute", "Mad"];

const handleVibrate = () => {
  if ("vibrate" in window.navigator) {
    window.navigator.vibrate(60);
  }
};

interface Props {
  emoji?: IHomeDataEmoji[];
  dark?: boolean;
  id: string | number;
}

interface INoteEmoji {
  emoji: IEmoji;
  count: number;
  index: number;
  clicked: boolean;
}

const DetailEmojis: React.FC<Props> = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const refContainer = useRef<HTMLDivElement | null>(null);
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);

  const [emojiList, setEmojiList] = useState<Array<INoteEmoji>>([]);
  const emojiListValue = useRecoilValue(emojiListState);
  // apply的表情组
  const { applyedEmojiGroup } = useApplyEmojiGroup();
  const applyEmojiGroupMap = setInfo2Map(applyedEmojiGroup);

  useEffect(() => {
    const emojis = props.emoji || [];
    if (emojiListValue.length > 8) {
      let res: INoteEmoji[] = [];
      emojiListValue.forEach((i, index) => {
        const code = i.code;
        const noteEmoji = emojis.find((e) => e.emoji_code === code);
        if (noteEmoji && noteEmoji?.emoji_num) {
          res.push({
            emoji: i,
            count: noteEmoji?.emoji_num || 0,
            index: index,
            clicked: false,
          });
        } else {
          if (defaultEmojiCodes.includes(code)) {
            res.push({
              emoji: i,
              count: 0,
              index: index,
              clicked: false,
            });
          }
        }
        // return ;
      });
      // 排序，大的在前面
      res.sort((a, b) => {
        return a.count > b.count ? -1 : 0;
      });
      setEmojiList(res);
    }
  }, [props.emoji, emojiListValue]);

  useEffect(() => {
    const onScroll = () => {
      if (!isLargerThan414) {
        return;
      }
      if (ref.current && ref2.current && refContainer.current) {
        const top = refContainer.current.getClientRects()[0].top;

        if (top <= -1) {
          ref.current.classList.add("emoji-fixed");
          ref2.current.style.display = "block";
        } else {
          ref.current.classList.remove("emoji-fixed");
          ref2.current.style.display = "none";
        }
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isLargerThan414]);

  const handleClickSelectedEmoji = (data: INoteEmoji) => async () => {
    handleVibrate();
    const newEmojiList = emojiList.map((item) => {
      if (item.emoji.code === data.emoji.code) {
        return {
          ...data,
          clicked: !data.clicked,
          count: data.clicked ? item.count - 1 : item.count + 1,
        };
      } else {
        return item;
      }
    });
    setEmojiList([...newEmojiList]);
    API.postEmoji(+props.id, data.emoji.code);
    return 0;
  };

  return (
    <div ref={refContainer}>
      <div ref={ref2} className="min-h-12 hidden" style={{ width: isLargerThan414 ? 648 : "100%" }}>
        <div className="px-1 flex items-center flex-wrap">
          {emojiList.map((item) => {
            return (
              <DiscordEmoji
                onClick={handleClickSelectedEmoji(item)}
                emoji={item.emoji}
                applyedEmoji={applyEmojiGroupMap[item.emoji.code]}
                clicked={item.clicked}
                count={item.count}
                key={item.emoji.code}
                dark={props.dark}
                showName={true}
              />
            );
          })}
        </div>
      </div>

      <div
        ref={ref}
        className="min-h-12 bg-white w-full pt-2"
        style={{ width: isLargerThan414 ? 648 : "100%" }}
      >
        <div className="px-1 flex items-center flex-wrap">
          {emojiList.map((item) => {
            return (
              <DiscordEmoji
                onClick={handleClickSelectedEmoji(item)}
                emoji={item.emoji}
                applyedEmoji={applyEmojiGroupMap[item.emoji.code]}
                clicked={item.clicked}
                count={item.count}
                key={item.emoji.code}
                dark={props.dark}
                showName={true}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DetailEmojis;
