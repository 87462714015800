import IMG_UPLOAD from "@/assets/upload.png";
import EmojiV2 from "@/components/Emoji/EmojiV2";
import useApplyEmojiGroup from "@/hooks/useApplyEmojiGroup";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { IEmoji } from "@/models";
import { emojiListState } from "@/store";
import { setInfo2Map } from "@/utils";
import { Form, Input, InputRef, Popover, Tabs, Tag, Upload } from "antd";
import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import "./postForm.css";

const rulesImage = [
  {
    required: true,
    message: "Please upload an image",
  },
];
const rulesYoutube = [
  {
    required: true,
    message: "Please enter youtube share link",
  },
];
const rulesText = [
  {
    required: true,
    message: "Please enter youtube share link",
  },
];

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const getUrl = (values: any) => {
  let url = "";
  if (values.image && values.image[0]) {
    url = values.image[0]?.response.data || "";
  }

  return url;
};

interface Props {
  onSubmit: (values: any) => void;
}

const PostForm = (props: Props) => {
  const [emojiCode, setEmojiCode] = useState<string>("");
  const [form] = Form.useForm();
  const [isLargerThan414] = useMediaQuery(["(min-width: 415px)"]);
  const [activeTab, setActiveTab] = useState<string>("image");
  const [tags, setTags] = useState<string[]>([]);
  const inputRef = useRef<InputRef | null>(null);
  const emojiListValue = useRecoilValue(emojiListState);

  const { applyedEmojiGroup } = useApplyEmojiGroup();
  // const [applyedEmojiGroup, setApplyedEmojiGroup] = useState<ISetInfoWithEmojis>();
  // const emojiGroupsValue = useRecoilValue(emojiGroupsState);
  // const officialEmojiGroupsValue = useRecoilValue(officialEmojiGroupsState);
  // useEffect(() => {
  //   const applyEmojiGroup = emojiGroupsValue.find((group) => group?.apply === 1);
  //   if (applyEmojiGroup) {
  //     setApplyedEmojiGroup(applyEmojiGroup);
  //   } else {
  //     const applyed = officialEmojiGroupsValue.find(
  //       (group) => group?.apply === 1 && group?.id !== 1
  //     );
  //     applyed && setApplyedEmojiGroup(applyed);
  //   }
  // }, [emojiGroupsValue, officialEmojiGroupsValue]);

  const applyEmojiGroupMap = setInfo2Map(applyedEmojiGroup);

  const emojiSize = isLargerThan414 ? 40 : 28;

  const isActive = (i: string) => i === emojiCode;
  const handleClickEmoji = (i: IEmoji) => async () => {
    setEmojiCode(i.code);

    form.validateFields().then(() => {
      const values = form.getFieldsValue(true);
      const imageUrl = getUrl(values);
      let data = {
        ...values,
        image: imageUrl,
        emoji: i.code,
        tags: tags.join(" "),
        website: "1",
      };
      if (!data.title) {
        data.title = " ";
      }

      props.onSubmit(data);
    });
    return 0;
  };

  const handleFinish = (values: any) => {
    const imageUrl = getUrl(values);

    props.onSubmit({
      ...values,
      image: imageUrl,
    });
  };
  return (
    <Form
      autoComplete="off"
      layout="vertical"
      style={{ width: "100%" }}
      onFinish={handleFinish}
      className="login-form"
      form={form}
    >
      <Tabs defaultActiveKey="image" onChange={(e) => setActiveTab(e)}>
        <Tabs.TabPane tab="Image/Video" key="image">
          {/* <Form.Item
            rules={activeTab === "image" ? rulesImage : []}
            name="image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            // noStyle
          >
            <Upload.Dragger
              name="file"
              action="/backend/api/upload"
              headers={{
                "sv-access-token": localStorage.getItem("access_token") || "",
              }}
              listType="picture-card"
              className="post-upload-dragger"
              maxCount={1}
            >
              <div className="flex items-center justify-center flex-col">
                <div className="text-base mb-5">Click or Drag File to This Area to Upload</div>
                <img className="overflow-hidden" src={IMG_UPLOAD} alt="" />
              </div>
            </Upload.Dragger>
          </Form.Item> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Youtube Link" key="youtube">
          {/* <Form.Item name="youtube" rules={activeTab === "youtube" ? rulesYoutube : []}>
            <Input placeholder="Required" />
          </Form.Item> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Text" key="text">
          {/* <Form.Item name="text" rules={activeTab === "text" ? rulesText : []}>
            <Input.TextArea className="rounded-xl" rows={4} placeholder="Required" />
          </Form.Item> */}
        </Tabs.TabPane>
      </Tabs>

      <Form.Item
        name="title"
        label="Title"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please enter the title",
        //   },
        // ]}
      >
        <Input placeholder="Optional" />
      </Form.Item>

      <Form.Item
        rules={activeTab === "image" ? rulesImage : []}
        name="image"
        label="Content"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        className={`${activeTab !== "image" ? "hidden" : ""}`}
        extra="JPG/PNG/GIF/WEBP/MP4/MOV"
        // noStyle
      >
        <Upload.Dragger
          name="file"
          action="/backend/api/upload"
          headers={{
            "sv-access-token": localStorage.getItem("access_token") || "",
          }}
          listType="picture-card"
          className="post-upload-dragger"
          maxCount={1}
        >
          <div className="flex items-center justify-center flex-col">
            <div className="text-base mb-5">Click or Drag File to This Area to Upload</div>
            <img className="overflow-hidden" src={IMG_UPLOAD} alt="" />
          </div>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        className={`${activeTab !== "youtube" ? "hidden" : ""}`}
        name="youtube"
        label="Youtube Video Link"
        rules={activeTab === "youtube" ? rulesYoutube : []}
      >
        <Input placeholder="Required" />
      </Form.Item>

      <Form.Item
        className={`${activeTab !== "text" ? "hidden" : ""}`}
        name="text"
        label="Content"
        rules={activeTab === "text" ? rulesText : []}
      >
        <Input.TextArea className="rounded-xl" rows={4} placeholder="Required" />
      </Form.Item>

      {/* <Form.Item
        name="website"
        label="WEBSITE"
        rules={[
          {
            required: true,
            message: "Please enter a website",
          },
        ]}
      >
        <Input placeholder="Enter website adress" />
      </Form.Item> */}
      <Form.Item
        name="tags"
        label="Tag(s)"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please enter a tag name",
        //   },
        // ]}
      >
        <Input
          placeholder="Optional"
          ref={inputRef}
          // value={tagsValue}
          onChange={(e) => {
            const value = e.target.value;

            if (value.endsWith(" ") && value.trim()) {
              const tag = value.trim();
              setTags([...tags, tag]);
              form.setFieldsValue({ tags: "" });
              // setTagsValue("");
            } else {
              // setTagsValue(value);
            }
          }}
          prefix={
            <div>
              {tags.map((t, index) => {
                return (
                  <Tag closable key={index}>
                    {t}
                  </Tag>
                );
              })}
            </div>
          }
        />
      </Form.Item>
      <Form.Item
        name="link"
        label="Source"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please enter a link",
        //   },
        // ]}
      >
        <Input placeholder="Optional" />
      </Form.Item>
      <Form.Item
        label="Click/Tap an Emoji to Post"
        style={{ textAlign: "center", marginBottom: 0 }}
      >
        <div className="emojis grid grid-cols-8">
          {emojiListValue.map((item, index) => {
            return (
              <Popover
                key={item.code}
                // trigger="click"
                content={<div>{item.code}</div>}
                title={null}
                className="post-emoji-popover"
                overlayClassName="post-emoji-popover"
              >
                <div
                  // title={EmojiTitle[item]}
                  className="px-1 py-1 my-1 border border-transparent rounded-md sm:hover:bg-zinc-200"
                >
                  <EmojiV2
                    emoji={item}
                    applyedEmoji={applyEmojiGroupMap[item.code]}
                    isActive={isActive(item.code)}
                    onClick={handleClickEmoji(item)}
                    width={emojiSize}
                    height={emojiSize}
                    autoScaleBack
                    // name={item}
                  />
                </div>
              </Popover>
            );
          })}
        </div>
      </Form.Item>
    </Form>
  );
};

export default PostForm;
