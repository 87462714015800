import API from "@/apis";
import { IHomeData } from "@/models";
import { loginVisibleState, userInfoState } from "@/store";
import { DeleteOutlined } from "@ant-design/icons";
import { Popover, message } from "antd";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import LikeIcon from "../Icons/Like";
import LikeFullIcon from "../Icons/LikeFull";
import ReportIcon from "../Icons/Report";
import ShareIcon from "../Icons/Share";

interface INoteButtonsProps {
  data: IHomeData;
  showTopEmoji?: boolean;
  onDelete?: () => void;
}

const NoteButtonPopover = (props: { title: string; children: React.ReactNode }) => {
  return (
    <Popover
      content={<div>{props.title}</div>}
      title={null}
      className="post-emoji-popover"
      overlayClassName="post-emoji-popover"
    >
      {props.children}
    </Popover>
  );
};

const NoteButtons = (props: INoteButtonsProps) => {
  const [like, setLike] = useState<boolean>(!!props.data.like);
  const userInfo = useRecoilValue(userInfoState);
  const setLoginVisible = useSetRecoilState(loginVisibleState);

  const handleToggleLike = (e: any) => {
    e.stopPropagation();
    if (userInfo === null) {
      message.info("Please Login");
      setLoginVisible(true);
      return;
    }
    setLike(!like);
    postLike(!like ? 1 : -1);
  };

  const postLike = async (like: number) => {
    await API.likePost({
      post_id: props.data.id,
      like, //-1=踩 1=like 2=love
    });
  };

  const handleClickDanger = (e: any) => {
    e.stopPropagation();
    message.info("TODO");
  };

  const handleClickShare = (e: any) => {
    e.stopPropagation();
    message.info("TODO");
  };
  const handleClickDelete = (e: any) => {
    e.stopPropagation();
    props.onDelete?.();
  };

  return (
    <>
      <div
        className={`absolute h-12 right-2 sm:right-5 -top-60 duration-300 ${
          props.showTopEmoji
            ? "group-hover:top-12 sm:group-hover:top-14"
            : "group-hover:top-2 sm:group-hover:top-4"
        }`}
      >
        <NoteButtonPopover title="Like, Save and Follow">
          <div
            className="cursor-pointer note-btn h-6 sm:h-10 w-6 sm:w-10 rounded flex items-center justify-center ml-4 overflow-hidden p-0 sm:p-2"
            onClick={handleToggleLike}
          >
            {like ? <LikeFullIcon color="#F26F4C" /> : <LikeIcon color="#FFFFFF" />}
          </div>
        </NoteButtonPopover>
        <NoteButtonPopover title="Share">
          <div
            className="cursor-pointer note-btn h-6 sm:h-10 w-6 sm:w-10 rounded flex items-center justify-center ml-4 mt-3 overflow-hidden p-0 sm:p-2"
            onClick={handleClickShare}
          >
            <ShareIcon color="#FFFFFF" />
          </div>
        </NoteButtonPopover>
        <NoteButtonPopover title="Report">
          <div
            className="cursor-pointer note-btn h-6 sm:h-10 w-6 sm:w-10 rounded flex items-center justify-center ml-4 mt-3 overflow-hidden p-0 sm:p-2"
            onClick={handleClickDanger}
          >
            <ReportIcon color="#FFFFFF" />
          </div>
        </NoteButtonPopover>
        {props.onDelete ? (
          <div
            className="cursor-pointer note-btn h-6 sm:h-10 w-6 sm:w-10 rounded flex items-center justify-center ml-4 mt-3 overflow-hidden p-0 sm:p-2"
            onClick={handleClickDelete}
          >
            <DeleteOutlined style={{ color: "#FFFFFF", fontSize: 22 }} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NoteButtons;
