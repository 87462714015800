import API from "@/apis";
import IMG_UPLOAD from "@/assets/upload.png";
import { IEmoji } from "@/models";
import { emojiListState, userInfoState } from "@/store";
import { Button, Drawer, Form, Input, Modal, Popover, Upload, message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import EmojiV2 from "../Emoji/EmojiV2";
import IconFont from "../IconFont";

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const getUrl = (values: any) => {
  let url = "";
  if (values.image && values.image[0]) {
    url = values.image[0]?.response.data || "";
  }

  return url;
};

interface Props {
  ispc: boolean;
  onSuccess: (code: string) => void;
  type?: string;
  linkEmoji: IEmoji | null;
}

const AddEmoji: React.FC<Props> = (props: Props) => {
  const emojiListValue = useRecoilValue(emojiListState);
  const userInfo = useRecoilValue(userInfoState);
  const [emoji, setEmoji] = useState<IEmoji | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleFinish = () => {};

  const handleAddEmoji = () => {
    if (userInfo === null) {
      message.info("Please Login");
      navigate("/login");
      return;
    }

    if (props.ispc) {
      setVisible(true);
    } else {
      setDrawerVisible(true);
    }
  };

  const handleClickEmoji = (item: IEmoji) => async () => {
    setEmoji(item);

    form.validateFields().then(() => {
      const values = form.getFieldsValue(true);
      const imageUrl = getUrl(values);
      submitEmoji({
        name: values.name,
        description: values.description,
        path: imageUrl,
        related: item.code,
      });
    });
    return 0;
  };

  const submitEmoji = async (params: any) => {
    const isSuccess = await API.addEmoji(params);
    if (isSuccess) {
      message.success("Success");
      setVisible(false);
      setDrawerVisible(false);
      props.onSuccess(params.related);
    }
  };

  const createForm = (
    <div>
      <div className="mt-4">
        <Form
          autoComplete="off"
          layout="vertical"
          style={{ width: "100%" }}
          onFinish={handleFinish}
          className="login-form"
          form={form}
        >
          <Form.Item
            name="name"
            label="STICKER NAME"
            rules={[
              {
                required: true,
                message: "Please enter sticker name",
              },
            ]}
          >
            <Input placeholder="ex: cat hug" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please upload an image",
              },
            ]}
            label="IMAGE"
            name="image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            // noStyle
            extra="File should be APNG or PNG(512KB max)"
          >
            <Upload.Dragger
              name="file"
              action="/backend/api/upload"
              headers={{
                "sv-access-token": localStorage.getItem("access_token") || "",
              }}
              listType="picture-card"
              className="post-upload-dragger"
              maxCount={1}
            >
              <div className="flex items-center justify-center flex-col">
                <div className="text-base mb-5">Click or Drag File to This Area to Upload</div>
                <img className="overflow-hidden" src={IMG_UPLOAD} alt="" />
              </div>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item
            name="description"
            label="DESCRIPTION"
            rules={[
              {
                required: true,
                message: "Please enter description",
              },
            ]}
          >
            <Input placeholder="See our Help Center for tips on waiting sticker description" />
          </Form.Item>
          {props.type === "icon" && props.linkEmoji ? (
            <Form.Item>
              <div className="flex justify-center">
                <div
                  className="w-10 h-10 cursor-pointer p-0.5"
                  onClick={handleClickEmoji(props.linkEmoji)}
                >
                  <IconFont type="icon-add-2" style={{ fontSize: 36 }} />
                </div>
              </div>
              {/* <Button
                className="rounded-lg"
                type="primary"
                onClick={handleClickEmoji(props.linkEmoji)}
              >
                Send
              </Button> */}
            </Form.Item>
          ) : (
            <Form.Item
              label="Click Relevant Emoji to Add"
              style={{ textAlign: "center", marginBottom: 0 }}
            >
              <div className="emojis grid grid-cols-5">
                {emojiListValue.map((item) => {
                  return (
                    <Popover
                      key={item.code}
                      content={<div className="bg-slate-700 text-white">{item.code}</div>}
                      title={null}
                      className="home-emoji-popover"
                      overlayClassName="home-emoji-popover"
                      trigger="hover"
                      zIndex={12000}
                    >
                      <div className="px-1 py-1 my-1 border border-transparent rounded-md hover:bg-zinc-200">
                        <EmojiV2
                          autoScaleBack
                          onClick={handleClickEmoji(item)}
                          width={40}
                          height={40}
                          emoji={item}
                        />
                      </div>
                    </Popover>
                  );
                })}
              </div>
            </Form.Item>
          )}
        </Form>
      </div>
    </div>
  );

  return (
    <div>
      {props.type === "icon" ? (
        <div className="w-10 h-10 p-0.5" onClick={handleAddEmoji}>
          <IconFont type="icon-add-2" style={{ fontSize: 36 }} />
        </div>
      ) : (
        <Button className="rounded-lg" type="primary" onClick={handleAddEmoji}>
          Add your Own
        </Button>
      )}
      <Drawer
        closable={false}
        extra={<div>Close</div>}
        placement="bottom"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        className="detail-drawer"
        height={480}
      >
        {createForm}
      </Drawer>

      <Modal
        className="post-modal"
        zIndex={2002}
        onCancel={() => setVisible(false)}
        title={
          <div>
            {props.linkEmoji?.code ? (
              <div>
                Add<span className="font-bold mx-2">{props.linkEmoji?.code}</span>Emojis
              </div>
            ) : (
              "Add Emoji/GIF/Sticker"
            )}
          </div>
        }
        visible={visible}
        footer={null}
        width={500}
        closeIcon={
          <div className="flex items-center justify-center w-14 h-14">
            <div className="flex items-center justify-center w-8 h-8">
              <IconFont type="icon-close" style={{ color: "#000", fontSize: 24 }} />
            </div>
          </div>
        }
      >
        {createForm}
      </Modal>
    </div>
  );
};

export default AddEmoji;
