import HeaderSearch from "@/layout/Header/HeaderSearch";
import "./index.css";

const BannerAuthors = () => {
  return (
    <div className="authors-banner px-3 pt-12" style={{ maxWidth: 1390, margin: "0px auto" }}>
      <div className="text-black text-7xl font-bold tracking-tighter mb-6">Discover Authors</div>
      <p className="text-black text-2xl tracking-normal mb-8">
        This page lists Overall Leaderboard page. Get to know the best authors and their works.
      </p>
      <HeaderSearch />
    </div>
  );
};
export default BannerAuthors;
