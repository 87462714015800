import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title: "Marvel died with Tony",
    image: "https://img-9gag-fun.9cache.com/photo/aEq1nDe_700b.jpg",
    published: "10/9/22",
    tag: "wtf",
    emoji_primary: "funny",
    emoji_primary_count: 5,
    updated_date: "",
  },
  {
    title: "Extreme pain",
    image: "https://img-9gag-fun.9cache.com/photo/axoZD8W_700b.jpg",
    published: "10/9/22",
    tag: "latest-news",
    emoji_primary: "funny",
    emoji_primary_count: 7,
    updated_date: "",
  },
  {
    title: "View is enough",
    image: "https://img-9gag-fun.9cache.com/photo/aqeNLML_700b.jpg",
    published: "10/9/22",
    tag: "random",
    emoji_primary: "funny",
    emoji_primary_count: 7,
    updated_date: "",
  },
  {
    title: "Yes",
    image: "https://img-9gag-fun.9cache.com/photo/a4o9MGv_700b.jpg",
    published: "10/9/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 4,
    updated_date: "",
  },
  {
    title: "Yesterday YT decided demonetized all channels that talks about Russo-Ukrainian War",
    image: "https://img-9gag-fun.9cache.com/photo/ay208rX_700b.jpg",
    published: "10/9/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 3,
    updated_date: "",
  },
  {
    title: "At every point in history",
    image: "https://img-9gag-fun.9cache.com/photo/adP63GD_700b.jpg",
    published: "10/9/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "",
  },
  {
    title: "Every damn time",
    image: "https://img-9gag-fun.9cache.com/photo/avQZ9Y5_700b.jpg",
    published: "10/6/22",
    tag: "wtf",
    emoji_primary: "funny",
    emoji_primary_count: 5,
    updated_date: "",
  },
  {
    title:
      "American tourist smashes 2 busts at the Vatican when told that would not be possible to meet the Pope",
    image: "https://img-9gag-fun.9cache.com/photo/amALZjy_700b.jpg",
    published: "10/6/22",
    tag: "latest-news",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "",
  },
  {
    title: "Despair",
    image: "https://img-9gag-fun.9cache.com/photo/a8qbxyd_700b.jpg",
    published: "10/6/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 1,
    updated_date: "",
  },
  {
    title:
      "It's a drawing by Haruko Kudo and won the first place out of 27 hyper realistic cat drawings.",
    image: "https://img-9gag-fun.9cache.com/photo/aDYwqOB_700b.jpg",
    published: "10/6/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 3,
    updated_date: "",
  },
  {
    title: "Such a creepy pose",
    image: "https://img-9gag-fun.9cache.com/photo/aBdQjBD_700b.jpg",
    published: "10/6/22",
    tag: "animals",
    emoji_primary: "funny",
    emoji_primary_count: 3,
    updated_date: "",
  },
  {
    title: "Georgia police officers",
    image: "https://img-9gag-fun.9cache.com/photo/adPYw9D_700b.jpg",
    published: "10/14/2022",
    tag: "wtf",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "10/14/2022",
  },
  {
    title: "Tell us the weirdest fact you know",
    image: "https://img-9gag-fun.9cache.com/photo/aeQ0w1b_700b.jpg",
    published: "10/14/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 9,
    updated_date: "10/14/2022",
  },
  {
    title: "I have questions....",
    image: "https://img-9gag-fun.9cache.com/photo/a8qY9xV_700b.jpg",
    published: "10/14/2022",
    tag: "wtf",
    emoji_primary: "funny",
    emoji_primary_count: 6,
    updated_date: "10/14/2022",
  },
  {
    title: "One ticket, please!",
    image: "https://img-9gag-fun.9cache.com/photo/anzKVN5_700b.jpg",
    published: "10/14/2022",
    tag: "wtf",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "10/14/2022",
  },
  {
    title: "And he makes your game perfec!",
    image: "https://img-9gag-fun.9cache.com/photo/az2EZWB_700b.jpg",
    published: "10/14/2022",
    tag: "gaming",
    emoji_primary: "funny",
    emoji_primary_count: 6,
    updated_date: "10/14/2022",
  },
  {
    title:
      "I found something interesting. This little guy is flying over the black sea for hours. Right now its been 16-17 hours. Big Brothers is watching.",
    image: "https://img-9gag-fun.9cache.com/photo/avQgmOZ_700b.jpg",
    published: "10/14/2022",
    tag: "latest-news",
    emoji_primary: "funny",
    emoji_primary_count: 8,
    updated_date: "10/14/2022",
  },
  {
    title: "What is weaker?",
    image: "https://img-9gag-fun.9cache.com/photo/a6qy6PL_700b.jpg",
    published: "10/14/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 6,
    updated_date: "10/14/2022",
  },
  {
    title: "There's a truck on the left side of this photo (not photoshopped)",
    image: "https://img-9gag-fun.9cache.com/photo/a8qY9MO_700b.jpg",
    published: "10/14/2022",
    tag: "awesome",
    emoji_primary: "funny",
    emoji_primary_count: 9,
    updated_date: "10/14/2022",
  },
  {
    title: "AAAAAAAAAH",
    image: "https://img-9gag-fun.9cache.com/photo/ap90YLD_700b.jpg",
    published: "10/14/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 9,
    updated_date: "10/14/2022",
  },
  {
    title: "This needs a Netflix series",
    image: "https://img-9gag-fun.9cache.com/photo/amA0QGX_700b.jpg",
    published: "10/14/2022",
    tag: "savage",
    emoji_primary: "funny",
    emoji_primary_count: 1,
    updated_date: "10/14/2022",
  },
  {
    title: "Sure would love to hang out again",
    image: "https://img-9gag-fun.9cache.com/photo/aZDxz8p_700b.jpg",
    published: "10/14/2022",
    tag: "random",
    emoji_primary: "funny",
    emoji_primary_count: 5,
    updated_date: "10/14/2022",
  },
  {
    title: "Just hang in there...",
    image: "https://img-9gag-fun.9cache.com/photo/axoZP72_700b.jpg",
    published: "10/13/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 8,
    updated_date: "10/13/2022",
  },
  {
    title: "The RTX 4090 Reviews Are Out!",
    image: "https://img-9gag-fun.9cache.com/photo/aXnpVE6_700b.jpg",
    published: "10/13/2022",
    tag: "gaming",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "10/13/2022",
  },
  {
    title: "The few. The proud.",
    image: "https://img-9gag-fun.9cache.com/photo/aWGW2Gx_700b.jpg",
    published: "10/13/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 4,
    updated_date: "10/13/2022",
  },
  {
    title: "Good ole days",
    image: "https://img-9gag-fun.9cache.com/photo/agov7MK_700b.jpg",
    published: "10/13/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "10/13/2022",
  },
  {
    title: "A honest mirror",
    image: "https://img-9gag-fun.9cache.com/photo/arm4dny_700b.jpg",
    published: "10/13/2022",
    tag: "girl-celebrity",
    emoji_primary: "funny",
    emoji_primary_count: 7,
    updated_date: "10/13/2022",
  },
  {
    title:
      "0000000000000 ..Russians found a passport of a Ukrainian,who blew the bridge. ... In Wikipedia , even signature is the same",
    image: "https://img-9gag-fun.9cache.com/photo/ap90bRp_700b.jpg",
    published: "10/13/2022",
    tag: "latest-news",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "10/13/2022",
  },
  {
    title: "Forget Tesla, Boston Dynamics etc This is Johnny 5.",
    image: "https://img-9gag-fun.9cache.com/photo/arm4ZQV_700b.jpg",
    published: "10/13/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 7,
    updated_date: "10/13/2022",
  },
  {
    title: "When you play Cities Skylines to torture the NPC",
    image: "https://img-9gag-fun.9cache.com/photo/a5XP1oo_700b.jpg",
    published: "10/12/2022",
    tag: "wtf",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "10/12/2022",
  },
  {
    title: "Kenyon Martin called out Jeremy Lin for his hair and Lin had a wholesome response",
    image: "https://img-9gag-fun.9cache.com/photo/aGEwygK_700b.jpg",
    published: "10/12/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 4,
    updated_date: "10/12/2022",
  },
  {
    title: "Baby bunnies found in the backyard",
    image: "https://img-9gag-fun.9cache.com/photo/avQgygW_700b.jpg",
    published: "10/12/2022",
    tag: "animals",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "10/12/2022",
  },
  {
    title: "Trucks play Britney Spears on rear gear",
    image: "https://img-9gag-fun.9cache.com/photo/aA0rXZg_700b.jpg",
    published: "10/12/2022",
    tag: "travel",
    emoji_primary: "funny",
    emoji_primary_count: 8,
    updated_date: "10/12/2022",
  },
  {
    title: "Let's get edgy",
    image: "https://img-9gag-fun.9cache.com/photo/ap90NQD_700b.jpg",
    published: "10/11/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "10/11/2022",
  },
  {
    title: "Have a nice day folks!",
    image: "https://img-9gag-fun.9cache.com/photo/aZDxZNQ_700b.jpg",
    published: "10/11/2022",
    tag: "wholesome",
    emoji_primary: "funny",
    emoji_primary_count: 5,
    updated_date: "10/11/2022",
  },
  {
    title: "We've come a long way",
    image: "https://img-9gag-fun.9cache.com/photo/a04LNPO_700b.jpg",
    published: "10/11/2022",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 3,
    updated_date: "10/11/2022",
  },
  {
    title: "Louisiana was more or less the \\u201csiberia\\u201d of france",
    image: "https://img-9gag-fun.9cache.com/photo/aoK00jn_700b.jpg",
    published: "10/10/22",
    tag: "awesome",
    emoji_primary: "funny",
    emoji_primary_count: 6,
    updated_date: "",
  },
  {
    title: "Where's the logic",
    image: "https://img-9gag-fun.9cache.com/photo/a4odQDZ_700b.jpg",
    published: "10/10/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 7,
    updated_date: "",
  },
  {
    title:
      "Echoes of the past, robot gorillaman finding out what deathwatch think of them during a visit.",
    image: "https://img-9gag-fun.9cache.com/photo/aL1PMzW_700b.jpg",
    published: "10/10/22",
    tag: "warhammer",
    emoji_primary: "funny",
    emoji_primary_count: 3,
    updated_date: "",
  },
  {
    title: "Only backup puukko",
    image: "https://img-9gag-fun.9cache.com/photo/aVbWP58_700b.jpg",
    published: "10/10/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 2,
    updated_date: "",
  },
  {
    title: "Killer Queen",
    image: "https://img-9gag-fun.9cache.com/photo/aA0r3nR_700b.jpg",
    published: "10/10/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 4,
    updated_date: "",
  },
  {
    title: "Is there an incognito mode for this ?",
    image: "https://img-9gag-fun.9cache.com/photo/aWGWOv6_700b.jpg",
    published: "10/10/22",
    tag: "funny",
    emoji_primary: "funny",
    emoji_primary_count: 1,
    updated_date: "",
  },
];

export const MOCK_FUNNY_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "funny",
    isDemo: true,
    link: "",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: "",
      nickname: "Funny",
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsFunny: React.FC<Props> = (props: Props) => {
  // const [list, setList] = React.useState<IHomeData[]>([]);
  // const [loading, setLoading] = React.useState<boolean>(true);
  // const navigate = useNavigate();

  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_FUNNY_TO_NOTE} />
    </div>
  );
};

export default NewsFunny;
