import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Encore Wire Corporation: Still Extremely Undervalued",
    link: "https://seekingalpha.com/article/4544661-encore-wire-corporation-still-extremely-undervalued?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 101,
    createdAt: "10/04/2022",
    author: {
      id: 101,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Jabil: The Quarter That Was, The Years To Be",
    link: "https://seekingalpha.com/article/4544660-jabil-the-quarter-that-was-the-years-to-be?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 102,
    createdAt: "10/04/2022",
    author: {
      id: 102,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Roku: This One Has Been De-Risked A Bit",
    link: "https://seekingalpha.com/article/4544659-roku-this-one-has-been-de-risked-a-bit?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 103,
    createdAt: "10/04/2022",
    author: {
      id: 103,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Semiconductor Winners And Losers At The Start Of Q4 2022 (Technical Analysis)",
    link: "https://seekingalpha.com/article/4544658-semiconductor-winners-and-losers-at-the-start-of-q4-2022?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 104,
    createdAt: "10/04/2022",
    author: {
      id: 104,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Bill.com: Buy The Stock On Solid Growth Prospects",
    link: "https://seekingalpha.com/article/4544655-billcom-buy-the-stock-on-solid-growth-prospects?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 105,
    createdAt: "10/04/2022",
    author: {
      id: 105,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "HelloFresh: Slowing Growth But Improving Margins",
    link: "https://seekingalpha.com/article/4544654-hellofresh-slowing-growth-but-improving-margins?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 106,
    createdAt: "10/04/2022",
    author: {
      id: 106,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "It's Time To Replace Your Old Bond Portfolio - Here's How To Start",
    link: "https://seekingalpha.com/article/4544663-time-to-replace-your-old-bond-portfolio?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 107,
    createdAt: "10/04/2022",
    author: {
      id: 107,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "There Is Nothing Wrong With Optane And 3D Xpoint",
    link: "https://seekingalpha.com/article/4544653-there-is-nothing-wrong-with-optane-and-3d-xpoint?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 108,
    createdAt: "10/04/2022",
    author: {
      id: 108,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "STMicroelectronics: We Are Long",
    link: "https://seekingalpha.com/article/4544651-stmicroelectronics-we-are-long?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 109,
    createdAt: "10/04/2022",
    author: {
      id: 109,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title:
      "Choosing Closed-End Credit Funds Over Equity Funds: Collecting Real Cash Vs. 'Eating Your Seed Corn'",
    link: "https://seekingalpha.com/article/4544649-choosing-closed-end-credit-funds-over-equity-funds-collecting-real-cash?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 110,
    createdAt: "10/04/2022",
    author: {
      id: 110,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Microsoft: A Stock For All Seasons",
    link: "https://seekingalpha.com/article/4544648-microsoft-a-stock-for-all-seasons?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 111,
    createdAt: "10/04/2022",
    author: {
      id: 111,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Myovant: Sumitomo Bid Is A Head Scratcher, Where Is The Value?",
    link: "https://seekingalpha.com/article/4544650-myovant-stock-sumitomo-bid-defies-logic?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 112,
    createdAt: "10/04/2022",
    author: {
      id: 112,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Ahold Delhaize: A Safe Place During Uncertain Times",
    link: "https://seekingalpha.com/article/4544647-ahold-delhaize-a-safe-place-during-uncertain-times?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 113,
    createdAt: "10/04/2022",
    author: {
      id: 113,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Compounders And Dividends: September 2022 Portfolio Update",
    link: "https://seekingalpha.com/article/4544646-compounders-and-dividends-september-2022-portfolio-update?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 114,
    createdAt: "10/04/2022",
    author: {
      id: 114,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Total Return Forecasts: Major Asset Classes - October 4, 2022",
    link: "https://seekingalpha.com/article/4544645-total-return-forecasts-major-asset-classes-october-4-2022?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 115,
    createdAt: "10/04/2022",
    author: {
      id: 115,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Private Sector Job Openings Fell Sharply In August",
    link: "https://seekingalpha.com/article/4544643-private-sector-job-openings-fell-sharply-in-august?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 116,
    createdAt: "10/04/2022",
    author: {
      id: 116,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Absolute Software: Rising Star In The $240.27 Billion Cybersecurity Industry",
    link: "https://seekingalpha.com/article/4544641-absolute-software-rising-star-in-the-240-27-billion-cybersecurity-industry?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 117,
    createdAt: "10/04/2022",
    author: {
      id: 117,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Calamos Investment Team Outlooks, October 2022",
    link: "https://seekingalpha.com/article/4544640-calamos-investment-team-outlooks-october-2022?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 118,
    createdAt: "10/04/2022",
    author: {
      id: 118,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Intel And Nvidia Debate Moore's Law Amid Bloodbaths",
    link: "https://seekingalpha.com/article/4544639-intel-nvidia-moores-law-chips-act?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 119,
    createdAt: "10/04/2022",
    author: {
      id: 119,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "PayPal: Q3 Earnings Could Be The Turning Point",
    link: "https://seekingalpha.com/article/4544638-paypal-q3-earnings-could-be-the-turning-point?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },

  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "What Is Currency Risk?",
    link: "https://seekingalpha.com/article/4544644-what-is-currency-risk?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "RYLD Vs. IWM: When To Pick Which And Why",
    link: "https://seekingalpha.com/article/4544636-ryld-vs-iwm-when-to-pick-which-and-why?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title:
      "Bet On A Recovery With Raytheon Technologies, But Defend Your Portfolio With Lockheed Martin",
    link: "https://seekingalpha.com/article/4544642-raytheon-technologies-bet-recovery-lockheed-martin-defend-portfolio?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "GoPro: Not The Time To Gamble",
    link: "https://seekingalpha.com/article/4544635-gopro-not-the-time-to-gamble?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Novonix: Early-Stage, But Well-Positioned Anode Material Manufacturer",
    link: "https://seekingalpha.com/article/4544634-novonix-well-positioned-anode-material-manufacturer?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Crypto Contrarian: The Market Hates Litecoin, It Shouldn't",
    link: "https://seekingalpha.com/article/4544633-crypto-contrarian-market-hates-litecoin-it-shouldnt?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "A Deep-Dive Into Shopify's Long-Term Potential",
    link: "https://seekingalpha.com/article/4544632-deep-dive-shopify-long-term-potential?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Google's 'Other Bet': A Deep Dive Into Waymo",
    link: "https://seekingalpha.com/article/4544631-googles-other-bet-a-deep-dive-into-waymo?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "ETF Reliable Retirement Portfolio-The Quarter Everything Collapsed: 2022 Q3 Update",
    link: "https://seekingalpha.com/article/4544652-etf-reliable-retirement-portfolio-the-quarter-everything-collapsed-2022-q3-update?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
  {
    image: "",
    tag: "news",
    tags: "",
    website: "seekingalpha.com",
    isDemo: true,
    title: "Sovos Brands: Strong Growth Continues",
    link: "https://seekingalpha.com/article/4544630-sovos-brands-strong-growth-continues?source=feed_all_articles",
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 120,
    createdAt: "10/04/2022",
    author: {
      id: 120,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: ["news"],
    },
  },
];

interface Props {}

const SeekingAlpha: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA} />
    </div>
  );
};

export default SeekingAlpha;
