import { Button, Drawer, message } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

import API from "@/apis";
import IconFont from "@/components/IconFont";
import LoginButton from "@/layout/Header/Login";
import SignupButton from "@/layout/Header/SignUp";

const MenuButton = () => {
  const [visible, setVisible] = useState(false);
  const handleClose = () => setVisible(false);
  const handleOpen = () => setVisible(true);

  const handleLoginSuccess = async () => {
    // setIsLogin(true);
    const data = await API.getUserInfo();
    console.log(data);
    message.success("Login success!");
  };

  const handleSignupSuccess = () => {
    // setIsLogin(true);
    message.success("Sign up success!");
  };

  return (
    <>
      <Button
        type="text"
        icon={<IconFont style={{ fontSize: 20, color: "#23262F" }} type="icon-menu" />}
        onClick={handleOpen}
      />
      <Drawer
        title={
          <div className="flex items-center justify-between">
            <h3>Mojiverse</h3>
            {/* <DarkSwitch /> */}
          </div>
        }
        placement="right"
        onClose={handleClose}
        visible={visible}
        className="menu-drawer"
      >
        <Link to="/" onClick={handleClose}>
          <div className="h-16 flex items-center justify-between cursor-pointer hover:bg-slate-100 px-6 duration-300">
            <div className="text-base font-bold">Home</div>
            <IconFont type="icon-right" />
          </div>
        </Link>
        <Link to="/following" onClick={handleClose}>
          <div className="h-16 flex items-center justify-between cursor-pointer hover:bg-slate-100 px-6 duration-300">
            <div className="text-base font-bold">Following</div>
            <IconFont type="icon-right" />
          </div>
        </Link>
        <Link to="/ranking" onClick={handleClose}>
          <div className="h-16 flex items-center justify-between cursor-pointer hover:bg-slate-100 px-6 duration-300">
            <div className="text-base font-bold">Ranking</div>
            <IconFont type="icon-right" />
          </div>
        </Link>
        <div className="h-16 flex items-center justify-between cursor-pointer hover:bg-slate-100 px-6 duration-300">
          <div className="text-base font-bold">Message</div>
          <IconFont type="icon-right" />
        </div>
        <div className="h-12 md:hidden flex text-base items-center justify-between mt-8 px-6">
          <LoginButton onLoginSuccess={handleLoginSuccess} />
          <SignupButton onSignupSuccess={handleSignupSuccess} />
        </div>
      </Drawer>
    </>
  );
};
export default MenuButton;
