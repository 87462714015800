import API from "@/apis";
import PostForm from "@/layout/PostNew/PostForm";
import { userInfoState } from "@/store";
import { message } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

interface Props {}

const PostPage: React.FC<Props> = (props: Props) => {
  const userInfo = useRecoilValue(userInfoState);
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    const res = await API.createPost({
      ...values,
      author_id: userInfo?.id,
      tags: JSON.stringify([values.tags]),
    });

    if (res) {
      message.success("post successfully!", 1.5, () => {
        navigate("/");
      });
    }
  };

  return (
    <div className="post-page-container max-w-lg mx-auto p-4">
      <h3 className="text-center mb-3" style={{ fontSize: 32, lineHeight: 1.1 }}>
        Post
      </h3>
      <PostForm onSubmit={handleSubmit} />
    </div>
  );
};

export default PostPage;
