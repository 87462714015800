import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    link: "https://www.foxnews.com/sports/padres-eliminate-mets-game-3-joe-musgroves-stellar-start",
    title: "Padres eliminate Mets in do-or-die Game 3 behind Joe Musgrove's stellar start",
    summary:
      "The San Diego Padres rallied behind Joe Musgrove's stellar start, as the offense capitalized on New York Mets mistakes to advance to the NLDS against the Los Angeles Dodgers.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/10/1200/675/joe-musgrove-1.jpg?ve=1&tl=1",
    published: "10/10/2022",
    tag: "sports",
  },
  {
    link: "https://www.foxnews.com/sports/browns-acquire-pro-bowl-linebacker-falcons-report",
    title: "Browns to acquire Pro Bowl linebacker from Falcons: report",
    summary:
      "The Cleveland Browns have reportedly added Pro Bowl linebacker Deion Jones after trading a late-round future draft pick to the Atlanta Falcons.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/10/1200/675/deion-jones.jpg?ve=1&tl=1",
    published: "10/10/2022",
    tag: "sports",
  },
  {
    link: "https://www.foxnews.com/sports/steelers-kenny-picket-involved-two-heated-skirmishes-first-career-start",
    title: "Steelers' Kenny Pickett involved in two heated skirmishes in first career start",
    summary:
      "Kenny Pickett showed just show fiery he could be when he got into the face of Shaq Lawson during the Pittsburgh Steelers' loss to the Buffalo Bills Sunday.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/10/1200/675/Kenny-Pickett7.jpg?ve=1&tl=1",
    published: "10/10/2022",
    tag: "sports",
  },
  {
    link: "https://www.foxnews.com/sports/mets-make-umpires-check-joe-musgrove-sticky-substances-down-four-runs-padres-take-exception",
    title:
      "Mets make umpires check Joe Musgrove for sticky substances down four runs, Padres take exception",
    summary:
      "New York Mets manager Buck Showalter checked San Diego Padres starter Joe Musgrove for sticky substances as he dominated his team's lineup in a do-or-die wild card game.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/10/1200/675/joe-musgrove.jpg?ve=1&tl=1",
    published: "10/10/2022",
    tag: "sports",
  },
  {
    link: "https://www.foxnews.com/us/chicago-train-hits-car-citys-far-south-side-injuries-reported",
    title: "Chicago train hits car on city's far South Side, 2 injuries reported",
    summary:
      "A Chicago Metra train hit a car on Sunday afternoon on the city's far South Side on Sunday afternoon, leaving two people seriously injured.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/10/931/523/Untitled-design-173.png?ve=1&tl=1",
    published: "10/10/2022",
    tag: "us",
  },
  {
    link: "https://www.foxnews.com/sports/aaron-rodgers-rips-packers-losing-talk-thats-not-winning-football",
    title: "Aaron Rodgers rips Packers' losing talk: 'That's not winning football'",
    summary:
      "Green Bay Packers quarterback Aaron Rodgers addressed his teammates who talked about losing after they fell to the New York Giants in London Sunday 27-22.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/10/1200/675/Aaron-rodgers.jpg?ve=1&tl=1",
    published: "10/10/2022",
    tag: "sports",
  },
  {
    link: "https://www.foxnews.com/media/levin-claims-americas-woes-man-made-disasters-democrats",
    title: "Mark Levin claims America's woes are 'man-made’ disasters by Democrats",
    summary:
      "Life, Liberty & Levin host Mark Levin scorched Democratic Party leadership for their track record across a wide variety of issues from crime to political persecution.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2021/06/1200/675/Mark-Levin-on-district-city-attorneys-using-power-to-advance-political-agenda.jpeg?ve=1&tl=1",
    published: "10/10/2022",
    tag: "media",
  },
  {
    link: "https://www.foxnews.com/sports/lightning-suspend-ian-cole-defenseman-faces-accusations-of-sexual-abuse-grooming-woman-she-minor",
    title:
      "Lightning suspend Ian Cole as defenseman faces accusations of sexual abuse, grooming woman when she was minor",
    summary:
      "Tampa Bay Lightning defenseman Ian Cole is accused of sexually assaulting and grooming a woman when she was a minor. The accusations were made on Twitter.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/10/1200/675/Ian-Cole2.jpg?ve=1&tl=1",
    published: "10/10/2022",
    tag: "sports",
  },
  {
    link: "https://www.foxnews.com/media/rep-elise-stefanik-predicts-republicans-win-largest-majority-since-great-depression",
    title:
      "Rep. Elise Stefanik predicts Republicans could win 'the largest majority since the Great Depression'",
    summary:
      "Rep. Elise Stefanik, R-N.Y., appeared on “Sunday Night in America with Trey Gowdy” to preview a Republican Congress and address a shooting outside Lee Zeldin’s home.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/06/1200/675/House-Republicans-Speak-After-Caucus-Meeting.jpg?ve=1&tl=1",
    published: "10/10/2022",
    tag: "media",
  },
  {
    link: "https://www.foxnews.com/politics/new-york-gop-gov-candidate-lee-zeldin-crime-crisis-hits-really-close-after-shooting-outside-home",
    title:
      "New York GOP gov. candidate Lee Zeldin says crime crisis 'hits really close' after shooting outside home",
    summary:
      "New York Rep. Lee Zeldin, R-N.Y., who's also running for governor in the state, said that the state's crime crisis is hitting home after two men were shot outside of his home on Sunday.",
    image:
      "https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2022/10/931/523/Untitled-design-172.png?ve=1&tl=1",
    published: "10/10/2022",
    tag: "politics",
  },
];

const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "fox",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: "",
      nickname: "",
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsFox: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsFox;
