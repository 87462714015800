import NoteV2 from "@/components/Note/NoteV2";
import { IHomeData, IPostDetail } from "@/models";
import { getIDFromUrl } from "@/utils";
import { useEffect, useState } from "react";
import PostDetailV2 from "../detail/PostDetailV2";
import { NYTIMES_MOCK_DATA } from "./nytimes";

const NoteList = (props: { dataList: IHomeData[] }) => {
  return (
    <div className="note-detail-more w-full max-w-[1264px] mx-auto px-0 sm:flex xl:justify-center flex-wrap">
      {props.dataList.map((item) => {
        return (
          <div className="mx-2" key={item.id}>
            <NoteV2 data={item} />
          </div>
        );
      })}
    </div>
  );
};

const NyTimesDetail = () => {
  const [detail, setDetail] = useState<IPostDetail | null>(null);
  const [nextId, setNextId] = useState<string>("");
  const [prevId, setPrevId] = useState<string>("");

  useEffect(() => {
    const id = getIDFromUrl();
    NYTIMES_MOCK_DATA.forEach((item, index) => {
      if (`${item.id}` === id) {
        setDetail({
          id: item.id,
          title: item.title,
          website: item.website,
          image: item.image,
          link: item.link,
          tags: [item.tag],
          author: item.author,
          emoji: item.emoji,
          createdAt: item.createdAt,
          summary: item.summary,
          credit: item.credit,
          media_category: item.media_category,
        });

        if (NYTIMES_MOCK_DATA[index + 1]) {
          setNextId(NYTIMES_MOCK_DATA[index + 1].id?.toString());
        }

        if (NYTIMES_MOCK_DATA[index - 1]) {
          setPrevId(NYTIMES_MOCK_DATA[index - 1].id?.toString());
        }
      }
    });
  }, []);

  return (
    <div className="bg-white">
      <div className="common-page-container pb-24 sm:px-4">
        <div className="relative">
          <PostDetailV2 detail={detail} nextId={nextId} prevId={prevId} />

          <div className="py-8 sm:py-20 px-2">
            <h3 className="text-center merriweather-font text-lg font-bold m-0 tracking-normal mb-4 sm:mb-8">
              View More
            </h3>
            <NoteList
              dataList={[
                NYTIMES_MOCK_DATA[0],
                NYTIMES_MOCK_DATA[1],
                NYTIMES_MOCK_DATA[2],
                NYTIMES_MOCK_DATA[3],
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NyTimesDetail;
