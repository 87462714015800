import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title: "Yuki Yuna is a Hero: Hanayui no Kirameki coming to consoles",
    link: "https://www.gematsu.com/2022/10/yuki-yuna-is-a-hero-hanayui-no-kirameki-coming-to-consoles",
    comments:
      "https://www.gematsu.com/2022/10/yuki-yuna-is-a-hero-hanayui-no-kirameki-coming-to-consoles#respond",
    author: "Sal Romano",
    published: "10/09/2022",
    tags: "[{'term': 'Industry', 'scheme': None, 'label': None}, {'term': 'AltPlus', 'scheme': None, 'label': None}, {'term': 'Entergram', 'scheme': None, 'label': None}, {'term': 'Kadokawa Corporation', 'scheme': None, 'label': None}, {'term': 'RPG', 'scheme': None, 'label': None}, {'term': 'scopes', 'scheme': None, 'label': None}, {'term': 'Yuki Yuna is a Hero: Hanayui no Kirameki', 'scheme': None, 'label': None}]",
    summary:
      'Entergram will release a console version of Yuki Yuna is a Hero: Hanayui no Kirameki, &#8230;\n<p><a href="https://www.gematsu.com/2022/10/yuki-yuna-is-a-hero-hanayui-no-kirameki-coming-to-consoles" rel="nofollow">Source</a></p>',
    tag: "Industry",
  },
  {
    title:
      "Persona 3 Portable and Persona 4 Golden for Xbox Series, PS4, Xbox One, Switch, and PC launch January 19, 2023",
    link: "https://www.gematsu.com/2022/10/persona-3-portable-and-persona-4-golden-for-xbox-series-ps4-xbox-one-switch-and-pc-launch-january-19-2023",
    comments:
      "https://www.gematsu.com/2022/10/persona-3-portable-and-persona-4-golden-for-xbox-series-ps4-xbox-one-switch-and-pc-launch-january-19-2023#respond",
    author: "Sal Romano",
    published: "10/08/2022",
    tags: "[{'term': 'PC', 'scheme': None, 'label': None}, {'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Xbox One', 'scheme': None, 'label': None}, {'term': 'Xbox Series', 'scheme': None, 'label': None}, {'term': 'ATLUS', 'scheme': None, 'label': None}, {'term': 'Persona (series)', 'scheme': None, 'label': None}, {'term': 'Persona 3 Portable', 'scheme': None, 'label': None}, {'term': 'Persona 4 Golden', 'scheme': None, 'label': None}, {'term': 'Release Dates', 'scheme': None, 'label': None}, {'term': 'RPG', 'scheme': None, 'label': None}]",
    summary:
      'Persona 3 Portable will launch digitally for Xbox Series, PlayStation 4, Xbox One, Switch, and &#8230;\n<p><a href="https://www.gematsu.com/2022/10/persona-3-portable-and-persona-4-golden-for-xbox-series-ps4-xbox-one-switch-and-pc-launch-january-19-2023" rel="nofollow">Source</a></p>',
    tag: "PC",
  },
  {
    title:
      "Master Detective Archives: RAIN CODE details setting, characters, gameplay mechanics, more",
    link: "https://www.gematsu.com/2022/10/master-detective-archives-rain-code-details-setting-characters-gameplay-mechanics-more",
    comments:
      "https://www.gematsu.com/2022/10/master-detective-archives-rain-code-details-setting-characters-gameplay-mechanics-more#respond",
    author: "Sal Romano",
    published: "10/08/2022",
    tags: "[{'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Master Detective Archives: RAIN CODE', 'scheme': None, 'label': None}, {'term': 'Screenshots', 'scheme': None, 'label': None}, {'term': 'Spike Chunsoft', 'scheme': None, 'label': None}, {'term': 'Too Kyo Games', 'scheme': None, 'label': None}]",
    summary:
      'Spike Chunsoft has released new information and screenshots for Master Detective Archives: RAIN CODE, the &#8230;\n<p><a href="https://www.gematsu.com/2022/10/master-detective-archives-rain-code-details-setting-characters-gameplay-mechanics-more" rel="nofollow">Source</a></p>',
    tag: "Switch",
  },
  {
    title:
      "Crash Bandicoot 4: It’s About Time coming to Steam on October 18; new game announcement teased for December 8",
    link: "https://www.gematsu.com/2022/10/crash-bandicoot-4-its-about-time-coming-to-steam-on-october-18-new-game-announcement-teased-for-december-8",
    comments:
      "https://www.gematsu.com/2022/10/crash-bandicoot-4-its-about-time-coming-to-steam-on-october-18-new-game-announcement-teased-for-december-8#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'Industry', 'scheme': None, 'label': None}, {'term': 'PC', 'scheme': None, 'label': None}, {'term': 'Activision', 'scheme': None, 'label': None}, {'term': \"Crash Bandicoot 4: It's About Time\", 'scheme': None, 'label': None}, {'term': 'Release Dates', 'scheme': None, 'label': None}, {'term': 'Toys for Bob', 'scheme': None, 'label': None}]",
    summary:
      'Publisher Activision and developer Toys for Bob will release the PC version of Crash Bandicoot &#8230;\n<p><a href="https://www.gematsu.com/2022/10/crash-bandicoot-4-its-about-time-coming-to-steam-on-october-18-new-game-announcement-teased-for-december-8" rel="nofollow">Source</a></p>',
    tag: "Industry",
  },
  {
    title: "Ultra Kaiju Monster Rancher launches October 20 in the west",
    link: "https://www.gematsu.com/2022/10/ultra-kaiju-monster-rancher-launches-october-20-in-the-west",
    comments:
      "https://www.gematsu.com/2022/10/ultra-kaiju-monster-rancher-launches-october-20-in-the-west#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Bandai Namco', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'Koei Tecmo', 'scheme': None, 'label': None}, {'term': 'Release Dates', 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}, {'term': 'Ultra Kaiju Monster Rancher', 'scheme': None, 'label': None}]",
    summary:
      'Ultra Kaiju Monster Rancher will launch for Switch on October 20 in the west, publisher &#8230;\n<p><a href="https://www.gematsu.com/2022/10/ultra-kaiju-monster-rancher-launches-october-20-in-the-west" rel="nofollow">Source</a></p>',
    tag: "Switch",
  },
  {
    title: "Nickelodeon All-Star Brawl DLC character Rocko now available",
    link: "https://www.gematsu.com/2022/10/nickelodeon-all-star-brawl-dlc-character-rocko-now-available",
    comments:
      "https://www.gematsu.com/2022/10/nickelodeon-all-star-brawl-dlc-character-rocko-now-available#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PS3', 'scheme': None, 'label': None}, {'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Xbox One', 'scheme': None, 'label': None}, {'term': 'Xbox Series', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'DLC', 'scheme': None, 'label': None}, {'term': 'Fair Play Labs', 'scheme': None, 'label': None}, {'term': 'Gameplay', 'scheme': None, 'label': None}, {'term': 'Ludosity', 'scheme': None, 'label': None}, {'term': 'Nickelodeon All-Star Brawl', 'scheme': None, 'label': None}]",
    summary:
      'Publisher GameMill Entertainment and developers Ludosity and Fair Play Labs have released Nickelodeon All-Star Brawl &#8230;\n<p><a href="https://www.gematsu.com/2022/10/nickelodeon-all-star-brawl-dlc-character-rocko-now-available" rel="nofollow">Source</a></p>',
    tag: "PS3",
  },
  {
    title: "God of War Ragnarok goes gold",
    link: "https://www.gematsu.com/2022/10/god-of-war-ragnarok-goes-gold",
    comments: "https://www.gematsu.com/2022/10/god-of-war-ragnarok-goes-gold#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'God of War Ragnarok', 'scheme': None, 'label': None}, {'term': 'Gold Status', 'scheme': None, 'label': None}, {'term': 'Santa Monica Studio', 'scheme': None, 'label': None}, {'term': 'SIE', 'scheme': None, 'label': None}, {'term': 'SIEA', 'scheme': None, 'label': None}, {'term': 'SIEE', 'scheme': None, 'label': None}]",
    summary:
      'God of War Ragnarok has gone gold, publisher Sony Interactive Entertainment and developer Santa Monica &#8230;\n<p><a href="https://www.gematsu.com/2022/10/god-of-war-ragnarok-goes-gold" rel="nofollow">Source</a></p>',
    tag: "PS4",
  },
  {
    title: "Trinity Trigger ‘Day 1 Edition’ announced for North America",
    link: "https://www.gematsu.com/2022/10/trinity-trigger-day-1-edition-announced-for-north-america",
    comments:
      "https://www.gematsu.com/2022/10/trinity-trigger-day-1-edition-announced-for-north-america#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'FuRyu', 'scheme': None, 'label': None}, {'term': 'RPG', 'scheme': None, 'label': None}, {'term': 'Special Editions', 'scheme': None, 'label': None}, {'term': 'Trinity Trigger', 'scheme': None, 'label': None}, {'term': 'XSEED Games', 'scheme': None, 'label': None}]",
    summary:
      'Publisher XSEED Games has announced the &#8220;Day 1 Edition&#8221; for Trinity Trigger, which will be &#8230;\n<p><a href="https://www.gematsu.com/2022/10/trinity-trigger-day-1-edition-announced-for-north-america" rel="nofollow">Source</a></p>',
    tag: "PS4",
  },
  {
    title: "Loop8: Summer of Gods ‘Celestial Edition’ announced for North America",
    link: "https://www.gematsu.com/2022/10/loop8-summer-of-gods-celestial-edition-announced-for-north-america",
    comments:
      "https://www.gematsu.com/2022/10/loop8-summer-of-gods-celestial-edition-announced-for-north-america#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Xbox One', 'scheme': None, 'label': None}, {'term': 'Loop8: Summer of Gods', 'scheme': None, 'label': None}, {'term': 'Marvelous', 'scheme': None, 'label': None}, {'term': 'Sieg Games', 'scheme': None, 'label': None}, {'term': 'Special Editions', 'scheme': None, 'label': None}, {'term': 'XSEED Games', 'scheme': None, 'label': None}]",
    summary:
      'Publisher XSEED Games has announced the &#8220;Celestial Edition&#8221; for Loop8: Summer of Gods, which will &#8230;\n<p><a href="https://www.gematsu.com/2022/10/loop8-summer-of-gods-celestial-edition-announced-for-north-america" rel="nofollow">Source</a></p>',
    tag: "PS4",
  },
  {
    title: "Gotham Knights gameplay launch trailer",
    link: "https://www.gematsu.com/2022/10/gotham-knights-gameplay-launch-trailer",
    comments: "https://www.gematsu.com/2022/10/gotham-knights-gameplay-launch-trailer#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PC', 'scheme': None, 'label': None}, {'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'Xbox Series', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'Gotham Knights', 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}, {'term': 'Warner Bros. Interactive Entertainment', 'scheme': None, 'label': None}, {'term': 'WB Games Montreal', 'scheme': None, 'label': None}]",
    summary:
      'Publisher Warner Bros. Games and developer WB Games Montreal have released the gameplay launch trailer &#8230;\n<p><a href="https://www.gematsu.com/2022/10/gotham-knights-gameplay-launch-trailer" rel="nofollow">Source</a></p>',
    tag: "PC",
  },
  {
    title: "JoJo’s Bizarre Adventure: All Star Battle R DLC character Risotto Nero announced",
    link: "https://www.gematsu.com/2022/10/jojos-bizarre-adventure-all-star-battle-r-dlc-character-risotto-nero-announced",
    comments:
      "https://www.gematsu.com/2022/10/jojos-bizarre-adventure-all-star-battle-r-dlc-character-risotto-nero-announced#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Xbox One', 'scheme': None, 'label': None}, {'term': 'Xbox Series', 'scheme': None, 'label': None}, {'term': 'Bandai Namco', 'scheme': None, 'label': None}, {'term': 'CyberConnect2', 'scheme': None, 'label': None}, {'term': 'DLC', 'scheme': None, 'label': None}, {'term': \"JoJo's Bizarre Adventure: All Star Battle R\", 'scheme': None, 'label': None}]",
    summary:
      'Publisher Bandai Namco and developer CyberConnect2 have announced JoJo&#8217;s Bizarre Adventure: All Star Battle R &#8230;\n<p><a href="https://www.gematsu.com/2022/10/jojos-bizarre-adventure-all-star-battle-r-dlc-character-risotto-nero-announced" rel="nofollow">Source</a></p>',
    tag: "PS4",
  },
  {
    title: "No Man’s Sky ‘Waypoint’ update now available",
    link: "https://www.gematsu.com/2022/10/no-mans-sky-waypoint-update-now-available",
    comments: "https://www.gematsu.com/2022/10/no-mans-sky-waypoint-update-now-available#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PC', 'scheme': None, 'label': None}, {'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Xbox One', 'scheme': None, 'label': None}, {'term': 'Xbox Series', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'Hello Games', 'scheme': None, 'label': None}, {'term': \"No Man's Sky\", 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}]",
    summary:
      'Hello Games has released the &#8220;Waypoint&#8221; (version 4.0) update for No Man&#8217;s Sky, which overhauls &#8230;\n<p><a href="https://www.gematsu.com/2022/10/no-mans-sky-waypoint-update-now-available" rel="nofollow">Source</a></p>',
    tag: "PC",
  },
  {
    title: "Dragon Ball Xenoverse 2 DLC characters Gamma 1, Gohan (DBS Super Hero) announced",
    link: "https://www.gematsu.com/2022/10/dragon-ball-xenoverse-2-dlc-characters-gamma-1-gohan-dbs-super-hero-announced",
    comments:
      "https://www.gematsu.com/2022/10/dragon-ball-xenoverse-2-dlc-characters-gamma-1-gohan-dbs-super-hero-announced#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PC', 'scheme': None, 'label': None}, {'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Xbox One', 'scheme': None, 'label': None}, {'term': 'Bandai Namco', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'DLC', 'scheme': None, 'label': None}, {'term': 'Dragon Ball Xenoverse 2', 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}]",
    summary:
      'Publisher Bandai Namco and developer Dimps have announced Dragon Ball Xenoverse 2 downloadable content characters &#8230;\n<p><a href="https://www.gematsu.com/2022/10/dragon-ball-xenoverse-2-dlc-characters-gamma-1-gohan-dbs-super-hero-announced" rel="nofollow">Source</a></p>',
    tag: "PC",
  },
  {
    title: "Elle Fanning to appear in next game by Kojima Productions",
    link: "https://www.gematsu.com/2022/10/elle-fanning-to-appear-in-next-game-by-kojima-productions",
    comments:
      "https://www.gematsu.com/2022/10/elle-fanning-to-appear-in-next-game-by-kojima-productions#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'Industry', 'scheme': None, 'label': None}, {'term': 'Hideo Kojima', 'scheme': None, 'label': None}, {'term': 'Unannounced Game', 'scheme': None, 'label': None}]",
    summary:
      'Maleficent actor Elle Fanning will appear in the next game developed by Kojima Productions, the &#8230;\n<p><a href="https://www.gematsu.com/2022/10/elle-fanning-to-appear-in-next-game-by-kojima-productions" rel="nofollow">Source</a></p>',
    tag: "Industry",
  },
  {
    title: "Nintendo 64 – Nintendo Switch Online adds Pilotwings 64 on October 13",
    link: "https://www.gematsu.com/2022/10/nintendo-64-nintendo-switch-online-adds-pilotwings-64-on-october-13",
    comments:
      "https://www.gematsu.com/2022/10/nintendo-64-nintendo-switch-online-adds-pilotwings-64-on-october-13#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'Switch', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'Nintendo', 'scheme': None, 'label': None}, {'term': 'Nintendo 64 - Nintendo Switch Online', 'scheme': None, 'label': None}, {'term': 'Nintendo Switch Online', 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}]",
    summary:
      'Nintendo 64 &#8211; Nintendo Switch Online will add Pilotwings 64 on October 13, Nintendo announced. &#8230;\n<p><a href="https://www.gematsu.com/2022/10/nintendo-64-nintendo-switch-online-adds-pilotwings-64-on-october-13" rel="nofollow">Source</a></p>',
    tag: "Switch",
  },
  {
    title: "CHAOS;HEAD NOAH for PC to launch via Steam as previously planned on October 7",
    link: "https://www.gematsu.com/2022/10/chaoshead-noah-for-pc-to-launch-via-steam-as-previously-planned-on-october-7",
    comments:
      "https://www.gematsu.com/2022/10/chaoshead-noah-for-pc-to-launch-via-steam-as-previously-planned-on-october-7#respond",
    author: "Sal Romano",
    published: "10/07/2022",
    tags: "[{'term': 'PC', 'scheme': None, 'label': None}, {'term': 'Chaos;Head Noah', 'scheme': None, 'label': None}, {'term': 'Chiyomaru Studio', 'scheme': None, 'label': None}, {'term': 'MAGES.', 'scheme': None, 'label': None}, {'term': 'Spike Chunsoft', 'scheme': None, 'label': None}, {'term': 'Visual Novel', 'scheme': None, 'label': None}]",
    summary:
      'The previously cancelled Steam release of CHAOS;HEAD NOAH for PC will launch as originally planned &#8230;\n<p><a href="https://www.gematsu.com/2022/10/chaoshead-noah-for-pc-to-launch-via-steam-as-previously-planned-on-october-7" rel="nofollow">Source</a></p>',
    tag: "PC",
  },
  {
    title: "The Super Mario Bros. Movie teaser trailer",
    link: "https://www.gematsu.com/2022/10/the-super-mario-bros-movie-teaser-trailer",
    comments: "https://www.gematsu.com/2022/10/the-super-mario-bros-movie-teaser-trailer#respond",
    author: "Sal Romano",
    published: "10/06/2022",
    tags: "[{'term': 'Industry', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'Illumination Entertainment', 'scheme': None, 'label': None}, {'term': 'Nintendo', 'scheme': None, 'label': None}, {'term': 'The Super Mario Bros. Movie', 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}]",
    summary:
      'Nintendo and Illumination Entertainment have released the first teaser trailer for The Super Mario Bros. &#8230;\n<p><a href="https://www.gematsu.com/2022/10/the-super-mario-bros-movie-teaser-trailer" rel="nofollow">Source</a></p>',
    tag: "Industry",
  },
  {
    title: "CATAN: Console Edition announced for PS5, Xbox Series, PS4 and Xbox One",
    link: "https://www.gematsu.com/2022/10/catan-console-edition-announced-for-ps5-xbox-series-ps4-and-xbox-one",
    comments:
      "https://www.gematsu.com/2022/10/catan-console-edition-announced-for-ps5-xbox-series-ps4-and-xbox-one#respond",
    author: "Sal Romano",
    published: "10/06/2022",
    tags: "[{'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'Xbox One', 'scheme': None, 'label': None}, {'term': 'Xbox Series', 'scheme': None, 'label': None}, {'term': 'CATAN: Console Edition', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'Dovetail Games', 'scheme': None, 'label': None}, {'term': 'Game Announce', 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}]",
    summary:
      'Dovetail Games has announced CATAN: Console Edition for PlayStation 5, Xbox Series, PlayStation 4, and &#8230;\n<p><a href="https://www.gematsu.com/2022/10/catan-console-edition-announced-for-ps5-xbox-series-ps4-and-xbox-one" rel="nofollow">Source</a></p>',
    tag: "PS4",
  },
  {
    title: "Serious Sam: Siberian Mayhem now available for PS5, Xbox Series",
    link: "https://www.gematsu.com/2022/10/serious-sam-siberian-mayhem-now-available-for-ps5-xbox-series",
    comments:
      "https://www.gematsu.com/2022/10/serious-sam-siberian-mayhem-now-available-for-ps5-xbox-series#respond",
    author: "Sal Romano",
    published: "10/06/2022",
    tags: "[{'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'Xbox Series', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'Croteam', 'scheme': None, 'label': None}, {'term': 'Devolver Digital', 'scheme': None, 'label': None}, {'term': 'FPS', 'scheme': None, 'label': None}, {'term': 'Serious Sam: Siberian Mayhem', 'scheme': None, 'label': None}, {'term': 'Shooter', 'scheme': None, 'label': None}, {'term': 'Timelock Studio', 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}]",
    summary:
      'Publisher Devolver Digital and developers Croteam and Timelock Studio have released Serious Sam: Siberian Mayhem &#8230;\n<p><a href="https://www.gematsu.com/2022/10/serious-sam-siberian-mayhem-now-available-for-ps5-xbox-series" rel="nofollow">Source</a></p>',
    tag: "PS5",
  },
  {
    title: "One Piece Odyssey ‘Alabasta’ trailer",
    link: "https://www.gematsu.com/2022/10/one-piece-odyssey-alabasta-trailer",
    comments: "https://www.gematsu.com/2022/10/one-piece-odyssey-alabasta-trailer#respond",
    author: "Sal Romano",
    published: "10/06/2022",
    tags: "[{'term': 'PC', 'scheme': None, 'label': None}, {'term': 'PS4', 'scheme': None, 'label': None}, {'term': 'PS5', 'scheme': None, 'label': None}, {'term': 'Xbox Series', 'scheme': None, 'label': None}, {'term': 'Bandai Namco', 'scheme': None, 'label': None}, {'term': 'Clips', 'scheme': None, 'label': None}, {'term': 'ILCA', 'scheme': None, 'label': None}, {'term': 'One Piece Odyssey', 'scheme': None, 'label': None}, {'term': 'RPG', 'scheme': None, 'label': None}, {'term': 'Trailers', 'scheme': None, 'label': None}]",
    summary:
      'Publisher Bandai Namco and developer ILCA have released a new trailer for One Piece Odyssey &#8230;\n<p><a href="https://www.gematsu.com/2022/10/one-piece-odyssey-alabasta-trailer" rel="nofollow">Source</a></p>',
    tag: "PC\n",
  },
];
const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "gematsu",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: item.author,
      nickname: item.author,
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsGematsu: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsGematsu;
