import { WaterfallWithoutInfiniteScroll } from "@/components/Waterfall";
import * as React from "react";

const MOCK_DATA = [
  {
    title:
      "Meta’s Horizon Worlds Metaverse App Still Too Buggy to Be Used According to Company Executives",
    link: "https://news.bitcoin.com/metas-horizon-worlds-metaverse-app-still-too-buggy-to-be-used-according-to-company-executives/",
    author: "Sergio Goschenko",
    published: "10/10/2022",
    tag: "Metaverse",
    summary:
      "Meta's VP of Metaverse, Vishal Shah, acknowledged that Horizon Worlds, the flagship app that Meta uses to show the potential of its metaverse, still has many problems affecting its usability. In a recent memo, Shah explains that even Meta's employees are not using the app so much, and hints at the establishment of time quotas ...",
    image:
      "https://static.news.bitcoin.com/wp-content/uploads/2022/10/shutterstock_2165508023-760x428.jpg",
  },
  {
    title:
      "Mad Money’s Jim Cramer Wants Crypto Investors to Bet Against Him —  ‘I Have Done This for 42 Years’",
    link: "https://news.bitcoin.com/mad-moneys-jim-cramer-wants-crypto-investors-to-bet-against-him-i-have-done-this-for-42-years/",
    author: "Kevin Helms",
    published: "10/09/2022",
    tag: "Featured",
    summary:
      'The host of Mad Money, Jim Cramer, has challenged crypto investors to bet against him. "You do not do this for 42 years and lose money every year," he said. His statement followed a filing with the U.S. Securities and Exchange Commission (SEC) for "Inverse Cramer ETF." Jim Cramer to Crypto Investors: Bet Against Me ...',
    image:
      "https://static.news.bitcoin.com/wp-content/uploads/2022/10/jim-cramer-mad-money-760x428.jpg",
  },
  {
    title:
      "USD Index Starts Inching Toward Highs Again, Société Générale Economists Say Rebound Towards 114.8 Peak ‘Is Not Ruled Out’",
    link: "https://news.bitcoin.com/usd-index-starts-inching-toward-highs-again-societe-generale-economists-say-rebound-towards-114-8-peak-is-not-ruled-out/",
    author: "Jamie Redman",
    published: "10/09/2022",
    tag: "Economics",
    summary:
      "In 2022, the U.S. dollar has been very strong, despite the global economy's downturn and the rising inflation worldwide. 12 days ago, the U.S. Dollar Index (DXY) rallied to a high of 114.8 and since then, the index has pulled pack and a recent analysis from economists at Soci&#xe9;t&#xe9; G&#xe9;n&#xe9;rale notes that the index will ...",
    image: "https://static.news.bitcoin.com/wp-content/uploads/2022/10/usd-760x428.jpg",
  },
  {
    title:
      "Progress Toward Bitcoin’s Halving Is 60% Complete, Block Times Suggest Reduction Could Happen Next Year",
    link: "https://news.bitcoin.com/progress-toward-bitcoins-halving-is-60-complete-block-times-suggest-reduction-could-happen-next-year/",
    author: "Jamie Redman",
    published: "10/09/2022",
    tag: "Mining",
    summary:
      "According to countdown statistics based on the average block generation time of around ten minutes, progress toward the next Bitcoin block reward halving has surpassed 60%. However, while most halving countdown clocks leverage the ten-minute average, the countdown leveraging the most current block intervals of around 7:65 minutes shows the halving could occur in 2023. ...",
    image: "https://static.news.bitcoin.com/wp-content/uploads/2022/10/cx-760x428.jpg",
  },
  {
    title: "30-Day NFT Sales Are 88% Lower Than They Were 8 Months Ago, Google Searches Nosedived",
    link: "https://news.bitcoin.com/30-day-nft-sales-are-88-lower-than-they-were-8-months-ago-google-searches-nosedived/",
    author: "Jamie Redman",
    published: "10/09/2022",
    tag: "Markets and Prices",
    summary:
      "At the end of January 2022, non-fungible token (NFT) sales raked in $4.77 billion and NFT sales have slipped month after month since then. Last month, $549.82 million in NFT sales took place in September which shows monthly NFT sales are more than 88% lower than they were in January. Furthermore, after the search term ...",
    image: "https://static.news.bitcoin.com/wp-content/uploads/2022/10/nft-760x428.jpg",
  },
  {
    title:
      "2008 ‘Lehman Moment About to Hit’ — Major Banks Suffer; How to Access Your ETHW, ‘No Digital Dollar Act,’ Sega Blockchain Game — Bitcoin.com News Week in Review",
    link: "https://news.bitcoin.com/2008-lehman-moment-about-to-hit-major-banks-suffer-how-to-access-your-ethw-no-digital-dollar-act-sega-blockchain-game-bitcoin-com-news-week-in-review/",
    author: "Bitcoin.com",
    published: "10/09/2022",
    tag: "The Weekly",
    summary:
      "In this week's edition of the Bitcoin.com News Week in Review, two of the world's largest banks - Credit Suisse and Deutsche Bank - are suffering from distressed valuations, with credit default insurance levels looking like those last seen in the 2008 financial crisis. For those interested in assets outside of such behemoth tradfi (traditional ...",
    image:
      "https://static.news.bitcoin.com/wp-content/uploads/2022/10/shutterstock_2162503219-760x428.jpg",
  },
  {
    title: "Brazil Registers Record Number of Institutions Declaring Cryptocurrency Holdings",
    link: "https://news.bitcoin.com/brazil-registers-record-number-of-institutions-declaring-cryptocurrency-holdings/",
    author: "Sergio Goschenko",
    published: "10/09/2022",
    tag: "News",
    summary:
      "Brazil registered its highest number of companies and institutions declaring ownership of cryptocurrencies in August. The numbers, which come directly from the Brazilian Tax Authority (RFB), show that more and more institutions are eager to purchase cryptocurrency, and also indicate that the most significant volumes are moved using Tether's stablecoin usdt.  Institutions Are Holding ...",
    image:
      "https://static.news.bitcoin.com/wp-content/uploads/2022/10/shutterstock_2161214415-760x428.jpg",
  },
  {
    title:
      "Veteran Trader Peter Brandt Suggests All BTC Acquired in 2021 Is ‘a Losing Trade’ — Reignites Feud With Laser Eyes Movement",
    link: "https://news.bitcoin.com/veteran-trader-peter-brandt-suggests-all-btc-acquired-in-2021-is-a-losing-trade-reignites-feud-with-laser-eyes-movement/",
    author: "Terence Zimwara",
    published: "10/09/2022",
    tag: "News",
    summary:
      "Veteran trader Peter Brandt's latest remarks which mock crypto traders still using laser eyes crypto memes have sparked an immediate and angry response from bitcoiners on Twitter. One very famous bitcoiner, Michael Saylor, reminded Brandt that the laser eyes signify \"a long-term commitment to bitcoin based on its ethical, technical, and economic superiority to alternative ...",
    image:
      "https://static.news.bitcoin.com/wp-content/uploads/2022/10/shutterstock_748262341-760x428.jpg",
  },
  {
    title:
      "Salvadoran President Nayib Bukele Takes Aim at Bitcoin Detractors, Says the Ones Who Are Afraid ‘Are the World’s Powerful Elites’",
    link: "https://news.bitcoin.com/salvadoran-president-nayib-bukele-takes-aim-at-bitcoin-detractors-says-the-ones-who-are-afraid-are-the-worlds-powerful-elites/",
    author: "Jamie Redman",
    published: "10/09/2022",
    tag: "News",
    summary:
      "It's been over a year since El Salvador codified bitcoin as legal tender in the Latin American country, and by popping the 'orange pill,' the country was propelled into the international spotlight. At the end of September, the 41-year-old Salvadoran president Nayib Bukele penned an opinion editorial that takes aim at the detractors who think ...",
    image: "https://static.news.bitcoin.com/wp-content/uploads/2022/10/elsalv-760x428.jpg\n",
  },
];

const MOCK_DATA_TO_NOTE = MOCK_DATA.map((item, index) => {
  return {
    ...item,

    createdAt: item.published,
    tags: "",
    website: "bitcoin",
    isDemo: true,
    link: item.link,
    // emoji: [4, 3, 2, 0, 3, 4, 1, 0],
    emoji: [],
    id: 300 + index,
    author: {
      id: 300 + index,
      username: item.author,
      nickname: item.author,
      password: "",
      avatar: "",
      bio: "",
      tags: [item.tag],
    },
  };
});

interface Props {}

const NewsBitCoin: React.FC<Props> = (props: Props) => {
  return (
    <div className="waterfall-container sm:pt-4 pb-6">
      <WaterfallWithoutInfiniteScroll useV2 keyword="following" list={MOCK_DATA_TO_NOTE} />
    </div>
  );
};

export default NewsBitCoin;
