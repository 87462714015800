import NEWS_ICON_nytimes from "@/assets/news/nytimes.png";
import MovieIcon from "@/components/Icons/Movie";
import UserImg from "@/components/UserImg";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { IPostDetail } from "@/models";
import { Avatar } from "antd";
import dayjs from "dayjs";
import * as React from "react";
interface Props {
  detail: IPostDetail;
}

// 页面平滑滚动到元素
export const smoothScrollIntoView = (id: string) => {
  const target = document.getElementById(id);
  if (target && target.scrollIntoView) {
    target.scrollIntoView({
      behavior: "smooth",
    });
  }
};

const PostDetailAuthorInfo: React.FC<Props> = (props: Props) => {
  const author = props.detail.author;
  return (
    <div>
      <div className="flex items-center mt-2 mb-2">
        {props.detail.author.avatar ? (
          <div className="min-w-[36px] rounded-full overflow-hidden mr-2">
            <img className="w-9 h-9 object-cover" src={props.detail.author.avatar} alt="" />
          </div>
        ) : (
          <WebsiteLogo
            nickname={author.nickname || author.username}
            website={props.detail.website}
            type={props.detail.type}
          />
        )}
        <div className="mr-4 overflow-hidden">
          <div className="text-sm font-bold leading-[18px] text-slate-600 text-ellipsis whitespace-nowrap overflow-hidden">
            {author.nickname || author.username || author.email}
          </div>
          <div className="flex items-start mt-0.5">
            <div className="text-xs leading-[16px] text-slate-400 mr-2">
              {props.detail?.createdAt
                ? dayjs(props.detail?.createdAt).format("MMM DD, YYYY")
                : dayjs().format("MMM DD, YYYY")}
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className="text-xl font-extrabold mr-2 sm:m-0 break-word merriweather-font">
          {props.detail.title}
        </h3>
      </div>
    </div>
  );
};

export default PostDetailAuthorInfo;

export const WebsiteLogo = ({
  website,
  nickname,
  type,
}: {
  website: string;
  nickname: string;
  type?: string;
}) => {
  const [isLargerThan640] = useMediaQuery(["(min-width: 641px)"]);
  if (website === "nytimes") {
    return (
      <div className="min-w-[30px] w-[30px] h-[30px] sm:h-9 sm:w-9 overflow-hidden rounded-full mr-2">
        <div className="flex items-center justify-center sm:w-9 sm:h-9">
          <Avatar
            size={isLargerThan640 ? 36 : 30}
            shape="square"
            src={<img src={NEWS_ICON_nytimes} alt="bitcoin" />}
          />
        </div>
      </div>
    );
  }
  if (website === "movies") {
    return (
      <div className="min-w-[30px] flex items-center justify-center sm:w-11 sm:h-11 mr-2">
        <MovieIcon size={32} />
      </div>
    );
  }
  return (
    <div className="min-w-[30px] w-[30px] h-[30px] overflow-hidden rounded-full mr-2">
      <UserImg name={nickname} size={30} website={website} type={type} />
    </div>
  );
};
